import React from "react";

import FilterListingItemContent from "../FilterListingItemContent";
import FilterListingItemContentHeading from "../FilterListingItemContentHeading";
import FilterListingItemContentMeta from "../FilterListingItemContentMeta";

interface Props {
  metaItems: Array<string>;
  heading: React.ReactNode;
}
export default class FilterListingItemContentDefault extends React.PureComponent<
  Props,
  {}
> {
  render() {
    const { metaItems, heading } = this.props;

    return (
      <FilterListingItemContent
        clsNames={["filter-listing-item-content-default"]}
      >
        <FilterListingItemContentHeading>
          {heading}
        </FilterListingItemContentHeading>
        <FilterListingItemContentMeta metaItems={metaItems} />
      </FilterListingItemContent>
    );
  }
}
