import { TypeScriptUtils } from "../../utils";
import { DB_STRING_2014_CORE_RULES, DB_STRING_CARAPACE, DB_STRING_CORE_RULES, DB_STRING_INTEGRATED_PROTECTION, } from '../Core';
import { DataOriginTypeEnum } from '../DataOrigin';
import { HelperUtils } from '../Helper';
import { ModifierAccessors, ModifierDerivers, ModifierValidators } from '../Modifier';
import { RacialTraitAccessors } from '../RacialTrait';
import { RuleDataAccessors } from '../RuleData';
import { getArmorTypeId, getSources } from './accessors';
import { ArmorTypeEnum, ItemBaseTypeIdEnum } from './constants';
/**
 * TODO fix this dirty dirty hack for Integrated Protection
 * @param modifier
 * @param modifierData
 * @param hasLightArmorProficiency
 */
export function hack__getSetUnarmoredModifierValue(modifier, modifierData, hasLightArmorProficiency) {
    const dataOrigin = ModifierAccessors.getDataOrigin(modifier);
    const dataOriginType = ModifierAccessors.getDataOriginType(modifier);
    if (dataOriginType === DataOriginTypeEnum.RACE &&
        RacialTraitAccessors.getName(dataOrigin.primary) === DB_STRING_INTEGRATED_PROTECTION &&
        ModifierAccessors.getValue(modifier) === 1 &&
        !hasLightArmorProficiency) {
        return ModifierDerivers.deriveValue(modifier, modifierData, undefined, false);
    }
    return ModifierDerivers.deriveValue(modifier, modifierData);
}
/**
 * TODO fix this dirty dirty hack for Carapace
 * @param modifier
 * @param modifierData
 * @param isWearingHeavyArmor
 */
export function hack__deriveBonusArmorModifierValue(modifier, modifierData, isWearingHeavyArmor) {
    const dataOrigin = ModifierAccessors.getDataOrigin(modifier);
    const dataOriginType = ModifierAccessors.getDataOriginType(modifier);
    if (dataOriginType === DataOriginTypeEnum.RACE &&
        RacialTraitAccessors.getName(dataOrigin.primary) === DB_STRING_CARAPACE &&
        ModifierAccessors.getValue(modifier) === 1 &&
        isWearingHeavyArmor) {
        return 0;
    }
    return ModifierDerivers.deriveValue(modifier, modifierData);
}
// This is a very CLEAN hack, but should probably not be here
// If we have a global modifier turning off disadvantage on stealth, and it is
// medium armor master, we need to check if this is medium armor
// since we only remove disadvantage in that case for that modifier
/**
 *
 * @param item
 * @param modifiers
 * @returns {boolean}
 */
export function hack__deriveStealthMediumArmorMasterShouldReturnStealthDefinition(item, modifiers) {
    if (modifiers.length === 1) {
        if (ModifierValidators.isStealthDisadvantageRemoveModifierForMediumArmor(modifiers[0])) {
            const armorType = getArmorTypeId(item);
            if (armorType && armorType !== ArmorTypeEnum.MEDIUM_ARMOR) {
                return true;
            }
        }
    }
    return false;
}
//TODO: REMOVE THIS WHEN WE CAN SUPPORT MULTISOURCING
//This is a dirty hack to trick the Item Source Category filters
//If item is RPGArmor or RPGWeapon, AND sourceCategory is Core Rules include 2014 Core Rules category as secondary
export function hack__deriveSecondarySourceCategoryIds(item, ruleData) {
    var _a, _b, _c, _d;
    const categoryIds = [];
    const itemSources = getSources(item);
    const sources = itemSources
        .map((source) => HelperUtils.lookupDataOrFallback(RuleDataAccessors.getSourceDataLookup(ruleData), source.sourceId))
        .filter(TypeScriptUtils.isNotNullOrUndefined);
    //Only add 2014 Core Rules if item is RPGArmor or RPGWeapon and sourceCategory is Core Rules
    if ((((_a = item.definition) === null || _a === void 0 ? void 0 : _a.entityTypeId) === ItemBaseTypeIdEnum.WEAPON ||
        ((_b = item.definition) === null || _b === void 0 ? void 0 : _b.entityTypeId) === ItemBaseTypeIdEnum.ARMOR) &&
        ((_d = (_c = sources[0]) === null || _c === void 0 ? void 0 : _c.sourceCategory) === null || _d === void 0 ? void 0 : _d.name) === DB_STRING_CORE_RULES) {
        const sourceCategories = RuleDataAccessors.getSourceCategories(ruleData);
        //find the 2014 Core Rules sourceCategory and add it to the secondarySourceCategoryIds
        const hack__sourceCategory__2014 = sourceCategories.find((category) => category.name === DB_STRING_2014_CORE_RULES);
        if (hack__sourceCategory__2014) {
            categoryIds.push(hack__sourceCategory__2014.id);
        }
    }
    return categoryIds;
}
//TODO: REMOVE THIS WHEN WE CAN SUPPORT MULTISOURCING
export function hack__getSecondarySourceCategoryIds(item) {
    return item.hack__secondarySourceCategoryIds;
}
