import React from "react";

import { Tooltip } from "@dndbeyond/character-common-components/es";
import { NoteComponents } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  Constants,
  ExtraManager,
} from "@dndbeyond/character-rules-engine/es";

import { NumberDisplay } from "~/components/NumberDisplay";

import ExtraName from "../ExtraName";

interface Props {
  extra: ExtraManager;
  onShow?: (extra: ExtraManager) => void;
  onStatusChange?: (extra: ExtraManager, newStatus: boolean) => void;
  showNotes: boolean;
  isReadonly: boolean;
  theme: CharacterTheme;
}
export default class ExtraRow extends React.PureComponent<Props, {}> {
  static defaultProps = {
    isReadonly: false,
  };

  handleClick = (evt: React.MouseEvent): void => {
    const { onShow, extra } = this.props;

    if (onShow) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
      onShow(extra);
    }
  };

  handleActivate = (): void => {
    const { onStatusChange, extra } = this.props;

    if (onStatusChange) {
      onStatusChange(extra, true);
    }
  };

  handleDeactivate = (): void => {
    const { onStatusChange, extra } = this.props;

    if (onStatusChange) {
      onStatusChange(extra, false);
    }
  };

  renderMetaItems = (): React.ReactNode => {
    const { extra, theme } = this.props;

    let metaItems: Array<string> = [];

    const sizeInfo = extra.getSizeInfo();
    let size: string = "";
    if (sizeInfo !== null && sizeInfo.name !== null) {
      size = sizeInfo.name;
    }

    let type = extra.getType();
    let typeName: string = "";
    if (type) {
      typeName = type;
    }

    let description: string = `${size} ${typeName.toLowerCase()}`.trim();
    if (description) {
      metaItems.push(description);
    }

    return (
      <div
        className={`ct-extra-row__meta ${
          theme.isDarkMode ? "ct-extra-row__meta--dark-mode" : ""
        }`}
      >
        {metaItems.map((metaItem, idx) => (
          <span key={idx} className="ct-extra-row__meta-item">
            {metaItem}
          </span>
        ))}
      </div>
    );
  };

  renderHitPoints = (): React.ReactNode => {
    const { extra, theme } = this.props;

    const hitPointInfo = extra.getHitPointInfo();
    const { data, showTooltip } = hitPointInfo;

    let contentNode: React.ReactNode = null;
    if (data !== null) {
      let totalHp: number = data.totalHp ? data.totalHp : 0;
      let remainingHp: number = data.remainingHp ? data.remainingHp : 0;
      let tempHp: number = data.tempHp ? data.tempHp : 0;

      const current: number = remainingHp + tempHp;
      const total: number = totalHp + tempHp;

      contentNode = (
        <React.Fragment>
          <span className="ct-extra-row__hp-value ct-extra-row__hp-value--current">
            {current}
          </span>
          <span className="ct-extra-row__hp-sep">/</span>
          <span className="ct-extra-row__hp-value ct-extra-row__hp-value--total">
            {total}
          </span>
        </React.Fragment>
      );
    } else {
      contentNode = (
        <span className="ct-extra-row__hp-value ct-extra-row__hp-value--current">
          --
        </span>
      );
    }

    let classNames: Array<string> = ["ct-extra-row__hp"];
    if (data !== null && data.tempHp !== null && data.tempHp > 0) {
      classNames.push("ct-extra-row__hp--has-temp");
    }

    if (theme.isDarkMode) {
      classNames.push("ct-extra-row__hp--dark-mode");
    }
    return (
      <div className={classNames.join(" ")}>
        {contentNode}
        {showTooltip && this.renderAdditionalInfoTooltip()}
      </div>
    );
  };

  renderSpeed = (): React.ReactNode => {
    const { extra, theme } = this.props;

    const movementInfo = extra.getMovementInfo();

    const { data, label, showTooltip } = movementInfo;

    let contentNode: React.ReactNode = null;
    if (data !== null) {
      contentNode = (
        <React.Fragment>
          <div className="ct-extra-row__speed-value">
            <NumberDisplay type="distanceInFt" number={data.speed} />
            {showTooltip && this.renderAdditionalInfoTooltip()}
          </div>
          {data.movementId !== Constants.MovementTypeEnum.WALK && (
            <div className="ct-extra-row__speed-callout">{label}</div>
          )}
        </React.Fragment>
      );
    } else {
      contentNode = (
        <div className="ct-extra-row__speed-value">
          --
          {showTooltip && this.renderAdditionalInfoTooltip()}
        </div>
      );
    }

    return (
      <div
        className={`ct-extra-row__speed ${
          theme.isDarkMode ? "ct-extra-row__speed--dark-mode" : ""
        }`}
      >
        {contentNode}
      </div>
    );
  };

  renderArmorClass = (): React.ReactNode => {
    const { extra } = this.props;

    let armorClassInfo = extra.getArmorClassInfo();

    let armorClassText: string | number = "--";
    if (armorClassInfo.value !== null) {
      armorClassText = armorClassInfo.value;
    }

    return (
      <React.Fragment>
        {armorClassText}
        {armorClassInfo.showTooltip && this.renderAdditionalInfoTooltip()}
      </React.Fragment>
    );
  };

  renderAdditionalInfoTooltip = (): React.ReactNode => {
    const { theme } = this.props;
    return (
      <Tooltip
        isDarkMode={theme.isDarkMode}
        title={"View the full description for additional info"}
        className={`ct-extra-row__tooltip ${
          theme.isDarkMode ? "ct-extra-row__tooltip--dark-mode" : ""
        }`}
      >
        *
      </Tooltip>
    );
  };

  render() {
    const { extra, isReadonly, showNotes, theme } = this.props;

    let avatarUrl = extra.getAvatarUrl();
    if (!avatarUrl) {
      avatarUrl = "";
    }
    const name = extra.getName();

    return (
      <div className="ct-extra-row" onClick={this.handleClick}>
        <div className="ct-extra-row__preview">
          {avatarUrl && (
            <img
              className="ct-extra-row__img"
              src={avatarUrl}
              alt={`${name} preview`}
            />
          )}
        </div>
        <div className="ct-extra-row__primary">
          <div
            className={`ct-extra-row__name ${
              theme.isDarkMode ? "ct-extra-row__name--dark-mode" : ""
            }`}
          >
            <ExtraName theme={theme} extra={extra} />
          </div>
          {this.renderMetaItems()}
        </div>
        <div
          className={`ct-extra-row__ac ${
            theme.isDarkMode ? "ct-extra-row__ac--dark-mode" : ""
          }`}
        >
          {this.renderArmorClass()}
        </div>
        {this.renderHitPoints()}
        {this.renderSpeed()}
        {showNotes && (
          <div className="ct-extra-row__notes">
            <NoteComponents theme={theme} notes={extra.getNoteComponents()} />
          </div>
        )}
        {/*<div className="ct-extra-row__action">
                    <SlotManager
                        used={isActive ? 1 : 0}
                        available={1}
                        size="small"
                        onUse={this.handleActivate}
                        onClear={this.handleDeactivate}
                        isInteractive={!isReadonly}
                    />
                </div>*/}
      </div>
    );
  }
}
