import React from "react";

export default class FilterListingItemContentHeading extends React.PureComponent<
  {},
  {}
> {
  render() {
    const { children } = this.props;

    return <div className="filter-listing-item-heading">{children}</div>;
  }
}
