import React from "react";
import { connect, DispatchProp } from "react-redux";

import { Select, XpBar } from "@dndbeyond/character-components/es";
import {
  rulesEngineSelectors,
  characterActions,
  CharacterUtils,
  ExperienceInfo,
  FormatUtils,
  HelperUtils,
  HtmlSelectOption,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import { toastMessageActions } from "../../../actions/toastMessage";
import { ThemeButton } from "../../../components/common/Button";
import { SharedAppState } from "../../../stores/typings";

const XP_CHANGE_TYPE = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

interface Props extends DispatchProp {
  xpInfo: ExperienceInfo;
  ruleData: RuleData;
}
interface State {
  xpNew: number | null;
  xpChange: number | null;
  levelChosenNew: number | null;
  changeType: string;
  isDirty: boolean;
}
class XpPane extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      xpNew: null,
      xpChange: null,
      levelChosenNew: null,
      changeType: XP_CHANGE_TYPE.ADD,
      isDirty: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { xpInfo } = this.props;

    if (xpInfo !== prevProps.xpInfo) {
      this.setState({
        xpNew: null,
        xpChange: null,
        levelChosenNew: null,
        isDirty: false,
      });
    }
  }

  reset = (): void => {
    this.setState({
      xpNew: null,
      xpChange: null,
      levelChosenNew: null,
      changeType: XP_CHANGE_TYPE.ADD,
      isDirty: false,
    });
  };

  getNewXpTotal = (): number => {
    const { xpNew, xpChange, changeType, levelChosenNew } = this.state;
    const { xpInfo, ruleData } = this.props;

    let xpDiff: number = xpChange === null ? 0 : xpChange;
    if (changeType === XP_CHANGE_TYPE.REMOVE) {
      xpDiff *= -1;
    }

    let newXpTotal: number = xpInfo.currentLevelXp;
    if (xpDiff) {
      newXpTotal = xpInfo.currentLevelXp + xpDiff;
    } else if (levelChosenNew !== null) {
      newXpTotal = CharacterUtils.deriveCurrentLevelXp(
        levelChosenNew,
        ruleData
      );
    } else if (xpNew !== null) {
      newXpTotal = xpNew;
    }

    newXpTotal = Math.min(
      Math.max(0, newXpTotal),
      CharacterUtils.deriveMaxXp(ruleData)
    );

    return newXpTotal;
  };

  handleReset = (): void => {
    this.reset();
  };

  handleSave = (): void => {
    const { dispatch, xpInfo } = this.props;

    const newXpTotal = this.getNewXpTotal();

    if (newXpTotal !== xpInfo.currentLevelXp) {
      dispatch(characterActions.xpSet(newXpTotal));
      dispatch(
        toastMessageActions.toastSuccess(
          "Experience Points Updated",
          "You have successfully updated your XP"
        )
      );
    }
  };

  handleXpSet = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      xpNew: HelperUtils.parseInputInt(evt.target.value),
      xpChange: null,
      levelChosenNew: null,
      isDirty: true,
    });
  };

  handleXpChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      xpNew: null,
      xpChange: HelperUtils.parseInputInt(evt.target.value),
      levelChosenNew: null,
      isDirty: true,
    });
  };

  handleChooseLevel = (value: string): void => {
    this.setState({
      xpNew: null,
      xpChange: null,
      levelChosenNew: HelperUtils.parseInputInt(value),
      isDirty: true,
    });
  };

  handleXpChangeTypeSet = (type: string): void => {
    this.setState({
      changeType: type,
      isDirty: true,
    });
  };

  render() {
    const { xpNew, xpChange, changeType, levelChosenNew, isDirty } = this.state;
    const { ruleData, xpInfo } = this.props;
    const {
      currentLevel,
      currentLevelXp,
      currentLevelStartingXp,
      nextLevelXp,
    } = xpInfo;

    const newXpTotal = this.getNewXpTotal();

    let changeAddCls: Array<string> = ["ct-xp-pane__change-type"];
    let changeRemoveCls: Array<string> = ["ct-xp-pane__change-type"];
    if (changeType === XP_CHANGE_TYPE.REMOVE) {
      changeRemoveCls.push("ct-xp-pane__change-type--active");
    } else {
      changeAddCls.push("ct-xp-pane__change-type--active");
    }

    let levelOptions: Array<HtmlSelectOption> = [];
    for (let i = 1; i <= RuleDataUtils.getMaxCharacterLevel(ruleData); i++) {
      levelOptions.push({
        label: `${i}`,
        value: i,
      });
    }

    let displayCurrentLevelXp: number = currentLevelStartingXp;
    if (currentLevel === ruleData.maxCharacterLevel) {
      displayCurrentLevelXp = CharacterUtils.deriveCurrentLevelXp(
        RuleDataUtils.getMaxCharacterLevel(ruleData) - 1,
        ruleData
      );
    }

    return (
      <div className="ct-xp-pane">
        <Header>Manage XP</Header>
        <div className="ct-xp-pane__main">
          <div className="ct-xp-pane__level">
            Current XP Total: {FormatUtils.renderLocaleNumber(currentLevelXp)}{" "}
            (Level {currentLevel})
          </div>
          <div className="ct-xp-pane__bar">
            <XpBar
              xp={currentLevelXp}
              showCurrentMarker={true}
              ruleData={ruleData}
            />
          </div>
          <div className="ct-xp-pane__level-amounts">
            <div className="ct-xp-pane__level-amounts-value">
              {FormatUtils.renderLocaleNumber(displayCurrentLevelXp)}
            </div>
            <div className="ct-xp-pane__level-amounts-value">
              {FormatUtils.renderLocaleNumber(nextLevelXp)}
            </div>
          </div>
        </div>
        <div className="ct-xp-pane__adjust">
          <div className="ct-xp-pane__choose">
            <div className="ct-xp-pane__choose-label">Set Level</div>
            <div className="ct-xp-pane__choose-value">
              <Select
                options={levelOptions}
                onChange={this.handleChooseLevel}
                value={levelChosenNew}
                placeholder={"--"}
              />
            </div>
          </div>
          <div className="ct-xp-pane__current">
            <div className="ct-xp-pane__current-label">Set XP</div>
            <div className="ct-xp-pane__current-value">
              <input
                className="character-input ct-xp-pane__current-input"
                type="number"
                value={xpNew === null ? "" : xpNew}
                onChange={this.handleXpSet}
              />
            </div>
          </div>
          <div className="ct-xp-pane__change">
            <div className="ct-xp-pane__change-types">
              <div
                className={changeAddCls.join(" ")}
                onClick={this.handleXpChangeTypeSet.bind(
                  this,
                  XP_CHANGE_TYPE.ADD
                )}
              >
                Add Xp
              </div>
              <div
                className={changeRemoveCls.join(" ")}
                onClick={this.handleXpChangeTypeSet.bind(
                  this,
                  XP_CHANGE_TYPE.REMOVE
                )}
              >
                Remove Xp
              </div>
            </div>
            <div className="ct-xp-pane__change-value">
              <input
                className="character-input-block-oversized ct-xp-pane__change-input"
                type="number"
                value={xpChange === null ? "" : xpChange}
                onChange={this.handleXpChange}
                placeholder="Type XP Value"
              />
            </div>
          </div>
        </div>
        <div className="ct-xp-pane__total">
          New XP Total: {FormatUtils.renderLocaleNumber(newXpTotal)} (Level{" "}
          {CharacterUtils.deriveXpLevel(newXpTotal, ruleData)})
        </div>
        {isDirty && (
          <div className="ct-xp-pane__actions">
            <div className="ct-xp-pane__action">
              <ThemeButton onClick={this.handleSave}>Apply Changes</ThemeButton>
            </div>
            <div className="ct-xp-pane__action">
              <ThemeButton onClick={this.handleReset} style="outline">
                Cancel
              </ThemeButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    xpInfo: rulesEngineSelectors.getExperienceInfo(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
  };
}

export default connect(mapStateToProps)(XpPane);
