import { FC, HTMLAttributes } from "react";
import { v4 as uuidv4 } from "uuid";

import { Accordion } from "~/components/Accordion";
import { HtmlContent } from "~/components/HtmlContent";
import { DdbBadgeSvg } from "~/tools/js/smartComponents/Svg";

export interface ConfirmSpeciesContentProps
  extends HTMLAttributes<HTMLDivElement> {
  heading: string;
  helpItems: { label: string; description: string }[];
  speciesTraits: { name: string; description: string | null }[];
}

export const ConfirmSpeciesContent: FC<ConfirmSpeciesContentProps> = ({
  heading,
  helpItems,
  speciesTraits,
  ...props
}) => {
  return (
    <div {...props}>
      <h3>{heading}</h3>
      {helpItems.map((helperText, idx) => (
        <Accordion
          key={`${helperText.label}-${idx}`}
          summary={
            <>
              {" "}
              <DdbBadgeSvg /> {helperText.label}
            </>
          }
        >
          <HtmlContent html={helperText.description} />
        </Accordion>
      ))}
      {speciesTraits.length > 0 && (
        <div>
          {speciesTraits.map(({ name, description }) => {
            return (
              <Accordion
                summary={name}
                key={uuidv4()}
                variant="paper"
                forceShow
              >
                <HtmlContent html={description || ""} />
              </Accordion>
            );
          })}
        </div>
      )}
    </div>
  );
};
