import { visuallyHidden } from "@mui/utils";
import React, { useContext } from "react";
import { connect, DispatchProp } from "react-redux";

import {
  TabOptions,
  TabOptionsContent,
  TabOptionsGroup,
} from "@dndbeyond/character-common-components/es";
import {
  AbilityLookup,
  Action,
  ActionUtils,
  Activatable,
  Attack,
  AttacksPerActionInfo,
  BaseInventoryContract,
  BasicActionContract,
  characterActions,
  CharacterTheme,
  CharClass,
  ClassFeature,
  ClassUtils,
  Constants,
  DataOriginRefData,
  Feat,
  FeatureUtils,
  FeatUtils,
  Hack__BaseCharClass,
  InventoryLookup,
  InventoryManager,
  Item,
  ItemManager,
  ItemUtils,
  RacialTrait,
  RacialTraitUtils,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
  SnippetData,
  SourceData,
  Spell,
  SpellCasterInfo,
  SpellUtils,
  WeaponSpellDamageGroup,
} from "@dndbeyond/character-rules-engine/es";
import { IRollContext } from "@dndbeyond/dice";

import { Link } from "~/components/Link";

import { sidebarActions } from "../../../Shared/actions";
import { InventoryManagerContext } from "../../../Shared/managers/InventoryManagerContext";
import {
  appEnvSelectors,
  characterRollContextSelectors,
} from "../../../Shared/selectors";
import { SharedAppState } from "../../../Shared/stores/typings";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../Shared/utils";
import { PaneIdentifiers } from "../../../Shared/utils/PaneIdentifier/typings";
import ActionsList from "../../components/ActionsList";

enum TabKeyEnum {
  ALL = "ALL",
  ACTIONS = "ACTIONS",
  BONUS_ACTIONS = "BONUS_ACTIONS",
  REACTIONS = "REACTIONS",
  OTHER = "OTHER",
  ATTACKS = "ATTACKS",
  LIMITED_USE = "LIMITED_USE",
}

/**
 * @deprecated use ActionsManager.ActivationGroupKeyEnum
 */
enum ActivationGroupKeyEnum {
  ACTIONS = "ACTIONS",
  BONUS_ACTIONS = "BONUS_ACTIONS",
  REACTIONS = "REACTIONS",
  OTHER = "OTHER",
}

type AttackGroups = Record<string, Array<Attack>>;

export interface ActionHandlers {
  onBasicActionClick: (basicAction: BasicActionContract) => void;
  onActionClick: (action: Action) => void;
  onActionUseSet: (action: Action, used: number) => void;
  onSpellClick: (spell: Spell) => void;
  onSpellUseSet: (spell: Spell, used: number) => void;
  onClassFeatureClick: (feature: ClassFeature, charClass: CharClass) => void;
  onFeatClick: (feat: Feat) => void;
  onRacialTraitClick: (racialTrait: RacialTrait) => void;
  onAttackClick: (attack: Attack) => void;
}

interface ActionData {
  weaponSpellDamageGroups: Array<WeaponSpellDamageGroup>;
  sourceDataLookup: Record<number, SourceData>;
  snippetData: SnippetData;
  spellCasterInfo: SpellCasterInfo;
  showNotes: boolean;
  abilityLookup: AbilityLookup;
  ruleData: RuleData;
  inventoryLookup: InventoryLookup;
  isInteractive: boolean;
  diceEnabled: boolean;
  theme: CharacterTheme;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
  rollContext: IRollContext;
}

interface Props extends DispatchProp {
  showNotes: boolean;

  activatables: Array<Activatable>;
  attacks: Array<Attack>;
  weaponSpellDamageGroups: Array<WeaponSpellDamageGroup>;
  attacksPerActionInfo: AttacksPerActionInfo;
  ritualSpells: Array<Spell>;
  abilityLookup: AbilityLookup;
  inventoryLookup: InventoryLookup;
  ruleData: RuleData;
  snippetData: SnippetData;
  spellCasterInfo: SpellCasterInfo;
  isReadonly: boolean;
  diceEnabled: boolean;
  theme: CharacterTheme;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
  characterRollContext: IRollContext;
  inventoryManager: InventoryManager;
}
interface State {
  attackGroups: AttackGroups;
}
class Actions extends React.PureComponent<Props, State> {
  static defaultProps = {
    showNotes: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.generateStateData(props);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { attacks } = this.props;

    if (attacks !== prevProps.attacks) {
      this.setState(this.generateStateData(this.props));
    }
  }

  generateStateData = (props: Props): State => {
    const { attacks } = props;

    let attackGroups: AttackGroups = {};
    attackGroups[ActivationGroupKeyEnum.ACTIONS] = attacks.filter(
      (attack) => attack.activation === Constants.ActivationTypeEnum.ACTION
    );
    attackGroups[ActivationGroupKeyEnum.BONUS_ACTIONS] = attacks.filter(
      (attack) =>
        attack.activation === Constants.ActivationTypeEnum.BONUS_ACTION
    );
    attackGroups[ActivationGroupKeyEnum.REACTIONS] = attacks.filter(
      (attack) => attack.activation === Constants.ActivationTypeEnum.REACTION
    );
    attackGroups[ActivationGroupKeyEnum.OTHER] = attacks.filter(
      (attack) =>
        attack.activation !== Constants.ActivationTypeEnum.ACTION &&
        attack.activation !== Constants.ActivationTypeEnum.BONUS_ACTION &&
        attack.activation !== Constants.ActivationTypeEnum.REACTION
    );

    return {
      attackGroups,
    };
  };

  getHandlers = (): ActionHandlers => {
    return {
      onBasicActionClick: this.handleBasicActionShow,
      onActionClick: this.handleActionShow,
      onActionUseSet: this.handleActionUseSet,
      onSpellClick: this.handleSpellDetailShow,
      onSpellUseSet: this.handleSpellUseSet,
      onClassFeatureClick: this.handleClassFeatureShow,
      onFeatClick: this.handleFeatShow,
      onRacialTraitClick: this.handleRacialTraitShow,
      onAttackClick: this.handleAttackClick,
    };
  };

  getActionData = (): ActionData => {
    const {
      weaponSpellDamageGroups,
      ruleData,
      abilityLookup,
      inventoryLookup,
      spellCasterInfo,
      snippetData,
      showNotes,
      isReadonly,
      diceEnabled,
      theme,
      dataOriginRefData,
      proficiencyBonus,
      characterRollContext,
    } = this.props;

    return {
      weaponSpellDamageGroups,
      abilityLookup,
      inventoryLookup,
      ruleData,
      sourceDataLookup: RuleDataUtils.getSourceDataLookup(ruleData),
      snippetData,
      spellCasterInfo,
      showNotes,
      isInteractive: !isReadonly,
      diceEnabled,
      theme,
      dataOriginRefData,
      proficiencyBonus,
      rollContext: characterRollContext,
    };
  };

  handleActionUseSet = (action: Action, uses: number): void => {
    const { dispatch, inventoryManager } = this.props;

    const dataOrigin = ActionUtils.getDataOrigin(action);
    const dataOriginType = ActionUtils.getDataOriginType(action);

    if (dataOriginType === Constants.DataOriginTypeEnum.ITEM) {
      const itemData = dataOrigin.primary as BaseInventoryContract;
      const item = ItemManager.getItem(ItemUtils.getMappingId(itemData));
      item.handleItemLimitedUseSet(uses);
    } else {
      const id = ActionUtils.getId(action);
      const entityTypeId = ActionUtils.getEntityTypeId(action);
      if (id !== null && entityTypeId !== null) {
        dispatch(
          characterActions.actionUseSet(id, entityTypeId, uses, dataOriginType)
        );
      }
    }
  };

  handleSpellUseSet = (spell: Spell, uses: number): void => {
    const { dispatch } = this.props;

    const mappingId = SpellUtils.getMappingId(spell);
    const mappingEntityTypeId = SpellUtils.getMappingEntityTypeId(spell);
    const dataOriginType = SpellUtils.getDataOriginType(spell);

    if (mappingId && mappingEntityTypeId) {
      dispatch(
        characterActions.spellUseSet(
          mappingId,
          mappingEntityTypeId,
          uses,
          dataOriginType
        )
      );
    }
  };

  handleSpellDetailShow = (spell: Spell): void => {
    const { dispatch } = this.props;

    let spellMappingId = SpellUtils.getMappingId(spell);
    const dataOriginType = SpellUtils.getDataOriginType(spell);
    const dataOrigin = SpellUtils.getDataOrigin(spell);

    if (spellMappingId !== null) {
      let paneComponent: PaneComponentEnum;
      let identifiers: PaneIdentifiers;
      if (dataOriginType === Constants.DataOriginTypeEnum.CLASS) {
        paneComponent = PaneComponentEnum.CLASS_SPELL_DETAIL;
        identifiers = PaneIdentifierUtils.generateClassSpell(
          ClassUtils.getMappingId(dataOrigin.primary as Hack__BaseCharClass),
          spellMappingId
        );
      } else {
        paneComponent = PaneComponentEnum.CHARACTER_SPELL_DETAIL;
        identifiers =
          PaneIdentifierUtils.generateCharacterSpell(spellMappingId);
      }
      dispatch(sidebarActions.paneHistoryStart(paneComponent, identifiers));
    }
  };

  handleClassFeatureShow = (
    feature: ClassFeature,
    charClass: CharClass
  ): void => {
    const { dispatch } = this.props;
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.CLASS_FEATURE_DETAIL,
        PaneIdentifierUtils.generateClassFeature(
          FeatureUtils.getId(feature),
          ClassUtils.getMappingId(charClass)
        )
      )
    );
  };

  handleRacialTraitShow = (racialTrait: RacialTrait): void => {
    const { dispatch } = this.props;
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.SPECIES_TRAIT_DETAIL,
        PaneIdentifierUtils.generateRacialTrait(
          RacialTraitUtils.getId(racialTrait)
        )
      )
    );
  };

  handleFeatShow = (feat: Feat): void => {
    const { dispatch } = this.props;
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.FEAT_DETAIL,
        PaneIdentifierUtils.generateFeat(FeatUtils.getId(feat))
      )
    );
  };

  handleActionShow = (action: Action): void => {
    const { dispatch } = this.props;

    const id = ActionUtils.getMappingId(action);
    const entityTypeId = ActionUtils.getMappingEntityTypeId(action);
    if (id === null) {
      return;
    }

    let dataOriginType = ActionUtils.getDataOriginType(action);

    let paneComponentType: PaneComponentEnum = PaneComponentEnum.ACTION;
    let paneComponentIdentifiers: PaneIdentifiers =
      PaneIdentifierUtils.generateAction(id, entityTypeId);

    if (dataOriginType === Constants.DataOriginTypeEnum.CUSTOM) {
      paneComponentType = PaneComponentEnum.CUSTOM_ACTION;
      paneComponentIdentifiers = PaneIdentifierUtils.generateCustomAction(id);
    }

    dispatch(
      sidebarActions.paneHistoryStart(
        paneComponentType,
        paneComponentIdentifiers
      )
    );
  };

  handleManageCustomActionsShow = (): void => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.CUSTOM_ACTIONS));
  };

  handleBasicActionShow = (basicAction: BasicActionContract): void => {
    const { dispatch } = this.props;
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.BASIC_ACTION,
        PaneIdentifierUtils.generateBasicAction(basicAction.id)
      )
    );
  };

  handleAttackClick = (attack: Attack): void => {
    const { dispatch } = this.props;

    let paneComponentType: PaneComponentEnum | null = null;
    let paneComponentIdentifiers: PaneIdentifiers | null = null;

    switch (attack.type) {
      case Constants.AttackSourceTypeEnum.ACTION: {
        const action = attack.data as Action;
        const mappingId = ActionUtils.getMappingId(action);
        const mappingEntityTypeId = ActionUtils.getMappingEntityTypeId(action);

        if (mappingId !== null && mappingEntityTypeId !== null) {
          paneComponentType = PaneComponentEnum.ACTION;
          paneComponentIdentifiers = PaneIdentifierUtils.generateAction(
            mappingId,
            mappingEntityTypeId
          );
        }
        break;
      }
      case Constants.AttackSourceTypeEnum.CUSTOM: {
        const action = attack.data as Action;
        const mappingId = ActionUtils.getMappingId(action);
        const mappingEntityTypeId = ActionUtils.getMappingEntityTypeId(action);

        if (mappingId !== null && mappingEntityTypeId !== null) {
          paneComponentType = PaneComponentEnum.CUSTOM_ACTION;
          paneComponentIdentifiers = PaneIdentifierUtils.generateAction(
            mappingId,
            mappingEntityTypeId
          );
        }
        break;
      }
      case Constants.AttackSourceTypeEnum.ITEM: {
        paneComponentType = PaneComponentEnum.ITEM_DETAIL;
        paneComponentIdentifiers = PaneIdentifierUtils.generateItem(
          ItemUtils.getMappingId(attack.data as Item)
        );
        break;
      }
      case Constants.AttackSourceTypeEnum.SPELL: {
        const spell: Spell = attack.data as Spell;
        const mappingId = SpellUtils.getMappingId(spell);
        const dataOriginType = SpellUtils.getDataOriginType(spell);
        const dataOrigin = SpellUtils.getDataOrigin(spell);

        if (mappingId !== null) {
          if (dataOriginType === Constants.DataOriginTypeEnum.CLASS) {
            paneComponentType = PaneComponentEnum.CLASS_SPELL_DETAIL;
            paneComponentIdentifiers = PaneIdentifierUtils.generateClassSpell(
              ClassUtils.getMappingId(
                dataOrigin.primary as Hack__BaseCharClass
              ),
              mappingId
            );
          } else {
            paneComponentType = PaneComponentEnum.CHARACTER_SPELL_DETAIL;
            paneComponentIdentifiers =
              PaneIdentifierUtils.generateCharacterSpell(mappingId);
          }
        }
        break;
      }
      default:
      // not implemented
    }

    if (paneComponentType !== null) {
      dispatch(
        sidebarActions.paneHistoryStart(
          paneComponentType,
          paneComponentIdentifiers
        )
      );
    }
  };

  renderManageCustomLink = (): React.ReactNode => {
    const { isReadonly } = this.props;

    if (isReadonly) {
      return null;
    }

    return (
      <Link
        useTheme={true}
        className="ct-actions__manage-custom-link"
        onClick={this.handleManageCustomActionsShow}
      >
        Manage Custom
      </Link>
    );
  };

  renderAttacksTab = (): React.ReactNode => {
    const { attackGroups } = this.state;

    if (
      !attackGroups[ActivationGroupKeyEnum.ACTIONS].length &&
      !attackGroups[ActivationGroupKeyEnum.BONUS_ACTIONS].length &&
      !attackGroups[ActivationGroupKeyEnum.REACTIONS].length &&
      !attackGroups[ActivationGroupKeyEnum.OTHER].length
    ) {
      return null;
    }

    let onHandlers = this.getHandlers();
    let actionData = this.getActionData();

    return (
      <TabOptionsGroup tabKey={TabKeyEnum.ATTACKS} label="Attack">
        <TabOptionsContent>
          {this.renderManageCustomLink()}
          <ActionsList
            heading={this.renderActionsHeader()}
            activationType={Constants.ActivationTypeEnum.ACTION}
            attacks={attackGroups[ActivationGroupKeyEnum.ACTIONS]}
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Bonus Actions"
            activationType={Constants.ActivationTypeEnum.BONUS_ACTION}
            attacks={attackGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]}
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Reactions"
            activationType={Constants.ActivationTypeEnum.REACTION}
            attacks={attackGroups[ActivationGroupKeyEnum.REACTIONS]}
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Other"
            attacks={attackGroups[ActivationGroupKeyEnum.OTHER]}
            {...onHandlers}
            {...actionData}
          />
        </TabOptionsContent>
      </TabOptionsGroup>
    );
  };

  renderLimitedUseTab = (): React.ReactNode => {
    const { activatables, ritualSpells } = this.props;

    let limitedUseActionGroups: Record<string, Array<Activatable>> = {};
    limitedUseActionGroups[ActivationGroupKeyEnum.ACTIONS] =
      activatables.filter(
        (a) =>
          a.activation.activationType === Constants.ActivationTypeEnum.ACTION &&
          a.limitedUse
      );
    limitedUseActionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS] =
      activatables.filter(
        (a) =>
          a.activation.activationType ===
            Constants.ActivationTypeEnum.BONUS_ACTION && a.limitedUse
      );
    limitedUseActionGroups[ActivationGroupKeyEnum.REACTIONS] =
      activatables.filter(
        (a) =>
          a.activation.activationType ===
            Constants.ActivationTypeEnum.REACTION && a.limitedUse
      );
    limitedUseActionGroups[ActivationGroupKeyEnum.OTHER] = activatables.filter(
      (a) =>
        a.activation.activationType !== Constants.ActivationTypeEnum.ACTION &&
        a.activation.activationType !==
          Constants.ActivationTypeEnum.BONUS_ACTION &&
        a.activation.activationType !== Constants.ActivationTypeEnum.REACTION &&
        a.limitedUse
    );

    let limitedUseRitualSpells = ritualSpells.filter((spell) =>
      SpellUtils.getLimitedUse(spell)
    );

    if (
      !limitedUseActionGroups[ActivationGroupKeyEnum.ACTIONS].length &&
      !limitedUseActionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS].length &&
      !limitedUseActionGroups[ActivationGroupKeyEnum.REACTIONS].length &&
      !limitedUseActionGroups[ActivationGroupKeyEnum.OTHER].length &&
      !limitedUseRitualSpells.length
    ) {
      return null;
    }

    let onHandlers = this.getHandlers();
    let actionData = this.getActionData();

    return (
      <TabOptionsGroup tabKey={TabKeyEnum.LIMITED_USE} label="Limited Use">
        <TabOptionsContent>
          {this.renderManageCustomLink()}
          <ActionsList
            heading="Actions"
            activationType={Constants.ActivationTypeEnum.ACTION}
            actions={limitedUseActionGroups[ActivationGroupKeyEnum.ACTIONS]}
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Bonus Actions"
            activationType={Constants.ActivationTypeEnum.BONUS_ACTION}
            actions={
              limitedUseActionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]
            }
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Reactions"
            activationType={Constants.ActivationTypeEnum.REACTION}
            actions={limitedUseActionGroups[ActivationGroupKeyEnum.REACTIONS]}
            {...onHandlers}
            {...actionData}
          />
          <ActionsList
            heading="Other"
            actions={limitedUseActionGroups[ActivationGroupKeyEnum.OTHER]}
            ritualSpells={limitedUseRitualSpells}
            showActivationInfo={true}
            {...onHandlers}
            {...actionData}
          />
        </TabOptionsContent>
      </TabOptionsGroup>
    );
  };

  renderActionsHeader = (): React.ReactNode => {
    const { attacksPerActionInfo, theme } = this.props;

    return (
      <React.Fragment>
        <div className="ct-actions__attacks-heading">
          Actions &bull;{" "}
          <span
            className={`ct-actions__attacks-per-action ${
              theme?.isDarkMode
                ? "ct-actions__attacks-per-action--dark-mode"
                : ""
            }`}
          >
            Attacks per Action: {attacksPerActionInfo.value}
          </span>
        </div>
        {attacksPerActionInfo.restriction && (
          <div className="ct-actions__attacks-restriction">
            {attacksPerActionInfo.restriction}
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { attackGroups } = this.state;
    const { activatables, ritualSpells, ruleData } = this.props;

    let actionGroups: Record<string, Array<Activatable>> = {};
    actionGroups[ActivationGroupKeyEnum.ACTIONS] = activatables.filter(
      (a) =>
        a.activation.activationType === Constants.ActivationTypeEnum.ACTION &&
        a.type !== Constants.ActivatableTypeEnum.CLASS_SPELL &&
        a.type !== Constants.ActivatableTypeEnum.CHARACTER_SPELL
    );
    actionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS] = activatables.filter(
      (a) =>
        a.activation.activationType ===
        Constants.ActivationTypeEnum.BONUS_ACTION
    );
    actionGroups[ActivationGroupKeyEnum.REACTIONS] = activatables.filter(
      (a) =>
        a.activation.activationType === Constants.ActivationTypeEnum.REACTION
    );
    actionGroups[ActivationGroupKeyEnum.OTHER] = activatables.filter(
      (a) =>
        a.activation.activationType !== Constants.ActivationTypeEnum.ACTION &&
        a.activation.activationType !==
          Constants.ActivationTypeEnum.BONUS_ACTION &&
        a.activation.activationType !== Constants.ActivationTypeEnum.REACTION
    );

    let otherBasicActions: Array<BasicActionContract> = [
      ...RuleDataUtils.getActivationTypeBasicActions(
        Constants.ActivationTypeEnum.NO_ACTION,
        ruleData
      ),
      ...RuleDataUtils.getActivationTypeBasicActions(
        Constants.ActivationTypeEnum.MINUTE,
        ruleData
      ),
      ...RuleDataUtils.getActivationTypeBasicActions(
        Constants.ActivationTypeEnum.HOUR,
        ruleData
      ),
      ...RuleDataUtils.getActivationTypeBasicActions(
        Constants.ActivationTypeEnum.SPECIAL,
        ruleData
      ),
    ];

    let onHandlers = this.getHandlers();
    let actionData = this.getActionData();

    return (
      <section className="ct-actions">
        <h2 style={visuallyHidden}>Actions</h2>
        <TabOptions
          hideBorder={true}
          initialActiveKey={TabKeyEnum.ALL}
          layoutType="pill"
        >
          <TabOptionsGroup tabKey={TabKeyEnum.ALL} label="All">
            <TabOptionsContent>
              {this.renderManageCustomLink()}
              <ActionsList
                heading={this.renderActionsHeader()}
                activationType={Constants.ActivationTypeEnum.ACTION}
                actions={actionGroups[ActivationGroupKeyEnum.ACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.ACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.ACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
              <ActionsList
                heading="Bonus Actions"
                activationType={Constants.ActivationTypeEnum.BONUS_ACTION}
                actions={actionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.BONUS_ACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
              <ActionsList
                heading="Reactions"
                activationType={Constants.ActivationTypeEnum.REACTION}
                actions={actionGroups[ActivationGroupKeyEnum.REACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.REACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.REACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
              <ActionsList
                heading="Other"
                actions={actionGroups[ActivationGroupKeyEnum.OTHER]}
                attacks={attackGroups[ActivationGroupKeyEnum.OTHER]}
                basicActions={otherBasicActions}
                ritualSpells={ritualSpells}
                showActivationInfo={true}
                {...onHandlers}
                {...actionData}
              />
            </TabOptionsContent>
          </TabOptionsGroup>
          {this.renderAttacksTab()}
          <TabOptionsGroup tabKey={TabKeyEnum.ACTIONS} label="Action">
            <TabOptionsContent>
              {this.renderManageCustomLink()}
              <ActionsList
                heading={this.renderActionsHeader()}
                activationType={Constants.ActivationTypeEnum.ACTION}
                actions={actionGroups[ActivationGroupKeyEnum.ACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.ACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.ACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup
            tabKey={TabKeyEnum.BONUS_ACTIONS}
            label="Bonus Action"
          >
            <TabOptionsContent>
              {this.renderManageCustomLink()}
              <ActionsList
                heading="Bonus Actions"
                activationType={Constants.ActivationTypeEnum.BONUS_ACTION}
                actions={actionGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.BONUS_ACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.BONUS_ACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TabKeyEnum.REACTIONS} label="Reaction">
            <TabOptionsContent>
              {this.renderManageCustomLink()}
              <ActionsList
                heading="Reactions"
                activationType={Constants.ActivationTypeEnum.REACTION}
                actions={actionGroups[ActivationGroupKeyEnum.REACTIONS]}
                attacks={attackGroups[ActivationGroupKeyEnum.REACTIONS]}
                basicActions={RuleDataUtils.getActivationTypeBasicActions(
                  Constants.ActivationTypeEnum.REACTION,
                  ruleData
                )}
                {...onHandlers}
                {...actionData}
              />
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TabKeyEnum.OTHER} label="Other">
            <TabOptionsContent>
              {this.renderManageCustomLink()}
              <ActionsList
                heading="Other"
                actions={actionGroups[ActivationGroupKeyEnum.OTHER]}
                attacks={attackGroups[ActivationGroupKeyEnum.OTHER]}
                basicActions={otherBasicActions}
                ritualSpells={ritualSpells}
                showActivationInfo={true}
                {...onHandlers}
                {...actionData}
              />
            </TabOptionsContent>
          </TabOptionsGroup>
          {this.renderLimitedUseTab()}
        </TabOptions>
      </section>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    activatables: rulesEngineSelectors.getActivatables(state),
    attacks: rulesEngineSelectors.getAttacks(state),
    weaponSpellDamageGroups:
      rulesEngineSelectors.getWeaponSpellDamageGroups(state),
    attacksPerActionInfo: rulesEngineSelectors.getAttacksPerActionInfo(state),
    ritualSpells: rulesEngineSelectors.getRitualSpells(state),
    abilityLookup: rulesEngineSelectors.getAbilityLookup(state),
    inventoryLookup: rulesEngineSelectors.getInventoryLookup(state),
    spellCasterInfo: rulesEngineSelectors.getSpellCasterInfo(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
    snippetData: rulesEngineSelectors.getSnippetData(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
    diceEnabled: appEnvSelectors.getDiceEnabled(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
    dataOriginRefData: rulesEngineSelectors.getDataOriginRefData(state),
    proficiencyBonus: rulesEngineSelectors.getProficiencyBonus(state),
    characterRollContext:
      characterRollContextSelectors.getCharacterRollContext(state),
  };
}

const ActionsContainer = (props) => {
  const { inventoryManager } = useContext(InventoryManagerContext);
  return <Actions inventoryManager={inventoryManager} {...props} />;
};

export default connect(mapStateToProps)(ActionsContainer);
