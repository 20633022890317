import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class SavingThrowRowBoxSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-saving-throw-row-box-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 116.1 34">
        <g id="SavingThrowRowBoxSvg-Page-1_1_">
          <g
            id="SavingThrowRowBoxSvg-Sheet_Desktop_Static"
            transform="translate(-683.000000, -651.000000)"
          >
            <path
              fill={fillColor}
              id="SavingThrowRowBoxSvg-Page-1"
              d="M789.8,651h-22l-0.3,0.2c-1.2,0.8-2.3,1.7-3.2,2.7h-75.6l-0.3,0.4c-0.7,1.2-3,4.5-4.9,5.4
                            l-0.5,0.2l0,16.1l0.5,0.2c1.8,0.9,4.1,4.2,4.9,5.4l0.3,0.4h75.6c1,1,2.1,1.9,3.2,2.7l0.3,0.2h21.9l0.3-0.2c5.6-3.8,9-10,9-16.8
                            s-3.4-13-9-16.8L789.8,651z M797.1,668c0,5.8-2.9,11.2-7.6,14.5h-10.3c-4.7-2.1-11.1-3.2-14.3-3.8c-2.3-3-3.7-6.8-3.7-10.7v0
                            c0-3.9,1.3-7.7,3.7-10.7c3.1-0.6,9.5-1.7,14.3-3.8h10.3C794.3,656.8,797.1,662.2,797.1,668L797.1,668z M752.8,655.6
                            c0.8,0.7,2.5,1.8,5.7,2.1c-0.9,1.5-3,5.5-3,10.3s2,8.8,3,10.3c-3.2,0.3-4.9,1.4-5.7,2.1h-55.4c-3.1-1.1-11.1-4.5-12.9-9.3l0-6.2
                            c1.9-4.8,9.9-8.1,12.9-9.3H752.8z M759.6,657.8c0.6,0,1.3,0,2,0c-1.8,3.1-2.8,6.6-2.8,10.3v0c0,3.7,1,7.2,2.9,10.3
                            c-0.7,0-1.3-0.1-2,0c-0.6-1-3.1-5.2-3.1-10.2S759,658.8,759.6,657.8L759.6,657.8z M768.9,682.5c-1.1-0.8-2.1-1.7-3-2.6
                            c2.4,0.5,5.1,1.3,8.3,2.6H768.9L768.9,682.5z M762.2,679.3c0.3,0.4,0.5,0.7,0.8,1.1h-8.5C755.9,679.7,758.3,678.9,762.2,679.3
                            L762.2,679.3z M689.3,680.4c-0.7-1.1-2.8-4.1-4.9-5.4v-1.9c2.3,3.4,7.1,5.9,10.4,7.3H689.3L689.3,680.4z M684.4,661
                            c2.1-1.3,4.2-4.3,4.9-5.4h5.5c-3.3,1.4-8,3.9-10.4,7.3V661L684.4,661z M763,655.6c-0.3,0.4-0.5,0.7-0.8,1.1
                            c-3.9,0.4-6.3-0.4-7.7-1.1H763z M765.9,656.1c0.9-1,1.9-1.9,3-2.6h5.3C771,654.8,768.2,655.6,765.9,656.1L765.9,656.1z"
            />
          </g>
        </g>
      </BaseSvg>
    );
  }
}
