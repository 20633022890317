import React, { useContext } from "react";
import { connect, DispatchProp } from "react-redux";

import {
  Checkbox,
  CloseSvg,
  DataOriginName,
  DigitalDiceWrapper,
  LightDiceSvg,
} from "@dndbeyond/character-components/es";
import {
  AbilityLookup,
  ActionUtils,
  characterActions,
  CharacterHitPointChange,
  CharacterHitPointInfo,
  CharacterUtils,
  Constants,
  DataOrigin,
  DeathSaveInfo,
  DiceAdjustment,
  HelperUtils,
  Item,
  ItemUtils,
  LimitedUseUtils,
  ModifierUtils,
  ProtectionSupplier,
  ShortModelInfoContract,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
  CharacterTheme,
  InventoryManager,
  ItemManager,
} from "@dndbeyond/character-rules-engine/es";
import { IRollContext, RollKind, RollRequest, RollType } from "@dndbeyond/dice";
import { GameLogContext } from "@dndbeyond/game-log-components";

import { HtmlContent } from "~/components/HtmlContent";
import { ItemName } from "~/components/ItemName";
import { RuleKeyEnum } from "~/constants";
import { useFeatureFlags } from "~/contexts/FeatureFlag";
import { Heading } from "~/subApps/sheet/components/Sidebar/components/Heading";
import {
  appEnvSelectors,
  characterRollContextSelectors,
} from "~/tools/js/Shared/selectors";

import { sidebarActions } from "../../../Shared/actions/sidebar";
import DiceAdjustmentSummary from "../../../Shared/components/DiceAdjustmentSummary";
import RestoreLifeManager from "../../../Shared/components/RestoreLifeManager";
import { ThemeButton } from "../../../Shared/components/common/Button";
import {
  HP_BONUS_VALUE,
  HP_DAMAGE_TAKEN_VALUE,
  HP_OVERRIDE_MAX_VALUE,
  HP_TEMP_VALUE,
} from "../../../Shared/constants/App";
import { InventoryManagerContext } from "../../../Shared/managers/InventoryManagerContext";
import { PaneComponentEnum, PaneUtils } from "../../../Shared/utils";
import { SheetAppState } from "../../typings";
import HealthManagerDeathsavesGroup from "./HealthManagerDeathsavesGroup";

//TODO
// if in deathsaves
//    - 3 successes takes you to stabilized at 0 hp
//    - if you add temp hp, it is used to shield you before having to do death saves throw

interface Props extends DispatchProp {
  hitPointInfo: CharacterHitPointInfo;
  deathSaves: DeathSaveInfo;
  protectionSuppliers: Array<ProtectionSupplier>;
  ruleData: RuleData;
  abilityLookup: AbilityLookup;
  deathCause: Constants.DeathCauseEnum;
  vibrationAmount: number;
  maxOverrideHitPoints: number;
  theme: CharacterTheme;
  inventoryManager: InventoryManager;
  diceEnabled: boolean;
  characterRollContext: IRollContext;
  messageTargetOptions: any; //EntityState<MessageTargetOption> from game-log-components;
  defaultMessageTargetOption?: any; //MessageTargetOption from game-log-components;
  userId: number;
  characterTheme: CharacterTheme;
  deathSaveRollsFlag: boolean;
}
interface State {
  newTempHp: number | null;
  newMaxModifier: number | null;
  newMaxOverride: number | null;
  hpDiff: number;
  tickCount: number;
  healingAmount: number | null;
  damageAmount: number | null;
  isDirtyCurHp: boolean;
  startFails: number;
  startSuccesses: number;
  activeProtectionSupplierKey: string | null;
}
class HealthManager extends React.PureComponent<Props, State> {
  static defaultProps = {
    vibrationAmount: 15,
    maxOverrideHitPoints: HP_OVERRIDE_MAX_VALUE,
  };

  tempInput = React.createRef<HTMLInputElement>();
  maxModifierInput = React.createRef<HTMLInputElement>();
  maxOverrideInput = React.createRef<HTMLInputElement>();
  damageInput = React.createRef<HTMLInputElement>();
  healingInput = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      newTempHp: props.hitPointInfo.tempHp,
      newMaxModifier: props.hitPointInfo.bonusHp,
      newMaxOverride: props.hitPointInfo.overrideHp,
      hpDiff: 0,
      tickCount: 0,
      healingAmount: 0,
      damageAmount: 0,
      isDirtyCurHp: false,
      startFails: props.deathSaves.failCount,
      startSuccesses: props.deathSaves.successCount,
      activeProtectionSupplierKey:
        this.getInitialActiveProtectionSupplierKey(props),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.hitPointInfo !== this.props.hitPointInfo ||
      prevProps.protectionSuppliers !== this.props.protectionSuppliers
    ) {
      this.setState({
        startFails: this.props.deathSaves.failCount,
        startSuccesses: this.props.deathSaves.successCount,
        newMaxModifier: this.props.hitPointInfo.bonusHp,
        newMaxOverride: this.props.hitPointInfo.overrideHp,
        activeProtectionSupplierKey: this.getInitialActiveProtectionSupplierKey(
          this.props
        ),
      });
      this.reset(this.props.hitPointInfo.tempHp);
    }
  }

  getInitialActiveProtectionSupplierKey = (props: Props): string | null => {
    const { protectionSuppliers } = props;

    const foundSupplier = protectionSuppliers.find(
      (supplier) =>
        supplier.availabilityStatus ===
        Constants.ProtectionAvailabilityStatusEnum.AVAILABLE
    );

    if (foundSupplier) {
      return foundSupplier.key;
    }

    return null;
  };

  resetFocus = (): void => {
    if (this.tempInput.current) {
      this.tempInput.current.blur();
    }
    if (this.maxModifierInput.current) {
      this.maxModifierInput.current.blur();
    }
    if (this.maxOverrideInput.current) {
      this.maxOverrideInput.current.blur();
    }
    if (this.damageInput.current) {
      this.damageInput.current.blur();
    }
    if (this.healingInput.current) {
      this.healingInput.current.blur();
    }
  };

  reset = (newTemp: number | null): void => {
    const { protectionSuppliers } = this.props;

    this.setState((prevState: State) => ({
      newTempHp: newTemp,
      hpDiff: 0,
      tickCount: 0,
      healingAmount: 0,
      damageAmount: 0,
      isDirtyCurHp: false,
      activeProtectionSupplierKey: this.getInitialActiveProtectionSupplierKey(
        this.props
      ),
    }));
  };

  calculateHitPoints = (
    props: Props,
    state: State
  ): CharacterHitPointChange => {
    let { healingAmount, damageAmount, tickCount } = state;
    const { hitPointInfo } = props;

    healingAmount = healingAmount === null ? 0 : healingAmount;
    damageAmount = damageAmount === null ? 0 : damageAmount;
    const hpDiff: number = healingAmount + damageAmount + tickCount;

    return CharacterUtils.calculateHitPoints(hitPointInfo, hpDiff);
  };

  handleIncrease = (): void => {
    const { vibrationAmount } = this.props;

    this.setState((prevState: State, props) => ({
      healingAmount:
        prevState.healingAmount !== null && prevState.damageAmount === 0
          ? prevState.healingAmount + 1
          : 0,
      damageAmount:
        prevState.damageAmount !== null && prevState.damageAmount < 0
          ? prevState.damageAmount + 1
          : 0,
      isDirtyCurHp: true,
    }));

    if (navigator.vibrate) {
      navigator.vibrate(vibrationAmount);
    }
  };

  handleDecrease = (): void => {
    const { vibrationAmount } = this.props;

    this.setState((prevState: State, props) => ({
      healingAmount:
        prevState.healingAmount !== null && prevState.healingAmount > 0
          ? prevState.healingAmount - 1
          : 0,
      damageAmount:
        prevState.damageAmount !== null && prevState.healingAmount === 0
          ? prevState.damageAmount - 1
          : 0,
      isDirtyCurHp: true,
    }));

    if (navigator.vibrate) {
      navigator.vibrate(vibrationAmount);
    }
  };

  handleTickChange = (tickCount: number): void => {
    const { healingAmount, damageAmount } = this.state;
    const { vibrationAmount } = this.props;

    this.setState({
      tickCount: tickCount,
      healingAmount: healingAmount === null ? 0 : healingAmount,
      damageAmount: damageAmount === null ? 0 : damageAmount,
      isDirtyCurHp: true,
    });

    this.resetFocus();
    if (navigator.vibrate) {
      navigator.vibrate(vibrationAmount);
    }
  };

  handleTempChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      newTempHp: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleTempBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { dispatch, hitPointInfo } = this.props;

    let value = HelperUtils.parseInputInt(evt.target.value, HP_TEMP_VALUE.MIN);
    let clampedValue = HelperUtils.clampInt(
      value,
      HP_TEMP_VALUE.MIN,
      HP_TEMP_VALUE.MAX
    );

    if (hitPointInfo.tempHp !== clampedValue) {
      dispatch(
        characterActions.hitPointsSet(hitPointInfo.removedHp, clampedValue)
      );
    }

    this.setState({
      newTempHp: clampedValue,
    });
  };

  handleMaxModifierChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      newMaxModifier: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleMaxOverrideChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({
      newMaxOverride: HelperUtils.parseInputInt(evt.target.value),
    });
  };

  handleMaxModifierBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { dispatch, hitPointInfo } = this.props;

    let value = HelperUtils.parseInputInt(evt.target.value);
    if (value !== null) {
      value = HelperUtils.clampInt(
        value,
        HP_BONUS_VALUE.MIN,
        HP_BONUS_VALUE.MAX
      );
    }

    if (value !== null) {
      this.handleUpdateDamageTaken(hitPointInfo.baseTotalHp + value);
    }

    if (value !== hitPointInfo.bonusHp) {
      dispatch(characterActions.bonusHitPointsSet(value));
    }

    this.setState({
      newMaxModifier: value,
    });
  };

  handleMaxOverrideBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { dispatch, hitPointInfo, maxOverrideHitPoints, ruleData } =
      this.props;

    let value = HelperUtils.parseInputInt(evt.target.value);
    if (value !== null) {
      value = HelperUtils.clampInt(
        value,
        RuleDataUtils.getMinimumHpTotal(ruleData),
        maxOverrideHitPoints
      );
    }

    if (value !== null) {
      this.handleUpdateDamageTaken(value);
    }

    if (value !== hitPointInfo.overrideHp) {
      //TODO fix when it can accept null
      dispatch(characterActions.overrideHitPointsSet(value as number));
    }

    this.setState({
      newMaxOverride: value,
    });
  };

  handleUpdateDamageTaken = (newMaxHp: number): void => {
    const { dispatch, hitPointInfo } = this.props;

    if (hitPointInfo.totalHp !== null) {
      let maxDiff = hitPointInfo.totalHp - newMaxHp;
      if (maxDiff > 0) {
        let newRemovedHp = Math.max(
          HP_DAMAGE_TAKEN_VALUE.MIN,
          hitPointInfo.removedHp - maxDiff
        );
        if (newRemovedHp !== hitPointInfo.removedHp) {
          dispatch(
            characterActions.hitPointsSet(
              newRemovedHp,
              hitPointInfo.tempHp ?? HP_TEMP_VALUE.MIN
            )
          );
        }
      }
    }
  };

  handleHealingSet = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      tickCount: 0,
      healingAmount: HelperUtils.clampInt(
        HelperUtils.parseInputInt(evt.target.value, HP_DAMAGE_TAKEN_VALUE.MIN),
        HP_DAMAGE_TAKEN_VALUE.MIN,
        HP_DAMAGE_TAKEN_VALUE.MAX
      ),
      damageAmount: 0,
      isDirtyCurHp: true,
    });
  };

  handleDamageSet = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      tickCount: 0,
      healingAmount: 0,
      damageAmount:
        HelperUtils.clampInt(
          HelperUtils.parseInputInt(
            evt.target.value,
            HP_DAMAGE_TAKEN_VALUE.MIN
          ),
          HP_DAMAGE_TAKEN_VALUE.MIN,
          HP_DAMAGE_TAKEN_VALUE.MAX
        ) * -1,
      isDirtyCurHp: true,
    });
  };

  handleKeyUp = (
    func: any,
    evt: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (evt.key === "Enter") {
      func(evt);
    }
  };

  handleDeathSavesSet = (fails: number, successes: number): void => {
    const { dispatch, ruleData } = this.props;

    dispatch(
      characterActions.deathSavesSet(
        Math.min(RuleDataUtils.getMaxDeathsavesFail(ruleData), fails),
        Math.min(RuleDataUtils.getMaxDeathsavesSuccess(ruleData), successes)
      )
    );
  };

  handleFailUse = (evt?: React.MouseEvent, isCritical = false): void => {
    const { deathSaves } = this.props;
    const uses = isCritical ? 2 : 1;

    if (evt) {
      evt.nativeEvent.stopImmediatePropagation();
      evt.stopPropagation();
    }

    this.handleDeathSavesSet(
      deathSaves.failCount + uses,
      deathSaves.successCount
    );
  };

  handleFailClear = (evt?: React.MouseEvent): void => {
    const { deathSaves } = this.props;

    if (evt) {
      evt.nativeEvent.stopImmediatePropagation();
      evt.stopPropagation();
    }

    this.handleDeathSavesSet(deathSaves.failCount - 1, deathSaves.successCount);
  };

  handleSuccessUse = (evt?: React.MouseEvent): void => {
    const { deathSaves } = this.props;

    if (evt) {
      evt.nativeEvent.stopImmediatePropagation();
      evt.stopPropagation();
    }

    this.handleDeathSavesSet(deathSaves.failCount, deathSaves.successCount + 1);
  };

  handleSuccessClear = (evt?: React.MouseEvent): void => {
    const { deathSaves } = this.props;

    if (evt) {
      evt.nativeEvent.stopImmediatePropagation();
      evt.stopPropagation();
    }

    this.handleDeathSavesSet(deathSaves.failCount, deathSaves.successCount - 1);
  };

  handleActiveProtectionSupplierChange = (
    key: string,
    isEnabled: boolean
  ): void => {
    this.setState({
      activeProtectionSupplierKey: isEnabled ? key : null,
    });
  };

  handleHpCancel = (): void => {
    const { hitPointInfo } = this.props;

    this.reset(hitPointInfo.tempHp);
  };

  handleHpSave = (): void => {
    const {
      activeProtectionSupplierKey,
      tickCount,
      healingAmount,
      damageAmount,
    } = this.state;
    const { hitPointInfo, dispatch, protectionSuppliers, inventoryManager } =
      this.props;
    const { newTemp, startHp, newHp } = this.calculateHitPoints(
      this.props,
      this.state
    );

    const damageNumericAmount: number =
      damageAmount === null
        ? 0
        : Math.min(
            tickCount +
              (healingAmount === null ? 0 : healingAmount) +
              damageAmount,
            0
          );

    let protectedHpResult: number | null = null;
    if (startHp !== 0 && newHp === 0 && activeProtectionSupplierKey !== null) {
      let foundActiveSupplier = protectionSuppliers.find(
        (supplier) => supplier.key === activeProtectionSupplierKey
      );

      if (foundActiveSupplier) {
        protectedHpResult = foundActiveSupplier.setHpValue;

        switch (foundActiveSupplier.type) {
          case Constants.ProtectionSupplierTypeEnum.ITEM: {
            const itemData = foundActiveSupplier.data as Item;
            const item = ItemManager.getItem(ItemUtils.getMappingId(itemData));

            const numberUsed = item.getNumberUsed();
            if (numberUsed !== null) {
              item.handleItemLimitedUseSet(numberUsed + 1);
            }
            break;
          }
          case Constants.ProtectionSupplierTypeEnum.RACIAL_TRAIT:
          case Constants.ProtectionSupplierTypeEnum.CLASS_FEATURE:
          case Constants.ProtectionSupplierTypeEnum.FEAT: {
            let action = foundActiveSupplier.action;
            if (action !== null) {
              let limitedUse = ActionUtils.getLimitedUse(action);
              const id = ActionUtils.getId(action);
              const entityTypeId = ActionUtils.getEntityTypeId(action);
              const dataOriginType = ActionUtils.getDataOriginType(action);

              if (limitedUse !== null && id !== null && entityTypeId !== null) {
                const numberUsed = LimitedUseUtils.getNumberUsed(limitedUse);
                dispatch(
                  characterActions.actionUseSet(
                    id,
                    entityTypeId,
                    numberUsed + 1,
                    dataOriginType
                  )
                );
              }
            }
            break;
          }
          default:
          //not implemented
        }
      }
    }

    let removedHitPoints: number = 0;
    if (protectedHpResult === null) {
      removedHitPoints = hitPointInfo.totalHp - newHp;
    } else {
      removedHitPoints = hitPointInfo.totalHp - protectedHpResult;
    }
    dispatch(characterActions.hitPointsSet(removedHitPoints, newTemp));

    if (startHp === 0) {
      if (newHp > 0) {
        dispatch(characterActions.deathSavesSet(0, 0));
      } else if (damageNumericAmount !== 0 && protectedHpResult === null) {
        this.handleFailUse();
        this.reset(hitPointInfo.tempHp);
      }
    }

    if (startHp === newHp) {
      this.reset(newTemp);
    }
  };

  handleRestoreToLife = (restoreType: ShortModelInfoContract): void => {
    const { dispatch } = this.props;

    const restoreChoice = restoreType.name === "Full" ? "full" : "1";

    dispatch(characterActions.restoreLife(restoreType.id));
    // dispatch(toastActions.toastSuccess('Character Restored to Life', `You have been restored to life with ${restoreChoice} HP.`));
  };

  handleDataOriginClick = (dataOrigin: DataOrigin): void => {
    const { dispatch } = this.props;

    let component = PaneUtils.getDataOriginComponentInfo(dataOrigin);
    if (component.type !== PaneComponentEnum.ERROR_404) {
      dispatch(
        sidebarActions.paneHistoryPush(component.type, component.identifiers)
      );
    }
  };

  renderDiceAdjustments = () => {
    const { deathSaves } = this.props;

    if (
      !deathSaves.advantageAdjustments.length &&
      !deathSaves.disadvantageAdjustments.length
    ) {
      return null;
    }

    return (
      <div className="ct-health-manager__deathsaves-dice-adjustments">
        {this.renderDiceAdjustmentList(deathSaves.advantageAdjustments)}
        {this.renderDiceAdjustmentList(deathSaves.disadvantageAdjustments)}
      </div>
    );
  };

  renderDiceAdjustmentList = (
    diceAdjustments: Array<DiceAdjustment>
  ): React.ReactNode => {
    const { ruleData, theme } = this.props;

    if (!diceAdjustments.length) {
      return null;
    }

    return (
      <React.Fragment>
        {diceAdjustments.map((diceAdjustment, idx) => {
          return (
            <DiceAdjustmentSummary
              key={diceAdjustment.uniqueKey}
              diceAdjustment={diceAdjustment}
              ruleData={ruleData}
              theme={theme}
              onDataOriginClick={this.handleDataOriginClick}
            />
          );
        })}
      </React.Fragment>
    );
  };

  renderDeathSavesManager = (): React.ReactNode => {
    const { tickCount, healingAmount, damageAmount } = this.state;
    const {
      dispatch,
      hitPointInfo,
      deathSaves,
      deathCause,
      ruleData,
      diceEnabled,
      characterRollContext,
      messageTargetOptions,
      defaultMessageTargetOption,
      userId,
      deathSaveRollsFlag,
    } = this.props;
    const oneHPRestoreType = RuleDataUtils.getRestoreTypes(ruleData).find(
      (r) => r.name === "OneHP"
    );

    if (
      hitPointInfo.remainingHp > 0 ||
      (deathCause !== Constants.DeathCauseEnum.NONE &&
        deathCause !== Constants.DeathCauseEnum.DEATHSAVES)
    ) {
      return null;
    }

    const damageNumericAmount: number =
      damageAmount === null
        ? 0
        : Math.min(
            tickCount +
              (healingAmount === null ? 0 : healingAmount) +
              damageAmount,
            0
          );

    const hasAdvantage = deathSaves.advantageAdjustments.length > 0;
    const hasDisadvantage = deathSaves.disadvantageAdjustments.length > 0;

    let rollKind = RollKind.None;
    if (hasAdvantage && !hasDisadvantage) {
      rollKind = RollKind.Advantage;
    } else if (hasDisadvantage && !hasAdvantage) {
      rollKind = RollKind.Disadvantage;
    }

    return (
      <div className="ct-health-manager__deathsaves">
        <div className="ct-health-manager__deathsaves-heading">
          <Heading>Death Saves</Heading>
          {diceEnabled && deathSaveRollsFlag && (
            <DigitalDiceWrapper
              diceNotation={"1d20"}
              onRollResults={(rollRequest: RollRequest) => {
                const roll = rollRequest.rolls[0].result?.total;

                if (roll) {
                  if (roll === 1) {
                    this.handleFailUse(undefined, true);
                  } else if (roll === 20 && oneHPRestoreType) {
                    dispatch(characterActions.restoreLife(oneHPRestoreType.id));
                  } else if (roll >= 10) {
                    this.handleSuccessUse.bind(this)();
                  } else {
                    this.handleFailUse.bind(this)();
                  }
                }
              }}
              rollType={RollType.Save}
              rollKind={rollKind}
              rollAction={"Death"}
              diceEnabled={diceEnabled}
              rollContext={characterRollContext}
              rollTargetOptions={
                messageTargetOptions
                  ? Object.values(messageTargetOptions?.entities)
                  : undefined
              }
              rollTargetDefault={defaultMessageTargetOption}
              userId={userId}
              advMenu={true}
            >
              <LightDiceSvg />
              <span>Roll</span>
            </DigitalDiceWrapper>
          )}
        </div>
        <div className="ct-health-manager__deathsaves-groups">
          <HealthManagerDeathsavesGroup
            label="Failures"
            type="fails"
            activeCount={deathSaves.failCount}
            willBeActiveCount={damageNumericAmount !== 0 ? 1 : 0}
            totalCount={ruleData.maxDeathsavesFail}
            onUse={this.handleFailUse}
            onClear={this.handleFailClear}
            markIcon={(key) => (
              <span
                className="ct-health-manager__deathsaves-mark"
                key={key}
                onClick={this.handleFailClear.bind(this)}
              >
                <CloseSvg
                  fillColor={"#c53131"}
                  secondaryFillColor=""
                  key={key}
                  className={
                    "ct-health-manager__deathsaves-mark ct-health-manager__deathsaves-mark--active"
                  }
                />
              </span>
            )}
          />
          <HealthManagerDeathsavesGroup
            label="Successes"
            type="successes"
            activeCount={deathSaves.successCount}
            totalCount={ruleData.maxDeathsavesSuccess}
            onUse={this.handleSuccessUse}
            onClear={this.handleSuccessClear}
            markIcon={(key) => (
              <span
                className="ct-health-manager__deathsaves-mark"
                key={key}
                onClick={this.handleSuccessClear.bind(this)}
              >
                <CloseSvg
                  fillColor={"#00c797"}
                  secondaryFillColor=""
                  key={key}
                  className={
                    "ct-health-manager__deathsaves-mark ct-health-manager__deathsaves-mark--active"
                  }
                />
              </span>
            )}
          />
        </div>
        {this.renderDiceAdjustments()}
      </div>
    );
  };

  renderDeathSavesRules = (): React.ReactNode => {
    const { hitPointInfo, deathCause, ruleData } = this.props;
    if (
      hitPointInfo.remainingHp > 0 ||
      (deathCause !== Constants.DeathCauseEnum.NONE &&
        deathCause !== Constants.DeathCauseEnum.DEATHSAVES)
    ) {
      return null;
    }

    const deathSavesRule = RuleDataUtils.getRule(
      RuleKeyEnum.DEATH_SAVING_THROWS,
      ruleData
    );

    return (
      <div className="ct-health-manager__deathsaves-rules">
        <Heading>Death Saving Throws Rules</Heading>
        <HtmlContent
          html={
            deathSavesRule && deathSavesRule.description
              ? deathSavesRule.description
              : ""
          }
          withoutTooltips
        />
      </div>
    );
  };

  renderProtectionInfo = (): React.ReactNode => {
    const { isDirtyCurHp, activeProtectionSupplierKey } = this.state;
    const { protectionSuppliers, theme } = this.props;

    const { startHp, newHp } = this.calculateHitPoints(this.props, this.state);

    if (
      !isDirtyCurHp ||
      startHp === 0 ||
      newHp !== 0 ||
      protectionSuppliers.length === 0
    ) {
      return null;
    }

    return (
      <div className="ct-health-manager__protection-notices">
        {protectionSuppliers.map((protectionSupplier) => {
          if (
            protectionSupplier.availabilityStatus !==
            Constants.ProtectionAvailabilityStatusEnum.AVAILABLE
          ) {
            return null;
          }

          let dataOrigin = ModifierUtils.getDataOrigin(
            protectionSupplier.modifier
          );
          let isEnabled =
            activeProtectionSupplierKey !== null &&
            protectionSupplier.key === activeProtectionSupplierKey;

          //TODO remove if check and ItemName => use DataOriginName when EntityDataOriginLookup is available to be passed as a prop to DataOriginName
          let nameNode: React.ReactNode = null;
          if (
            protectionSupplier.type ===
            Constants.ProtectionSupplierTypeEnum.ITEM
          ) {
            nameNode = <ItemName item={protectionSupplier.data as Item} />;
          } else {
            nameNode = <DataOriginName dataOrigin={dataOrigin} theme={theme} />;
          }

          return (
            <div
              className="ct-health-manager__protection-notice"
              key={protectionSupplier.key}
            >
              <div className="ct-health-manager__protection-notice-field">
                <Checkbox
                  stopPropagation={true}
                  initiallyEnabled={isEnabled}
                  onChange={this.handleActiveProtectionSupplierChange.bind(
                    this,
                    protectionSupplier.key
                  )}
                />
              </div>
              <div className="ct-health-manager__protection-notice-description">
                Instead of dropping to 0 hit points, use{" "}
                <strong>{nameNode}</strong> to set hit points to{" "}
                <strong>{protectionSupplier.setHpValue}</strong>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderAdjuster = (): React.ReactNode => {
    const { tickCount, healingAmount, damageAmount } = this.state;
    const { hitPointInfo } = this.props;

    let healingValue = healingAmount === null ? 0 : healingAmount;
    let damageValue = damageAmount === null ? 0 : damageAmount;

    let healingDisplay =
      healingAmount === null
        ? healingAmount
        : Math.max(tickCount + healingAmount + damageValue, 0);
    let damageDisplay =
      damageAmount === null
        ? damageAmount
        : Math.abs(Math.min(tickCount + healingValue + damageAmount, 0));

    const { newTemp, startHp, newHp } = this.calculateHitPoints(
      this.props,
      this.state
    );

    let hpDiffClsNames: Array<string> = ["ct-health-manager__adjuster-new"];
    if (newHp > startHp) {
      hpDiffClsNames.push("ct-health-manager__status--positive");
    } else if (newHp < startHp) {
      hpDiffClsNames.push("ct-health-manager__status--negative");
    }

    let tempDiffClsNames: Array<string> = ["ct-health-manager__adjuster-new"];
    if (hitPointInfo.tempHp !== null) {
      if (newTemp > hitPointInfo.tempHp) {
        tempDiffClsNames.push("ct-health-manager__status--positive");
      } else if (newTemp < hitPointInfo.tempHp) {
        tempDiffClsNames.push("ct-health-manager__status--negative");
      }
    }

    return (
      <div className="ct-health-manager__adjuster">
        <div className="ct-health-manager__adjuster-details">
          <div className="ct-health-manager__adjuster-healing">
            <div className="ct-health-manager__adjuster-healing-label">
              Healing
            </div>
            <div className="ct-health-manager__adjuster-healing-value">
              <input
                ref={this.healingInput}
                className="ct-health-manager__adjuster-healing-input"
                type="number"
                value={healingDisplay === null ? "" : healingDisplay}
                min={HP_DAMAGE_TAKEN_VALUE.MIN}
                max={HP_DAMAGE_TAKEN_VALUE.MAX}
                onChange={this.handleHealingSet}
              />
            </div>
          </div>
          <div className="ct-health-manager__adjuster-updates">
            <div className={hpDiffClsNames.join(" ")}>
              <div className="ct-health-manager__adjuster-new-label">
                New HP
              </div>
              <div className="ct-health-manager__adjuster-new-value">
                {newHp}
              </div>
            </div>
            {hitPointInfo.tempHp !== null && hitPointInfo.tempHp > 0 && (
              <div className={tempDiffClsNames.join(" ")}>
                <div className="ct-health-manager__adjuster-new-label">
                  New Temp
                </div>
                <div className="ct-health-manager__adjuster-new-value">
                  {newTemp}
                </div>
              </div>
            )}
          </div>
          <div className="ct-health-manager__adjuster-damage">
            <div className="ct-health-manager__adjuster-damage-label">
              Damage
            </div>
            <div className="ct-health-manager__adjuster-damage-value">
              <input
                ref={this.damageInput}
                className="ct-health-manager__adjuster-damage-input"
                type="number"
                value={damageDisplay === null ? "" : damageDisplay}
                min={HP_DAMAGE_TAKEN_VALUE.MIN}
                max={HP_DAMAGE_TAKEN_VALUE.MAX}
                onChange={this.handleDamageSet}
              />
            </div>
          </div>
        </div>
        <div className="ct-health-manager__adjuster-buttons">
          <div className="ct-health-manager__adjuster-button ct-health-manager__adjuster-button--increase">
            <ThemeButton
              className="action-increase"
              onClick={this.handleIncrease}
            />
          </div>
          <div className="ct-health-manager__adjuster-button ct-health-manager__adjuster-button--decrease">
            <ThemeButton
              className="action-decrease"
              onClick={this.handleDecrease}
            />
          </div>
        </div>
      </div>
    );
  };

  renderOverrides = (): React.ReactNode => {
    const { maxOverrideHitPoints, ruleData } = this.props;
    const { newMaxModifier, newMaxOverride } = this.state;

    return (
      <div className="ct-health-manager__overrides">
        <div className="ct-health-manager__override ct-health-manager__override--max-modifier">
          <div className="ct-health-manager__override-label">
            Max HP Modifier
          </div>
          <div className="ct-health-manager__override-value">
            <input
              ref={this.maxModifierInput}
              className="character-input ct-health-manager__input"
              type="number"
              value={newMaxModifier ?? ""}
              min={HP_BONUS_VALUE.MIN}
              max={HP_BONUS_VALUE.MAX}
              onChange={this.handleMaxModifierChange}
              onBlur={this.handleMaxModifierBlur}
              onKeyUp={this.handleKeyUp.bind(this, this.handleMaxModifierBlur)}
              placeholder="--"
            />
          </div>
        </div>
        <div className="ct-health-manager__override ct-health-manager__override--max-hp">
          <div className="ct-health-manager__override-label">
            Override Max HP
          </div>
          <div className="ct-health-manager__override-value">
            <input
              ref={this.maxOverrideInput}
              className="character-input ct-health-manager__input"
              type="number"
              value={newMaxOverride ?? ""}
              min={RuleDataUtils.getMinimumHpTotal(ruleData)}
              max={maxOverrideHitPoints}
              onChange={this.handleMaxOverrideChange}
              onBlur={this.handleMaxOverrideBlur}
              onKeyUp={this.handleKeyUp.bind(this, this.handleMaxOverrideBlur)}
              placeholder="--"
            />
          </div>
        </div>
      </div>
    );
  };

  renderHealthManager = (): React.ReactNode => {
    const { newTempHp } = this.state;
    const { hitPointInfo } = this.props;

    let curMaxClsNames: Array<string> = [
      "ct-health-manager__health-max-current",
    ];
    let showOriginalMax: boolean = false;
    if (hitPointInfo.overrideHp || hitPointInfo.bonusHp) {
      showOriginalMax = true;
      if (hitPointInfo.totalHp - hitPointInfo.baseTotalHp > 0) {
        curMaxClsNames.push("ct-health-manager__status--positive");
      } else {
        curMaxClsNames.push("ct-health-manager__status--negative");
      }
    }

    return (
      <div className="ct-health-manager__health">
        <div className="ct-health-manager__health-item ct-health-manager__health-item--cur">
          <div className="ct-health-manager__health-item-label">Current HP</div>
          <div className="ct-health-manager__health-item-value">
            {hitPointInfo.remainingHp}
          </div>
        </div>
        <div className="ct-health-manager__health-item ct-health-manager__health-item--max">
          <div className="ct-health-manager__health-item-label">Max HP</div>
          <div className="ct-health-manager__health-item-value">
            <span className={curMaxClsNames.join(" ")}>
              {hitPointInfo.totalHp}
            </span>
            {showOriginalMax && (
              <span className="ct-health-manager__health-original-max">
                ({hitPointInfo.baseTotalHp})
              </span>
            )}
          </div>
        </div>
        <div className="ct-health-manager__health-item ct-health-manager__health-item--temp">
          <div className="ct-health-manager__health-item-label">Temp HP</div>
          <div className="ct-health-manager__health-item-value">
            <input
              ref={this.tempInput}
              className="character-input ct-health-manager__input"
              type="number"
              value={newTempHp ?? ""}
              min={HP_TEMP_VALUE.MIN}
              max={HP_TEMP_VALUE.MAX}
              onChange={this.handleTempChange}
              onBlur={this.handleTempBlur}
              onKeyUp={this.handleKeyUp.bind(this, this.handleTempBlur)}
            />
          </div>
        </div>
      </div>
    );
  };

  renderRestoreLifeManager = (): React.ReactNode => {
    const { deathCause, ruleData, theme } = this.props;

    if (deathCause === Constants.DeathCauseEnum.NONE) {
      return null;
    }

    return (
      <div className="ct-health-manager__restore-life">
        <Heading>Restore Life</Heading>
        <RestoreLifeManager
          theme={theme}
          onSave={this.handleRestoreToLife}
          ruleData={ruleData}
        />
      </div>
    );
  };

  renderActions = (): React.ReactNode => {
    const { isDirtyCurHp } = this.state;

    if (!isDirtyCurHp) {
      return null;
    }

    return (
      <div className="ct-health-manager__actions">
        <div className="ct-health-manager__action">
          <ThemeButton onClick={this.handleHpSave}>Apply Changes</ThemeButton>
        </div>
        <div className="ct-health-manager__action">
          <ThemeButton onClick={this.handleHpCancel} style="outline">
            Cancel
          </ThemeButton>
        </div>
      </div>
    );
  };

  render() {
    const { theme, deathSaveRollsFlag } = this.props;

    return (
      <div
        className={`ct-health-manager ${
          theme.isDarkMode ? "ct-health-manager--dark-mode" : ""
        }`}
      >
        {this.renderDeathSavesManager()}
        {this.renderRestoreLifeManager()}
        {this.renderHealthManager()}
        {this.renderAdjuster()}
        {this.renderProtectionInfo()}
        {this.renderActions()}
        {this.renderOverrides()}
        {deathSaveRollsFlag && this.renderDeathSavesRules()}
      </div>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    hitPointInfo: rulesEngineSelectors.getHitPointInfo(state),
    deathSaves: rulesEngineSelectors.getDeathSaveInfo(state),
    protectionSuppliers: rulesEngineSelectors.getProtectionSuppliers(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
    abilityLookup: rulesEngineSelectors.getAbilityLookup(state),
    deathCause: rulesEngineSelectors.getDeathCause(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
    diceEnabled: appEnvSelectors.getDiceEnabled(state),
    characterRollContext:
      characterRollContextSelectors.getCharacterRollContext(state),
    characterTheme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

const HealthManagerContainer = (props) => {
  const { inventoryManager } = useContext(InventoryManagerContext);
  const [{ messageTargetOptions, defaultMessageTargetOption, userId }] =
    useContext(GameLogContext);

  const { deathSaveRollsFlag } = useFeatureFlags();
  return (
    <HealthManager
      inventoryManager={inventoryManager}
      messageTargetOptions={messageTargetOptions}
      defaultMessageTargetOption={defaultMessageTargetOption}
      userId={userId}
      deathSaveRollsFlag={deathSaveRollsFlag}
      {...props}
    />
  );
};

export default connect(mapStateToProps)(HealthManagerContainer);
