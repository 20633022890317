import React from "react";

import { BaseSvg } from "../../../BaseSvg";
import { InjectedSvgProps } from "../../../hocs";

export default class SavingThrowRowSmallBoxSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-saving-throw-row-small-box-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 88 28">
        <g id="SavingThrowRowSmallBoxSvg-Page-1">
          <g id="SavingThrowRowSmallBoxSvg-Sheet_Desktop_Static">
            <path
              id="SavingThrowRowSmallBoxSvg-Page-1-2"
              fill={fillColor}
              d="M81.02068,0H64.331l-.22758.1652a13.19155,13.19155,0,0,0-2.4276,2.23009H4.32415l-.22758.3304c-.531.99116-2.27586,3.7168-3.71726,4.46018L0,7.35107V20.64893l.37931.1652c1.36551.74338,3.11033,3.469,3.71726,4.46018l.22758.3304h57.3517a20.96667,20.96667,0,0,0,2.4276,2.23009L64.331,28H80.94484l.22757-.1652A17.166,17.166,0,0,0,88,13.95876,17.166,17.166,0,0,0,81.17241.08273ZM86.5586,14.04124a15.10111,15.10111,0,0,1-5.7655,11.97635H72.97932c-3.56553-1.73449-8.42067-2.64307-10.84827-3.13862a15.53188,15.53188,0,0,1-2.8069-8.83773h0a14.98609,14.98609,0,0,1,2.8069-8.83773C64.48276,4.708,69.338,3.79937,72.97932,2.06489H80.7931a14.8801,14.8801,0,0,1,5.7655,11.97635ZM52.95171,3.79937a7.09114,7.09114,0,0,0,4.32415,1.73449A18.4022,18.4022,0,0,0,55,14.04119a17.50539,17.50539,0,0,0,2.27586,8.50732A7.09144,7.09144,0,0,0,52.95171,24.283H10.92411c-2.35171-.90853-8.42067-3.7168-9.78623-7.68137V11.4807c1.4414-3.96458,7.51037-6.69022,9.78623-7.68138h42.0276Zm5.15862,1.81711h1.51724a18.00564,18.00564,0,0,0-2.12413,8.50733h0a17.221,17.221,0,0,0,2.2,8.50733,9.30868,9.30868,0,0,0-1.51724,0,18.18447,18.18447,0,0,1-2.35171-8.42475,19.36161,19.36161,0,0,1,2.27582-8.58991Zm7.05521,20.40111a19.90907,19.90907,0,0,1-2.27587-2.14747,34.16144,34.16144,0,0,1,6.29655,2.14747Zm-5.08277-2.64307c.22757.3304.37931.57818.60689.90853H54.24138a9.56687,9.56687,0,0,1,5.84139-.90853ZM4.7793,24.2831A15.78928,15.78928,0,0,0,1.062,19.82292V18.25359c1.74482,2.80827,5.38619,4.87315,7.88967,6.02946H4.7793ZM1.06209,8.25955A15.7888,15.7888,0,0,0,4.77935,3.79937H8.95176C6.44832,4.95573,2.8828,7.02062,1.06209,9.82884V8.25955ZM60.68966,3.79937c-.22758.33041-.37932.57818-.60689.90853a9.56642,9.56642,0,0,1-5.84139-.90853Zm2.2.413a11.52883,11.52883,0,0,1,2.27587-2.14746h4.02068a36.16356,36.16356,0,0,1-6.29655,2.14746Z"
            />
          </g>
        </g>
      </BaseSvg>
    );
  }
}
