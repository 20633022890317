import { createSelector } from "reselect";

import config from "~/config";
import {
  SidebarAlignmentEnum,
  SidebarPlacementEnum,
} from "~/subApps/sheet/components/Sidebar/types";

import { SIDEBAR_FIXED_POSITION_START_WIDTH } from "../../../Shared/constants/App";
import { appEnvSelectors, sidebarSelectors } from "../../../Shared/selectors";
import {
  SheetPositioningInfo,
  SidebarInfo,
  SidebarPositioningInfo,
} from "../../typings";

const BASE_PATHNAME = config.basePathname;

export const getSidebarInfo = createSelector(
  [
    sidebarSelectors.getPositionInfo,
    sidebarSelectors.getWidth,
    appEnvSelectors.getIsMobile,
    appEnvSelectors.getDimensions,
  ],
  (positionInfo, width, isMobile, dimensions): SidebarInfo => {
    let placement = positionInfo.placement;
    if (
      isMobile ||
      dimensions.window.width < SIDEBAR_FIXED_POSITION_START_WIDTH
    ) {
      placement = SidebarPlacementEnum.OVERLAY;
    }

    return {
      ...positionInfo,
      alignment: isMobile ? SidebarAlignmentEnum.RIGHT : positionInfo.alignment,
      placement,
      width,
    };
  }
);

export const getSheetPositioning = createSelector(
  [getSidebarInfo],
  (sidebarInfo): SheetPositioningInfo => {
    let offset: number = 0;

    if (!sidebarInfo.isVisible) {
      return {
        offset,
        left: offset,
      };
    }

    if (sidebarInfo.placement === SidebarPlacementEnum.FIXED) {
      offset = sidebarInfo.width / 2;
    }

    return {
      offset,
      left:
        sidebarInfo.alignment === SidebarAlignmentEnum.LEFT
          ? offset
          : -1 * offset,
    };
  }
);

export const getSidebarPositioning = createSelector(
  [appEnvSelectors.getDimensions, getSidebarInfo, getSheetPositioning],
  (envDimensions, sidebarInfo, sheetPositioning): SidebarPositioningInfo => {
    const { sheet: sheetDimensions, window: windowDimensions } = envDimensions;

    let posX: number = 0;
    let sidebarGutter: number = 15;
    let screenGutterSize: number =
      (windowDimensions.width - sheetDimensions.width) / 2;

    if (sidebarInfo.placement === SidebarPlacementEnum.FIXED) {
      posX =
        screenGutterSize +
        sheetPositioning.offset -
        sidebarGutter -
        sidebarInfo.width;
    } else {
      screenGutterSize -= sidebarGutter;
      let overflowOffset: number =
        sidebarInfo.width > screenGutterSize
          ? sidebarInfo.width - screenGutterSize
          : 0;
      posX = screenGutterSize - sidebarInfo.width + overflowOffset;
    }

    return {
      left: sidebarInfo.alignment === SidebarAlignmentEnum.LEFT ? posX : "auto",
      right:
        sidebarInfo.alignment === SidebarAlignmentEnum.RIGHT ? posX : "auto",
    };
  }
);

export const getBuilderUrl = createSelector(
  [appEnvSelectors.getCharacterId],
  (characterId) => `${BASE_PATHNAME}/${characterId}/builder/class/manage`
);
