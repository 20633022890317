import { FC } from "react";
import { connect, useDispatch } from "react-redux";

import {
  BaseFeat,
  BuilderChoiceOptionContract,
  characterActions,
  ChoiceData,
  ChoiceUtils,
  FeatUtils,
  HtmlSelectOption,
  HtmlSelectOptionGroup,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { useCharacterEngine } from "~/hooks/useCharacterEngine";

import { useSource } from "../../../../../../hooks/useSource";
import { SharedAppState } from "../../../stores/typings";
import DetailChoice from "../DetailChoice";

export interface Props {
  featId: number;
  choiceInfo: ChoiceData;
  feats: Array<BaseFeat>;
}

export const DetailChoiceFeat: FC<Props> = ({ featId, choiceInfo, feats }) => {
  const dispatch = useDispatch();
  const { entityRestrictionData } = useCharacterEngine();

  const handleChoiceChange = (
    id: string | null,
    type: number,
    subType: number | null,
    value: any
  ): void => {
    if (id !== null) {
      dispatch(characterActions.featChoiceSetRequest(featId, type, id, value));
    }
  };

  const { getGroupedOptionsBySourceCategory } = useSource();
  const feat = feats.find((feat) => FeatUtils.getId(feat) === featId);

  if (!feat) {
    return null;
  }

  const choices = FeatUtils.getChoices(feat);
  if (choices.length === 0) {
    return null;
  }

  return (
    <div className="ct-detail-choice-feat">
      {choices.map((choice) => {
        const options = ChoiceUtils.getOptions(choice);
        const id = ChoiceUtils.getId(choice);
        let description: string | undefined;

        // If every option in the array has a null sourceId, ignore sorting by sourceId.
        // If any option has a non-null sourceId, sort by sourceId.
        const shouldSortBySourceId = options.some(
          (option) =>
            option.hasOwnProperty("sourceId") && option.sourceId !== null
        );

        let featChoiceOptions: Array<
          | (HtmlSelectOption & BuilderChoiceOptionContract)
          | HtmlSelectOptionGroup
        >;

        // If true, instead of mapping the options below, map and shape it like we did in FeatureChoice for BuilderChoiceTypeEnum.ENTITY_SPELL_OPTION
        // Possibly make a util for DRY, possibly in the useSource hook
        if (shouldSortBySourceId) {
          const groupedOptions = options.map((option) => {
            return {
              name: option.label,
              sources: option.sourceId
                ? [
                    {
                      sourceId: option.sourceId,
                      pageNumber: null,
                      sourceType: -1,
                    },
                  ]
                : null,
              ...option,
              value: option.id,
            };
          });

          const chosenOption = options.find(
            (spell) => spell.id === choice.optionValue
          );

          if (chosenOption) {
            description = chosenOption.description ?? "";
          }

          featChoiceOptions = getGroupedOptionsBySourceCategory(
            groupedOptions,
            chosenOption?.id,
            entityRestrictionData
          );
        } else {
          featChoiceOptions = options.map((option) => ({
            ...option,
            value: option.id,
          }));
        }

        return (
          <DetailChoice
            {...choice}
            label={choice.label}
            choice={choice}
            key={id !== null ? id : ""}
            options={featChoiceOptions}
            onChange={handleChoiceChange}
            choiceInfo={choiceInfo}
            showBackgroundProficiencyOptions={true}
            description={description}
          />
        );
      })}
    </div>
  );
};

function mapStateToProps(state: SharedAppState) {
  return {
    choiceInfo: rulesEngineSelectors.getChoiceInfo(state),
    feats: rulesEngineSelectors.getBaseFeats(state),
  };
}

export default connect(mapStateToProps)(DetailChoiceFeat);
