import React from "react";
import { connect, DispatchProp } from "react-redux";

import { Select, XpBar } from "@dndbeyond/character-components/es";
import {
  characterActions,
  CharacterUtils,
  HelperUtils,
  HtmlSelectOption,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { modalActions } from "../../../actions/modal";
import { FullscreenModal } from "../../../components/common/FullscreenModal";
import * as modalSelectors from "../../../selectors/modal";

interface Props extends DispatchProp {
  currentXp: number;
  isOpen: boolean;
  modalKey: string;
  ruleData: RuleData;
}
interface State {
  xpNew: number | null;
  xpChange: number | null;
  levelChosenNew: number | null;
  changeType: string;
}
class XpManager extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      xpNew: null,
      xpChange: null,
      levelChosenNew: null,
      changeType: "add",
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { currentXp } = this.props;

    if (currentXp !== prevProps.currentXp) {
      this.setState({
        xpNew: null,
        xpChange: null,
        levelChosenNew: null,
      });
    }
  }

  reset = () => {
    this.setState((prevState, props) => ({
      xpNew: null,
      xpChange: null,
      levelChosenNew: null,
      changeType: "add",
    }));
  };

  handleCancelModal = () => {
    const { dispatch, modalKey } = this.props;

    dispatch(modalActions.close(modalKey));
    this.reset();
  };

  handleAcceptModal = () => {
    const { dispatch, currentXp, modalKey } = this.props;

    const newXpTotal = this.getNewXpTotal();

    if (newXpTotal !== currentXp) {
      dispatch(characterActions.xpSet(newXpTotal));
    }

    dispatch(modalActions.close(modalKey));
    this.reset();
  };

  handleXpSet = (evt) => {
    this.setState({
      xpNew: HelperUtils.parseInputInt(evt.target.value),
      xpChange: null,
      levelChosenNew: null,
    });
  };

  handleXpChange = (evt) => {
    this.setState({
      xpNew: null,
      xpChange: HelperUtils.parseInputInt(evt.target.value),
      levelChosenNew: null,
    });
  };

  handleChooseLevel = (value) => {
    this.setState({
      xpNew: null,
      xpChange: null,
      levelChosenNew: HelperUtils.parseInputInt(value),
    });
  };

  setChangeType = (type) => {
    this.setState({
      changeType: type,
    });
  };

  getNewXpTotal = () => {
    const { xpNew, xpChange, changeType, levelChosenNew } = this.state;
    const { currentXp, ruleData } = this.props;

    const xpChangeValue = xpChange === null ? 0 : xpChange;
    const xpDiff =
      changeType === "remove"
        ? -1 * (xpChangeValue ? xpChangeValue : 0)
        : xpChangeValue;

    let newXpTotal = currentXp;
    if (xpDiff) {
      newXpTotal = currentXp + xpDiff;
    } else if (levelChosenNew) {
      newXpTotal = CharacterUtils.deriveCurrentLevelXp(
        levelChosenNew,
        ruleData
      );
    } else if (xpNew) {
      newXpTotal = xpNew;
    }

    newXpTotal = Math.min(
      Math.max(0, newXpTotal),
      CharacterUtils.deriveMaxXp(ruleData)
    );

    return newXpTotal;
  };

  render() {
    const { xpNew, xpChange, changeType, levelChosenNew } = this.state;
    const { isOpen, currentXp, ruleData } = this.props;

    const currentLevel = CharacterUtils.deriveXpLevel(currentXp, ruleData);
    const nextLevelXp = CharacterUtils.deriveNextLevelXp(
      currentLevel,
      ruleData
    );
    const currentLevelXp = CharacterUtils.deriveCurrentLevelXp(
      currentLevel,
      ruleData
    );
    const newXpTotal = this.getNewXpTotal();

    let changeAddCls = ["xp-manager-change-type"];
    let changeRemoveCls = ["xp-manager-change-type"];
    if (changeType === "remove") {
      changeRemoveCls.push("xp-manager-change-type-active");
    } else {
      changeAddCls.push("xp-manager-change-type-active");
    }

    let levelOptions: Array<HtmlSelectOption> = [];
    for (let i = 1; i <= RuleDataUtils.getMaxCharacterLevel(ruleData); i++) {
      levelOptions.push({
        label: `${i}`,
        value: i,
      });
    }

    const displayCurrentLevelXp =
      currentLevel === ruleData.maxCharacterLevel
        ? CharacterUtils.deriveCurrentLevelXp(
            ruleData.maxCharacterLevel - 1,
            ruleData
          )
        : currentLevelXp;

    return (
      <FullscreenModal
        clsNames={["manage-xp-modal"]}
        onCancel={this.handleCancelModal}
        onAccept={this.handleAcceptModal}
        isOpen={isOpen}
        heading="Manage XP"
      >
        <div className="xp-manager">
          <div className="xp-manager-main">
            <div className="xp-manager-level">
              Current XP Total: {currentXp.toLocaleString()} (Level{" "}
              {currentLevel})
            </div>
            <div className="xp-manager-bar">
              <XpBar
                xp={currentXp}
                showCurrentMarker={true}
                ruleData={ruleData}
              />
            </div>
            <div className="xp-manager-level-amounts">
              <div className="xp-manager-level-amounts-value">
                {displayCurrentLevelXp.toLocaleString()}
              </div>
              <div className="xp-manager-level-amounts-value">
                {nextLevelXp.toLocaleString()}
              </div>
            </div>
          </div>
          <div className="xp-manager-adjust">
            <div className="xp-manager-choose">
              <div className="xp-manager-choose-label">Set Level</div>
              <div className="xp-manager-choose-value">
                <Select
                  options={levelOptions}
                  onChange={this.handleChooseLevel}
                  value={levelChosenNew}
                  placeholder={"--"}
                />
              </div>
            </div>
            <div className="xp-manager-current">
              <div className="xp-manager-current-label">Set XP</div>
              <div className="xp-manager-current-value">
                <input
                  ref="xpInput"
                  className="character-input xp-manager-current-input"
                  type="number"
                  value={xpNew === null ? "" : xpNew}
                  onChange={this.handleXpSet}
                />
              </div>
            </div>
            <div className="xp-manager-change">
              <div className="xp-manager-change-types">
                <div
                  className={changeAddCls.join(" ")}
                  onClick={this.setChangeType.bind(this, "add")}
                >
                  Add Xp
                </div>
                <div
                  className={changeRemoveCls.join(" ")}
                  onClick={this.setChangeType.bind(this, "remove")}
                >
                  Remove Xp
                </div>
              </div>
              <div className="xp-manager-change-value">
                <input
                  ref="xpChangeInput"
                  className="character-input-block-oversized xp-manager-change-input"
                  type="number"
                  value={xpChange === null ? "" : xpChange}
                  onChange={this.handleXpChange}
                  placeholder="Type XP Value"
                />
              </div>
            </div>
          </div>
          <div className="xp-manager-total">
            New XP Total: {newXpTotal.toLocaleString()} (Level{" "}
            {CharacterUtils.deriveXpLevel(newXpTotal, ruleData)})
          </div>
        </div>
      </FullscreenModal>
    );
  }
}

function mapStateToProps(state) {
  const modalKey = "xp";

  return {
    modalKey,
    isOpen: modalSelectors.getOpenStatus(state, modalKey),
    currentXp: rulesEngineSelectors.getCurrentXp(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
  };
}

export default connect(mapStateToProps)(XpManager);
