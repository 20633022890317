import * as React from "react";

interface Props {
  tabKey: string;
  onItemClick?: (tabKey: string) => void;
  isActive?: boolean;
  className: string;
  dataTestid?: string;
}

export default class TabOptionsHeader extends React.PureComponent<Props> {
  static defaultProps = {
    tabKey: "",
    className: "",
  };

  handleClick = (evt: React.MouseEvent): void => {
    const { onItemClick, tabKey } = this.props;

    evt.stopPropagation();

    if (onItemClick) {
      onItemClick(tabKey);
    }
  };

  render() {
    const { children, isActive, className, dataTestid } = this.props;

    let classNames: Array<string> = [className, "ddbc-tab-options__header"];
    let headingClassNames: Array<string> = ["ddbc-tab-options__header-heading"];
    if (isActive) {
      classNames.push("ddbc-tab-options__header--is-active");
      headingClassNames.push("ddbc-tab-options__header-heading--is-active");
    }

    return (
      <div
        className={classNames.join(" ")}
        onClick={this.handleClick}
        role="button"
        data-testid={dataTestid}
      >
        <div className={headingClassNames.join(" ")}>{children}</div>
      </div>
    );
  }
}
