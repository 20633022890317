export enum PaneComponentEnum {
  ERROR_404 = "ERROR_404",
  ABILITY = "ABILITY",
  ABILITY_SAVING_THROW = "ABILITY_SAVING_THROW",
  ACTION = "ACTION",
  ARMOR_MANAGE = "ARMOR_MANAGE",
  BASIC_ACTION = "BASIC_ACTION",
  BACKGROUND = "BACKGROUND",
  BLESSING_DETAIL = "BLESSING_DETAIL",
  CAMPAIGN = "CAMPAIGN",
  CHARACTER_MANAGE = "CHARACTER_MANAGE",
  CHARACTER_SPELL_DETAIL = "CHARACTER_SPELL_DETAIL",
  CLASS_FEATURE_DETAIL = "CLASS_FEATURE_DETAIL",
  CLASS_SPELL_DETAIL = "CLASS_SPELL_DETAIL",
  CONDITION_MANAGE = "CONDITION_MANAGE",
  CONTAINER = "CONTAINER",
  CREATURE = "CREATURE",
  CURRENCY = "CURRENCY",
  CUSTOM_ACTIONS = "CUSTOM_ACTIONS",
  CUSTOM_ACTION = "CUSTOM_ACTION",
  CUSTOM_SKILL = "CUSTOM_SKILL",
  DECORATE = "DECORATE",
  DEFENSE_MANAGE = "DEFENSE_MANAGE",
  DESCRIPTION = "DESCRIPTION",
  ENCUMBRANCE = "ENCUMBRANCE",
  EQUIPMENT_MANAGE = "EQUIPMENT_MANAGE",
  EXPORT_PDF = "EXPORT_PDF",
  EXTRA_MANAGE = "EXTRA_MANAGE",
  FEAT_DETAIL = "FEAT_DETAIL",
  FEATS_MANAGE = "FEATS_MANAGE",
  GAME_LOG = "GAME_LOG",
  HEALTH_MANAGE = "HEALTH_MANAGE",
  INFUSION_CHOICE = "INFUSION_CHOICE",
  INITIATIVE = "INITIATIVE",
  INSPIRATION = "INSPIRATION",
  ITEM_DETAIL = "ITEM_DETAIL",
  LONG_REST = "LONG_REST",
  NOTE_MANAGE = "NOTE_MANAGE",
  POSSESSIONS_MANAGE = "POSSESSIONS_MANAGE",
  PREFERENCES = "PREFERENCES",
  PREFERENCES_HIT_POINT_CONFIRM = "PREFERENCES_HIT_POINT_CONFIRM",
  PREFERENCES_OPTIONAL_CLASS_FEATURES_CONFIRM = "PREFERENCES_OPTIONAL_CLASS_FEATURES_CONFIRM",
  PREFERENCES_OPTIONAL_ORIGINS_CONFIRM = "PREFERENCES_OPTIONAL_ORIGINS_CONFIRM",
  PREFERENCES_PROGRESSION_CONFIRM = "PREFERENCES_PROGRESSION_CONFIRM",
  PROFICIENCIES = "PROFICIENCIES",
  PROFICIENCY_BONUS = "PROFICIENCY_BONUS",
  SPECIES_TRAIT_DETAIL = "SPECIES_TRAIT_DETAIL",
  SAVING_THROWS = "SAVING_THROWS",
  SENSE_MANAGE = "SENSE_MANAGE",
  SETTINGS = "SETTINGS",
  SHARE_URL = "SHARE_URL",
  SHORT_REST = "SHORT_REST",
  SKILLS = "SKILLS",
  SKILL = "SKILL",
  SPEED_MANAGE = "SPEED_MANAGE",
  SPELL_MANAGE = "SPELL_MANAGE",
  STARTING_EQUIPMENT = "STARTING_EQUIPMENT",
  TRAIT = "TRAIT",
  VEHICLE = "VEHICLE",
  VEHICLE_COMPONENT = "VEHICLE_COMPONENT",
  VEHICLE_ACTION_STATION = "VEHICLE_ACTION_STATION",
  XP = "XP",
}
