import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class CopperCoinSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;

    return (
      <BaseSvg viewBox="0 0 24 24" className={className}>
        <path
          clipRule="evenodd"
          d="m6.41421 3c-.26521 0-.51957.10536-.7071.29289l-2.41422 2.41422c-.18753.18753-.29289.44189-.29289.7071v11.17159c0 .2652.10536.5196.29289.7071l2.41422 2.4142c.18753.1875.44189.2929.7071.2929h11.17159c.2652 0 .5196-.1054.7071-.2929l2.4142-2.4142c.1875-.1875.2929-.4419.2929-.7071v-11.17159c0-.26521-.1054-.51957-.2929-.7071l-2.4142-2.41422c-.1875-.18753-.4419-.29289-.7071-.29289zm5.58579 10.5c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5z"
          fill="#ab6e57"
          fillRule="evenodd"
        />
        <path
          d="m10 9c0-1.10457.8954-2 2-2h4c1.1046 0 2 .89543 2 2v1c0 1.1046-.8954 2-2 2h-1c0-1.6569-1.3431-3-3-3-.7684 0-1.4692.28885-2 .76389z"
          fill="#c2866f"
        />
        <path
          d="m17.7071 7.94975-.6568-.65686c-.1876-.18753-.4419-.29289-.7072-.29289h-8.68625c-.26521 0-.51957.10536-.7071.29289l-.65686.65686c-.18753.18753-.29289.44189-.29289.7071v-1c0-.26521.10536-.51957.29289-.7071l.65686-.65686c.18753-.18753.44189-.29289.7071-.29289h8.68625c.2653 0 .5196.10536.7072.29289l.6568.65686c.1875.18753.2929.44189.2929.7071v1c0-.26521-.1054-.51957-.2929-.7071z"
          fill="#9f6854"
        />
        <path
          d="m14.9585 12.5c-.238 1.4189-1.472 2.5-2.9585 2.5s-2.72048-1.0811-2.95852-2.5c-.02728.1626-.04148.3296-.04148.5 0 1.6569 1.3431 3 3 3s3-1.3431 3-3c0-.1704-.0142-.3374-.0415-.5z"
          fill="#9f6854"
        />
        <g fill="#c2866f">
          <path
            clipRule="evenodd"
            d="m15 12c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3 1.3431-3 3-3 3 1.3431 3 3zm-3 1.5c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5z"
            fillRule="evenodd"
          />
          <circle cx="12" cy="12" fillOpacity=".5" r="1.5" />
          <path
            clipRule="evenodd"
            d="m6.29289 6.94975c-.18753.18753-.29289.44189-.29289.7071v8.68625c0 .2653.10536.5196.29289.7072l.65686.6568c.18753.1875.44189.2929.7071.2929h8.68625c.2653 0 .5196-.1054.7072-.2929l.6568-.6568c.1875-.1876.2929-.4419.2929-.7072v-8.68625c0-.26521-.1054-.51957-.2929-.7071l-.6568-.65686c-.1876-.18753-.4419-.29289-.7072-.29289h-8.68625c-.26521 0-.51957.10536-.7071.29289zm.12132-3.94975c-.26521 0-.51957.10536-.7071.29289l-2.41422 2.41422c-.18753.18753-.29289.44189-.29289.7071v11.17159c0 .2652.10536.5196.29289.7071l2.41422 2.4142c.18753.1875.44189.2929.7071.2929h11.17159c.2652 0 .5196-.1054.7071-.2929l2.4142-2.4142c.1875-.1875.2929-.4419.2929-.7071v-11.17159c0-.26521-.1054-.51957-.2929-.7071l-2.4142-2.41422c-.1875-.18753-.4419-.29289-.7071-.29289z"
            fillRule="evenodd"
          />
        </g>
        <path
          clipRule="evenodd"
          d="m10.125 4.5c0-.48325.3918-.875.875-.875h6.0858c.4973 0 .9742.19754 1.3258.54917l1.4142 1.41422c.3517.35163.5492.82854.5492 1.32582v3.58579c0 .4832-.3918.875-.875.875s-.875-.3918-.875-.875v-3.58579c0-.03315-.0132-.06494-.0366-.08838l-1.4142-1.41422c-.0235-.02344-.0553-.03661-.0884-.03661h-6.0858c-.4832 0-.875-.39175-.875-.875z"
          fill="#e6a58c"
          fillRule="evenodd"
        />
        <circle cx="8.375" cy="4.47501" fill="#e5a48c" r=".875" />
      </BaseSvg>
    );
  }
}
