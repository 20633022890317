import { visuallyHidden } from "@mui/utils";
import { FC, HTMLAttributes } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { useFeatureFlags } from "~/contexts/FeatureFlag";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";

import { sidebarActions } from "../../../../Shared/actions";
import { appEnvSelectors } from "../../../../Shared/selectors";
import { PaneComponentEnum } from "../../../../Shared/utils";
import MobileDivider from "../../../components/MobileDivider";
import ProficiencyGroups from "../../../components/ProficiencyGroups";
import SubsectionMobile from "../../../components/SubsectionMobile";
import { SheetAppState } from "../../../typings";

interface Props {}
export const ProficiencyGroupsMobile: FC<Props> = () => {
  const dispatch = useDispatch();
  const {
    featureFlags: { osirisCharacterAppFeFlag },
  } = useFeatureFlags();
  const { proficiencyGroups, characterTheme } = useCharacterEngine();
  const isReadonly = useSelector(appEnvSelectors.getIsReadonly);

  const handleManageShow = (): void => {
    if (!isReadonly) {
      dispatch(
        sidebarActions.paneHistoryStart(PaneComponentEnum.PROFICIENCIES)
      );
    }
  };

  return (
    <SubsectionMobile name="Proficiency Groups">
      <MobileDivider
        label={
          osirisCharacterAppFeFlag
            ? "Proficiencies & Training"
            : "Proficiencies & Languages"
        }
        onClick={handleManageShow}
        isReadonly={isReadonly}
        theme={characterTheme}
      />
      <section className="ct-proficiency-groups-mobile">
        <h2 style={visuallyHidden}>
          Proficiencies and{" "}
          {osirisCharacterAppFeFlag ? "Training" : "Languages"}
        </h2>
        <ProficiencyGroups
          proficiencyGroups={proficiencyGroups}
          onClick={handleManageShow}
        />
      </section>
      <MobileDivider isEnd={true} theme={characterTheme} />
    </SubsectionMobile>
  );
};
