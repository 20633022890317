export const CHARACTER_LOAD = 'character.CHARACTER_LOAD';
export const CHARACTER_LOAD_POST_ACTION = 'character.CHARACTER_LOAD_POST_ACTION';
export const NAME_SET = 'character.NAME_SET';
export const NAME_SET_COMMIT = 'character.NAME_SET_COMMIT';
export const RANDOM_NAME_REQUEST = 'character.RANDOM_NAME_REQUEST';
export const ALIGNMENT_SET = 'character.ALIGNMENT_SET';
export const ALIGNMENT_SET_COMMIT = 'character.ALIGNMENT_SET_COMMIT';
export const LIFESTYLE_SET = 'character.LIFESTYLE_SET';
export const LIFESTYLE_SET_COMMIT = 'character.LIFESTYLE_SET_COMMIT';
export const FAITH_SET = 'character.FAITH_SET';
export const FAITH_SET_COMMIT = 'character.FAITH_SET_COMMIT';
export const HAIR_SET = 'character.HAIR_SET';
export const HAIR_SET_COMMIT = 'character.HAIR_SET_COMMIT';
export const SKIN_SET = 'character.SKIN_SET';
export const SKIN_SET_COMMIT = 'character.SKIN_SET_COMMIT';
export const EYES_SET = 'character.EYES_SET';
export const EYES_SET_COMMIT = 'character.EYES_SET_COMMIT';
export const HEIGHT_SET = 'character.HEIGHT_SET';
export const HEIGHT_SET_COMMIT = 'character.HEIGHT_SET_COMMIT';
export const WEIGHT_SET = 'character.WEIGHT_SET';
export const WEIGHT_SET_COMMIT = 'character.WEIGHT_SET_COMMIT';
export const AGE_SET = 'character.AGE_SET';
export const AGE_SET_COMMIT = 'character.AGE_SET_COMMIT';
export const GENDER_SET = 'character.GENDER_SET';
export const GENDER_SET_COMMIT = 'character.GENDER_SET_COMMIT';
export const NOTE_SET = 'character.NOTE_SET';
export const NOTE_SET_COMMIT = 'character.NOTE_SET_COMMIT';
export const TRAIT_SET = 'character.TRAIT_SET';
export const TRAIT_SET_COMMIT = 'character.TRAIT_SET_COMMIT';
export const STARTING_EQUIPMENT_ADD_REQUEST = 'character.STARTING_EQUIPMENT_ADD_REQUEST';
export const STARTING_GOLD_ADD_REQUEST = 'character.STARTING_GOLD_ADD_REQUEST';
export const ABILITY_SCORE_SET = 'character.ABILITY_SCORE_SET';
export const ABILITY_SCORE_BONUS_SET = 'character.ABILITY_SCORE_BONUS_SET';
export const ABILITY_SCORE_BONUS_SET_COMMIT = 'character.ABILITY_SCORE_BONUS_SET_COMMIT';
export const ABILITY_SCORE_OVERRIDE_SET = 'character.ABILITY_SCORE_OVERRIDE_SET';
export const ABILITY_SCORE_OVERRIDE_SET_COMMIT = 'character.ABILITY_SCORE_OVERRIDE_SET_COMMIT';
export const ABILITY_SCORE_BASE_SET = 'character.ABILITY_SCORE_BASE_SET';
export const ABILITY_SCORE_BASE_SET_COMMIT = 'character.ABILITY_SCORE_BASE_SET_COMMIT';
export const MOVEMENT_ADD = 'character.MOVEMENT_ADD';
export const MOVEMENT_ADD_COMMIT = 'character.MOVEMENT_ADD_COMMIT';
export const MOVEMENT_SET = 'character.MOVEMENT_SET';
export const MOVEMENT_SET_COMMIT = 'character.MOVEMENT_SET_COMMIT';
export const MOVEMENT_REMOVE = 'character.MOVEMENT_REMOVE';
export const MOVEMENT_REMOVE_COMMIT = 'character.MOVEMENT_REMOVE_COMMIT';
export const SENSE_ADD = 'character.SENSE_ADD';
export const SENSE_ADD_COMMIT = 'character.SENSE_ADD_COMMIT';
export const SENSE_SET = 'character.SENSE_SET';
export const SENSE_SET_COMMIT = 'character.SENSE_SET_COMMIT';
export const SENSE_REMOVE = 'character.SENSE_REMOVE';
export const SENSE_REMOVE_COMMIT = 'character.SENSE_REMOVE_COMMIT';
export const CUSTOM_PROFICIENCY_CREATE = 'character.CUSTOM_PROFICIENCY_CREATE';
export const CUSTOM_PROFICIENCY_ADD = 'character.CUSTOM_PROFICIENCY_ADD';
export const CUSTOM_PROFICIENCY_ADD_COMMIT = 'character.CUSTOM_PROFICIENCY_ADD_COMMIT';
export const CUSTOM_PROFICIENCY_SET = 'character.CUSTOM_PROFICIENCY_SET';
export const CUSTOM_PROFICIENCY_SET_COMMIT = 'character.CUSTOM_PROFICIENCY_SET_COMMIT';
export const CUSTOM_PROFICIENCY_REMOVE = 'character.CUSTOM_PROFICIENCY_REMOVE';
export const CUSTOM_PROFICIENCY_REMOVE_COMMIT = 'character.CUSTOM_PROFICIENCY_REMOVE_COMMIT';
export const CUSTOM_DEFENSE_ADJUSTMENT_ADD = 'character.CUSTOM_DEFENSE_ADJUSTMENT_ADD';
export const CUSTOM_DEFENSE_ADJUSTMENT_ADD_COMMIT = 'character.CUSTOM_DEFENSE_ADJUSTMENT_ADD_COMMIT';
export const CUSTOM_DEFENSE_ADJUSTMENT_SET = 'character.CUSTOM_DEFENSE_ADJUSTMENT_SET';
export const CUSTOM_DEFENSE_ADJUSTMENT_SET_COMMIT = 'character.CUSTOM_DEFENSE_ADJUSTMENT_SET_COMMIT';
export const CUSTOM_DEFENSE_ADJUSTMENT_REMOVE = 'character.CUSTOM_DEFENSE_ADJUSTMENT_REMOVE';
export const CUSTOM_DEFENSE_ADJUSTMENT_REMOVE_COMMIT = 'character.CUSTOM_DEFENSE_ADJUSTMENT_REMOVE_COMMIT';
export const XP_SET = 'character.XP_SET';
export const XP_SET_COMMIT = 'character.XP_SET_COMMIT';
export const XP_SET_REQUEST = 'character.XP_SET_REQUEST';
export const BASE_HIT_POINTS_SET = 'character.BASE_HIT_POINTS_SET';
export const BASE_HIT_POINTS_SET_COMMIT = 'character.BASE_HIT_POINTS_SET_COMMIT';
export const HIT_POINTS_SET = 'character.HIT_POINTS_SET';
export const HIT_POINTS_SET_COMMIT = 'character.HIT_POINTS_SET_COMMIT';
export const BONUS_HIT_POINTS_SET = 'character.BONUS_HIT_POINTS_SET';
export const BONUS_HIT_POINTS_SET_COMMIT = 'character.BONUS_HIT_POINTS_SET_COMMIT';
export const OVERRIDE_HIT_POINTS_SET = 'character.OVERRIDE_HIT_POINTS_SET';
export const OVERRIDE_HIT_POINTS_SET_COMMIT = 'character.OVERRIDE_HIT_POINTS_SET_COMMIT';
export const DEATHSAVES_SET = 'character.DEATHSAVES_SET';
export const DEATHSAVES_SET_COMMIT = 'character.DEATHSAVES_SET_COMMIT';
export const RESTORE_LIFE = 'character.RESTORE_LIFE';
export const SHORT_REST = 'character.SHORT_REST';
export const SHORT_REST_COMMIT = 'character.SHORT_REST_COMMIT';
export const LONG_REST = 'character.LONG_REST';
export const LONG_REST_COMMIT = 'character.LONG_REST_COMMIT';
export const CURRENCIES_SET = 'character.CURRENCIES_SET';
export const CURRENCIES_SET_COMMIT = 'character.CURRENCIES_SET_COMMIT';
export const CURRENCY_TRANSACTION_SET = 'character.CURRENCY_TRANSACTION_SET';
export const CURRENCY_COPPER_SET = 'character.CURRENCY_COPPER_SET';
export const CURRENCY_COPPER_SET_COMMIT = 'character.CURRENCY_COPPER_SET_COMMIT';
export const CURRENCY_ELECTRUM_SET = 'character.CURRENCY_ELECTRUM_SET';
export const CURRENCY_ELECTRUM_SET_COMMIT = 'character.CURRENCY_ELECTRUM_SET_COMMIT';
export const CURRENCY_GOLD_SET = 'character.CURRENCY_GOLD_SET';
export const CURRENCY_GOLD_SET_COMMIT = 'character.CURRENCY_GOLD_SET_COMMIT';
export const CURRENCY_PLATINUM_SET = 'character.CURRENCY_PLATINUM_SET';
export const CURRENCY_PLATINUM_SET_COMMIT = 'character.CURRENCY_PLATINUM_SET_COMMIT';
export const CURRENCY_SILVER_SET = 'character.CURRENCY_SILVER_SET';
export const CURRENCY_SILVER_SET_COMMIT = 'character.CURRENCY_SILVER_SET_COMMIT';
export const INSPIRATION_SET = 'character.INSPIRATION_SET';
export const INSPIRATION_SET_COMMIT = 'character.INSPIRATION_SET_COMMIT';
export const STATUS_SET = 'character.STATUS_SET';
export const STATUS_SET_COMMIT = 'character.STATUS_SET_COMMIT';
export const STATUS_SLUG_SET = 'character.STATUS_SLUG_SET';
export const STATUS_SLUG_SET_COMMIT = 'character.STATUS_SLUG_SET_COMMIT';
