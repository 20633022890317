import { Dispatch } from "react";

import {
  FeatManager,
  Action,
  ActionUtils,
  characterActions,
  Spell,
  SpellUtils,
} from "@dndbeyond/character-rules-engine/es";

import { sidebarActions } from "../tools/js/Shared/actions";
import {
  PaneComponentEnum,
  PaneIdentifierUtils,
} from "../tools/js/Shared/utils";

export const handleFeatShow = (feat: FeatManager, dispatch: Dispatch<any>) =>
  dispatch(
    sidebarActions.paneHistoryStart(
      PaneComponentEnum.FEAT_DETAIL,
      PaneIdentifierUtils.generateFeat(feat.getId())
    )
  );

export const handleActionUseSet = (
  action: Action,
  uses: number,
  dispatch: Dispatch<any>
): void => {
  const id = ActionUtils.getId(action);
  const entityTypeId = ActionUtils.getEntityTypeId(action);
  const dataOriginType = ActionUtils.getDataOriginType(action);

  if (id !== null && entityTypeId !== null) {
    dispatch(
      characterActions.actionUseSet(id, entityTypeId, uses, dataOriginType)
    );
  }
};

export const handleActionShow = (
  action: Action,
  dispatch: Dispatch<any>
): void => {
  const mappingId = ActionUtils.getMappingId(action);
  const mappingEntityTypeId = ActionUtils.getMappingEntityTypeId(action);

  if (mappingId !== null && mappingEntityTypeId !== null) {
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.ACTION,
        PaneIdentifierUtils.generateAction(mappingId, mappingEntityTypeId)
      )
    );
  }
};

export const handleSpellDetailShow = (
  spell: Spell,
  dispatch: Dispatch<any>
): void => {
  const mappingId = SpellUtils.getMappingId(spell);
  if (mappingId !== null) {
    dispatch(
      sidebarActions.paneHistoryStart(
        PaneComponentEnum.CHARACTER_SPELL_DETAIL,
        PaneIdentifierUtils.generateCharacterSpell(mappingId)
      )
    );
  }
};

export const handleSpellUseSet = (
  spell: Spell,
  uses: number,
  dispatch: Dispatch<any>
): void => {
  const mappingId = SpellUtils.getMappingId(spell);
  const mappingEntityTypeId = SpellUtils.getMappingEntityTypeId(spell);
  const dataOriginType = SpellUtils.getDataOriginType(spell);

  if (
    mappingId !== null &&
    mappingEntityTypeId !== null &&
    dataOriginType !== null
  ) {
    dispatch(
      characterActions.spellUseSet(
        mappingId,
        mappingEntityTypeId,
        uses,
        dataOriginType
      )
    );
  }
};
