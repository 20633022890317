export const ITEM_CREATE = 'character.ITEM_CREATE';
export const ITEM_ADD = 'character.ITEM_ADD';
export const ITEM_ADD_COMMIT = 'character.ITEM_ADD_COMMIT';
export const ITEM_REMOVE = 'character.ITEM_REMOVE';
export const ITEM_REMOVE_COMMIT = 'character.ITEM_REMOVE_COMMIT';
export const ITEM_DESTROY = 'character.ITEM_DESTROY';
export const ITEM_MOVE_SET = 'character.ITEM_MOVE_SET';
export const ITEM_MOVE_SET_COMMIT = 'character.ITEM_MOVE_SET_COMMIT';
export const ITEM_EQUIPPED_SET = 'character.ITEM_EQUIPPED_SET';
export const ITEM_EQUIPPED_SET_COMMIT = 'character.ITEM_EQUIPPED_SET_COMMIT';
export const ITEM_ATTUNE_SET = 'character.ITEM_ATTUNE_SET';
export const ITEM_ATTUNE_SET_COMMIT = 'character.ITEM_ATTUNE_SET_COMMIT';
export const ITEM_QUANTITY_SET = 'character.ITEM_QUANTITY_SET';
export const ITEM_QUANTITY_SET_COMMIT = 'character.ITEM_QUANTITY_SET_COMMIT';
export const ITEM_CHARGES_SET = 'character.ITEM_CHARGES_SET';
export const ITEM_CHARGES_SET_COMMIT = 'character.ITEM_CHARGES_SET_COMMIT';
export const ITEM_DISPLAY_AS_ATTACK_SET = 'character.ITEM_DISPLAY_AS_ATTACK_SET';
export const ITEM_DISPLAY_AS_ATTACK_SET_COMMIT = 'character.ITEM_DISPLAY_AS_ATTACK_SET_COMMIT';
export const ITEM_CUSTOMIZATIONS_DELETE = 'character.ITEM_CUSTOMIZATIONS_DELETE';
export const CUSTOM_ITEM_CREATE = 'character.CUSTOM_ITEM_CREATE';
export const CUSTOM_ITEM_DESTROY = 'character.CUSTOM_ITEM_DESTROY';
export const CUSTOM_ITEM_ADD = 'character.CUSTOM_ITEM_ADD';
export const CUSTOM_ITEM_ADD_COMMIT = 'character.CUSTOM_ITEM_ADD_COMMIT';
export const CUSTOM_ITEM_REMOVE = 'character.CUSTOM_ITEM_REMOVE';
export const CUSTOM_ITEM_REMOVE_COMMIT = 'character.CUSTOM_ITEM_REMOVE_COMMIT';
export const CUSTOM_ITEM_SET = 'character.CUSTOM_ITEM_SET';
export const CUSTOM_ITEM_SET_COMMIT = 'character.CUSTOM_ITEM_SET_COMMIT';
export const ITEM_CURRENCY_SET = 'character.ITEM_CURRENCY_SET';
export const ITEM_CURRENCY_SET_COMMIT = 'character.ITEM_CURRENCY_SET_COMMIT';
export const ITEM_CURRENCY_COPPER_SET = 'character.ITEM_CURRENCY_COPPER_SET';
export const ITEM_CURRENCY_COPPER_SET_COMMIT = 'character.ITEM_CURRENCY_COPPER_SET_COMMIT';
export const ITEM_CURRENCY_ELECTRUM_SET = 'character.ITEM_CURRENCY_ELECTRUM_SET';
export const ITEM_CURRENCY_ELECTRUM_SET_COMMIT = 'character.ITEM_CURRENCY_ELECTRUM_SET_COMMIT';
export const ITEM_CURRENCY_GOLD_SET = 'character.ITEM_CURRENCY_GOLD_SET';
export const ITEM_CURRENCY_GOLD_SET_COMMIT = 'character.ITEM_CURRENCY_GOLD_SET_COMMIT';
export const ITEM_CURRENCY_PLATINUM_SET = 'character.ITEM_CURRENCY_PLATINUM_SET';
export const ITEM_CURRENCY_PLATINUM_SET_COMMIT = 'character.ITEM_CURRENCY_PLATINUM_SET_COMMIT';
export const ITEM_CURRENCY_SILVER_SET = 'character.ITEM_CURRENCY_SILVER_SET';
export const ITEM_CURRENCY_SILVER_SET_COMMIT = 'character.ITEM_CURRENCY_SILVER_SET_COMMIT';
