import { visuallyHidden } from "@mui/utils";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  TabOptions,
  TabOptionsContent,
  TabOptionsGroup,
} from "@dndbeyond/character-common-components/es";
import {
  rulesEngineSelectors,
  ClassUtils,
  FeatureUtils,
  InfusionChoiceUtils,
  RacialTrait,
  RacialTraitUtils,
  FeaturesManager,
} from "@dndbeyond/character-rules-engine/es";

import { useFeatureFlags } from "~/contexts/FeatureFlag";
import { SpeciesDetail } from "~/subApps/sheet/components/SpeciesDetail";
import { ThemeButton } from "~/tools/js/Shared/components/common/Button";

import {
  handleActionShow,
  handleActionUseSet,
  handleFeatShow,
  handleSpellDetailShow,
  handleSpellUseSet,
} from "../../../../../handlers/commonHandlers";
import { sidebarActions } from "../../../Shared/actions";
import { CharacterFeaturesManagerContext } from "../../../Shared/managers/CharacterFeaturesManagerContext";
import { appEnvSelectors } from "../../../Shared/selectors";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../Shared/utils";
import BlessingsDetail from "../../components/BlessingsDetail";
import ClassesDetail from "../../components/ClassesDetail";
import ContentGroup from "../../components/ContentGroup";
import FeatsDetail from "../../components/FeatsDetail";

const SpeciesTraitsGroup: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { characterFeaturesManager } = useContext(
    CharacterFeaturesManagerContext
  );

  const snippetData = useSelector(rulesEngineSelectors.getSnippetData);
  const ruleData = useSelector(rulesEngineSelectors.getRuleData);
  const abilityLookup = useSelector(rulesEngineSelectors.getAbilityLookup);
  const dataOriginRefData = useSelector(
    rulesEngineSelectors.getDataOriginRefData
  );
  const isReadonly = useSelector(appEnvSelectors.getIsReadonly);
  const proficiencyBonus = useSelector(
    rulesEngineSelectors.getProficiencyBonus
  ) as number;
  const theme = useSelector(rulesEngineSelectors.getCharacterTheme);
  const speciesTraits = useSelector(
    rulesEngineSelectors.getCurrentLevelRacialTraits
  ) as RacialTrait[];

  return (
    <ContentGroup header="Species Traits">
      <SpeciesDetail
        onActionUseSet={(action, uses) =>
          handleActionUseSet(action, uses, dispatch)
        }
        onActionClick={(action) => handleActionShow(action, dispatch)}
        onSpellClick={(spell) => handleSpellDetailShow(spell, dispatch)}
        onSpellUseSet={(spell, uses) =>
          handleSpellUseSet(spell, uses, dispatch)
        }
        onFeatureClick={(feature) => {
          dispatch(
            sidebarActions.paneHistoryStart(
              PaneComponentEnum.SPECIES_TRAIT_DETAIL,
              PaneIdentifierUtils.generateRacialTrait(
                RacialTraitUtils.getId(feature)
              )
            )
          );
        }}
        feats={characterFeaturesManager
          .getFeats()
          .map((manager) => manager.feat)}
        isReadonly={isReadonly}
        snippetData={snippetData}
        ruleData={ruleData}
        abilityLookup={abilityLookup}
        dataOriginRefData={dataOriginRefData}
        proficiencyBonus={proficiencyBonus}
        theme={theme}
        speciesTraits={speciesTraits}
        onFeatClick={(feat) => handleFeatShow(feat, dispatch)}
        featuresManager={characterFeaturesManager}
      />
    </ContentGroup>
  );
};

const ClassFeaturesGroup: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { characterFeaturesManager } = useContext(
    CharacterFeaturesManagerContext
  );

  const classes = useSelector(rulesEngineSelectors.getClasses);
  const snippetData = useSelector(rulesEngineSelectors.getSnippetData);
  const ruleData = useSelector(rulesEngineSelectors.getRuleData);
  const abilityLookup = useSelector(rulesEngineSelectors.getAbilityLookup);
  const dataOriginRefData = useSelector(
    rulesEngineSelectors.getDataOriginRefData
  );
  const isReadonly = useSelector(appEnvSelectors.getIsReadonly);
  const proficiencyBonus = useSelector(
    rulesEngineSelectors.getProficiencyBonus
  );
  const theme = useSelector(rulesEngineSelectors.getCharacterTheme);

  return (
    <ContentGroup header="Class Features">
      <ClassesDetail
        classes={classes}
        onActionUseSet={(action, uses) =>
          handleActionUseSet(action, uses, dispatch)
        }
        onActionClick={(action) => handleActionShow(action, dispatch)}
        onSpellClick={(spell) => handleSpellDetailShow(spell, dispatch)}
        onSpellUseSet={(spell, uses) =>
          handleSpellUseSet(spell, uses, dispatch)
        }
        onFeatureClick={(feature, charClass) => {
          dispatch(
            sidebarActions.paneHistoryStart(
              PaneComponentEnum.CLASS_FEATURE_DETAIL,
              PaneIdentifierUtils.generateClassFeature(
                FeatureUtils.getId(feature),
                ClassUtils.getMappingId(charClass)
              )
            )
          );
        }}
        onInfusionChoiceClick={(infusionChoice) => {
          const choiceKey = InfusionChoiceUtils.getKey(infusionChoice);
          if (choiceKey !== null) {
            dispatch(
              sidebarActions.paneHistoryStart(
                PaneComponentEnum.INFUSION_CHOICE,
                PaneIdentifierUtils.generateInfusionChoice(choiceKey)
              )
            );
          }
        }}
        feats={characterFeaturesManager
          .getFeats()
          .map((manager) => manager.feat)}
        isReadonly={isReadonly}
        snippetData={snippetData}
        ruleData={ruleData}
        abilityLookup={abilityLookup}
        dataOriginRefData={dataOriginRefData}
        proficiencyBonus={proficiencyBonus}
        theme={theme}
        onFeatClick={(feat) => handleFeatShow(feat, dispatch)}
        featuresManager={characterFeaturesManager}
      />
    </ContentGroup>
  );
};

const FeatsGroup: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { gfsBlessingsUiFlag } = useFeatureFlags();

  const snippetData = useSelector(rulesEngineSelectors.getSnippetData);
  const ruleData = useSelector(rulesEngineSelectors.getRuleData);
  const abilityLookup = useSelector(rulesEngineSelectors.getAbilityLookup);
  const dataOriginRefData = useSelector(
    rulesEngineSelectors.getDataOriginRefData
  );
  const isReadonly = useSelector(appEnvSelectors.getIsReadonly);
  const proficiencyBonus = useSelector(
    rulesEngineSelectors.getProficiencyBonus
  );
  const theme = useSelector(rulesEngineSelectors.getCharacterTheme);

  return (
    <ContentGroup header="Feats">
      {!isReadonly && (
        <div className="ct-features__management-link">
          <ThemeButton
            onClick={() =>
              dispatch(
                sidebarActions.paneHistoryStart(PaneComponentEnum.FEATS_MANAGE)
              )
            }
            style="outline"
            size="medium"
          >
            Manage {gfsBlessingsUiFlag ? "Features" : "Feats"}
          </ThemeButton>
        </div>
      )}
      <FeatsDetail
        onActionUseSet={(action, uses) =>
          handleActionUseSet(action, uses, dispatch)
        }
        onActionClick={(action) => handleActionShow(action, dispatch)}
        onSpellClick={(spell) => handleSpellDetailShow(spell, dispatch)}
        onSpellUseSet={(spell, uses) =>
          handleSpellUseSet(spell, uses, dispatch)
        }
        onFeatureClick={(feat) => handleFeatShow(feat, dispatch)}
        isReadonly={isReadonly}
        snippetData={snippetData}
        ruleData={ruleData}
        abilityLookup={abilityLookup}
        dataOriginRefData={dataOriginRefData}
        proficiencyBonus={proficiencyBonus}
        theme={theme}
      />
    </ContentGroup>
  );
};

const BlessingsGroup: React.FC<{}> = () => {
  return (
    <ContentGroup header="Blessings">
      <BlessingsDetail />
    </ContentGroup>
  );
};

const Features: React.FC<{}> = () => {
  const { gfsBlessingsUiFlag } = useFeatureFlags();
  const { characterFeaturesManager } = useContext(
    CharacterFeaturesManagerContext
  );

  const TAB_KEY = {
    ALL: "ALL",
    CLASS: "CLASS",
    SPECIES: "SPECIES",
    FEAT: "FEAT",
    BLESSING: "BLESSING",
  };

  const [hasBlessings, setHasBlessings] = useState(false);

  useEffect(() => {
    async function onUpdate() {
      const hasBlessings = gfsBlessingsUiFlag
        ? await characterFeaturesManager.hasBlessings()
        : false;
      setHasBlessings(hasBlessings);
    }
    return FeaturesManager.subscribeToUpdates({ onUpdate });
  }, [setHasBlessings]);

  return (
    <section className="ct-features">
      <h2 style={visuallyHidden}>Features and Traits</h2>
      <TabOptions
        hideBorder={true}
        initialActiveKey={TAB_KEY.ALL}
        layoutType="pill"
      >
        <TabOptionsGroup tabKey={TAB_KEY.ALL} label="All">
          <TabOptionsContent>
            <ClassFeaturesGroup />
            <SpeciesTraitsGroup />
            <FeatsGroup />
            {gfsBlessingsUiFlag && hasBlessings && <BlessingsGroup />}
          </TabOptionsContent>
        </TabOptionsGroup>

        <TabOptionsGroup tabKey={TAB_KEY.CLASS} label="Class Features">
          <TabOptionsContent>
            <ClassFeaturesGroup />
          </TabOptionsContent>
        </TabOptionsGroup>

        <TabOptionsGroup tabKey={TAB_KEY.SPECIES} label="Species Traits">
          <TabOptionsContent>
            <SpeciesTraitsGroup />
          </TabOptionsContent>
        </TabOptionsGroup>

        <TabOptionsGroup tabKey={TAB_KEY.FEAT} label="Feats">
          <TabOptionsContent>
            <FeatsGroup />
          </TabOptionsContent>
        </TabOptionsGroup>
        {gfsBlessingsUiFlag && hasBlessings && (
          <TabOptionsGroup tabKey={TAB_KEY.BLESSING} label="Blessings">
            <TabOptionsContent>
              <BlessingsGroup />
            </TabOptionsContent>
          </TabOptionsGroup>
        )}
      </TabOptions>
    </section>
  );
};

export default Features;
