import * as CreatureAccessors from './accessors';
import * as CreatureConstants from './constants';
import * as CreatureDerivers from './derivers';
import * as CreatureGenerators from './generators';
import * as CreatureNotes from './notes';
import * as CreatureSimulators from './simulators';
import * as CreatureTypings from './typings';
import * as CreatureUtils from './utils';
export * from './typings';
export * from './constants';
export { CreatureAccessors, CreatureDerivers, CreatureGenerators, CreatureNotes, CreatureSimulators, CreatureUtils };
export default Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, CreatureAccessors), CreatureConstants), CreatureDerivers), CreatureGenerators), CreatureNotes), CreatureTypings), CreatureSimulators), CreatureUtils);
