import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  ApiAdapterResponse,
  ApiAdapterUtils,
  ApiRequests,
  ApiResponse,
  characterActions,
  rulesEngineSelectors,
  syncTransactionActions,
  characterSagaHandlers,
} from "@dndbeyond/character-rules-engine/es";

import { sidebarActions } from "../../Shared/actions";
import { PaneHistoryInitTypeEnum } from "../../Shared/constants/App";
import { appEnvSelectors } from "../../Shared/selectors";
import { PaneComponentEnum } from "../../Shared/utils";
import { PaneIdentifiers } from "../../Shared/utils/PaneIdentifier/typings";
import appConfig from "../../config";
import {
  CharacterInitAction,
  ExportPdfAction,
  ShareUrlAction,
  SheetAction,
  sheetActions,
  sheetActionTypes,
} from "../actions/sheet";

export default function* saga() {
  yield takeEvery(
    Object.keys(sheetActionTypes).map((key) => sheetActionTypes[key]),
    filter
  );
}

function* filter(action: SheetAction) {
  switch (action.type) {
    case sheetActionTypes.CHARACTER_INIT:
      yield call(initCurrentCharacter, action);
      break;

    case sheetActionTypes.SHARE_URL:
      yield call(handleShareUrl, action);
      break;

    case sheetActionTypes.EXPORT_PDF:
      yield call(handleExportPdf, action);
      break;

    default:
    // not implemented
  }
}

export function* handlePaneHistoryInit(
  type: PaneHistoryInitTypeEnum,
  paneComponent: PaneComponentEnum,
  componentIdentifiers: PaneIdentifiers | null,
  componentProps: any
) {
  switch (type) {
    case PaneHistoryInitTypeEnum.PUSH:
      yield put(
        sidebarActions.paneHistoryPush(
          paneComponent,
          componentIdentifiers,
          componentProps
        )
      );
      break;
    case PaneHistoryInitTypeEnum.START:
    default:
      yield put(
        sidebarActions.paneHistoryStart(
          paneComponent,
          componentIdentifiers,
          componentProps
        )
      );
      break;
  }
}

export function* handleShareUrl(action: ShareUrlAction) {
  // get a generated URL for latest "version"
  const response: ApiAdapterResponse<ApiResponse<string>> = yield call(
    ApiRequests.getCharacterShareUrl
  );
  const data = ApiAdapterUtils.getResponseData(response);
  const componentProps: any = { shareUrl: data };

  yield call(
    handlePaneHistoryInit,
    action.payload.paneHistoryInitType,
    PaneComponentEnum.SHARE_URL,
    null,
    componentProps
  );

  // update the social image last so it doesn't block the URL as the URL is random and not data dependent
  try {
    yield call(handleSendSocialImageData);
  } catch (e) {
    // pass as we can silently fail
  }
}

export function* handleSendSocialImageData() {
  const socialImageData: ReturnType<
    typeof rulesEngineSelectors.getSocialImageData
  > = yield select(rulesEngineSelectors.getSocialImageData);
  const isSheetReady: ReturnType<
    typeof rulesEngineSelectors.isCharacterSheetReady
  > = yield select(rulesEngineSelectors.isCharacterSheetReady);
  if (isSheetReady) {
    let requestData: ApiRequests.PutCharacterDecorationSocialImage_RequestData =
      socialImageData;
    yield call(ApiRequests.putCharacterDecorationSocialImage, requestData);
  }
}

export function* handleExportPdf(action: ExportPdfAction) {
  let syncActivationId: string = "PDF-SYNC";
  yield put(syncTransactionActions.activate(syncActivationId));

  yield call(characterSagaHandlers.handleLoadCharacterLazyData);

  const pdfExportData: ReturnType<
    typeof rulesEngineSelectors.getPdfExportData
  > = yield select(rulesEngineSelectors.getPdfExportData);
  const response: ApiAdapterResponse<ApiResponse<string>> = yield call(
    ApiRequests.postCharacterPdf,
    {
      exportData: JSON.stringify(pdfExportData),
    }
  );
  const data = ApiAdapterUtils.getResponseData(response);
  const componentProps: any = {
    pdfUrl: data,
  };

  yield call(
    handlePaneHistoryInit,
    action.payload.paneHistoryInitType,
    PaneComponentEnum.EXPORT_PDF,
    null,
    componentProps
  );

  yield put(syncTransactionActions.deactivate());
}

function* initCurrentCharacter(action: CharacterInitAction) {
  const characterId: ReturnType<typeof appEnvSelectors.getCharacterId> =
    yield select(appEnvSelectors.getCharacterId);
  if (characterId === null) {
    yield put(
      sheetActions.characterReceiveFail(new Error("Missing Character Id"))
    );
    return;
  }

  try {
    yield put(
      characterActions.characterLoad(characterId, { v: appConfig.version })
    );
  } catch (error) {
    yield put(sheetActions.characterReceiveFail(error));
  }
}
