export var ComponentAdjustmentEnum;
(function (ComponentAdjustmentEnum) {
    ComponentAdjustmentEnum["HIT_POINT_SPEED_ADJUSTMENT"] = "speed";
})(ComponentAdjustmentEnum || (ComponentAdjustmentEnum = {}));
//TODO new for spelljammers
export var ComponentCostTypeEnum;
(function (ComponentCostTypeEnum) {
    ComponentCostTypeEnum["COMPONENT"] = "component";
    ComponentCostTypeEnum["AMMUNITION"] = "ammunition";
})(ComponentCostTypeEnum || (ComponentCostTypeEnum = {}));
