export const PARTY_ITEM_ADD = 'campaign.PARTY_ITEM_ADD';
export const PARTY_ITEM_ADD_COMMIT = 'campaign.PARTY_ITEM_ADD_COMMIT';
export const PARTY_ITEM_MOVE_SET = 'campaign.PARTY_ITEM_MOVE_SET';
export const PARTY_ITEM_MOVE_SET_COMMIT = 'campaign.PARTY_ITEM_MOVE_SET_COMMIT';
export const PARTY_ITEM_EQUIPPED_SET = 'campaign.PARTY_ITEM_EQUIPPED_SET';
export const PARTY_ITEM_EQUIPPED_SET_COMMIT = 'campaign.PARTY_ITEM_EQUIPPED_SET_COMMIT';
export const PARTY_ITEM_ATTUNE_SET = 'campaign.PARTY_ITEM_ATTUNE_SET';
export const PARTY_ITEM_ATTUNE_SET_COMMIT = 'campaign.PARTY_ITEM_ATTUNE_SET_COMMIT';
export const PARTY_ITEM_QUANTITY_SET = 'campaign.PARTY_ITEM_QUANTITY_SET';
export const PARTY_ITEM_QUANTITY_SET_COMMIT = 'campaign.PARTY_ITEM_QUANTITY_SET_COMMIT';
export const PARTY_ITEM_REMOVE = 'campaign.PARTY_ITEM_REMOVE';
export const PARTY_ITEM_REMOVE_COMMIT = 'campaign.PARTY_ITEM_REMOVE_COMMIT';
export const PARTY_ITEM_CHARGES_SET = 'campaign.PARTY_ITEM_CHARGES_SET';
export const PARTY_ITEM_CHARGES_SET_COMMIT = 'campaign.PARTY_ITEM_CHARGES_SET_COMMIT';
export const PARTY_CAMPAIGN_INFO_SET = 'campaign.PARTY_CAMPAIGN_INFO_SET';
export const PARTY_INVENTORY_REQUEST = 'campaign.PARTY_INVENTORY_REQUEST';
export const PARTY_CURRENCY_TRANSACTION_SET = 'campaign.PARTY_CURRENCY_TRANSACTION_SET';
export const PARTY_CURRENCIES_SET = 'campaign.PARTY_CURRENCIES_SET';
export const PARTY_CURRENCIES_SET_COMMIT = 'campaign.PARTY_CURRENCIES_SET_COMMIT';
export const PARTY_CURRENCY_COPPER_SET = 'campaign.PARTY_CURRENCY_COPPER_SET';
export const PARTY_CURRENCY_COPPER_SET_COMMIT = 'campaign.PARTY_CURRENCY_COPPER_SET_COMMIT';
export const PARTY_CURRENCY_ELECTRUM_SET = 'campaign.PARTY_CURRENCY_ELECTRUM_SET';
export const PARTY_CURRENCY_ELECTRUM_SET_COMMIT = 'campaign.PARTY_CURRENCY_ELECTRUM_SET_COMMIT';
export const PARTY_CURRENCY_GOLD_SET = 'campaign.PARTY_CURRENCY_GOLD_SET';
export const PARTY_CURRENCY_GOLD_SET_COMMIT = 'campaign.PARTY_CURRENCY_GOLD_SET_COMMIT';
export const PARTY_CURRENCY_PLATINUM_SET = 'campaign.PARTY_CURRENCY_PLATINUM_SET';
export const PARTY_CURRENCY_PLATINUM_SET_COMMIT = 'campaign.PARTY_CURRENCY_PLATINUM_SET_COMMIT';
export const PARTY_CURRENCY_SILVER_SET = 'campaign.PARTY_CURRENCY_SILVER_SET';
export const PARTY_CURRENCY_SILVER_SET_COMMIT = 'campaign.PARTY_CURRENCY_SILVER_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_SET = 'campaign.PARTY_ITEM_CURRENCY_SET';
export const PARTY_ITEM_CURRENCY_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_COPPER_SET = 'campaign.PARTY_ITEM_CURRENCY_COPPER_SET';
export const PARTY_ITEM_CURRENCY_COPPER_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_COPPER_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_ELECTRUM_SET = 'campaign.PARTY_ITEM_CURRENCY_ELECTRUM_SET';
export const PARTY_ITEM_CURRENCY_ELECTRUM_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_ELECTRUM_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_GOLD_SET = 'campaign.PARTY_ITEM_CURRENCY_GOLD_SET';
export const PARTY_ITEM_CURRENCY_GOLD_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_GOLD_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_PLATINUM_SET = 'campaign.PARTY_ITEM_CURRENCY_PLATINUM_SET';
export const PARTY_ITEM_CURRENCY_PLATINUM_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_PLATINUM_SET_COMMIT';
export const PARTY_ITEM_CURRENCY_SILVER_SET = 'campaign.PARTY_ITEM_CURRENCY_SILVER_SET';
export const PARTY_ITEM_CURRENCY_SILVER_SET_COMMIT = 'campaign.PARTY_ITEM_CURRENCY_SILVER_SET_COMMIT';
