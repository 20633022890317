/**
 *
 * @param preferences
 * @param activeSourceLookup
 */
export function generateEntityRestrictionData(preferences, activeSourceLookup) {
    return {
        preferences,
        activeSourceLookup,
    };
}
