import React from "react";

import { Button } from "@dndbeyond/character-components/es";

import TextareaEditorManagerModal from "../../Modals/TextareaEditorManager";

interface Props {
  heading: string;
  placeholder: string;
  content: string;
  editButtonLabel: string;
  addButtonLabel: string;
  onSave?: (content) => void;
  extraNode?: React.ReactNode;
  saveOnBlur: boolean;
}
interface State {
  modal: boolean;
  newContent: string;
}
export default class TextareaEditor extends React.PureComponent<Props, State> {
  static defaultProps = {
    saveOnBlur: true,
  };

  initialState: Pick<State, "modal"> = {
    modal: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.initialState,
      newContent: props.content,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { content } = this.props;

    if (content !== prevProps.content) {
      this.setState({
        newContent: content,
      });
    }
  }

  showModal = (): void => {
    this.setState({
      modal: true,
    });
  };

  closeModal = (content: string): void => {
    const { onSave } = this.props;

    if (onSave) {
      onSave(content);
    }

    // TODO scroll into view once its done editing, but make sure its cross device compliant
    // setTimeout(() => {
    //     document.body.scrollTop = ReactDOM.findDOMNode(this).offsetTop;
    // }, 50);

    this.setState({
      modal: false,
      newContent: content,
    });
  };

  handleInputBlur = (content: string): void => {
    const { onSave, saveOnBlur } = this.props;

    if (saveOnBlur) {
      if (onSave) {
        onSave(content);
      }

      this.setState({
        newContent: content,
      });
    }
  };

  render() {
    const { modal, newContent } = this.state;
    const { heading, placeholder, editButtonLabel, addButtonLabel, extraNode } =
      this.props;

    const modalHeading: string = newContent.trim().length
      ? editButtonLabel
      : addButtonLabel;
    const buttonLabel: string = modalHeading;

    return (
      <div className="textarea-editor">
        <div className="textarea-editor-header">
          <div className="textarea-editor-heading">{heading}</div>
          <div className="textarea-editor-actions">
            <Button onClick={this.showModal} size="small">
              {buttonLabel}
            </Button>
          </div>
        </div>
        <div className="textarea-editor-preview">
          {newContent.length ? (
            <div className="textarea-editor-preview-content">{newContent}</div>
          ) : null}
        </div>
        <TextareaEditorManagerModal
          modalHeading={modalHeading}
          heading={heading}
          placeholder={placeholder}
          content={newContent}
          isOpen={modal}
          onRequestClose={this.closeModal}
          onInputBlur={this.handleInputBlur}
          extraNode={extraNode}
        />
      </div>
    );
  }
}
