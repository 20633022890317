import { HelperUtils } from "@dndbeyond/character-rules-engine/es";

import {
  SidebarAlignmentEnum,
  SidebarPlacementEnum,
} from "~/subApps/sheet/components/Sidebar/types";

import * as types from "../actions/sidebar/actionTypes";
import { SidebarAction } from "../actions/sidebar/typings";
import {
  SidebarPaneComponentInfoState,
  SidebarPaneState,
  SidebarState,
} from "../stores/typings";

const initialPaneHistoryState: SidebarPaneComponentInfoState = {
  componentType: null,
  componentIdentifiers: null,
  componentProps: {},
};
function paneHistory(
  state: SidebarPaneComponentInfoState = initialPaneHistoryState,
  action: SidebarAction
): SidebarPaneComponentInfoState {
  switch (action.type) {
    case types.PANE_HISTORY_START:
    case types.PANE_HISTORY_PUSH:
      return {
        ...state,
        componentType: action.payload.componentType,
        componentIdentifiers: action.payload.componentIdentifiers,
        componentProps: action.payload.componentProps,
      };
  }

  return state;
}

const initialPaneState: SidebarPaneState = {
  id: null,
  historyIdx: null,
  history: [],
};
function pane(
  state: SidebarPaneState = initialPaneState,
  action: SidebarAction
): SidebarPaneState {
  switch (action.type) {
    case types.PANE_HISTORY_START:
      return {
        ...state,
        historyIdx: 0,
        history: [paneHistory(initialPaneHistoryState, action)],
      };

    case types.PANE_HISTORY_PUSH:
      let newHistory: Array<SidebarPaneComponentInfoState> = state.history;
      if (state.historyIdx !== null) {
        newHistory = [
          ...state.history.slice(0, state.historyIdx + 1),
          paneHistory(initialPaneHistoryState, action),
        ];
      }
      return {
        ...state,
        historyIdx: newHistory.length - 1,
        history: newHistory,
      };

    case types.PANE_HISTORY_PREVIOUS:
      return {
        ...state,
        historyIdx: Math.max(0, (state.historyIdx ? state.historyIdx : 0) - 1),
      };

    case types.PANE_HISTORY_NEXT:
      return {
        ...state,
        historyIdx: Math.min(
          state.history.length - 1,
          (state.historyIdx ? state.historyIdx : 0) + 1
        ),
      };

    case types.PANE_CREATE:
      return {
        ...state,
        id: HelperUtils.generateGuid(),
        historyIdx: 0,
        history: [...state.history],
      };
  }

  return state;
}

export const initialState: SidebarState = {
  placement: SidebarPlacementEnum.OVERLAY,
  alignment: SidebarAlignmentEnum.RIGHT,
  isLocked: false,
  isVisible: false,
  activePaneId: null,
  panes: [],
  width: 340,
};
function sidebar(
  state: SidebarState = initialState,
  action: SidebarAction
): SidebarState {
  switch (action.type) {
    case types.VISIBLE_SET:
      return {
        ...state,
        isVisible: action.payload.isVisible,
      };

    case types.LOCK_SET:
      return {
        ...state,
        isLocked: action.payload.isLocked,
      };

    case types.PLACEMENT_SET:
      return {
        ...state,
        placement: action.payload.placement,
      };

    case types.ALIGNMENT_SET:
      return {
        ...state,
        alignment: action.payload.alignment,
      };

    case types.PANE_HISTORY_START:
    case types.PANE_HISTORY_PUSH:
    case types.PANE_HISTORY_PREVIOUS:
    case types.PANE_HISTORY_NEXT: {
      let matchingId = action.payload.id
        ? action.payload.id
        : state.activePaneId;
      let paneIdx = state.panes.findIndex((pane) => pane.id === matchingId);
      return {
        ...state,
        activePaneId: matchingId,
        isVisible: true,
        panes: [
          ...state.panes.slice(0, paneIdx),
          pane(state.panes[paneIdx], action),
          ...state.panes.slice(paneIdx + 1),
        ],
      };
    }

    case types.PANE_REMOVE:
      return {
        ...state,
        panes: state.panes.filter((pane) => pane.id === action.payload.id),
      };

    case types.PANE_CREATE: {
      let createdPane = pane(undefined, action);
      return {
        ...state,
        activePaneId: createdPane.id,
        panes: [...state.panes, createdPane],
      };
    }
  }

  return state;
}

export default sidebar;
