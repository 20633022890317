/**
 *
 * @param org
 */
export function getId(org) {
    return org.id;
}
/**
 *
 * @param org
 */
export function getName(org) {
    return org.name;
}
/**
 *
 * @param org
 */
export function getDescription(org) {
    return org.description;
}
