import * as React from "react";

import TabOptionsContent from "./TabOptionsContent";
import TabOptionsHeader from "./TabOptionsHeader";

interface Props {
  initialActiveKey: string;
  className: string;
  onSwitch?: (activeTabKey: string | null, newTabKey: string | null) => void;
  hideBorder?: boolean;
  layoutType: "pill" | "standard";
  clickActiveTabToHide?: boolean;
  children?: React.ReactNode;
  sharedChildren?: React.ReactNode;
}

interface State {
  activeTabKey: string | null;
}

export default class TabOptions extends React.PureComponent<Props, State> {
  static defaultProps = {
    className: "",
    hideBorder: false,
    layoutType: "standard",
  };

  constructor(props: Props) {
    super(props);

    const { initialActiveKey } = props;

    this.state = {
      activeTabKey: initialActiveKey,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { children } = this.props;

    const tabs = React.Children.toArray(children);
    const tabKeys = React.Children.map(tabs, (child) => {
      if (this.isComponentTypeLike(child)) {
        return child ? child.props.tabKey : null;
      }
    });

    if (
      !prevProps.clickActiveTabToHide &&
      !tabKeys.includes(prevState.activeTabKey)
    ) {
      this.setActiveTabKey(tabKeys[0]);
    }
  }

  isComponentTypeLike = (
    component: React.ReactNode
  ): component is React.ReactElement => {
    return (component as React.ReactElement).type !== "undefined";
  };

  setActiveTabKey = (tabKey: string | null): void => {
    this.setState({
      activeTabKey: tabKey,
    });
  };

  handleNavClick = (clickedTabKey: string): void => {
    const { activeTabKey } = this.state;
    const { onSwitch, clickActiveTabToHide } = this.props;

    let newTabKey: string | null = clickedTabKey;
    if (clickActiveTabToHide) {
      newTabKey = activeTabKey === clickedTabKey ? null : clickedTabKey;
    }

    if (onSwitch) {
      onSwitch(activeTabKey, newTabKey);
    }

    this.setActiveTabKey(newTabKey);
  };

  render() {
    const { activeTabKey } = this.state;
    const { className, children, hideBorder, layoutType, sharedChildren } =
      this.props;

    let classNames: Array<string> = ["ddbc-tab-options", className];

    switch (layoutType) {
      case "standard":
      case "pill":
      default:
        classNames.push(`ddbc-tab-options--layout-${layoutType.toLowerCase()}`);
    }

    let navClassNames: Array<string> = ["ddbc-tab-options__nav"];
    if (hideBorder) {
      navClassNames.push("ddbc-tab-options__nav--no-border");
    }

    return (
      <div className={classNames.join(" ")}>
        <div className={navClassNames.join(" ")}>
          {React.Children.map(children, (group) => {
            if (group === null) {
              return null;
            }

            if (this.isComponentTypeLike(group)) {
              const { tabKey, label, children: groupChildren } = group.props;

              let headerNode: React.ReactNode;

              React.Children.forEach(groupChildren, (child) => {
                if (this.isComponentTypeLike(child)) {
                  if (child.type === TabOptionsHeader) {
                    headerNode = React.cloneElement(child, {
                      key: tabKey,
                      tabKey,
                      isActive: tabKey === activeTabKey,
                      onItemClick: this.handleNavClick,
                    });
                  }
                }
              });

              if (label && !headerNode) {
                headerNode = (
                  <TabOptionsHeader
                    key={tabKey}
                    tabKey={tabKey}
                    isActive={tabKey === activeTabKey}
                    onItemClick={this.handleNavClick}
                    dataTestid={`tab-options-header-${label}`
                      .toLowerCase()
                      .replace(/\s/g, "-")}
                  >
                    {label}
                  </TabOptionsHeader>
                );
              }

              if (!headerNode) {
                console.log("Missing TabOption header");
                return null;
              }

              return headerNode;
            }
          })}
        </div>

        <div className="ddbc-tab-options__body">
          {sharedChildren}
          {React.Children.map(children, (group) => {
            if (group === null) {
              return null;
            }

            if (this.isComponentTypeLike(group)) {
              const { tabKey, children: groupChildren } = group.props;

              if (tabKey !== activeTabKey) {
                return null;
              }

              let contentNode: React.ReactNode;
              React.Children.forEach(groupChildren, (child) => {
                if (this.isComponentTypeLike(child)) {
                  if (child.type === TabOptionsContent) {
                    contentNode = React.cloneElement(child, {
                      key: tabKey,
                    });
                  }
                }
              });

              if (!contentNode) {
                console.log("Missing TabOptionContent");
                return null;
              }

              return contentNode;
            }
          })}
        </div>
      </div>
    );
  }
}
