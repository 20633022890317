import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const RangedSpellSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 20.28 20.17">
      <defs>
        <style>
          {`.prefix__cls-1{stroke:${fillColor};stroke-miterlimit:10}.prefix__cls-5{fill:${secondaryFillColor}}`}
        </style>
      </defs>
      <title>{"Asset 14"}</title>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M15.25 19.55l-3.97-3.05M9.37 9.48c-.11-.1-.21-.21-.32-.32.2.19.32.32.32.32z"
          />
          <path
            d="M16.41 16.42l-7.82-12h3.83L7.58 2.84l-.31-.55 4.19-.79L4.91.55 4.4.52.51.5v7.17l.76 3.72s.07-2.67 0-3a49.92 49.92 0 013 4c-.3-1.3-.69-3.51-.86-4.5zM7.17 8.3h.17l.07.18z"
            fill={fillColor}
            stroke={fillColor}
            strokeMiterlimit={10}
          />
          <path
            d="M5.41 7.42c.11.11 1.89.9 2 1a18.82 18.82 0 00-1-2.32c-1.47-1.48.37-.81 0-2.68s-3.18-.47-5-1c.34 1.07-.77 1.89 1 4 1.06 1.32 2.29.34 3 1z"
            fill="none"
          />
          <path
            d="M15.16 19.8c-1.57 0-3.06-.58-3.46-1.35a5.69 5.69 0 00-2.65-.75h-.27a.74.74 0 01-.73-.61A1 1 0 018.72 16a3.38 3.38 0 011.28-.2 7.94 7.94 0 011.92.22.93.93 0 00.27 0 .29.29 0 00.18 0v-.28c0-.19-.53-1.06-1-1.9-1.83-3.27-2.16-4.07-1.87-4.47a.64.64 0 01.5-.26c.72 0 1.86 1.1 2.47 2.12a6 6 0 001 1.31c-.18-.77-.69-2.28-.94-3l-.3-.9A1.17 1.17 0 0113.47 7c.59 0 .88.83 1.51 3.49l.14.57c0-.35.07-.78.08-1.26 0-1.22.41-1.84 1.08-1.84a.61.61 0 01.47.23c.57.68.24 3.33.23 3.35a2.59 2.59 0 000 .65c.54-1.08 1.63-2.05 2.28-2.05a.58.58 0 01.57.39c.14.39-.08 1.1-1.62 3.62l-.18.29a10.57 10.57 0 000 1.34 13.58 13.58 0 01-.1 2.32c-.25 1.31-2.49 1.62-2.74 1.66z"
            fill={fillColor}
          />
          <path
            className="prefix__cls-5"
            d="M13.47 7.42c.64 0 1.39 5 1.69 5 .29 0 .4-1.94.42-2.54s.12-1.48.71-1.48c.6 0 .38 2.73.31 3.15s0 1.34.33 1.34c.1 0 .23-.12.39-.45.51-1 1.5-1.87 2-1.87a.21.21 0 01.21.14c.19.52-1.58 3.27-1.77 3.6s0 2.69-.17 3.77-2.42 1.35-2.42 1.35c-1.54 0-2.83-.58-3.12-1.15s-2.24-1-3-1h-.27c-.43 0-.58-.72.09-1a3.12 3.12 0 011.13-.1 7.67 7.67 0 011.81.2 1.3 1.3 0 00.39.06c.37 0 .71-.19.49-.81-.29-.83-3.23-5.58-2.9-6a.26.26 0 01.21-.15c.51 0 1.55.94 2.15 1.94s1.3 1.71 1.62 1.71a.15.15 0 00.13-.06c.21-.29-1.1-3.9-1.29-4.54a.8.8 0 01.83-1.1m0-.75a1.64 1.64 0 00-1.33.62 1.61 1.61 0 00-.22 1.45c0 .16.16.5.3.92l.32 1c-.64-.88-1.67-1.87-2.5-1.87a1 1 0 00-.83.41C8.77 9.74 9 10.42 11 14c.32.57.67 1.21.85 1.58a9.06 9.06 0 00-1.85-.15 3.67 3.67 0 00-1.45.23 1.38 1.38 0 00-.88 1.54 1.12 1.12 0 001.09.9H9a6.16 6.16 0 012.38.61c.5.87 2.07 1.49 3.76 1.49h.09c.46-.06 2.77-.42 3.06-2a13.8 13.8 0 00.1-2.4v-1.21l.13-.21c1.65-2.69 1.84-3.42 1.65-3.95a1 1 0 00-.92-.64A2.94 2.94 0 0017.43 11c.07-.88.11-2.39-.38-3a1 1 0 00-.71-.36c-.53 0-1.12.24-1.38 1.23-.41-1.51-.76-2.19-1.48-2.19zM1.37 1.47L2.29 8 3 4.34s3.42 4 3.33 3.79-2-4.37-2-4.37l4 .42-4.21-1.92 4.13-.75z"
          />
        </g>
      </g>
    </BaseSvg>
  );
};

export default RangedSpellSvg;
