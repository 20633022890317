import * as React from "react";

import {
  AbilityLookup,
  Action,
  Attack,
  CharacterTheme,
  Constants,
  DataOriginRefData,
  Item,
  RuleData,
  Spell,
  SpellCasterInfo,
  WeaponSpellDamageGroup,
} from "@dndbeyond/character-rules-engine/es";
import { IRollContext } from "@dndbeyond/dice";

import CombatActionAttack from "../CombatAttack/CombatActionAttack";
import CombatItemAttack from "../CombatAttack/CombatItemAttack";
import CombatSpellAttack from "../CombatAttack/CombatSpellAttack";

interface Props {
  attacks: Array<Attack>;
  weaponSpellDamageGroups: Array<WeaponSpellDamageGroup>;
  abilityLookup: AbilityLookup;
  ruleData: RuleData;
  spellCasterInfo: SpellCasterInfo;
  onAttackClick?: (attack: Attack) => void;
  showNotes: boolean;
  className: string;
  diceEnabled: boolean;
  theme: CharacterTheme;
  rollContext: IRollContext;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
}
export default class AttackTable extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    showNotes: true,
    diceEnabled: false,
  };

  renderDefault = (): React.ReactNode => {
    const { theme } = this.props;
    return (
      <div
        className={`ddbc-attack-table__default ${
          theme.isDarkMode ? "ddbc-attack-table__default--dark-mode" : ""
        }`}
      >
        Equip weapons or add spells to see your attacks here.
      </div>
    );
  };

  renderAttacks = (): React.ReactNode => {
    const {
      attacks,
      weaponSpellDamageGroups,
      ruleData,
      abilityLookup,
      spellCasterInfo,
      onAttackClick,
      showNotes,
      diceEnabled,
      theme,
      dataOriginRefData,
      proficiencyBonus,
      rollContext,
    } = this.props;

    if (!attacks.length) {
      return this.renderDefault();
    }

    return (
      <React.Fragment>
        {attacks.map((attack): React.ReactNode => {
          switch (attack.type) {
            case Constants.AttackSourceTypeEnum.ACTION:
              return (
                <CombatActionAttack
                  key={attack.key}
                  attack={attack}
                  action={attack.data as Action}
                  ruleData={ruleData}
                  abilityLookup={abilityLookup}
                  onClick={onAttackClick}
                  showNotes={showNotes}
                  diceEnabled={diceEnabled}
                  theme={theme}
                  rollContext={rollContext}
                  proficiencyBonus={proficiencyBonus}
                />
              );

            case Constants.AttackSourceTypeEnum.CUSTOM:
              return (
                <CombatActionAttack
                  key={attack.key}
                  attack={attack}
                  action={attack.data as Action}
                  ruleData={ruleData}
                  abilityLookup={abilityLookup}
                  onClick={onAttackClick}
                  showNotes={showNotes}
                  diceEnabled={diceEnabled}
                  theme={theme}
                  rollContext={rollContext}
                  proficiencyBonus={proficiencyBonus}
                />
              );

            case Constants.AttackSourceTypeEnum.ITEM:
              return (
                <CombatItemAttack
                  key={attack.key}
                  attack={attack}
                  item={attack.data as Item}
                  weaponSpellDamageGroups={weaponSpellDamageGroups}
                  ruleData={ruleData}
                  abilityLookup={abilityLookup}
                  onClick={onAttackClick}
                  showNotes={showNotes}
                  diceEnabled={diceEnabled}
                  theme={theme}
                  rollContext={rollContext}
                  proficiencyBonus={proficiencyBonus}
                />
              );

            case Constants.AttackSourceTypeEnum.SPELL:
              return (
                <CombatSpellAttack
                  key={attack.key}
                  attack={attack}
                  spell={attack.data as Spell}
                  ruleData={ruleData}
                  abilityLookup={abilityLookup}
                  spellCasterInfo={spellCasterInfo}
                  onClick={onAttackClick}
                  showNotes={showNotes}
                  diceEnabled={diceEnabled}
                  theme={theme}
                  rollContext={rollContext}
                  dataOriginRefData={dataOriginRefData}
                  proficiencyBonus={proficiencyBonus}
                />
              );

            default:
              return null;
          }
        })}
      </React.Fragment>
    );
  };

  render() {
    const { showNotes, className, theme } = this.props;

    let classNames: Array<string> = [className, "ddbc-attack-table"];

    return (
      <div className={classNames.join(" ")}>
        <div className="ddbc-attack-table__row-header">
          <div
            className={`ddbc-attack-table__col ddbc-attack-table__col--icon ${
              theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
            }`}
          />
          <div
            className={`ddbc-attack-table__col ddbc-attack-table__col--name ${
              theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
            }`}
          >
            Attack
          </div>
          <div
            className={`ddbc-attack-table__col ddbc-attack-table__col--range ${
              theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
            }`}
          >
            Range
          </div>
          <div
            className={`ddbc-attack-table__col ddbc-attack-table__col--tohit ${
              theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
            }`}
          >
            Hit / DC
          </div>
          <div
            className={`ddbc-attack-table__col ddbc-attack-table__col--damage ${
              theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
            }`}
          >
            Damage
          </div>
          {showNotes && (
            <div
              className={`ddbc-attack-table__col ddbc-attack-table__col--notes ${
                theme.isDarkMode ? "ddbc-attack-table__col--dark-mode" : ""
              }`}
            >
              Notes
            </div>
          )}
        </div>
        <div className="ddbc-attack-table__content">{this.renderAttacks()}</div>
      </div>
    );
  }
}
