import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const BludgeoningSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 20.29 19.76">
      <polygon
        fill={fillColor}
        points="5.88 14.95 0.45 15.85 4.14 12.74 0.15 9.36 4.26 9.36 0 0 5.52 4.58 4.61 1.05 6.75 2.8 8.19 0.43 9.93 3.57 11.67 0.68 10.68 13.56 5.88 14.95"
      />
      <polygon
        fill={fillColor}
        points="5.17 19.26 8.62 13.3 8.09 12.99 8.71 11.92 7.2 11.05 6.71 11.9 4.16 10.44 7.91 3.95 10.45 5.41 9.97 6.25 11.46 7.11 13.53 4.75 16.22 7.44 14.9 9.09 16.58 10.06 17.06 9.23 19.6 10.7 15.86 17.19 13.31 15.72 13.81 14.86 12.13 13.89 11.51 14.96 10.99 14.66 8.35 19.26 5.17 19.26"
      />
      <path
        fill={secondaryFillColor}
        d="M8.09,4.63l1.68,1L6.52,11.22l-1.68-1L8.09,4.63m5.46.85,2,2L14.5,8.79,11.95,7.32l1.61-1.84M9.78,6.71l6.5,3.75L14,14.4,7.5,10.64,9.78,6.71m7.47,3.2,1.68,1L15.67,16.5l-1.68-1,3.25-5.63h0M9.12,12.22h0m0,0,2.55,1.47-.34.59L8.78,12.81l.34-.59M9,13.6l1.51.87-2.47,4.3H6L9,13.6H9M7.73,3.26l-.5.87L4,9.75l-.5.87.87.5,1.68,1,.87.5.5-.86L8,12.1l-.12.21-.5.87.53.3L5.17,18.26l-.87,1.5H8.63l.29-.5,2.25-3.92.52.3.5-.87.12-.21.81.47-.5.86.87.5,1.68,1,.87.5.5-.87,3.25-5.63.5-.87-.87-.5L17.74,9l-.87-.5-.48.83-.74-.43.68-.84.56-.7-.63-.63-2-2L13.5,4l-.7.81L11.36,6.47l-.7-.41.48-.83-.87-.5-1.68-1-.87-.5Z"
      />
    </BaseSvg>
  );
};

export default BludgeoningSvg;
