import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const DeafenedSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 24 24">
      <path
        fill={secondaryFillColor}
        d="M11.28,6.07a1.92,1.92,0,0,0-3-.39,1.56,1.56,0,0,0-.19,2,5.88,5.88,0,0,0,.81.86l2.44-2.43Z"
      />
      <path
        fill={secondaryFillColor}
        d="M7.66,18.36a2.41,2.41,0,0,0,2.79-1.12,9.25,9.25,0,0,0,.74-1.64,16.06,16.06,0,0,1,2.34-4.43A8.22,8.22,0,0,0,15,8.09l-9,9A2.18,2.18,0,0,0,7.66,18.36Z"
      />
      <path
        fill={fillColor}
        d="M14.53,2.62A6.29,6.29,0,0,0,11.64,1c-.46-.12-.93-.18-1.4-.27H9.5C8.91.82,8.32.9,7.74,1A5.09,5.09,0,0,0,4,5.18c0,.36,0,.66.45.71S5,5.69,5,5.33a3.82,3.82,0,0,1,3-3.24,9.8,9.8,0,0,1,2.52-.2,4.73,4.73,0,0,1,3,1.31C13.9,3,14.22,2.82,14.53,2.62Z"
      />
      <path
        fill={fillColor}
        d="M15.24,8.09,15,8.35a8.44,8.44,0,0,1-1.44,2.83,16.06,16.06,0,0,0-2.34,4.43,9.25,9.25,0,0,1-.74,1.64,2.41,2.41,0,0,1-2.79,1.12,2.21,2.21,0,0,1-1.31-.8l-.86.67a3.14,3.14,0,0,0,1.68,1.12A3.42,3.42,0,0,0,11,18.27a8.1,8.1,0,0,0,1.14-2.19,17.26,17.26,0,0,1,2.29-4.34,12.38,12.38,0,0,0,1.14-1.85,6.8,6.8,0,0,0,.69-2.48A8.9,8.9,0,0,1,15.24,8.09Z"
      />
      <path
        fill={fillColor}
        d="M8.25,5.68a1.92,1.92,0,0,1,2.62-.1c.28-.21.57-.41.86-.6a2.66,2.66,0,0,0-.79-.59,3.24,3.24,0,0,0-3.65.71C6.47,6,6.16,7.88,7.17,8.77l.07.07a8.05,8.05,0,0,1,1-.89c-.07-.08-.14-.15-.2-.23A1.56,1.56,0,0,1,8.25,5.68Z"
      />
      <path
        fill={fillColor}
        d="M12.51,6.38a3.11,3.11,0,0,1,0,1.09,6.75,6.75,0,0,1-.62,1.83c-.14.3-.5.5-.76.75l-.22-.13a7.26,7.26,0,0,1,.41-1.16,2.55,2.55,0,0,0,.32-1.51L9.56,9.3a2,2,0,0,1,.33,1.8,9,9,0,0,1-.37,1.06l5.65-5.62a5,5,0,0,0-.65-2.18Z"
      />
      <path
        fill={fillColor}
        d="M20.33,1.42,18.92,0l-3.6,3.58a6,6,0,0,1,.83,2Z"
      />
      <path
        fill={fillColor}
        d="M6.78,13.72a1.2,1.2,0,0,1-.29-1.36L0,18.82l1.41,1.42,3.4-3.38a3.73,3.73,0,0,1-.07-.41c0-.17-.07-.4,0-.52a.94.94,0,0,1,.56-.42c.14,0,.31.24.45.41L7.66,14A1.13,1.13,0,0,1,6.78,13.72Z"
      />
      <path
        fill={fillColor}
        d="M5.3,15.51a.94.94,0,0,0-.56.42c-.08.12,0,.34,0,.52a3.73,3.73,0,0,0,.07.41l.94-.94C5.61,15.75,5.44,15.5,5.3,15.51Z"
      />
      <path
        fill={fillColor}
        d="M15.18,6.54l1-1a6,6,0,0,0-.83-2l-.79.79A5,5,0,0,1,15.18,6.54Z"
      />
      <path
        fill={fillColor}
        d="M6.78,13.72a1.13,1.13,0,0,0,.89.29l1.86-1.85A9,9,0,0,0,9.9,11.1a2,2,0,0,0-.33-1.8L6.49,12.37A1.2,1.2,0,0,0,6.78,13.72Z"
      />
      <path
        fill={fillColor}
        d="M11.33,8.75a7.26,7.26,0,0,0-.41,1.16l.22.13c.26-.25.62-.45.76-.75a6.75,6.75,0,0,0,.62-1.83,3.11,3.11,0,0,0,0-1.09l-.86.85A2.55,2.55,0,0,1,11.33,8.75Z"
      />
      <path
        fill={secondaryFillColor}
        d="M14.53,4.36l.79-.79a5.93,5.93,0,0,0-.61-.8l-.77.76A5,5,0,0,1,14.53,4.36Z"
      />
    </BaseSvg>
  );
};

export default DeafenedSvg;
