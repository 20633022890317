import axios, { Canceler } from "axios";
import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  Button,
  CharacterAvatarPortrait,
  LoadingPlaceholder,
} from "@dndbeyond/character-components/es";
import {
  ApiRequests,
  ApiAdapterUtils,
  characterActions,
  CharacterPortraitContract,
  Race,
  RaceUtils,
  rulesEngineSelectors,
  DecorationInfo,
  DecorationUtils,
} from "@dndbeyond/character-rules-engine/es";

import { modalActions } from "../../../../Shared/actions/modal";
import { FullscreenModal } from "../../../../Shared/components/common/FullscreenModal";
import DataLoadingStatusEnum from "../../../../Shared/constants/DataLoadingStatusEnum";
import { modalSelectors } from "../../../../Shared/selectors";
import { AppLoggerUtils } from "../../../../Shared/utils";
import { BuilderAppState } from "../../../typings";

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
const SIZE_3MB = 3 * 1024 * 1024;

interface PortraitProps {
  portrait: CharacterPortraitContract | null;
  currentAvatarId: number | null;
  newPortrait: CharacterPortraitContract | null;
  onPortraitSelected: (portrait: CharacterPortraitContract | null) => void;
}
class AvatarManagerPortrait extends React.PureComponent<PortraitProps> {
  handlePortraitClick = (): void => {
    const { onPortraitSelected, portrait } = this.props;

    if (onPortraitSelected) {
      onPortraitSelected(portrait);
    }
  };

  render() {
    const { portrait, currentAvatarId, newPortrait } = this.props;

    let conClassNames: string[] = ["avatar-manager-list-item"];
    if (portrait) {
      if (newPortrait && portrait.avatarId === newPortrait.avatarId) {
        conClassNames.push("avatar-manager-list-item-active");
      }
      if (portrait.avatarId === currentAvatarId) {
        conClassNames.push("avatar-manager-list-item-current");
      }
    }

    let imgSrc: string = "";
    if (portrait && portrait.avatarUrl) {
      imgSrc = portrait.avatarUrl;
    }

    return (
      <div
        className={conClassNames.join(" ")}
        onClick={this.handlePortraitClick}
      >
        <div className="avatar-manager-list-item-inner">
          <img
            className="avatar-manager-list-item-img"
            src={imgSrc}
            loading="lazy"
            alt=""
          />
        </div>
      </div>
    );
  }
}

interface Props extends DispatchProp {
  decorationInfo: DecorationInfo;
  species: Race | null;
  isOpen: boolean;
  modalKey: string;
}
interface State {
  newPortrait: CharacterPortraitContract | null;
  portraitUploadData: string | null;
  portraitUploadWidth: number | null;
  portraitUploadHeight: number | null;
  portraitUploadValid: boolean | null;
  portraitUploadValidSize: boolean | null;
  isCustomPortrait: boolean;
  portraitData: CharacterPortraitContract[];
  loadingStatus: DataLoadingStatusEnum;
}
class AvatarManager extends React.PureComponent<Props, State> {
  loadPortraitsCanceler: null | Canceler = null;
  portraitUpload = React.createRef<HTMLInputElement>();
  portraitUploadForm = React.createRef<HTMLFormElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      newPortrait: null,
      portraitUploadData: null,
      portraitUploadWidth: null,
      portraitUploadHeight: null,
      portraitUploadValid: null,
      portraitUploadValidSize: null,
      isCustomPortrait: false,
      portraitData: [],
      loadingStatus: DataLoadingStatusEnum.NOT_INITIALIZED,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { isOpen, decorationInfo } = this.props;
    const { loadingStatus } = this.state;

    const currentAvatarId =
      DecorationUtils.getAvatarInfo(decorationInfo).avatarId;

    if (
      !prevProps.isOpen &&
      isOpen &&
      loadingStatus === DataLoadingStatusEnum.NOT_INITIALIZED
    ) {
      this.setState({
        loadingStatus: DataLoadingStatusEnum.LOADING,
      });

      ApiRequests.getCharacterGameDataPortraits({
        cancelToken: new axios.CancelToken((c) => {
          this.loadPortraitsCanceler = c;
        }),
      })
        .then((response) => {
          let apiData: CharacterPortraitContract[] = [];

          const data = ApiAdapterUtils.getResponseData(response);
          if (data !== null) {
            apiData = data;
          }

          const isCustomPortrait =
            currentAvatarId !== null &&
            !apiData.find((portrait) => portrait.avatarId === currentAvatarId);
          this.setState({
            portraitData: apiData,
            loadingStatus: DataLoadingStatusEnum.LOADED,
            isCustomPortrait,
          });
          this.loadPortraitsCanceler = null;
        })
        .catch(AppLoggerUtils.handleAdhocApiError);
    }
  }

  componentWillUnmount(): void {
    if (this.loadPortraitsCanceler !== null) {
      this.loadPortraitsCanceler();
    }
  }

  reset = (): void => {
    this.setState((prevState, props) => ({
      newPortrait: null,
      portraitUploadData: null,
      portraitUploadWidth: null,
      portraitUploadHeight: null,
      portraitUploadValid: null,
      portraitUploadValidSize: null,
    }));

    if (this.portraitUploadForm.current) {
      this.portraitUploadForm.current.reset();
    }
  };

  handleCancelModal = (): void => {
    const { dispatch, modalKey } = this.props;

    dispatch(modalActions.close(modalKey));
    this.reset();
  };

  handleAcceptModal = (): void => {
    const { dispatch, modalKey } = this.props;
    const { newPortrait } = this.state;

    if (newPortrait && newPortrait.avatarId && newPortrait.avatarUrl) {
      dispatch(
        characterActions.portraitSet(
          newPortrait.avatarId,
          newPortrait.avatarUrl
        )
      );
    }
    dispatch(modalActions.close(modalKey));
    this.reset();
  };

  handlePortraitClick = (portrait: CharacterPortraitContract | null): void => {
    this.setState({
      newPortrait: portrait,
    });
  };

  isPortraitAvailableForspecies = (
    portrait: CharacterPortraitContract,
    species: Race | null
  ): boolean => {
    let currentspeciesId: number | null = null;
    let currentSpeciesOptionId: number | null = null;
    if (species) {
      const basespeciesId = RaceUtils.getBaseRaceId(species);
      const entityspeciesId = RaceUtils.getEntityRaceId(species);
      currentspeciesId =
        basespeciesId !== null ? basespeciesId : entityspeciesId;
      currentSpeciesOptionId = basespeciesId !== null ? entityspeciesId : null;
    }

    if (portrait.raceId !== null && currentspeciesId === portrait.raceId) {
      return true;
    }
    if (
      portrait.subRaceId !== null &&
      currentSpeciesOptionId === portrait.subRaceId
    ) {
      return true;
    }

    return false;
  };

  renderPortraits = (heading, portraits): React.ReactNode => {
    const { newPortrait } = this.state;
    const { decorationInfo } = this.props;

    return (
      <div className="avatar-manager-group">
        <div className="avatar-manager-heading">{heading}</div>
        <div className="avatar-manager-list">
          {portraits.map((portrait) => (
            <AvatarManagerPortrait
              key={portrait.id}
              portrait={portrait}
              newPortrait={newPortrait}
              currentAvatarId={
                DecorationUtils.getAvatarInfo(decorationInfo).avatarId
              }
              onPortraitSelected={this.handlePortraitClick}
            />
          ))}
        </div>
      </div>
    );
  };

  handlePortraitUpload = (): void => {
    const { dispatch, modalKey } = this.props;
    const { portraitUploadData } = this.state;

    if (portraitUploadData !== null) {
      dispatch(characterActions.portraitUpload(portraitUploadData));
    }

    this.setState({
      portraitUploadData: null,
      portraitUploadWidth: null,
      portraitUploadHeight: null,
      portraitUploadValid: null,
      portraitUploadValidSize: null,
    });

    if (this.portraitUploadForm.current) {
      this.portraitUploadForm.current.reset();
    }

    dispatch(modalActions.close(modalKey));
  };

  handlePortraitChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const fileInput = this.portraitUpload.current;

    if (fileInput && fileInput.files && fileInput.files[0]) {
      let reader = new FileReader();
      let file = fileInput.files[0];

      let validFile: boolean = true;
      if (!ACCEPTED_IMAGE_TYPES.includes(file.type)) {
        this.setState({
          portraitUploadValid: false,
          portraitUploadValidSize: null,
        });
        validFile = false;
      }
      if (file.size > SIZE_3MB) {
        this.setState({
          portraitUploadValid: null,
          portraitUploadValidSize: false,
        });
        validFile = false;
      }

      if (validFile) {
        reader.onload = (e: ProgressEvent) => {
          let imageData = reader.result;
          if (typeof imageData === "string") {
            this.setState({
              portraitUploadData: imageData,
              portraitUploadValid: true,
              portraitUploadValidSize: true,
            });

            let img = new Image();
            img.onload = () => {
              this.setState({
                portraitUploadWidth: img.width,
                portraitUploadHeight: img.height,
              });
            };
            img.src = imageData;
          }
        };

        reader.readAsDataURL(file);
      }
    } else {
      this.setState({
        portraitUploadData: null,
        portraitUploadWidth: null,
        portraitUploadHeight: null,
        portraitUploadValid: null,
        portraitUploadValidSize: null,
      });
    }
  };

  renderPortraitUploader = (): React.ReactNode => {
    const {
      portraitUploadData,
      portraitUploadValid,
      portraitUploadValidSize,
      isCustomPortrait,
    } = this.state;

    let setButtonLabel: string = "Set Portrait";
    if (isCustomPortrait) {
      setButtonLabel = "Change Portrait";
    }

    let confirmNode: React.ReactNode;
    if (portraitUploadValid !== null && !portraitUploadValid) {
      confirmNode = (
        <div className="avatar-manager-upload-warning">
          Cannot upload the selected file type. <br />
          Acceptable file types are jpg, gif, and png.
        </div>
      );
    } else if (portraitUploadValidSize !== null && !portraitUploadValidSize) {
      confirmNode = (
        <div className="avatar-manager-upload-warning">
          Cannot upload the selected file as it exceeds the maximum file size of
          3MB.
        </div>
      );
    } else if (portraitUploadData) {
      confirmNode = (
        <div className="avatar-manager-upload-confirm">
          <div className="avatar-manager-upload-image">
            <div className="avatar-manager-upload-image-heading">Preview</div>
            <CharacterAvatarPortrait
              className="avatar-manager-upload-image-preview"
              avatarUrl={portraitUploadData}
            />
          </div>
          <div className="avatar-manager-upload-action">
            <Button
              onClick={this.handlePortraitUpload}
              disabled={portraitUploadData === null}
            >
              {setButtonLabel}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="avatar-manager-upload">
        <div className="avatar-manager-upload-heading">
          <div className="avatar-manager-heading">Upload Portrait</div>
          <div className="avatar-manager-upload-heading-rules">
            Recommended Size: 150 x 150 Pixels, Maximum File Size: 3MB
          </div>
        </div>
        <form
          className="avatar-manager-upload-form"
          ref={this.portraitUploadForm}
        >
          <div className="avatar-manager-upload-file">
            <input
              className="avatar-manager-upload-file-input"
              type="file"
              ref={this.portraitUpload}
              onChange={this.handlePortraitChange}
            />
          </div>
        </form>
        {confirmNode}
      </div>
    );
  };

  renderUploadedPortrait = (): React.ReactNode => {
    const { decorationInfo } = this.props;
    const { isCustomPortrait } = this.state;

    if (!isCustomPortrait) {
      return null;
    }

    return (
      <div className="avatar-manager-custom">
        <div className="avatar-manager-heading">Current Custom Portrait</div>
        <div className="avatar-manager-custom-content">
          <CharacterAvatarPortrait
            className="avatar-manager-custom-preview"
            avatarUrl={DecorationUtils.getAvatarInfo(decorationInfo).avatarUrl}
          />
        </div>
      </div>
    );
  };

  renderContent = (): React.ReactNode => {
    const { species } = this.props;
    const { portraitData } = this.state;

    let speciesPortraits: CharacterPortraitContract[] = [];
    let otherPortraits: CharacterPortraitContract[] = portraitData;
    if (species) {
      speciesPortraits = portraitData.filter((portrait) =>
        this.isPortraitAvailableForspecies(portrait, species)
      );
      otherPortraits = portraitData.filter(
        (portrait) => !this.isPortraitAvailableForspecies(portrait, species)
      );
    }

    const otherPortraitLabel =
      speciesPortraits.length > 0 ? "Other Portraits" : "Portraits";

    return (
      <React.Fragment>
        {this.renderUploadedPortrait()}
        {this.renderPortraitUploader()}
        {species &&
          speciesPortraits.length > 0 &&
          this.renderPortraits(
            `${species.fullName} Portraits`,
            speciesPortraits
          )}
        {this.renderPortraits(otherPortraitLabel, otherPortraits)}
      </React.Fragment>
    );
  };

  render() {
    const { isOpen } = this.props;
    const { loadingStatus } = this.state;

    let contentNode: React.ReactNode;
    if (loadingStatus === DataLoadingStatusEnum.LOADED) {
      contentNode = this.renderContent();
    } else {
      contentNode = <LoadingPlaceholder />;
    }

    //TODO fix local => com
    return (
      <FullscreenModal
        onCancel={this.handleCancelModal}
        onAccept={this.handleAcceptModal}
        isOpen={isOpen}
        heading="Manage Portrait"
      >
        <div className="avatar-manager">{contentNode}</div>
      </FullscreenModal>
    );
  }
}

function mapStateToProps(state: BuilderAppState) {
  const modalKey = "avatar";

  return {
    modalKey,
    isOpen: modalSelectors.getOpenStatus(state, modalKey),
    decorationInfo: rulesEngineSelectors.getDecorationInfo(state),
    species: rulesEngineSelectors.getRace(state),
  };
}

export default connect(mapStateToProps)(AvatarManager);
