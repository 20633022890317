import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../../BaseSvg";

export default class ActionsSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 149 144" className={className}>
        <g fill={fillColor} opacity=".89" transform="translate(.74)">
          <path d="M132.39628,49.653393 C131.802145,45.5227285 130.482935,41.5048751 128.47568,37.7919268 C126.616052,35.4844132 123.845339,34.099057 120.883551,33.9958627 L120.37741,33.9958627 C116.024895,33.8911513 111.944792,36.1079 109.664073,39.8164943 C106.458508,30.5372265 102.999872,25.3070938 96.6730984,25.3070938 C91.6517967,25.1734503 86.8640495,27.4265078 83.7664805,31.3807963 C80.8077369,35.4487749 79.9911944,40.6934904 81.5731991,45.4684119 C81.5731991,46.5650526 82.5854828,48.5896201 83.4290526,51.2046865 C79.3039463,46.5925507 73.6320228,43.6515533 67.4855835,42.9377025 C64.1399815,42.9905965 61.0145904,44.6157999 59.0498856,47.3242654 C54.4946087,53.5666819 57.6158169,60.6526682 74.3184989,90.8524668 L79.4642746,100.300449 C75.5513209,99.7515725 71.6055386,99.4697309 67.6542975,99.4568787 C63.1649158,99.1352122 58.6572778,99.7381447 54.4102517,101.228375 C49.2943021,103.317841 45.9933934,108.176867 45.7655325,113.515879 L1.77149657,84.6100503 L49.9393318,84.6100503 L0,0 L64.7018032,41.5036339 L53.9884668,9.53233867 L79.1268467,25.3070938 L95.9982426,3.88042105 L116.328275,32.3087231 L136.742664,6.1580595 L132.39628,49.653393 Z M46.0261587,116.928377 C46.9188787,122.365388 51.5679176,126.421047 57.1096751,126.535469 C57.9230483,126.61779 58.7426542,126.61779 59.5560275,126.535469 C65.8189235,126.997519 71.9548286,128.53863 77.692778,131.090746 C78.0978182,131.72624 78.562983,132.346703 79.0838693,132.950268 L68.9196522,135.224238 L5.3144897,143.406865 L46.0261587,116.928377 Z" />
          <path d="M96.6730984,33.7427918 C102.071945,33.7427918 108.398719,75.9212815 110.929428,75.9212815 C113.37578,75.9212815 114.303707,59.5560275 114.472421,54.4946087 C114.641135,49.4331899 115.400348,42.1784897 120.37741,42.1784897 C125.438828,42.1784897 123.582975,65.2079451 122.992476,68.7509382 C122.401977,72.2939314 122.992476,80.0547735 125.776256,80.0547735 C126.619826,80.0547735 127.716467,79.0424897 129.066178,76.2587094 C133.368384,67.8230114 141.719725,60.4839542 145.937574,60.4839542 C146.725159,60.4392854 147.447387,60.9207704 147.709071,61.6649519 C149.311854,66.0515149 134.380668,89.2496842 132.777886,92.0334645 C131.175103,94.8172449 132.777886,114.725492 131.343817,123.836046 C129.909748,132.9466 110.929428,134.971167 110.929428,134.971167 C97.9384531,134.971167 87.0564027,130.078462 84.6100503,125.270114 C82.1636979,120.461767 65.714087,116.834416 59.3029565,116.834416 L57.1096751,116.834416 C53.4823249,116.834416 52.2169703,110.760714 57.8688879,108.398719 C61.0160448,107.640007 64.2761704,107.468421 67.4855835,107.892577 C72.6240604,107.846637 77.7497143,108.413007 82.7541968,109.579716 C83.8186951,109.914848 84.9281127,110.085528 86.044119,110.085858 C89.1653272,110.085858 92.0334645,108.483076 90.177611,103.252943 C87.7312586,96.2513135 62.9303066,56.1817483 65.714087,52.6387551 C66.0388127,51.9329975 66.7126585,51.4516791 67.4855835,51.3734005 C71.7877895,51.3734005 80.5609153,59.3029565 85.6223341,67.7386545 C90.6837529,76.1743524 96.5887414,82.1636979 99.2881648,82.1636979 C99.7158392,82.1915906 100.128564,82.0011021 100.384805,81.6575561 C102.156302,79.2112037 91.1055378,48.7583341 89.5027551,43.3594874 C88.3952186,41.0895358 88.6479264,38.3911426 90.1576753,36.3663029 C91.6674242,34.3414633 94.1814682,33.3291541 96.6730984,33.7427918 Z" />
        </g>
      </BaseSvg>
    );
  }
}
