import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const AcidSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 10.91 17.92">
      <path
        fill={fillColor}
        d="M6.84,10.62q.65,0,.65-.8a.69.69,0,0,0-.16-.5.64.64,0,0,0-.48-.16q-.67,0-.67.72T6.84,10.62Z"
      />
      <path
        fill={fillColor}
        d="M4.53,10.62q.66,0,.66-.8t-.64-.66A.66.66,0,0,0,4,9.33a.76.76,0,0,0-.17.54Q3.87,10.62,4.53,10.62Z"
      />
      <path
        fill={fillColor}
        d="M5.49.65V0a3.3,3.3,0,0,1,0,.34,3.3,3.3,0,0,1,0-.34V.65C4.8,3.87,0,8.34,0,12.08c0,4,2,5.8,5.42,5.83h.07c3.47,0,5.42-1.86,5.42-5.83C10.91,8.34,6.11,3.87,5.49.65ZM3.69,6.91a2.93,2.93,0,0,1,2-.65,2.93,2.93,0,0,1,2,.65A2.12,2.12,0,0,1,8.39,8.6a3.45,3.45,0,0,1-.5,1.75,1,1,0,0,0-.08.28l-.1.65-1.09.52-.16.4a2.54,2.54,0,0,1-.8.1,3.37,3.37,0,0,1-.45,0,2.09,2.09,0,0,1-.35-.07l-.16-.4-1.1-.52-.1-.65a2.67,2.67,0,0,0-.34-.73,2.65,2.65,0,0,1-.24-1.3A2.12,2.12,0,0,1,3.69,6.91ZM8.08,16q-.3.36-.49.36t-.45-.61l-.07-.19a10,10,0,0,0-1.63-1.13,8.87,8.87,0,0,0-1.65,1.19q-.1.73-.44.73t-.49-.41q-.66,0-.66-.36a.4.4,0,0,1,.25-.39,4.29,4.29,0,0,1,1-.24,11.71,11.71,0,0,1,1.4-.87,7,7,0,0,0-1.78-.67,1.57,1.57,0,0,1-.3.21.55.55,0,0,1-.25.07.4.4,0,0,1-.26-.07.25.25,0,0,1-.09-.21,1.24,1.24,0,0,1,.29-.5,1.86,1.86,0,0,1-.06-.4.45.45,0,0,1,.08-.29.28.28,0,0,1,.23-.09.7.7,0,0,1,.39.19,4.73,4.73,0,0,1,.58.59,15.64,15.64,0,0,1,1.79.8q.42-.23,1.48-.68l.27-.12a6.63,6.63,0,0,1,.62-.57.75.75,0,0,1,.36-.19.24.24,0,0,1,.2.08.42.42,0,0,1,.06.26,2.75,2.75,0,0,1,0,.42,1,1,0,0,1,.28.52.29.29,0,0,1-.08.22.33.33,0,0,1-.23.07.6.6,0,0,1-.24-.08l-.4-.24A7.2,7.2,0,0,0,6,14.1l.21.12q.39.22,1,.59l.32.19q1.18.14,1.18.6Q8.72,15.9,8.08,16Z"
      />
      <path
        fill={fillColor}
        d="M5.51,11.67a.36.36,0,0,0,.19-.06.36.36,0,0,0,.19.06q.29,0,.29-.26A.6.6,0,0,0,6,11.09a3.06,3.06,0,0,1-.29-.41,3.38,3.38,0,0,1-.29.41.6.6,0,0,0-.19.33.23.23,0,0,0,.07.19A.32.32,0,0,0,5.51,11.67Z"
      />
    </BaseSvg>
  );
};

export default AcidSvg;
