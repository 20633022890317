import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class WisdomSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 30.04 31.68" className={className}>
        <path
          fill={fillColor}
          d="M14.46,4.32S21.57-1.33,30,3A9.07,9.07,0,0,0,27,3.41a4.45,4.45,0,0,1,1.19,0,3.17,3.17,0,0,1-2,.4s0,.23.89.28a3.66,3.66,0,0,1-2.46.58,2.62,2.62,0,0,0,1.16.25s-.54.62-2.23.56a.68.68,0,0,0,.41.18,2.45,2.45,0,0,1-1.47.16A.92.92,0,0,0,23,6.1s-1.85.3-2.39,1S14.46,4.32,14.46,4.32Z"
        />
        <path
          fill={fillColor}
          d="M5.91,5.48s4-6,10.7-5.45a6.45,6.45,0,0,0-1.9,1.1,3.16,3.16,0,0,1,.78-.32,2.25,2.25,0,0,1-1.18.79s0,.16.66-.06c0,0-.25.56-1.46,1a1.86,1.86,0,0,0,.83-.15s-.18.55-1.31,1a.48.48,0,0,0,.32,0,1.74,1.74,0,0,1-.92.5.66.66,0,0,0,.43,0s-1.13.7-1.3,1.3S5.91,5.48,5.91,5.48Z"
        />
        <path
          fill={fillColor}
          d="M3.34,12.44l-.45-.2C2.37,7.72,5,3.3,11.74,3.3a16.36,16.36,0,0,1,2.72.22A7.54,7.54,0,0,0,10.73,8.4c-1.26,4.8-3.78,4.81-5.86,5.14A4.65,4.65,0,0,0,3.34,12.44Z"
        />
        <path fill={fillColor} d="M3.77,14.8" />
        <path
          fill={fillColor}
          d="M19.44,15.72l.57-.34a3.35,3.35,0,0,0-.33-.85l.53-.38a4.61,4.61,0,0,0-.74-1l-2-1.84,1.26.66.27-.55.13.06a3.42,3.42,0,0,0,.52.15c-.59-.53-1-.86-1-.86L17.23,9.7l1.48.42a56.1,56.1,0,0,0-5.25-2.37l.11-.31a6.85,6.85,0,0,0-1.1,2.38,10,10,0,0,1-.57,1.63,1.61,1.61,0,0,1,.18,0c1,0,2.79.83,2.79,1.84S13.52,16,12.54,16a2.9,2.9,0,0,1-2.3-1.8c-1,1-3.12.75-4.06.89a3.2,3.2,0,0,1,.17,2.62l.55.5L6,18.44l.16.29,0,0s5.51,2.65,8.77,2.28,4.18-3.59,4.22-3.73l.24-.78.39.72a5.17,5.17,0,0,0,.68.92A19.09,19.09,0,0,0,19.44,15.72Z"
        />
        <path
          fill="none"
          d="M3.51,15.49a3.83,3.83,0,0,0-1.83-1.95A3.83,3.83,0,0,1,3.51,15.49Z"
        />
        <path
          fill={fillColor}
          d="M4.45,18.36a9,9,0,0,0-.76-3.09A4.36,4.36,0,0,0,1.6,13S-1.56,17.25,1,21.32a5.34,5.34,0,0,1,.91-.45A3.41,3.41,0,0,1,4.45,18.36Z"
        />
        <path
          fill={fillColor}
          d="M1.67,21.54A6.29,6.29,0,0,1,2,20.43a4.7,4.7,0,0,0-.8.39A6.63,6.63,0,0,0,1.67,21.54Z"
        />
        <path
          fill={fillColor}
          d="M29,22.84a2.13,2.13,0,0,1,.88.88S29.44,19.65,27,18a2.46,2.46,0,0,1,1.86.85s-.28-2.26-1.75-3a3.71,3.71,0,0,1,1.47.31,5.25,5.25,0,0,0-2.77-3.42,3.11,3.11,0,0,1,1.19.28s-.37-2.2-3.5-3.53A3.65,3.65,0,0,1,25,9.69l-1-.71A15.15,15.15,0,0,0,15,4a7.63,7.63,0,0,0-2.11,1.71c1.16.45,7.4,2.89,8.62,4.33l.86,1-1.74-.48.84.78.88.84-1.2-.12a2.07,2.07,0,0,1,.25.3l.45.66L21,13H21a9.43,9.43,0,0,1,1.09,2l.4,1-1-.46-.27-.14a6.46,6.46,0,0,1,.15.94l.06.75L21.15,17a13,13,0,0,1,.94,2.93L22.25,21l-.84-.7a12.24,12.24,0,0,1-1.6-1.64,7,7,0,0,1-5.52,4.22,8.15,8.15,0,0,1-.89,0c-3.28,0-7-1.76-8.4-2.52a4.32,4.32,0,0,0-1.62-.25,4.75,4.75,0,0,0-.53,1.53l-.33,2.76v0a16,16,0,0,0,2,4.79,5.4,5.4,0,0,0,2,2,4.58,4.58,0,0,1-.69-2.16A7.65,7.65,0,0,0,11,31.68a3.22,3.22,0,0,1-.23-2.7,4.71,4.71,0,0,0,3,2.56,3,3,0,0,0,.63-2.73c1.77,2.76,6.62,2.86,6.62,2.86a4.12,4.12,0,0,1-.43-3.26c.8,2.16,4.13,2.83,4.13,2.83a3.47,3.47,0,0,1-.43-2.3A3.38,3.38,0,0,0,27,30.24c-.92-1-1.33-3.5-1.33-3.5a6.56,6.56,0,0,1,2.26,4.93,3.3,3.3,0,0,0,2-2.15h0a2.3,2.3,0,0,0,.07-.46h0A11.7,11.7,0,0,0,29,22.84Z"
        />
      </BaseSvg>
    );
  }
}
