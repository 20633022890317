import { getDefaultSubtypes, getOptions, getOptionValue, isOptional } from './accessors';
export function isOnlyDefaultSelected(choice) {
    const defaultSubtypes = getDefaultSubtypes(choice);
    if (defaultSubtypes.length !== 1) {
        return false;
    }
    if (!getOptionValue(choice)) {
        return false;
    }
    const selectedOption = getSelectedOption(choice);
    if (selectedOption) {
        return selectedOption.label === defaultSubtypes[0];
    }
    return false;
}
export function getSelectedOption(choice) {
    return getOptions(choice).find((option) => option.id === getOptionValue(choice));
}
export function isOptionSelected(choice) {
    return !!getSelectedOption(choice);
}
export function isTodo(choice) {
    return !isOptional(choice) && getOptionValue(choice) === null;
}
