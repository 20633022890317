import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class AttunementSlotBoxSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-attunement-slot-box-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 247.1 50">
        <defs>
          <rect id="SVGID_1_" x="-38.5" y="-152.3" width="612" height="792" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
        </clipPath>
        <path
          fill={fillColor}
          clipPath="url(#SVGID_2_)"
          d="M27.3,8c1.4,1.2,3.1,2.4,5.2,3.2c2,0.8,4.2,1.1,6.5,1.1c2.8,3.5,4.5,7.9,4.5,12.8c0,4.8-1.7,9.3-4.5,12.8
                    c-2.3-0.1-4.4,0.3-6.5,1.1c-2.1,0.8-3.8,1.9-5.2,3.2c-2,0.7-6.2,1.9-11.3,2.1C8.4,41.1,2.9,33.7,2.9,25c0-8.7,5.5-16.1,13.2-19
                    C21.1,6.1,25.3,7.3,27.3,8 M49.7,9.3L49.7,9.3l180.8,0c3.5,1.3,12.5,5.1,14.7,10.7v5v5c-2.1,5.5-11.2,9.4-14.7,10.7H49.7l0,0
                    c-2.6-1.3-5-2.1-7.4-2.6c2.6-3.7,4.1-8.3,4.1-13.1s-1.5-9.4-4.1-13.1C44.7,11.4,47.2,10.6,49.7,9.3 M40.3,9.3h6.8
                    c-1.9,0.8-3.7,1.3-5.5,1.6C41.2,10.3,40.7,9.8,40.3,9.3 M239.6,9.3c0.8,1.3,3.1,4.7,5.5,6.2v2.1c-2.6-3.9-8-6.7-11.7-8.4H239.6z
                     M245.2,34.5c-2.4,1.5-4.7,4.9-5.5,6.2h-6.2c3.7-1.6,9.1-4.5,11.7-8.4V34.5z M40.3,40.7c0.5-0.5,0.9-1,1.3-1.6
                    c1.8,0.3,3.6,0.8,5.5,1.6H40.3z M22.2,0c-0.3,0.1-0.4,0.4-0.3,0.7c0,0.1,0.2,0.4,0.5,1C19.1,1.9,16,2.7,13.2,4
                    c2.7-0.3,6.9-0.1,10.9,0.7c0.1,0,0.1,0,0.2,0C24.5,5,24.7,5.3,25,5.6c-18.4-4.3-23.1,5-23.1,5C3.5,9,5.3,7.8,7.3,6.9
                    c0.6-0.2,1.2-0.3,1.8-0.4C3.6,10.8,0,17.5,0,25c0,7.5,3.6,14.2,9.1,18.5c-0.6-0.1-1.2-0.3-1.8-0.4c-2-0.8-3.9-2-5.5-3.7
                    c0,0,4.8,9.3,23.1,5c-0.2,0.3-0.5,0.6-0.7,0.9c-0.1,0-0.1,0-0.2,0c-4,0.8-8.2,1-10.9,0.7c2.8,1.4,5.9,2.1,9.2,2.3
                    c-0.3,0.6-0.5,1-0.5,1c-0.1,0.3,0,0.6,0.3,0.7c0.3,0.1,0.6,0,0.7-0.3c0,0,4.3-8.3,10-9.8c1.7-0.5,3.3-0.9,5.1-1
                    c-2.9,3.2-6.8,5.4-11.2,6.2c-1.2,1.2-1.7,2.3-1.9,3.2c3.3-0.2,6.3-1.2,9.1-2.6l3.1-2c0.4-0.3,0.8-0.6,1.1-0.9h202.5l0.3-0.5
                    c0.8-1.4,3.4-5.2,5.5-6.2l0.6-0.3V25V14.3l-0.6-0.3c-2.1-1-4.7-4.8-5.5-6.2l-0.3-0.5H38.2c-0.4-0.3-0.7-0.6-1.1-0.9l-3.1-2
                    C31.3,3,28.2,2,24.9,1.8c0.2,0.9,0.7,2,1.9,3.2c4.4,0.8,8.3,3,11.2,6.2c-1.8-0.1-3.5-0.5-5.1-1c-5.6-1.6-10-9.8-10-9.8
                    C22.8,0.1,22.5-0.1,22.2,0"
        />
      </BaseSvg>
    );
  }
}
