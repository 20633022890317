import React from "react";

import BaseSvg from "../../BaseSvg";

interface Props {
  className?: string;
}

const DdbBadgeSvg: React.FunctionComponent<Props> = ({ className = "" }) => {
  return (
    <BaseSvg className={className} viewBox="0 0 24 25">
      <path
        d="M1 0.832519C1 0.556377 1.22386 0.33252 1.5 0.33252H22.5C22.7761 0.33252 23 0.556377 23 0.83252V23.7334C23 24.0454 22.7175 24.2812 22.4106 24.2253L12.0894 22.3488C12.0303 22.338 11.9697 22.338 11.9106 22.3488L1.58944 24.2253C1.28252 24.2812 1 24.0454 1 23.7334V0.832519Z"
        fill="#E40712"
      />
      <path
        d="M17.2309 12.4843C16.8013 12.0744 16.2351 11.7622 15.4931 11.5475C15.786 11.4694 16.0593 11.3523 16.3132 11.1961C16.567 11.04 16.7622 10.8839 16.938 10.6887C17.1137 10.4935 17.2504 10.2983 17.3285 10.0836C17.4261 9.88846 17.4652 9.69329 17.4652 9.49811V9.12727C17.4652 8.67837 17.3675 8.24898 17.1527 7.87814C16.938 7.50731 16.6451 7.17551 16.2546 6.92178C15.8641 6.64853 15.3174 6.45335 14.7707 6.29721C14.224 6.16059 13.6187 6.08252 12.9548 6.08252H7.683H7.13629H6.12098L7.13629 7.13647V10.7277H5.75L7.13629 12.5038V16.4269L6.12098 17.4808H7.13629H7.683H13.3063C14.6535 17.4808 15.825 17.2466 16.6451 16.7587C17.4652 16.2707 17.8557 15.5681 17.8557 14.6117V13.9677C17.8557 13.3821 17.6409 12.8942 17.2309 12.4843ZM10.4556 8.19043H12.291C12.8181 8.19043 13.2477 8.2685 13.5796 8.42464C13.9116 8.58078 14.0678 8.87355 14.0678 9.30293V9.67377C14.0678 10.0056 13.9506 10.2593 13.6968 10.4545C13.443 10.6497 13.111 10.7472 12.6815 10.7472H10.4556V8.19043ZM14.4583 14.2799C14.4583 14.5141 14.4192 14.7093 14.3216 14.846C14.224 14.9826 14.1068 15.0997 13.9506 15.1973C13.7944 15.2753 13.6187 15.3339 13.4039 15.3729C13.1891 15.3924 12.9743 15.412 12.7596 15.412H10.4556V12.66H12.7791C13.2086 12.66 13.5796 12.7576 13.9311 12.9527C14.263 13.1479 14.4387 13.4016 14.4387 13.7334V14.2799H14.4583Z"
        fill="white"
      />
    </BaseSvg>
  );
};

export default DdbBadgeSvg;
