export const PANE_ADD = "sidebar.PANE_ADD";
export const PANE_HISTORY_START = "sidebar.PANE_HISTORY_START";
export const PANE_HISTORY_PUSH = "sidebar.PANE_HISTORY_PUSH";
export const PANE_HISTORY_PREVIOUS = "sidebar.PANE_HISTORY_PREVIOUS";
export const PANE_HISTORY_NEXT = "sidebar.PANE_HISTORY_NEXT";
export const PANE_REMOVE = "sidebar.PANE_REMOVE";
export const PANE_CREATE = "sidebar.PANE_CREATE";

export const LOCK_SET = "sidebar.LOCK_SET";
export const ALIGNMENT_SET = "sidebar.ALIGNMENT_SET";
export const PLACEMENT_SET = "sidebar.PLACEMENT_SET";
export const VISIBLE_SET = "sidebar.VISIBLE_SET";
