import React from "react";

interface Props {
  className?: string;
  avatarUrl: string | null;
}

const CharacterAvatarPortrait: React.FC<Props> = ({
  className = "",
  avatarUrl,
}) => {
  let portraitClasses: Array<string> = [
    "ddbc-character-avatar__portrait",
    className,
  ];
  let portraitStyles: React.CSSProperties = {};
  if (avatarUrl) {
    portraitStyles = {
      backgroundImage: `url(${avatarUrl})`,
    };
  } else {
    portraitClasses.push("ddbc-character-avatar__portrait--none");
  }

  return <div className={portraitClasses.join(" ")} style={portraitStyles} />;
};

export default CharacterAvatarPortrait;
