export const CREATURE_CREATE = 'character.CREATURE_CREATE';
export const CREATURE_ADD = 'character.CREATURE_ADD';
export const CREATURE_ADD_COMMIT = 'character.CREATURE_ADD_COMMIT';
export const CREATURE_REMOVE = 'character.CREATURE_REMOVE';
export const CREATURE_REMOVE_COMMIT = 'character.CREATURE_REMOVE_COMMIT';
export const CREATURE_DATA_SET = 'character.CREATURE_DATA_SET';
export const CREATURE_DATA_SET_COMMIT = 'character.CREATURE_DATA_SET_COMMIT';
export const CREATURE_HIT_POINTS_SET = 'character.CREATURE_HIT_POINTS_SET';
export const CREATURE_HIT_POINTS_SET_COMMIT = 'character.CREATURE_HIT_POINTS_SET_COMMIT';
export const CREATURE_ACTIVE_SET = 'character.CREATURE_ACTIVE_SET';
export const CREATURE_ACTIVE_SET_COMMIT = 'character.CREATURE_ACTIVE_SET_COMMIT';
export const CREATURE_CUSTOMIZATIONS_DELETE = 'character.CREATURE_CUSTOMIZATIONS_DELETE';
