import { MouseEvent } from "react";

type Func = (...args: any[]) => void;

/**
 * Prevents click events from propagating to parent elements.
 * @param onClick The function to call when the element is clicked.
 * @returns A function that will prevent the click event from propagating.
 */
export const useUnpropagatedClick = (onClick?: Func) => {
  const handleClick = (e: MouseEvent, ...args: any) => {
    if (onClick) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onClick(args);
    }
  };

  return handleClick;
};
