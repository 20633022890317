import React from "react";

interface Props {
  type: string;
  activeCount: number;
  totalCount: number;
  willBeActiveCount: number;
  onUse: (evt: React.MouseEvent) => void;
  onClear: (evt: React.MouseEvent) => void;
  markIcon: (key: string) => React.ReactNode;
}
export default class HealthManagerDeathsavesMarkManager extends React.PureComponent<Props> {
  static defaultProps = {
    willBeActiveCount: 0,
  };

  render() {
    const {
      type,
      willBeActiveCount,
      activeCount,
      totalCount,
      onUse,
      onClear,
      markIcon,
    } = this.props;

    let marks: Array<React.ReactNode> = [];
    let availableSlots: number = totalCount;

    for (let i = 0; i < activeCount; i++) {
      marks.push(markIcon(`${type}-active-${i}`));
    }

    availableSlots -= activeCount;

    for (let i = 0; i < Math.min(availableSlots, willBeActiveCount); i++) {
      marks.push(
        <span
          key={`${type}-willbe-${i}`}
          className="ct-health-manager__deathsaves-mark ct-health-manager__deathsaves-mark--willbe"
        />
      );
    }

    availableSlots -= willBeActiveCount;

    for (let i = 0; i < Math.min(availableSlots, totalCount); i++) {
      marks.push(
        <span
          key={`${type}-inactive-${i}`}
          className={
            "ct-health-manager__deathsaves-mark ct-health-manager__deathsaves-mark--inactive"
          }
          onClick={onUse}
        />
      );
    }
    //`

    return <div className="ct-health-manager__deathsaves-marks">{marks}</div>;
  }
}
