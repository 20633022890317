/**
 * NOTE: This file is meant to be removed over time.  This was a quick copy/paste/edit to
 * handle all the needs of the species listing
 // TODO new note about this component
 */
import axios, { Canceler } from "axios";
import { groupBy, keyBy, orderBy } from "lodash";
import React, { useState } from "react";

import {
  DisabledChevronDownSvg,
  DisabledChevronUpSvg,
  LoadingPlaceholder,
} from "@dndbeyond/character-components/es";
import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiAdapterUtils,
  ApiResponse,
  Race,
  RaceDefinitionContract,
  RaceGroupContract,
  RaceUtils,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import SpeciesFilterListingItemContent from "~/tools/js/CharacterBuilder/components/SpeciesFilterListingItemContent";

import { AppLoggerUtils, FilterUtils } from "../../utils";

const FILTER_LIST_ITEM_TYPE = {
  GROUP: 1,
  ITEM: 2,
};

interface SpeciesListingItemProps {
  species: RaceDefinitionContract;
  onSpeciesSelected: (species: RaceDefinitionContract) => void;
  disabled: boolean;
  ruleData: RuleData;
  defaultImageUrl: string;
}
const FilterListingItem: React.FC<SpeciesListingItemProps> = ({
  ruleData,
  species,
  onSpeciesSelected,
  disabled,
  defaultImageUrl,
}) => {
  let clsNames: string[] = ["filter-listing-item", "filter-listing-entry"];
  if (disabled) {
    clsNames.push("filter-listing-item-disabled");
  }

  return (
    <div
      className={clsNames.join(" ")}
      onClick={disabled ? undefined : () => onSpeciesSelected(species)}
    >
      <SpeciesFilterListingItemContent
        ruleData={ruleData}
        species={species}
        defaultImageUrl={defaultImageUrl}
      />
    </div>
  );
};

interface SpeciesListingGroupProps {
  onSpeciesSelected: (species: RaceDefinitionContract) => void;
  ruleData: RuleData;
  items: SpeciesItemsLazyOrFilteredState[];
  defaultImageUrl: string;
  name: string | null;
  avatarUrl: string | null;
}
const FilterListingGroup: React.FC<SpeciesListingGroupProps> = ({
  ruleData,
  items,
  defaultImageUrl,
  onSpeciesSelected,
  name,
  avatarUrl,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  let image = avatarUrl;
  if (!image) {
    image = defaultImageUrl;
  }

  let classNames: string[] = ["filter-listing-group", "filter-listing-entry"];
  if (!isCollapsed) {
    classNames.push("filter-listing-group-opened");
  }

  return (
    <div className={classNames.join(" ")}>
      <div
        className="filter-listing-item"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="filter-listing-item-content">
          <div className="filter-listing-item-heading">
            <div className="filter-listing-group-header">
              <div className="filter-listing-group-preview">
                <img
                  className="filter-listing-group-preview-img"
                  src={image}
                  alt=""
                />
              </div>
              <div className="filter-listing-group-content">
                <div className="filter-listing-group-parent">{name}</div>
                <div className="filter-listing-group-info">
                  ({items.length})
                </div>
              </div>
              <div className="filter-listing-group-action">
                {isCollapsed ? (
                  <DisabledChevronDownSvg />
                ) : (
                  <DisabledChevronUpSvg />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isCollapsed && (
        <div className="filter-listing-group-items">
          {items.map((listItem) => (
            <FilterListingItem
              species={listItem.entity}
              disabled={listItem.disabled}
              key={listItem.id}
              onSpeciesSelected={onSpeciesSelected}
              ruleData={ruleData}
              defaultImageUrl={defaultImageUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const HOMEBREW_ID = "HOMEBREW";

interface SpeciesItemsLazyOrFilteredState {
  type: string;
  id: string;
  entityTypeId: number;
  name: string | null;
  disabled: boolean;
  entity: RaceDefinitionContract;
  groupId?: number;
}
interface FilteredSpeciesItem {
  item: SpeciesItemsLazyOrFilteredState;
  key: string;
  sortString: string;
  type: number;
}
interface FilteredSpeciesGroup extends RaceGroupContract {
  items: SpeciesItemsLazyOrFilteredState[];
  key: string;
  sortString: string;
  type: number;
}
interface Props {
  loadSpecies: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<RaceDefinitionContract[]>>;
  onLoadSpecies: (data: RaceDefinitionContract[]) => void;
  onSpeciesSelected: (species: RaceDefinitionContract) => void;
  ruleData: RuleData;
  currentSpecies?: Race | null;
  currentSourceFilter: string | null;
  isLegacyShowing: boolean;
}
interface State {
  query: string;
  lazyItems: SpeciesItemsLazyOrFilteredState[];
  filteredGroups: (FilteredSpeciesGroup | FilteredSpeciesItem)[];
  itemsLoading: boolean;
  itemsLoaded: boolean;
}
export default class SpeciesFilterListing extends React.PureComponent<
  Props,
  State
> {
  loadItemsCanceler: null | Canceler = null;
  listingContent = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      query: "",
      lazyItems: [],
      filteredGroups: [],
      itemsLoading: false,
      itemsLoaded: false,
    };
  }

  componentDidMount() {
    const { loadSpecies, onLoadSpecies } = this.props;

    this.setState({
      itemsLoading: true,
    });

    loadSpecies({
      cancelToken: new axios.CancelToken((c) => {
        this.loadItemsCanceler = c;
      }),
    })
      .then((response) => {
        let data = ApiAdapterUtils.getResponseData(response);
        let transformedItems = this.disableItems(
          this.transformLoadedSpecies(data)
        );
        let transformedGroups = this.groupItems(transformedItems);
        onLoadSpecies(data ?? []);
        this.setState({
          lazyItems: transformedItems,
          filteredGroups: transformedGroups,
          itemsLoaded: true,
          itemsLoading: false,
        });
        this.loadItemsCanceler = null;
      })
      .catch(AppLoggerUtils.handleAdhocApiError);
  }

  componentWillUnmount(): void {
    if (this.loadItemsCanceler !== null) {
      this.loadItemsCanceler();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { loadSpecies, currentSourceFilter, isLegacyShowing } = this.props;

    if (
      loadSpecies !== prevProps.loadSpecies ||
      currentSourceFilter !== prevProps.currentSourceFilter ||
      isLegacyShowing !== prevProps.isLegacyShowing
    ) {
      this.setState((prevState) => ({
        lazyItems: this.disableItems(prevState.lazyItems),
        filteredGroups: this.groupItems(
          this.disableItems(this.getFilteredItems(""))
        ),
      }));
    }
  }

  makeSpeciesId = (species: RaceDefinitionContract): string => {
    return `${species.entityRaceId}-${species.entityRaceTypeId}`;
  };

  transformLoadedSpecies = (
    data: RaceDefinitionContract[] | null
  ): Array<
    Pick<
      SpeciesItemsLazyOrFilteredState,
      "type" | "id" | "entityTypeId" | "name" | "entity"
    >
  > => {
    const { currentSpecies } = this.props;
    let speciesDefinitions: RaceDefinitionContract[] = data ? [...data] : [];
    if (currentSpecies) {
      if (
        !speciesDefinitions.some(
          (speciesDef) =>
            RaceUtils.getEntityRaceId(speciesDef) ===
            RaceUtils.getEntityRaceId(currentSpecies)
        )
      ) {
        speciesDefinitions.push(currentSpecies);
      }
    }

    return speciesDefinitions.map((species) => ({
      type: "species",
      id: this.makeSpeciesId(species),
      entityTypeId: species.entityRaceTypeId,
      name: species.fullName,
      entity: species,
    }));
  };

  resetUi = () => {
    if (this.listingContent.current) {
      this.listingContent.current.scrollTop = 0;
    }
  };

  disableItems = (
    items: Array<
      Pick<
        SpeciesItemsLazyOrFilteredState,
        "type" | "id" | "entityTypeId" | "name" | "entity"
      >
    >
  ): SpeciesItemsLazyOrFilteredState[] => {
    const { currentSpecies } = this.props;
    const disabledItemIds = currentSpecies
      ? items
          .filter((item) => item.id === this.makeSpeciesId(currentSpecies))
          .map((item) => item.id)
      : [];

    return items.map((item) => ({
      ...item,
      disabled: disabledItemIds.indexOf(item.id) > -1,
    }));
  };

  groupItems = (
    items: SpeciesItemsLazyOrFilteredState[]
  ): (FilteredSpeciesGroup | FilteredSpeciesItem)[] => {
    const { ruleData } = this.props;
    const groupData = RuleDataUtils.getRaceGroups(ruleData);
    let groupableItems: SpeciesItemsLazyOrFilteredState[] = [];
    let ungroupedItems: FilteredSpeciesItem[] = [];
    items.forEach((item) => {
      if (item.entity.groupIds?.length) {
        item.entity.groupIds.forEach((groupId) => {
          groupableItems.push({
            ...item,
            groupId,
          });
        });
      } else {
        ungroupedItems.push({
          type: FILTER_LIST_ITEM_TYPE.ITEM,
          item,
          sortString: item.name ?? "",
          key: `${FILTER_LIST_ITEM_TYPE.ITEM}-${item.id}`,
        });
      }
    });

    let groupDataLookup = keyBy(groupData, "id");
    let groups: (FilteredSpeciesGroup | FilteredSpeciesItem)[] = [];
    let groupedItems = groupBy(groupableItems, "groupId");
    Object.keys(groupedItems).forEach((groupKey) => {
      let groupItems = groupedItems[groupKey];
      if (groupItems.length === 1) {
        groups.push({
          type: FILTER_LIST_ITEM_TYPE.ITEM,
          item: groupItems[0],
          sortString: groupItems[0].name ?? "",
          key: `${FILTER_LIST_ITEM_TYPE.ITEM}-${groupItems[0].id}`,
        });
      } else {
        groups.push({
          type: FILTER_LIST_ITEM_TYPE.GROUP,
          items: orderBy(groupedItems[groupKey], [
            (item) => Number(item.entity.isLegacy),
            (item) => {
              const isHomebrew = item.entity.isHomebrew ?? false;
              let sourceName = "";
              if (isHomebrew) {
                sourceName = "Homebrew";
              } else {
                sourceName = item.entity.sources?.[0]?.sourceId
                  ? RuleDataUtils.getSourceDataInfo(
                      item.entity.sources?.[0].sourceId,
                      ruleData
                    )?.name ?? ""
                  : "";
              }

              return sourceName;
            },
            (item) => item.name,
          ]),
          sortString: groupDataLookup[groupKey].name ?? "",
          key: `${FILTER_LIST_ITEM_TYPE.GROUP}-${groupDataLookup[groupKey].id}`,
          ...groupDataLookup[groupKey],
        });
      }
    });

    let listItems: (FilteredSpeciesGroup | FilteredSpeciesItem)[] = [
      ...groups,
      ...ungroupedItems,
    ];

    return orderBy(listItems, "sortString");
  };

  getFilteredItems = (query: string): SpeciesItemsLazyOrFilteredState[] => {
    const { lazyItems } = this.state;
    const { currentSourceFilter, isLegacyShowing } = this.props;

    let filteredItems = lazyItems;

    if (currentSourceFilter) {
      const filteredIds =
        currentSourceFilter === HOMEBREW_ID
          ? lazyItems
              .filter((item) => !item.entity.isHomebrew)
              .map((item) => item.id)
          : lazyItems
              .filter((item) => {
                let sources = (item.entity as RaceDefinitionContract).sources;
                if (sources === null) {
                  return true;
                }
                return !sources.some(
                  (sourceMapping) =>
                    sourceMapping.sourceId === parseInt(currentSourceFilter)
                );
              })
              .map((item) => item.id);

      filteredItems = lazyItems.filter(
        (item) => !(filteredIds.indexOf(item.id) > -1)
      );
    }
    return filteredItems
      .filter((item) => {
        return isLegacyShowing ? item : !item.entity.isLegacy;
      })
      .filter((item) => {
        const headingTag = item.name;
        const tagsElem = headingTag ? [headingTag] : [];

        return FilterUtils.doesQueryMatchData(
          query,
          item.entity.baseRaceName,
          tagsElem
        );
      });
  };

  handleQueryChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const query = evt.target.value;

    this.setState({
      query,
      filteredGroups: this.groupItems(this.getFilteredItems(query)),
    });

    this.resetUi();
  };

  renderUi = (): React.ReactNode => {
    const { query, filteredGroups } = this.state;
    const { ruleData, onSpeciesSelected } = this.props;
    const fallbackImageUrl =
      RuleDataUtils.getDefaultRaceImageUrl(ruleData) ?? "";
    return (
      <div className="filter-listing-ui">
        <div className="filter-constraints">
          <div className="filter-constraint">
            <input
              type="search"
              className="filter-constraint-query"
              value={query}
              onChange={this.handleQueryChange}
              placeholder={"Search..."}
              spellCheck={false}
              autoComplete={"off"}
            />
          </div>
        </div>
        <div className="filter-listing-content" ref={this.listingContent}>
          {!filteredGroups.length ? (
            <div className="filter-listing-no-results">No Results Found</div>
          ) : (
            <div className="filter-listing-entries">
              {filteredGroups.map((listItem) => {
                switch (listItem.type) {
                  case FILTER_LIST_ITEM_TYPE.ITEM:
                    listItem = listItem as FilteredSpeciesItem;
                    return (
                      <FilterListingItem
                        key={listItem.key}
                        species={listItem.item.entity}
                        disabled={listItem.item.disabled}
                        onSpeciesSelected={(species) =>
                          onSpeciesSelected(species)
                        }
                        ruleData={ruleData}
                        defaultImageUrl={fallbackImageUrl}
                      />
                    );
                  case FILTER_LIST_ITEM_TYPE.GROUP:
                    listItem = listItem as FilteredSpeciesGroup;
                    return (
                      <FilterListingGroup
                        key={listItem.key}
                        items={listItem.items}
                        name={listItem.name}
                        avatarUrl={listItem.avatarUrl}
                        onSpeciesSelected={(species) =>
                          onSpeciesSelected(species)
                        }
                        defaultImageUrl={fallbackImageUrl}
                        ruleData={ruleData}
                      />
                    );
                }

                return null;
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { itemsLoaded, itemsLoading } = this.state;

    return (
      <div className="filter-listing">
        {itemsLoading && (
          <div className="filter-listing-loading">
            <LoadingPlaceholder
              label={
                <div className="filter-listing-loading-text">Loading...</div>
              }
            />
          </div>
        )}
        {!itemsLoading && itemsLoaded && this.renderUi()}
      </div>
    );
  }
}
