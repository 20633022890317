import React from "react";

interface Props {
  clsNames: Array<string>;
}
export default class FilterListingItemContent extends React.PureComponent<
  Props,
  {}
> {
  static defaultProps = {
    clsNames: [],
  };

  render() {
    const { children, clsNames } = this.props;

    let conClassNames: Array<string> = [
      "filter-listing-item-content",
      ...clsNames,
    ];

    return <div className={conClassNames.join(" ")}>{children}</div>;
  }
}
