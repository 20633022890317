import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const EnchantmentSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 32 32">
      <path
        fill={fillColor}
        d="M25.78673,20.34876a2.44921,2.44921,0,0,0-2.63806-1.688A2.061,2.061,0,0,0,21.44221,20.55a1.08453,1.08453,0,0,0,2.13853.31708,1.30412,1.30412,0,0,1,.2242.36278,1.29752,1.29752,0,0,1-.17791.90043,6.50062,6.50062,0,0,1-3.05379,2.92217,1.648,1.648,0,0,1-1.56244-.33978.87453.87453,0,0,1-.28895-.86624,16.273,16.273,0,0,1,1.58-2.4066c2.17332-3.00205,3.67162-5.26039,3.15212-6.98713a2.184,2.184,0,0,0-1.40118-1.43975c-1.21388-.45657-2.63261.42874-3.9061,1.66607a3.08539,3.08539,0,0,0-1.46956-2.79479,2.94606,2.94606,0,0,0-3.24954.49214V10.21181a9.17233,9.17233,0,0,1,4.35511-.20153,1.08439,1.08439,0,1,0,.515-2.10676,10.91773,10.91773,0,0,0-4.87008.0733V5.38846a1.0844,1.0844,0,0,0-2.16879,0V8.01022a15.295,15.295,0,0,0-4.32274-.00231A1.0844,1.0844,0,0,0,7.232,10.15642a12.01152,12.01152,0,0,1,4.02683.07252v16.3826a1.0844,1.0844,0,0,0,2.16879,0V15.46132c.70592-.97155,1.798-1.89447,2.21961-1.66853.28139.15189.64537.84113-.07927,3.75059a6.255,6.255,0,0,0-.14977.69015l-.51133,4.27736L17.3827,18.9854c1.42357-2.02778,3.28555-3.88008,3.94181-3.92819.03268.01392.04962.0242.05386.0242h.0003c.28713.77578-1.97877,3.90611-2.83352,5.08672a11.20509,11.20509,0,0,0-1.93943,3.20325,3.03137,3.03137,0,0,0,.91828,2.92,3.74038,3.74038,0,0,0,2.567.99756,3.92411,3.92411,0,0,0,1.078-.15129,8.46434,8.46434,0,0,0,4.42289-4.08916A3.166,3.166,0,0,0,25.78673,20.34876Z"
      />
    </BaseSvg>
  );
};

export default EnchantmentSvg;
