import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const RestrainedSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 18.82 21.16">
      <text
        stroke={fillColor}
        style={{ fontSize: "8px" }}
        transform="translate(7 6.84)"
      >
        R
      </text>
      <path
        fill={fillColor}
        d="M18.75,10.61c-1.5,1.41-2.84,3.08-4.56,4.13-1.05.64-2.68.34-4,.46A7.14,7.14,0,0,1,13.8,7.39c.32-.15,1-.18,1.1,0a1.67,1.67,0,0,1,.21,1.33,14,14,0,0,1-1.61,2.17c2.2-.87,2.5-1.69,3-3.76.46-1.82,0-4.66.71-4.95.83,0,1.69-.33,1.57,1.26C18.59,6.18,18.75,7.85,18.75,10.61Z"
      />
      <path
        fill={fillColor}
        d="M.07,10.61c1.5,1.41,2.84,3.08,4.56,4.13,1.05.64,2.68.34,4,.46A7.14,7.14,0,0,0,5,7.39c-.32-.15-1-.18-1.1,0C3.69,7.74,4.84,8.8,5,9.16c.28.62,0,1.12.32,1.73-3-1.23-2.5-1.69-3-3.76-.46-1.82,0-4.66-.71-4.95C.75,2.2-.11,1.84,0,3.43.23,6.18.07,7.85.07,10.61Z"
      />
      <path
        fill={fillColor}
        d="M2.51,21.16c-.71-1.14-.56-2,.93-2q5.6-.07,11.19,0c1.49,0,1.64.83.93,2Z"
      />
      <path
        fill={fillColor}
        d="M9.22,18.12c-1.92,0-3.85.06-5.76,0-.46,0-.89-.54-1.34-.82.45-.37.89-1,1.34-1.06,3.76-.08,7.52-.07,11.28,0,.41,0,.81.61,1.21.94-.41.32-.8.91-1.22.93C12.9,18.18,11.06,18.12,9.22,18.12Z"
      />
    </BaseSvg>
  );
};

export default RestrainedSvg;
