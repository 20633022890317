import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const StunnedSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 18.7 23.2">
      <path
        fill={fillColor}
        d="M16.6,12.7c0,0-0.1,0-0.1,0c0-0.1,0-0.1,0-0.2c0-4.3-3.2-7.8-7.2-7.8s-7.2,3.5-7.2,7.8c0,0.1,0,0.1,0,0.2   c-0.1,0-0.1,0-0.2,0c-0.6,0.2-0.8,1.1-0.5,2.1c0.3,1,1,1.6,1.6,1.5c0.2,0.4,0.4,0.8,0.7,1.1c0.5,2.9,2.8,5.8,5.6,5.8   c2.8,0,5.1-3,5.6-5.8c0.3-0.3,0.5-0.7,0.7-1.1c0.6,0.1,1.2-0.5,1.5-1.5C17.4,13.8,17.2,12.9,16.6,12.7z"
      />

      <path
        fill={secondaryFillColor}
        d="M10.3,15.6c0-0.3-0.1-0.5-0.2-0.7s-0.4-0.3-0.8-0.5c-0.7-0.3-1.3-0.6-1.7-0.9C7.2,13.3,7,12.8,7,12.2    c0-0.6,0.2-1.1,0.7-1.4c0.4-0.4,1-0.6,1.7-0.6c0.7,0,1.2,0.2,1.7,0.6c0.4,0.4,0.6,0.9,0.6,1.6l0,0h-1.4c0-0.3-0.1-0.6-0.2-0.8    c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.3,0.6s0.5,0.3,0.9,0.5    c0.7,0.2,1.2,0.5,1.5,0.9s0.5,0.8,0.5,1.4c0,0.6-0.2,1.1-0.6,1.5s-1,0.5-1.7,0.5c-0.7,0-1.3-0.2-1.8-0.6c-0.5-0.4-0.7-1-0.7-1.7    l0,0h1.4c0,0.4,0.1,0.8,0.3,1c0.2,0.2,0.5,0.3,0.9,0.3c0.3,0,0.5-0.1,0.7-0.2S10.3,15.9,10.3,15.6z"
      />

      <path
        stroke={fillColor}
        fill={secondaryFillColor}
        d="M7.9,3.1c0,0-1.7,0.2-2.8,0.3c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0.1,0.3C5.7,4.6,7,5.8,7,5.8     S6.6,7.5,6.4,8.6c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0c1-0.6,2.5-1.4,2.5-1.4s1.5,0.8,2.5,1.4c0.1,0,0.2,0,0.3,0     c0.1-0.1,0.1-0.2,0.1-0.3c-0.2-1.1-0.6-2.8-0.6-2.8s1.3-1.1,2.1-1.9c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.2-0.2     c-1.1-0.1-2.8-0.3-2.8-0.3S10,1.6,9.5,0.5c0-0.1-0.1-0.2-0.2-0.2C9.2,0.4,9.1,0.4,9,0.5C8.6,1.6,7.9,3.1,7.9,3.1z"
      />

      <path
        stroke={fillColor}
        fill={secondaryFillColor}
        d="M15,7.2c0,0-1,0.1-1.6,0.2c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.2c0.5,0.4,1.2,1.1,1.2,1.1s-0.2,1-0.3,1.6     c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0c0.6-0.3,1.4-0.8,1.4-0.8s0.9,0.5,1.4,0.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.2     c-0.1-0.6-0.3-1.6-0.3-1.6s0.7-0.7,1.2-1.1c0,0,0.1-0.1,0-0.2s-0.1-0.1-0.1-0.1c-0.7-0.1-1.6-0.2-1.6-0.2s-0.4-0.9-0.7-1.5     c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1C15.4,6.3,15,7.2,15,7.2z"
      />

      <path
        stroke={fillColor}
        fill={secondaryFillColor}
        d="M2.1,7.2c0,0-1,0.1-1.6,0.2c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.2c0.5,0.4,1.2,1.1,1.2,1.1     s-0.2,1-0.3,1.6c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.2,0C2.1,10.2,3,9.7,3,9.7s0.9,0.5,1.4,0.8c0.1,0,0.1,0,0.2,0     c0.1,0,0.1-0.1,0.1-0.2C4.5,9.7,4.3,8.7,4.3,8.7S5,8.1,5.5,7.6c0,0,0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1C4.8,7.3,3.8,7.2,3.8,7.2     S3.4,6.3,3.1,5.7C3.1,5.6,3,5.6,3,5.6c-0.1,0-0.1,0-0.1,0.1C2.6,6.3,2.1,7.2,2.1,7.2z"
      />
    </BaseSvg>
  );
};

export default StunnedSvg;
