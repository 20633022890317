export var ExpressionOperators;
(function (ExpressionOperators) {
    ExpressionOperators["ADD"] = "ADD";
    ExpressionOperators["MIN"] = "MIN";
    ExpressionOperators["MAX"] = "MAX";
    ExpressionOperators["FLOOR"] = "FLOOR";
    ExpressionOperators["MULTIPLY"] = "MULTIPLY";
    ExpressionOperators["DYNAMIC_VALUE"] = "DYNAMIC_VALUE";
    ExpressionOperators["STATIC_VALUE"] = "STATIC_VALUE";
})(ExpressionOperators || (ExpressionOperators = {}));
export var StatementOperators;
(function (StatementOperators) {
    StatementOperators["SET"] = "SET";
    StatementOperators["PUSH"] = "PUSH";
})(StatementOperators || (StatementOperators = {}));
