import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class IntelligenceSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor, secondaryFillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 30.86 31.19" className={className}>
        <path
          fill={fillColor}
          d="M5.25,6.93a8.88,8.88,0,0,1,.49-.79,4.36,4.36,0,0,1,.63-.66,7.76,7.76,0,0,1,1.74-.89C4.42,2.69,1.84.88,2.35,2.56c.29.95.21,1.09,2,3.38Z"
        />
        <path fill={fillColor} d="M23,8v.06C23,8.08,23,8,23,8Z" />
        <path
          fill={fillColor}
          d="M5.82,8.83A31.34,31.34,0,0,1,21.34,7.16c-.86-.48-2.2-1.25-4.28-2.49C10.47.74,5.81-1.23,7.18.82A11.26,11.26,0,0,1,9.35,6S6.56,7,5.82,8.83Z"
        />
        <path
          fill={fillColor}
          d="M22.92,9.39c-.3-.35-.56-.75-.87-1.16.2,0,.34,0,.48,0a.54.54,0,0,0,.18-.08.65.65,0,0,0-.08-.2c-.29-.31-.57-.62-.87-.91a18.35,18.35,0,0,0-4.95-3.29,9.6,9.6,0,0,0-3.79-1c-.31.16-.61.34-.93.48s-.88.56-1.35.81-1.14.66-1.7,1c-.14.11-.3.2-.44.3.1.22-.05.43-.23.63Q7.3,7.18,6.27,8.4a4.12,4.12,0,0,0-1,3.39,4.22,4.22,0,0,1-.73,3,10.25,10.25,0,0,1-1.91,2.12c-.8.69-1.62,1.35-2.42,2-.26.22-.26.29,0,.53a4.52,4.52,0,0,0,2,1.1,1,1,0,0,0,.37,0c.18,0,.38-.2.49.12,0,0,.12.06.18.05a9.94,9.94,0,0,0,1.69-.17c1.23-.32,2.44-.71,3.65-1.09A11.76,11.76,0,0,1,12,18.66c.62,0,.62,0,.84.67.32,1,1.7,1.82,1.48,2.8,0,.08,0,.15-.06.23a4.42,4.42,0,0,1,.53,3.24,5.41,5.41,0,0,1-1.51,3.61c-.09.21-.17.42-.27.61a3.89,3.89,0,0,1-.53.76.28.28,0,0,0,.15.08c1.74-.77,4.27-3.57,5.64-5.13.46-.52.77-.14.41.49-.68,1.18-2.08,4.1-3,4.91q1.85.13,3.71.2l1.79.06.37-.06c.55-.11,1.09-.26,1.63-.42.89-.79,2.16-4,2.65-4.94.39-.72.55-.09.62.72.13,1.4-1.72,3.5-2.06,4a20.7,20.7,0,0,0,4.31-2c3.19-2.79,1.84-6.61,1-8.11-.34-.63-1.47-3.09-1.54-3.42a18.82,18.82,0,0,1,2.63,4.73C30.84,14.15,23.88,10.5,22.92,9.39Z"
        />
        <polygon
          fill={secondaryFillColor}
          points="8.11 11.41 8.04 11.11 8.84 9.73 10.49 9.73 10.04 10.94 8.4 11.73 8.11 11.41"
        />
        <path
          fill={secondaryFillColor}
          d="M13.17,28.75a8.88,8.88,0,0,0,1.14-3.16v-.07l0-.07a4,4,0,0,0-.51-2.88l-.07-.16.1-.39c.08-.34-.28-.78-.65-1.24a4.27,4.27,0,0,1-.82-1.29c0-.12-.08-.24-.11-.32H12a11.27,11.27,0,0,0-3.28.7c-1.21.39-2.44.78-3.68,1.1a6.2,6.2,0,0,1-1.29.15l-.48,0h0A.77.77,0,0,1,2.71,21H2.66a1.72,1.72,0,0,1-.38.06H2.22l-.13,0-.19-.07-.49-.14.13-.5.14-.44.1-.09c.1-.09,2.42-2.23,3.75-3.19s5-3,7.73-3a4.52,4.52,0,0,1,1.12.13,6.86,6.86,0,0,1,3.86,3.62,9.52,9.52,0,0,1,.31,7.95l-.1.25-.27.05c-1.75,2-3.41,3.77-4.5,3.93l-1,.15Z"
        />
        <path
          fill={fillColor}
          d="M13.26,14.06a4,4,0,0,1,1,.12c2.52.64,6,5.42,3.82,10.89a.5.5,0,0,0-.29.16c-1.37,1.55-3.22,3.62-4.18,3.76a9.62,9.62,0,0,0,1.21-3.4,4.42,4.42,0,0,0-.53-3.24c0-.08,0-.15.06-.23.22-1-1.16-1.83-1.48-2.8-.2-.61-.22-.67-.7-.67H12a11.76,11.76,0,0,0-3.43.73c-1.21.39-2.42.77-3.65,1.09a9.94,9.94,0,0,1-1.69.17h0c-.06,0-.15,0-.16-.05s-.13-.2-.21-.2a1.93,1.93,0,0,0-.29.07,1.79,1.79,0,0,1-.29,0H2.2L2,20.45l.1-.31s2.39-2.2,3.7-3.16c1.16-.84,4.77-2.92,7.44-2.92m0-1c-3.06,0-6.91,2.3-8,3.11-1.34,1-3.69,3.14-3.79,3.23l-.2.18-.08.26-.1.31-.3,1,.94.28.26.09a1.32,1.32,0,0,0,.32,0,1.63,1.63,0,0,0,.31,0,1.27,1.27,0,0,0,.63.16h.11l.46,0a6.69,6.69,0,0,0,1.39-.17c1.25-.33,2.49-.72,3.7-1.1a11.39,11.39,0,0,1,3-.68,4.79,4.79,0,0,0,.9,1.43,2.94,2.94,0,0,1,.55.82l0,.07,0,.13-.08.34.14.31a3.59,3.59,0,0,1,.49,2.51l0,.14v.15a8.31,8.31,0,0,1-1.07,2.93l-1,1.78,2-.3c1.13-.17,2.47-1.47,4.68-4l.38-.08.2-.5a10,10,0,0,0-.33-8.36,7.27,7.27,0,0,0-4.18-3.88,5,5,0,0,0-1.25-.15Z"
        />
      </BaseSvg>
    );
  }
}
