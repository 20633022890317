import React from "react";
import { connect } from "react-redux";

import { ThemedShareSvg } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import { SharedAppState } from "../../../stores/typings";
import { ClipboardUtils } from "../../../utils";

interface Props {
  shareUrl: string;
  theme: CharacterTheme;
}
interface State {
  shareUrl: string | null;
  hasCopied: boolean;
}
class ShareUrlPane extends React.PureComponent<Props, State> {
  urlInput = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      shareUrl: props.shareUrl,
      hasCopied: false,
    };
  }

  componentDidMount() {
    this.selectUrlInput();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { shareUrl } = this.props;

    if (shareUrl !== prevProps.shareUrl) {
      this.setState(
        {
          shareUrl,
        },
        this.selectUrlInput
      );
    }
  }

  selectUrlInput = (): void => {
    if (this.urlInput.current) {
      this.urlInput.current.focus();
      this.urlInput.current.setSelectionRange(
        0,
        this.urlInput.current.value.length
      );
    }
  };

  handleClick = (evt: React.MouseEvent): void => {
    const { shareUrl } = this.state;

    ClipboardUtils.copyTextToClipboard(shareUrl === null ? "" : shareUrl);
    this.setState({
      hasCopied: true,
    });
  };

  render() {
    const { hasCopied, shareUrl } = this.state;
    const { theme } = this.props;

    return (
      <div className="ct-share-url-pane">
        <div className="ct-share-url-pane__splash-icon">
          <ThemedShareSvg theme={theme} />
        </div>
        <Header>Shareable Link Generated</Header>
        <div className="ct-share-url-pane__url">
          <input
            type="text"
            value={shareUrl ? shareUrl.replace(/https*:\/\//, "") : ""}
            className="ct-share-url-pane__input"
            ref={this.urlInput}
          />
        </div>
        <div
          className="ct-share-url-pane__clipboard"
          onClick={this.handleClick}
        >
          {hasCopied ? (
            <React.Fragment>Copied! &#x2714;</React.Fragment>
          ) : (
            "Click to Copy"
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(ShareUrlPane);
