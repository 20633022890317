import * as VehicleComponentAccessors from './accessors';
import * as VehicleComponentConstants from './constants';
import * as VehicleComponentDerivers from './derivers';
import * as VehicleComponentGenerators from './generators';
import * as VehicleComponentSimulators from './simulators';
import * as VehicleComponentTypings from './typings';
export * from './constants';
export * from './typings';
export { VehicleComponentAccessors, VehicleComponentDerivers, VehicleComponentGenerators, VehicleComponentSimulators };
export default Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, VehicleComponentAccessors), VehicleComponentConstants), VehicleComponentDerivers), VehicleComponentGenerators), VehicleComponentTypings), VehicleComponentSimulators);
