import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class ConstitutionSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 34.76 32.85" className={className}>
        <path
          fill={fillColor}
          d="M2.41,13.25l2.89.47.78,3.08h.49l.42-3,3,2.14c-2.7,2.42-3.43,5.23-2.15,8.8L6.19,24v1.82c-.39.06-2.22-.09-2.51,0a2.42,2.42,0,0,1-.14-.25C3,23.88,5.79,22.94,6,21.51a5.19,5.19,0,0,0-2.06-5.13c-.62-.47-1.18-.86-.91-1.91.08-.33-.36-.83-.56-1.26Z"
        />
        <path
          fill={fillColor}
          d="M20.71,1.89c-2,.29-6.66,1.53-9.16,1.89C12.87,2.57,16,.08,17.63,0,18.55,0,20,1.08,21,1.45Z"
        />
        <path fill="none" d="M26.3,7.44l.56-2-.23-.12-2.25,4,3.13-1.39Z" />
        <path
          fill="none"
          d="M12.66,8.84c-.7.06-1.52,2.65-2.25,3.78,1.88-.11,4.25.1,6,0C15.41,11.26,15.11,8.61,12.66,8.84Z"
        />
        <path
          fill={fillColor}
          d="M5.49,6.6c-.12-.53-.73-.88-1.25-1.16a4.15,4.15,0,0,0-.73-.32l-.22,0A2.35,2.35,0,0,0,2.85,5a1.8,1.8,0,0,0-.5,0l-.07,0a1.8,1.8,0,0,0-.41.19l-.13.09a2.54,2.54,0,0,0-.36.31l-.09.09a4.31,4.31,0,0,0-.41.56l-.1.17q-.15.26-.3.58l-.11.23c-.13.28-.25.58-.37.92l.33.09h0l.35.11L1,8.5h.05l.3,0h.17l.07,0,.09,0,.07,0,.08-.06.06-.06L2,8.25l0-.08L2.08,8l0-.1c0-.05,0-.11,0-.18s0-.08,0-.12,0-.16,0-.25,0-.08,0-.13,0-.29,0-.46c.51.4.72.7.95.72l.73.07c.23,0,.46,0,.66,0H4.7a1.61,1.61,0,0,0,.32,0l.05,0a.43.43,0,0,0,.25-.16A1,1,0,0,0,5.49,6.6Z"
        />
        <path
          fill={fillColor}
          d="M6.08,16.09l-.26-2.26C6.74,14.1,6.77,14.34,6.08,16.09Z"
        />
        <path
          fill={fillColor}
          d="M34.76,16.27c-1.1-3-1.6-6.19-4.52-7.61-2,2.38-2.26,2.51-4.05,2.05.32-.11.65-.19,1-.32A3.53,3.53,0,0,0,29.6,7.64a4.31,4.31,0,0,0-1.14-3.92,1.91,1.91,0,0,0-2.72-.29c-.69.47-1.39.9-2.1,1.34A.57.57,0,0,1,23.3,5c-.16.1-.76-.88-1.71-.94a18.17,18.17,0,0,0-5.81.46A45.59,45.59,0,0,1,8.81,6a4.8,4.8,0,0,0-.86.17s0,.07.05.11a2.29,2.29,0,0,1-.35,1.83L8.18,8l.06.32L.35,10.83l1.06,3.1,1-.68c-.25-.66.15-.53.49-.48,1.29.21,2.57.46,3.86.64,2.56.35,3.86,2.18,4.58,4.69.64,2.2-.29,3.83-1.64,5.3l-.24.28c-.54,3-2.29,4-4.93,3.58a3.79,3.79,0,0,0,3.93.89A29.55,29.55,0,0,1,11.88,27c2.17-.51,4.42-1.28,6.56-.31,1.55.71,2.83,2.08,4.29,3.06s3.16,2.13,4.8,3c.31-.85.59-1.71.83-2.59.35-1.27,1-2.47,1.28-3.75a.5.5,0,0,1,.57-.44l1-2,.59,2.31a11.8,11.8,0,0,0,.7-1.72,49.17,49.17,0,0,0,1.24-6.29,14.3,14.3,0,0,0-.66-3.8ZM11.12,12.18c.55-.87,1.18-2.84,1.72-2.89,1.87-.17,2.1,1.85,2.86,2.89C14.37,12.25,12.56,12.1,11.12,12.18Z"
        />
      </BaseSvg>
    );
  }
}
