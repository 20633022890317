const DESKTOP_COMPONENT_START_WIDTH = 1024;
const DESKTOP_LARGE_COMPONENT_START_WIDTH = 1200;
const TABLET_COMPONENT_START_WIDTH = 768;
const MQ_IS_MOBILE_SIZE = `(max-width: ${TABLET_COMPONENT_START_WIDTH - 1}px)`;
const SIDEBAR_FIXED_POSITION_START_WIDTH = 1600;

export {
  DESKTOP_COMPONENT_START_WIDTH,
  DESKTOP_LARGE_COMPONENT_START_WIDTH,
  TABLET_COMPONENT_START_WIDTH,
  MQ_IS_MOBILE_SIZE,
  SIDEBAR_FIXED_POSITION_START_WIDTH,
};
