import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class PlatinumCoinSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;

    return (
      <BaseSvg viewBox="0 0 24 24" className={className}>
        <path
          d="m9.95662 3.30735c.16878-.19532.41258-.30735.66898-.30735h2.7488c.2564 0 .5002.11203.669.30735l5.7367 6.63816c.1418.16409.2199.37469.2199.59269v2.9236c0 .218-.0781.4286-.2199.5927l-5.7367 6.6382c-.1688.1953-.4126.3073-.669.3073h-2.7488c-.2564 0-.5002-.112-.66899-.3073l-5.73668-6.6382c-.14178-.1641-.21993-.3747-.21993-.5927v-2.9236c0-.218.07815-.4286.21993-.59269z"
          fill="#b5b5b5"
        />
        <path
          d="m10.8356 18.1585-5.08818-5.8151c-.1595-.1823-.24742-.4162-.24742-.6585v1c0 .2423.08792.4762.24742.6585l5.08818 5.8151c.1899.217.4642.3415.7526.3415h.8236c.2884 0 .5627-.1245.7526-.3415l5.0882-5.8151c.1595-.1823.2474-.4162.2474-.6585v-1c0 .2423-.0879.4762-.2474.6585l-5.0882 5.8151c-.1899.217-.4642.3415-.7526.3415h-.8236c-.2884 0-.5627-.1245-.7526-.3415z"
          fill="#a3a3a3"
        />
        <path
          clipRule="evenodd"
          d="m11.5882 4.5c-.2884 0-.5627.12448-.7526.34149l-5.08818 5.81511c-.1595.1823-.24742.4163-.24742.6585v1.3698c0 .2422.08792.4762.24742.6585l5.08818 5.8151c.1899.217.4642.3415.7526.3415h.8236c.2884 0 .5627-.1245.7526-.3415l5.0882-5.8151c.1595-.1823.2474-.4163.2474-.6585v-1.3698c0-.2423-.0879-.4762-.2474-.6585l-5.0882-5.8151c-.1899-.21702-.4642-.3415-.7526-.3415zm-1.1344-2.5c-.2884 0-.56272.12448-.75261.34149l-6.45377 7.37574c-.1595.18229-.24742.41627-.24742.65847v3.2486c0 .2422.08792.4762.24742.6585l6.45377 7.3757c.18989.217.46421.3415.75261.3415h3.0924c.2884 0 .5627-.1245.7526-.3415l6.4538-7.3757c.1595-.1823.2474-.4163.2474-.6585v-3.2486c0-.2422-.0879-.47618-.2474-.65847l-6.4538-7.37573c-.1899-.21702-.4642-.3415-.7526-.3415z"
          fill="#949494"
          fillRule="evenodd"
        />
        <path
          d="m10.2929 9.12132c-.39053-.39052-.39053-1.02369 0-1.41421l1.2463-1.41422c.3905-.39052 1.0237-.39052 1.4142 0l3.6679 4.29291c.3905.3905.3905 1.0237 0 1.4142l-1.4142 1.4142c-.3905.3905-1.0237.3905-1.4142 0z"
          fill="#dcdcdc"
        />
        <path
          d="m8.34764 10.2442c.42128-.36072 1.05967-.31674 1.42589.0983l1.25207 1.3511c.3662.415.3216 1.0439-.0997 1.4046-.4213.3608-1.05969.3168-1.4259-.0982l-1.25207-1.3511c-.36621-.415-.32157-1.0439.09971-1.4047z"
          fill="#ccc"
        />
        <path
          d="m11.9999 2.47315c.3413-.26295 1.5-.37315 2 .02685l4 4.5c.3662.37344.3216.93932-.0997 1.26394s-1.0597.28505-1.4259-.08838c0 0-2.74-3.30604-3.5744-3.98415-.3682-.29925-.7065-.1553-.9997-.45432-.2932-.29901-.2416-1.00098.0997-1.26394z"
          fill="#ccc"
        />
        <circle cx="18.6001" cy="9.25" fill="#b5b5b5" r="1" />
      </BaseSvg>
    );
  }
}
