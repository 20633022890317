import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const GrappledSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 20 20">
      <path
        fill={fillColor}
        d="M10.5,9.5c0,0,1.5-1.6,0.9-2.6c-0.6-1-2.3-2.6-2.3-3S8.6,0.3,8.1,0S7.2,1.4,7.6,2.8S6.6,2.9,6.4,2.6   C6.2,2.2,4.6,0,4.1,0.4s0,1.1,0.4,1.6c0.4,0.5,1.6,2,1.3,2.2c-0.2,0.2-4-3.1-4.5-2.7s-0.5,1,0.1,1.4s3.9,2.2,3.9,2.6S4.3,5.8,3,5.4   C1.7,4.9,0,5,0,5.5s5.4,2.2,6.1,2.7c0.8,0.4,0.3,1-0.2,1.1C5.5,9.5,4,10.6,3.7,11.3C3.4,12,4,12.4,4.4,12c0.3-0.4,2.4-1.6,3-1.4   C8,10.9,9.3,10.5,10.5,9.5z"
      />
      <polygon
        fill={fillColor}
        points="11.4,6.9 21.6,13.7 18.5,17.3 8.5,9.2  "
      />
      <path
        fill={fillColor}
        stroke={secondaryFillColor}
        d="M10.6,19.1c-1.2-0.9-2.5-1.8-3.7-2.8c0.1-0.1,1.5-2,1.5-2.1c0.5-0.6,1-1.2,1.4-1.9c0-0.1,0.1-0.2,0.1-0.3   c-0.2-1.2-0.4-2.4-0.5-3.6c-0.1-0.6,0.2-1,0.8-1c0.4-0.1,0.8-0.1,1.2-0.2c0.1,0,0.1,0,0.2,0c0.2,0,0.2,0.2,0.2,0.3   c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.2-0.1,0.3,0,0.3c0.1,0.1,0.2,0,0.3-0.1c0.3-0.4,0.6-0.9,0.9-1.3c0.5-0.6,1.3-0.5,1.6,0.2   c0.1,0.2,0.2,0.2,0.3,0.1c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.7-0.1,1,0.1c0.3,0.2,0.4,0.5,0.3,0.9c0,0.3,0,0.4,0.3,0.4   c0.7,0.1,0.9,0.7,0.7,1.3c-0.1,0.2,0,0.4,0.2,0.6c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.5,0.7,0.2,1.1c-0.6,0.9-1.4,1.6-2.4,2.1   c-0.4,0.2-0.8,0.2-1.2,0.2c-0.1,0-0.2,0-0.3,0.1c-0.6,0.9-1.3,1.9-2,2.8C11.9,17.3,10.6,19.1,10.6,19.1z"
      />
    </BaseSvg>
  );
};

export default GrappledSvg;
