import * as React from "react";

import {
  Creature,
  CreatureUtils,
  DiceUtils,
  FormatUtils,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import { DDB_MEDIA_URL } from "../../../../../constants";

interface Props {
  creature: Creature;
  ruleData: RuleData;
  className: string;
}
export default class CreatureBlockBasic extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
  };

  renderMeta = (): string => {
    const { creature, ruleData } = this.props;

    let level: string = "";
    let levelValue = CreatureUtils.getLevel(creature);
    if (levelValue !== null) {
      level = `${FormatUtils.ordinalize(levelValue)}-level`;
    }

    const sizeInfo = RuleDataUtils.getCreatureSizeInfo(
      CreatureUtils.getSizeId(creature),
      ruleData
    );
    let size: string = "";
    if (sizeInfo && sizeInfo.name !== null) {
      size = sizeInfo.name;
    }

    const typeInfo = RuleDataUtils.getMonsterTypeInfo(
      CreatureUtils.getTypeId(creature),
      ruleData
    );
    let type: string = "";
    if (typeInfo && typeInfo.name !== null) {
      type = typeInfo.name;
    }

    const alignmentInfo = RuleDataUtils.getAlignmentInfo(
      CreatureUtils.getAlignmentId(creature),
      ruleData
    );
    let alignment: string = "";
    if (alignmentInfo && alignmentInfo.name !== null) {
      alignment = alignmentInfo.name;
    }

    let extraTextChunks: Array<string> = [];
    if (
      CreatureUtils.getName(creature) !==
      CreatureUtils.getDefinitionName(creature)
    ) {
      extraTextChunks.push(CreatureUtils.getDefinitionName(creature));
    }
    let subTypes = CreatureUtils.getSubTypes(creature);
    if (subTypes.length) {
      subTypes.forEach((monsterInfo) => {
        if (monsterInfo.name !== null) {
          extraTextChunks.push(monsterInfo.name);
        }
      });
    }

    let extraText: string = extraTextChunks.join(", ");

    return `${level} ${size} ${type}${extraText ? ` (${extraText})` : ""}${
      alignment ? `, ${alignment}` : ""
    }`.trim();
  };

  renderSpeed = (): string => {
    const { creature, ruleData } = this.props;

    let movements = CreatureUtils.getMovements(creature);

    let movementDisplays: Array<string> = movements.map((movement, idx) => {
      let label: string = "";
      if (idx !== 0) {
        label = RuleDataUtils.getMovementName(
          movement.movementId,
          ruleData
        ).toLowerCase();
      }
      return `${label} ${FormatUtils.renderDistance(movement.speed)} ${
        movement.notes ? movement.notes : ""
      }`.trim();
    });

    return movementDisplays.join(", ");
  };

  renderChallengeTidbits = (): React.ReactNode => {
    const { creature } = this.props;

    let challengeInfo = CreatureUtils.getChallengeInfo(creature);
    const hideCr = CreatureUtils.getHideCr(creature);

    if (!challengeInfo || hideCr) {
      return null;
    }

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Challenge</span>
        <span className="ddbc-creature-block__tidbit-data">
          {FormatUtils.renderChallengeRating(challengeInfo.value)} (
          {FormatUtils.renderLocaleNumber(challengeInfo.xp)} XP)
        </span>
      </div>
    );
  };

  renderSeparator = (): React.ReactNode => {
    //TODO not hard-code the src url here?
    return (
      <div className="ddbc-creature-block__separator">
        <img
          className="ddbc-creature-block__separator-img"
          alt=""
          src={`${DDB_MEDIA_URL}/file-attachments/0/579/stat-block-header-bar.svg`}
        />
      </div>
    );
  };

  render() {
    const { creature } = this.props;

    let armorClassDescription =
      CreatureUtils.getArmorClassDescription(creature);
    let hitPointInfo = CreatureUtils.getHitPointInfo(creature);
    let hitPointDice = CreatureUtils.getHitPointDice(creature);

    return (
      <div className="ddbc-creature-block ddbc-creature-block--basic">
        <div className="ddbc-creature-block__header">
          <div className="ddbc-creature-block__name">
            {CreatureUtils.getName(creature)}
          </div>
          <div className="ddbc-creature-block__meta">{this.renderMeta()}</div>
        </div>
        {this.renderSeparator()}
        <div className="ddbc-creature-block__attributes">
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">
              Armor Class
            </span>
            <span className="ddbc-creature-block__attribute-value">
              <span className="ddbc-creature-block__attribute-data-value">
                {CreatureUtils.getArmorClass(creature)}
              </span>
              {armorClassDescription && (
                <span className="ddbc-creature-block__attribute-data-extra">
                  {armorClassDescription}
                </span>
              )}
            </span>
          </div>
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">
              Hit Points
            </span>
            <span className="ddbc-creature-block__attribute-data">
              <span className="ddbc-creature-block__attribute-data-value">
                {hitPointInfo.totalHp}
              </span>
              <span className="ddbc-creature-block__attribute-data-extra">
                ({DiceUtils.renderDice(hitPointDice)})
              </span>
            </span>
          </div>
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">Speed</span>
            <span className="ddbc-creature-block__attribute-data">
              <span className="ddbc-creature-block__attribute-data-value">
                {this.renderSpeed()}
              </span>
            </span>
          </div>
        </div>
        <div className="ddbc-creature-block__tidbits">
          {this.renderChallengeTidbits()}
        </div>
      </div>
    );
  }
}
