import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class ElectrumCoinSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;

    return (
      <BaseSvg viewBox="0 0 24 24" className={className}>
        <path
          clipRule="evenodd"
          d="m7.32745 3c-.42911 0-.84556.13554-1.16546.42156-1.20722 1.07938-4.16199 4.17139-4.16199 8.57844 0 4.407 2.95477 7.4991 4.16199 8.5784.3199.2861.73635.4216 1.16547.4216h9.34504c.4292 0 .8456-.1355 1.1655-.4216 1.2072-1.0793 4.162-4.1714 4.162-8.5784 0-4.40704-2.9548-7.49906-4.162-8.57844-.3199-.28602-.7363-.42156-1.1655-.42156zm4.67255 14.25c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25-1.25.5596-1.25 1.25.5596 1.25 1.25 1.25z"
          fill="#8a9eac"
          fillRule="evenodd"
        />
        <path
          d="m7.03711 16.9284c.34586.3796.84504.5716 1.35854.5716h7.20875c.5134 0 1.0126-.192 1.3585-.5716 1.0485-1.1506 2.3552-3.0498 2.5198-5.4284.0114.1644.0173.3311.0173.5 0 2.6125-1.4162 4.6983-2.5371 5.9284-.3459.3796-.845.5716-1.3585.5716h-7.20875c-.5135 0-1.01268-.192-1.35854-.5716-1.12095-1.2301-2.53711-3.3159-2.53711-5.9284 0-.1689.00592-.3356.01729-.5.16458 2.3786 1.47133 4.2778 2.51982 5.4284z"
          fill="#6d7f8c"
        />
        <path
          d="m14.7246 16.375c-.1829 1.3414-1.333 2.375-2.7246 2.375s-2.54175-1.0336-2.72465-2.375c-.01671.1226-.02535.2478-.02535.375 0 1.5188 1.2312 2.75 2.75 2.75s2.75-1.2312 2.75-2.75c0-.1272-.0086-.2524-.0254-.375z"
          fill="#6d7f8c"
        />
        <path
          clipRule="evenodd"
          d="m14.75 16c0 1.5188-1.2312 2.75-2.75 2.75s-2.75-1.2312-2.75-2.75 1.2312-2.75 2.75-2.75 2.75 1.2312 2.75 2.75zm-2.75 1.25c.6904 0 1.25-.5596 1.25-1.25s-.5596-1.25-1.25-1.25-1.25.5596-1.25 1.25.5596 1.25 1.25 1.25z"
          fill="#7c8d99"
          fillRule="evenodd"
        />
        <circle cx="12" cy="16" fill="#8697a3" fillOpacity=".5" r="1.25" />
        <path
          clipRule="evenodd"
          d="m8.39565 5.5c-.5135 0-1.01268.19203-1.35854.57159-1.12095 1.23016-2.53711 3.31587-2.53711 5.92841 0 2.6125 1.41616 4.6983 2.53711 5.9284.34586.3796.84504.5716 1.35854.5716h7.20875c.5135 0 1.0126-.192 1.3585-.5716 1.1209-1.2301 2.5371-3.3159 2.5371-5.9284 0-2.61254-1.4162-4.69825-2.5371-5.92841-.3459-.37956-.8451-.57159-1.3585-.57159zm-1.0682-2.5c-.42911 0-.84556.13554-1.16546.42156-1.20722 1.07938-4.16199 4.17139-4.16199 8.57844 0 4.407 2.95477 7.4991 4.16199 8.5784.3199.2861.73635.4216 1.16547.4216h9.34504c.4292 0 .8456-.1355 1.1655-.4216 1.2072-1.0793 4.162-4.1714 4.162-8.5784 0-4.40704-2.9548-7.49906-4.162-8.57844-.3199-.28602-.7363-.42156-1.1655-.42156z"
          fill="#7c8d99"
          fillRule="evenodd"
        />
        <path
          d="m9.75003 9.13768c.23943.5003.03173 1.10552-.46392 1.35192l-1.62912.8597c-.49566.2463-1.09156.0404-1.33099-.4599s-.03173-1.10555.46392-1.35187l1.62912-.85971c.49566-.24633 1.09156-.04044 1.33099.45986z"
          fill="#7c8d99"
        />
        <path
          d="m10 8c0-1.10457.8954-2 2-2h3c1.6569 0 3 1.34315 3 3v1c0 1.1046-.8954 2-2 2h-4c-1.1046 0-2-.8954-2-2z"
          fill="#9fb3c0"
        />
        <path
          clipRule="evenodd"
          d="m11.8507 3.53389c-.4997.02746-.8798.4106-.8489.85577.0308.44514.4608.78374.9604.75632l.0092-.00049.0303-.00157c.027-.00137.0673-.00336.1193-.00575.1039-.0048.6517.00093.6517.00093l1.3785-.02395s1.0448.01588 1.4768.05451c.2164.01934.3931.04327.5261.07005.1131.02275.1591.04034.1591.04034.8407.47238 1.3364 1.1415 1.969 2.07148.3071.45151.5537.87024.7234 1.17593.0846.15247.1495.27584.1927.35991.0216.04201.0377.07413.0481.09509l.0113.02287.0023.00484c.1974.40967.7304.60007 1.1904.42444.4601-.1757.6733-.65045.4761-1.0604l-.0008-.00162-.0014-.0028-.0044-.00908-.0156-.03164c-.0133-.02693-.0326-.06533-.0576-.1139-.0499-.0971-.1225-.23511-.216-.40351-.1865-.33604-.4578-.797-.7981-1.29727-.6596-.96959-1.3422-1.92667-2.5695-2.61007-.2296-.12785-.4955-.197-.7095-.24006-.2307-.04644-.4866-.07863-.7446-.10169-.5167-.04619-1.6535-.06257-1.6535-.06257s-1.0706.0119-1.4533.02517c-.1918.00665-.5643-.0051-.6749 0-.0553.00255-.0988.00469-.1288.00622l-.0347.00179z"
          fill="#9fb3c0"
          fillRule="evenodd"
        />
        <circle cx="9.75" cy="4.25" fill="#8a9eac" r=".75" />
      </BaseSvg>
    );
  }
}
