import { visuallyHidden } from "@mui/utils";
import clsx from "clsx";
import React, { useContext } from "react";
import { connect, DispatchProp } from "react-redux";

import {
  TabOptions,
  TabOptionsContent,
  TabOptionsGroup,
} from "@dndbeyond/character-common-components/es";
import {
  AlignmentContract,
  Background,
  CharacterTraits,
  Constants,
  SizeContract,
  rulesEngineSelectors,
  CharacterTheme,
  CharacterFeaturesManager,
  SnippetData,
  RuleData,
  AbilityLookup,
  DataOriginRefData,
} from "@dndbeyond/character-rules-engine/es";

import { InfoItem } from "~/components/InfoItem";
import { NumberDisplay } from "~/components/NumberDisplay";

import { sidebarActions } from "../../../Shared/actions";
import { appEnvSelectors } from "../../../Shared/selectors";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../Shared/utils";
import BackgroundDetail from "../../components/BackgroundDetail";
import ContentGroup from "../../components/ContentGroup";
import TraitContent from "../../components/TraitContent";
import { SheetAppState } from "../../typings";
import styles from "./styles.module.css";
import { CharacterFeaturesManagerContext } from "~/tools/js/Shared/managers/CharacterFeaturesManagerContext";
import { handleActionShow, handleActionUseSet, handleFeatShow, handleSpellDetailShow, handleSpellUseSet } from "../../../../../handlers/commonHandlers";

const DEFAULT_VALUE = "--";

const TAB_KEY = {
  ALL: "ALL",
  BACKGROUND: "BACKGROUND",
  CHARACTERISTICS: "CHARACTERISTICS",
  APPEARANCE: "APPEARANCE",
};

interface Props extends DispatchProp {
  isVertical: boolean;
  background: Background | null;
  alignment: AlignmentContract | null;
  height: string | null;
  weight: number | null;
  size: SizeContract | null;
  faith: string | null;
  skin: string | null;
  eyes: string | null;
  hair: string | null;
  age: number | null;
  gender: string | null;
  traits: CharacterTraits;
  isReadonly: boolean;
  theme: CharacterTheme;
  characterFeaturesManager: CharacterFeaturesManager;
  snippetData: SnippetData;
  ruleData: RuleData;
  abilityLookup: AbilityLookup;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
}
class Description extends React.PureComponent<Props> {
  static defaultProps = {
    isVertical: false,
  };

  handlePhysicalCharacteristicsClick = (): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.DESCRIPTION));
    }
  };

  handleBackgroundClick = (): void => {
    const { dispatch } = this.props;

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.BACKGROUND));
  };

  handleTraitShow = (key: string): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      dispatch(
        sidebarActions.paneHistoryStart(PaneComponentEnum.TRAIT, null, {
          type: key,
        })
      );
    }
  };

  handleClick = (e: React.MouseEvent, onClick: Function): void => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClick();
  };

  renderDescriptionItem = (
    value: number | string | null,
    fallback: string = DEFAULT_VALUE
  ): React.ReactNode => {
    return value === null ? fallback : value;
  };

  renderCharacteristics = (): React.ReactNode => {
    const {
      alignment,
      height,
      weight,
      size,
      faith,
      skin,
      eyes,
      hair,
      age,
      gender,
      isVertical,
      theme,
    } = this.props;

    const infoItemProps = {
      role: "listitem",
      inline: isVertical,
    };

    return (
      <ContentGroup header="Characteristics">
        <div
          className={clsx(["ct-description__physical", styles.physical])}
          role="list"
          onClick={(e) =>
            this.handleClick(e, this.handlePhysicalCharacteristicsClick)
          }
        >
          <InfoItem label="Alignment" {...infoItemProps}>
            {alignment === null ? DEFAULT_VALUE : alignment.name}
          </InfoItem>
          <InfoItem label="Gender" {...infoItemProps}>
            {this.renderDescriptionItem(gender)}
          </InfoItem>
          <InfoItem label="Eyes" {...infoItemProps}>
            {this.renderDescriptionItem(eyes)}
          </InfoItem>
          <InfoItem label="Size" {...infoItemProps}>
            {this.renderDescriptionItem(size ? size.name : null)}
          </InfoItem>
          <InfoItem label="Height" {...infoItemProps}>
            {this.renderDescriptionItem(height)}
          </InfoItem>
          <InfoItem label="Faith" {...infoItemProps}>
            {this.renderDescriptionItem(faith)}
          </InfoItem>
          <InfoItem label="Hair" {...infoItemProps}>
            {this.renderDescriptionItem(hair)}
          </InfoItem>
          <InfoItem label="Skin" {...infoItemProps}>
            {this.renderDescriptionItem(skin)}
          </InfoItem>
          <InfoItem label="Age" {...infoItemProps}>
            {this.renderDescriptionItem(age)}
          </InfoItem>
          <InfoItem label="Weight" {...infoItemProps}>
            {weight === null ? (
              DEFAULT_VALUE
            ) : (
              <NumberDisplay type="weightInLb" number={weight} />
            )}
          </InfoItem>
        </div>
      </ContentGroup>
    );
  };

  renderTraits = (): React.ReactNode => {
    const { traits, theme } = this.props;

    return (
      <div
        className="ct-description__traits"
        style={
          theme.isDarkMode
            ? { borderColor: `${theme.themeColor}66` }
            : undefined
        }
      >
        <TraitContent
          traits={traits}
          traitKey={Constants.TraitTypeEnum.PERSONALITY_TRAITS}
          label="Personality Traits"
          fallback="+ Add Personality Traits"
          onClick={this.handleTraitShow}
        />
        <TraitContent
          traits={traits}
          traitKey={Constants.TraitTypeEnum.IDEALS}
          label="Ideals"
          fallback="+ Add Ideals"
          onClick={this.handleTraitShow}
        />
        <TraitContent
          traits={traits}
          traitKey={Constants.TraitTypeEnum.BONDS}
          label="Bonds"
          fallback="+ Add Bonds"
          onClick={this.handleTraitShow}
        />
        <TraitContent
          traits={traits}
          traitKey={Constants.TraitTypeEnum.FLAWS}
          label="Flaws"
          fallback="+ Add Flaws"
          onClick={this.handleTraitShow}
        />
      </div>
    );
  };

  renderAppearance = (): React.ReactNode => {
    const { traits } = this.props;

    if (traits === null) {
      return null;
    }

    return (
      <ContentGroup header="Appearance">
        <TraitContent
          traits={traits}
          traitKey={Constants.TraitTypeEnum.APPEARANCE}
          fallback="+ Add Appearance information"
          onClick={this.handleTraitShow}
        />
      </ContentGroup>
    );
  };

  renderBackground = (): React.ReactNode => {
    const {
      dispatch,
      background,
      characterFeaturesManager,
      snippetData,
      ruleData,
      abilityLookup,
      dataOriginRefData,
      isReadonly,
      proficiencyBonus,
      theme } = this.props;

    return (
      <ContentGroup header="Background">
        <BackgroundDetail
          background={background}
          onClick={this.handleBackgroundClick}
          onFeatClick={(feat) => handleFeatShow(feat, dispatch)}
          featuresManager={characterFeaturesManager}
          onActionUseSet={(action, uses) => handleActionUseSet(action, uses, dispatch)}
          onActionClick={(action) => handleActionShow(action, dispatch)}
          onSpellClick={(spell) => handleSpellDetailShow(spell, dispatch)}
          onSpellUseSet={(spell, uses) =>
            handleSpellUseSet(spell, uses, dispatch)
          }
          snippetData={snippetData}
          ruleData={ruleData}
          abilityLookup={abilityLookup}
          dataOriginRefData={dataOriginRefData}
          isReadonly={isReadonly}
          proficiencyBonus={proficiencyBonus}
          theme={theme}
        />
      </ContentGroup>
    );
  };

  render() {
    return (
      <section className="ct-description">
        <h2 style={visuallyHidden}>Description</h2>
        <TabOptions
          hideBorder={true}
          initialActiveKey={TAB_KEY.ALL}
          layoutType="pill"
        >
          <TabOptionsGroup tabKey={TAB_KEY.ALL} label="All">
            <TabOptionsContent>
              {this.renderBackground()}
              {this.renderCharacteristics()}
              {this.renderTraits()}
              {this.renderAppearance()}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.BACKGROUND} label="Background">
            <TabOptionsContent>{this.renderBackground()}</TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup
            tabKey={TAB_KEY.CHARACTERISTICS}
            label="Characteristics"
          >
            <TabOptionsContent>
              {this.renderCharacteristics()}
              {this.renderTraits()}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.APPEARANCE} label="Appearance">
            <TabOptionsContent>{this.renderAppearance()}</TabOptionsContent>
          </TabOptionsGroup>
        </TabOptions>
      </section>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    background: rulesEngineSelectors.getBackgroundInfo(state),
    alignment: rulesEngineSelectors.getAlignment(state),
    height: rulesEngineSelectors.getHeight(state),
    weight: rulesEngineSelectors.getWeight(state),
    size: rulesEngineSelectors.getSize(state),
    faith: rulesEngineSelectors.getFaith(state),
    skin: rulesEngineSelectors.getSkin(state),
    eyes: rulesEngineSelectors.getEyes(state),
    hair: rulesEngineSelectors.getHair(state),
    age: rulesEngineSelectors.getAge(state),
    gender: rulesEngineSelectors.getGender(state),
    traits: rulesEngineSelectors.getCharacterTraits(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
    snippetData: rulesEngineSelectors.getSnippetData(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
    abilityLookup: rulesEngineSelectors.getAbilityLookup(state),
    dataOriginRefData: rulesEngineSelectors.getDataOriginRefData(state),
    proficiencyBonus: rulesEngineSelectors.getProficiencyBonus(state),
  };
}

const DescriptionWrapper = (props) => {

  const { characterFeaturesManager } = useContext(
    CharacterFeaturesManagerContext
  );

  return (
    <Description
      characterFeaturesManager={characterFeaturesManager}
      {...props}
    />
  )
}

export default connect(mapStateToProps)(DescriptionWrapper);
