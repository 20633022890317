import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const PsychicSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 21 21">
      <polygon
        fill={fillColor}
        points="9.13 17.92 0.7 19 6.43 15.28 0.23 11.21 6.61 11.21 0 0 8.57 5.5 7.15 1.26 10.48 3.35 12.71 0.51 15.41 4.28 18.11 0.81 16.58 16.25 9.13 17.92"
      />
      <path
        fill={fillColor}
        d="M6.85,20.5l-.15-.17c-.29-.32-.57-.66-.83-1l-.39-.54.83-.31a3.79,3.79,0,0,1,.46-.16,24,24,0,0,0,3.89-1.37l.07-.15c0-.15.08-.29.12-.44A5.89,5.89,0,0,1,10,14.1.83.83,0,0,1,9.8,14a1.82,1.82,0,0,1-.28-.4A6,6,0,0,1,9,11.71a2.08,2.08,0,0,1,0-.26,1.05,1.05,0,0,1,.45-1,5.16,5.16,0,0,1-.1-.73,4.49,4.49,0,0,1,1.16-3.27A5.37,5.37,0,0,1,13.4,4.61a5.3,5.3,0,0,1,1-.11,4.08,4.08,0,0,1,3,1.24,2.8,2.8,0,0,0,.69.56A2.27,2.27,0,0,1,19.2,8a7.11,7.11,0,0,1,0,2.54c0,.14,0,.23-.05.32v0a1,1,0,0,1,.25.65A4.79,4.79,0,0,1,18.72,14a1,1,0,0,1-.54.48l0,0c-.09.31-.19.61-.3.9a4.2,4.2,0,0,1-.28.56l-.16.29a.46.46,0,0,0,0,.11l0,.12a.74.74,0,0,0,.56.65c.71.26,1.42.5,2.14.73l.35.11V20.5Z"
      />
      <path
        fill={secondaryFillColor}
        d="M14.45,5a3.59,3.59,0,0,1,2.62,1.09,3.19,3.19,0,0,0,.81.66,1.76,1.76,0,0,1,.83,1.33,6.56,6.56,0,0,1,0,2.36c0,.09,0,.19,0,.28s-.08.33.11.47a.47.47,0,0,1,.13.33,4.33,4.33,0,0,1-.6,2.27.42.42,0,0,1-.35.26l-.13,0c-.14.43-.25.85-.41,1.26s-.29.53-.42.8a.78.78,0,0,0-.07.3,1.11,1.11,0,0,0,0,.23,1.23,1.23,0,0,0,.89,1c.72.26,1.43.5,2.16.74V20H7.07a12.6,12.6,0,0,1-.8-1c.23-.08.42-.17.62-.22a24.69,24.69,0,0,0,4-1.39.48.48,0,0,0,.3-.31c.07-.24.14-.48.2-.73a.31.31,0,0,0-.06-.22A5.37,5.37,0,0,1,10.46,14a.66.66,0,0,0-.15-.23,2.92,2.92,0,0,1-.34-.44,5.41,5.41,0,0,1-.49-1.69,1.53,1.53,0,0,1,0-.19c0-.42.12-.57.51-.67a9.59,9.59,0,0,1-.16-1.06,4,4,0,0,1,1-2.92A4.82,4.82,0,0,1,13.5,5.1a4.78,4.78,0,0,1,.95-.1m0-1V4a5.8,5.8,0,0,0-1.15.12,5.84,5.84,0,0,0-3.19,2A5,5,0,0,0,8.82,9.77a4.41,4.41,0,0,0,.05.51,1.64,1.64,0,0,0-.41,1.17,2.53,2.53,0,0,0,0,.32,6.43,6.43,0,0,0,.58,2,2.29,2.29,0,0,0,.36.51l.08.09h0a6.37,6.37,0,0,0,.79,2.06l0,.12a23.32,23.32,0,0,1-3.62,1.26,4.27,4.27,0,0,0-.52.18l-.2.07-1.25.45.79,1.08a13.67,13.67,0,0,0,.86,1.06l.3.33H21V17.65l-.7-.22c-.67-.21-1.36-.45-2.12-.72-.21-.08-.22-.12-.24-.27v0l.08-.14a4.58,4.58,0,0,0,.31-.62c.1-.26.19-.52.26-.77a1.55,1.55,0,0,0,.57-.6,5.28,5.28,0,0,0,.72-2.78,1.6,1.6,0,0,0-.22-.76v-.09a7.65,7.65,0,0,0,0-2.77,2.72,2.72,0,0,0-1.36-2,2.21,2.21,0,0,1-.52-.43l0,0A4.57,4.57,0,0,0,14.45,4Z"
      />
    </BaseSvg>
  );
};

export default PsychicSvg;
