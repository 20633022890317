import React from "react";
import { connect } from "react-redux";

import { ThemedExportSvg } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import { ThemeLinkButton } from "../../../components/common/LinkButton";
import { SharedAppState } from "../../../stores/typings";
import { ClipboardUtils } from "../../../utils";

interface Props {
  pdfUrl: string;
  theme: CharacterTheme;
}
interface State {
  pdfUrl: string | null;
  hasCopied: boolean;
}
class ExportPdfPane extends React.PureComponent<Props, State> {
  urlInput = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    this.state = {
      pdfUrl: props.pdfUrl,
      hasCopied: false,
    };
  }

  componentDidMount() {
    this.selectUrlInput();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { pdfUrl } = this.props;

    if (pdfUrl !== prevProps.pdfUrl) {
      this.setState(
        {
          pdfUrl,
        },
        this.selectUrlInput
      );
    }
  }

  selectUrlInput = (): void => {
    if (this.urlInput.current) {
      this.urlInput.current.focus();
      this.urlInput.current.setSelectionRange(
        0,
        this.urlInput.current.value.length
      );
    }
  };

  handleClick = (evt: React.MouseEvent): void => {
    const { pdfUrl } = this.state;
    ClipboardUtils.copyTextToClipboard(pdfUrl === null ? "" : pdfUrl);
    this.setState({
      hasCopied: true,
    });
  };

  render() {
    const { hasCopied, pdfUrl } = this.state;
    const { theme } = this.props;

    return (
      <div className="ct-export-pdf-pane">
        <div className="ct-export-pdf-pane__splash-icon">
          <ThemedExportSvg theme={theme} />
        </div>
        <Header>PDF Generated</Header>
        <div className="ct-export-pdf-pane__url">
          <input
            type="text"
            value={pdfUrl ? pdfUrl.replace(/https*:\/\//, "") : ""}
            className="ct-export-pdf-pane__input"
            ref={this.urlInput}
          />
        </div>
        <div
          className="ct-export-pdf-pane__clipboard"
          onClick={this.handleClick}
        >
          {hasCopied ? (
            <React.Fragment>Copied! &#x2714;</React.Fragment>
          ) : (
            "Click to Copy"
          )}
        </div>
        {pdfUrl !== null && (
          <div className="ct-export-pdf-pane__download">
            <ThemeLinkButton
              url={pdfUrl}
              size={"large"}
              download={true}
              className="ct-export-pdf-pane__download-link"
            >
              Click to Download
            </ThemeLinkButton>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(ExportPdfPane);
