import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const NecroticSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 21.27 19.96">
      <line
        fill={"none"}
        stroke={secondaryFillColor}
        strokeMiterlimit={10}
        x1="7.14"
        y1="9.36"
        x2="19.1"
        y2="9.36"
      />
      <path
        fill={fillColor}
        d="M19.67,4.83S9.64-6.7,1.06,5.88c0,0,13-6.71,16.61,5Z"
      />
      <path
        fill={fillColor}
        d="M.53,18.95l0-.53a20.88,20.88,0,0,1,.64-3.81c.49-1.8,3.09-2.71,4.18-3C4.83,7.28,6,4.83,7.14,3.53a4.71,4.71,0,0,1,3.19-1.8,4.71,4.71,0,0,1,3.19,1.8c1.12,1.3,2.31,3.75,1.76,8.06,1.09.31,3.69,1.22,4.18,3a20.88,20.88,0,0,1,.64,3.81l0,.53ZM10.29,4.81A3,3,0,0,0,9,5.9a4.89,4.89,0,0,0-.42,3.87c.06.21,1.1,4.08,1.74,6.58C11,13.84,12,10,12.08,9.75a4.86,4.86,0,0,0-.42-3.84A2.64,2.64,0,0,0,10.29,4.81Z"
      />
      <path
        fill={secondaryFillColor}
        d="M10.33,2.23c1.33,0,5.54,2.16,4.39,9.73,0,0,3.73.85,4.25,2.78a20.6,20.6,0,0,1,.63,3.71H10.34c.15-.89,2.22-8.57,2.22-8.57,1-4.4-1.89-5.56-2.23-5.56S7.12,5.47,8.11,9.88c0,0,2.07,7.68,2.22,8.57H1.06a20.6,20.6,0,0,1,.63-3.71C2.21,12.81,5.94,12,5.94,12,4.79,4.39,9,2.23,10.33,2.23m0-1a5.22,5.22,0,0,0-3.57,2c-1.15,1.34-2.37,3.8-1.93,8-1.31.43-3.62,1.42-4.12,3.25a21.48,21.48,0,0,0-.66,3.91L0,19.45H20.66l-.06-1.06a21.48,21.48,0,0,0-.66-3.91c-.49-1.83-2.8-2.82-4.12-3.25.45-4.23-.78-6.69-1.93-8a5.22,5.22,0,0,0-3.57-2Zm0,13.13C9.78,12.26,9.19,10,9.07,9.62c-.69-3.06.78-4,1.26-4.26.48.23,1.95,1.2,1.26,4.3-.11.4-.7,2.61-1.25,4.7Z"
      />
      <polygon
        fill={fillColor}
        points="14.44 19.15 18.39 3.33 20.66 3.63 17.21 19.42 14.44 19.15"
      />
      <path
        fill={secondaryFillColor}
        d="M18.77,3.88,20.06,4,16.82,18.88l-1.76-.17L18.77,3.88M18,2.77l-.22.87L14.09,18.47l-.28,1.13L15,19.7l1.76.17.88.09.19-.87L21,4.26l.23-1.06-1.08-.14L18.9,2.89,18,2.77Z"
      />
    </BaseSvg>
  );
};

export default NecroticSvg;
