import React from "react";

import { BaseSvg } from "../../../BaseSvg";
import { InjectedSvgProps } from "../../../hocs";

export default class SenseRowSmallBoxSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-sense-row-small-box-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 188 33.90015">
        <g id="SenseRowSmallBoxSvg-Page-1">
          <g id="SenseRowSmallBoxSvg-Sheet_Desktop_Static">
            <path
              id="SenseRowSmallBoxSvg-Page-1-2"
              fill={fillColor}
              d="M8.72855.10016A20.33138,20.33138,0,0,0,0,16.90015a20.33152,20.33152,0,0,0,8.72855,16.8l.28774.2h21.198l.28774-.2a20.90539,20.90539,0,0,0,3.1653-2.7H182.53264l.28774-.4c.67144-1.2,2.87756-4.5,4.7-5.4l.47959-.2v-16.1l-.47959-.2c-1.82247-.9-4.02859-4.2-4.7-5.4l-.28774-.4H33.66733A20.9054,20.9054,0,0,0,30.502.2L30.2143,0H9.01635Zm-6.33059,16.8a18.01,18.01,0,0,1,7.28977-14.5h9.87959c4.50817,2.1,10.64692,3.2,13.71632,3.8a18.26332,18.26332,0,0,1,3.549,10.7h0a17.62364,17.62364,0,0,1-3.549,10.7c-2.97345.6-9.11225,1.7-13.71632,3.8H9.68773a18.01,18.01,0,0,1-7.28977-14.5ZM173.9,4.50012c2.97345,1.1,10.64692,4.5,12.3735,9.3v6.2c-1.82247,4.8-9.49595,8.2-12.3735,9.3H44.98575a9.16413,9.16413,0,0,0-5.46736-2.1,21.57693,21.57693,0,0,0,2.87755-10.3,20.52873,20.52873,0,0,0-2.87755-10.3,9.16452,9.16452,0,0,0,5.46736-2.1H173.9Zm-135.43679,2.2a21.34515,21.34515,0,0,1,2.97345,10.2,21.34509,21.34509,0,0,1-2.97345,10.2H36.54489a21.15057,21.15057,0,0,0,2.78165-10.3h0a21.06756,21.06756,0,0,0-2.6857-10.3,5.67381,5.67381,0,0,0,1.82241.19995Zm-8.9204,24.7H24.45919a47.13553,47.13553,0,0,1,7.96122-2.6,24.74945,24.74945,0,0,1-2.87756,2.6Zm6.42655-3.2a12.55865,12.55865,0,0,1,7.38573,1.1H35.20206a7.63546,7.63546,0,0,1,.76734-1.1Zm145.70007,1.1H176.394c3.16529-1.4,7.76936-3.9,9.97553-7.3v1.9c-2.11027,1.29992-4.02864,4.29992-4.7,5.4Zm4.60407-19.4v1.9c-2.20611-3.4-6.81018-5.9-9.97554-7.3h5.27551c.76734,1.1,2.68571,4.1,4.7,5.4Zm-143.01437-5.4c-1.34288.7-3.549,1.4-7.38572,1.1-.28775-.4-.4796-.7-.76734-1.1Zm-10.83876.5a44.52529,44.52529,0,0,1-7.96122-2.6h5.08366a24.74848,24.74848,0,0,1,2.87756,2.6Z"
            />
          </g>
        </g>
      </BaseSvg>
    );
  }
}
