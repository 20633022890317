export const INITIAL_CLASS_SET = 'character.INITIAL_CLASS_SET';
export const CLASS_ADD = 'character.CLASS_ADD';
export const CLASS_ADD_REQUEST = 'character.CLASS_ADD_REQUEST';
export const CLASS_ADD_POST_ACTION = 'character.CLASS_ADD_POST_ACTION';
export const CLASS_REMOVE_REQUEST = 'character.CLASS_REMOVE_REQUEST';
export const CLASS_REMOVE_POST_ACTION = 'character.CLASS_REMOVE_POST_ACTION';
export const CLASS_REMOVE_COMMIT = 'character.CLASS_REMOVE_COMMIT';
export const CLASS_SET = 'character.CLASS_SET';
export const CLASS_SET_COMMIT = 'character.CLASS_SET_COMMIT';
export const CLASS_LEVEL_SET_REQUEST = 'character.CLASS_LEVEL_SET_REQUEST';
export const CLASSES_SET = 'character.CLASSES_SET';
export const CLASSES_SET_COMMIT = 'character.CLASSES_SET_COMMIT';
export const CLASSES_UNSET = 'character.CLASSES_UNSET';
