import { PaneHistoryInitTypeEnum } from "../../../Shared/constants/App";
import {
  CharacterInitAction,
  CharacterReceiveFailAction,
  ExportPdfAction,
  ShareUrlAction,
  sheetActionTypes,
} from "../sheet";

/**
 *
 */
export function characterInit(): CharacterInitAction {
  return {
    type: sheetActionTypes.CHARACTER_INIT,
    payload: {},
  };
}

/**
 *
 * @param error
 */
export function characterReceiveFail(error: Error): CharacterReceiveFailAction {
  return {
    type: sheetActionTypes.CHARACTER_RECEIVE_FAIL,
    payload: {
      error,
    },
  };
}

/**
 *
 */
export function shareUrl(
  paneHistoryInitType: PaneHistoryInitTypeEnum = PaneHistoryInitTypeEnum.START
): ShareUrlAction {
  return {
    type: sheetActionTypes.SHARE_URL,
    payload: {
      paneHistoryInitType,
    },
  };
}

/**
 *
 */
export function exportPdf(
  paneHistoryInitType: PaneHistoryInitTypeEnum = PaneHistoryInitTypeEnum.START
): ExportPdfAction {
  return {
    type: sheetActionTypes.EXPORT_PDF,
    payload: {
      paneHistoryInitType,
    },
  };
}
