import React from "react";
import { connect } from "react-redux";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import HealthManager from "../../../../CharacterSheet/containers/HealthManager";
import { SharedAppState } from "../../../stores/typings";

class HealthManagePane extends React.PureComponent {
  render() {
    return (
      <div className="ct-health-manage-pane">
        <Header>HP Management</Header>
        <HealthManager />
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {};
}

export default connect(mapStateToProps)(HealthManagePane);
