import { orderBy } from "lodash";
import React from "react";

import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiResponse,
  CharClass,
  CharacterPreferences,
  ClassDefinitionContract,
  ClassUtils,
  HelperUtils,
  PrerequisiteData,
  PrerequisiteFailure,
  PrerequisiteUtils,
  SourceData,
} from "@dndbeyond/character-rules-engine/es";

import { FilterListing } from "../../../Shared/components/common/FilterListing";
import { MulticlassAvailability } from "../../typings";
import Button from "../Button";
import ClassFilterListingItemContent from "../ClassFilterListingItemContent";

function renderRequirement(req: PrerequisiteFailure): string {
  return `${req.data.statKey ? req.data.statKey.toUpperCase() : ""} ${
    req.data.requiredValue
  } (${req.data.currentAmount === null ? "None" : req.data.currentAmount})`;
}

function renderMissingRequirements(
  missingRequirementGroups: Array<Array<PrerequisiteFailure>>
): string {
  return missingRequirementGroups
    .map((reqGroup) => reqGroup.map((req) => renderRequirement(req)).join(", "))
    .join(" or ");
}

interface Props {
  loadClasses: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<Array<ClassDefinitionContract>>>;
  onClassSelected: (entity: any) => void;
  classes: Array<CharClass>;
  multiclassAvailability: Array<MulticlassAvailability>;
  sourceLookup: Record<string, SourceData>;
  startingClass: CharClass | null;
  preferences: CharacterPreferences;
  prerequisiteData: PrerequisiteData;
}
export default class ClassChooser extends React.PureComponent<Props> {
  static defaultProps = {
    classes: [],
    multiclassAvailability: [],
    sourceLookup: {},
    startingClass: null,
  };

  transformClassItems = (data: Array<ClassDefinitionContract>): Array<any> => {
    const { sourceLookup, classes } = this.props;

    let classDefinitions: Array<ClassDefinitionContract> = [...data];
    classes.forEach((charClass) => {
      if (
        !classDefinitions.some(
          (classDef) => classDef.id === ClassUtils.getId(charClass)
        )
      ) {
        let definition = ClassUtils.getDefinition(charClass);
        if (definition !== null) {
          classDefinitions.push(definition);
        }
      }
    });

    const multiclassAvailability =
      this.getMulticlassAvailability(classDefinitions);

    let classItems = classDefinitions.map(
      (classItem: ClassDefinitionContract) => {
        const existingClass = classes.find(
          (charClass) => ClassUtils.getId(charClass) === classItem.id
        );
        const multiclassInfo = multiclassAvailability.find(
          (classInfo) => classInfo.classId === classItem.id
        );

        let metaItems: Array<{ type: string; text: string }> = [];

        if (classItem.sources) {
          classItem.sources.forEach((sourceMapping) => {
            let source = HelperUtils.lookupDataOrFallback(
              sourceLookup,
              sourceMapping.sourceId
            );
            if (
              source &&
              source.description !== null &&
              source.sourceCategory &&
              source.sourceCategory.isToggleable
            ) {
              metaItems.push({
                type: "normal",
                text: source.description,
              });
            }
          });
        }

        if (classes.length >= 1) {
          if (existingClass) {
            metaItems.push({
              type: "normal",
              text: `Level ${existingClass.level}`,
            });
            if (existingClass.isStartingClass) {
              metaItems.push({
                type: "normal",
                text: "Starting Class",
              });
            }
          } else {
            if (multiclassInfo && !multiclassInfo.canStartingClassMulticlass) {
              metaItems.push({
                type: "error",
                text: `Starting class does not meet multiclass prerequisites: ${renderMissingRequirements(
                  multiclassInfo.startingClassRequirements
                )}`,
              });
            } else if (multiclassInfo && !multiclassInfo.canMulticlass) {
              metaItems.push({
                type: "error",
                text: `Prerequisites not met: ${renderMissingRequirements(
                  multiclassInfo.missingRequirements
                )}`,
              });
            }
          }
        }

        return {
          data: {
            type: "class",
            id: classItem.id,
            entityTypeId: -1,
            heading: classItem.name,
            headingText: classItem.name,
            metaItems,
          },
          entity: classItem,
          addText: "Select",
          ContentComponent: ClassFilterListingItemContent,
        };
      }
    );

    return orderBy(classItems, (classItem) => classItem.data.headingText);
  };

  getDisabledIds = (items): Array<number> => {
    const { classes, startingClass } = this.props;

    if (startingClass === null || !items.length) {
      return [];
    }

    const classDefinitions = items.map((item) => item.entity);
    const multiclassAvailability =
      this.getMulticlassAvailability(classDefinitions);

    // disable any current classes
    let currentClassIds = classes.map((charClass) =>
      ClassUtils.getId(charClass)
    );

    // disable any multiclass options that don't meet required perquisites
    let canMulticlassIds = multiclassAvailability
      .filter((classInfo) => !classInfo.canMulticlass)
      .map((classInfo) => classInfo.classId);

    return [...currentClassIds, ...canMulticlassIds];
  };

  getMulticlassAvailability = (
    classes: Array<ClassDefinitionContract>
  ): Array<MulticlassAvailability> => {
    const { preferences, prerequisiteData, startingClass } = this.props;

    let canStartingClassMulticlass: boolean = false;
    let startingClassRequirements: Array<Array<PrerequisiteFailure>> = [];
    if (preferences.enforceMulticlassRules) {
      if (startingClass) {
        canStartingClassMulticlass =
          PrerequisiteUtils.validatePrerequisiteGrouping(
            ClassUtils.getPrerequisites(startingClass),
            prerequisiteData
          );
        startingClassRequirements =
          PrerequisiteUtils.getPrerequisiteGroupingFailures(
            ClassUtils.getPrerequisites(startingClass),
            prerequisiteData
          );
      }
    } else {
      canStartingClassMulticlass = true;
    }

    return classes.map((classDefinition): MulticlassAvailability => {
      let classId = classDefinition.id;

      let canMulticlass: boolean = false;
      if (preferences.enforceMulticlassRules) {
        canMulticlass =
          canStartingClassMulticlass &&
          PrerequisiteUtils.validatePrerequisiteGrouping(
            classDefinition.prerequisites,
            prerequisiteData
          );
      } else {
        canMulticlass = true;
      }

      return {
        classId,
        canStartingClassMulticlass,
        startingClassRequirements,
        canMulticlass,
        missingRequirements: PrerequisiteUtils.getPrerequisiteGroupingFailures(
          classDefinition.prerequisites,
          prerequisiteData
        ),
      };
    });
  };

  render() {
    const { loadClasses, onClassSelected } = this.props;

    return (
      <div className="class-chooser">
        <FilterListing
          loadItems={loadClasses}
          transformLoadedItems={this.transformClassItems}
          onItemSelected={onClassSelected}
          selectText="Select"
          ButtonComponent={Button}
          getDisabledIds={this.getDisabledIds}
          buttonOnlyClick={false}
        />
      </div>
    );
  }
}
