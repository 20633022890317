import * as React from "react";

import { DarkRitualSvg, GrayRitualSvg, LightRitualSvg } from "../../Svg";

interface SvgInjectedProps {
  className?: string;
}
interface Props {
  themeMode?: "dark" | "gray" | "light";
  className: string;
}
export default class RitualIcon extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
    themeMode: "dark",
  };

  render() {
    const { themeMode, className } = this.props;

    let classNames: Array<string> = [className, "ddbc-ritual-icon"];

    let SvgIcon: React.ComponentType<SvgInjectedProps> | null = null;

    switch (themeMode) {
      case "light":
        SvgIcon = LightRitualSvg;
        break;
      case "gray":
        SvgIcon = GrayRitualSvg;
        break;
      case "dark":
      default:
        SvgIcon = DarkRitualSvg;
    }

    if (SvgIcon === null) {
      return null;
    }

    return <SvgIcon className={classNames.join(" ")} />;
  }
}
