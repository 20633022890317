import * as React from "react";

import {
  CampaignDataContract,
  CampaignUtils,
} from "@dndbeyond/character-rules-engine/es";

interface Props {
  campaign: CampaignDataContract;
  onCampaignShow?: () => void;
  className: string;
}

export default class CampaignSummary extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
  };

  handleClick = (evt: React.MouseEvent): void => {
    const { onCampaignShow } = this.props;

    evt.nativeEvent.stopImmediatePropagation();
    evt.stopPropagation();

    if (onCampaignShow) {
      onCampaignShow();
    }
  };

  render() {
    const { campaign, className } = this.props;

    let classNames: Array<string> = [className, "ddbc-campaign-summary"];

    return (
      <div className={classNames.join(" ")} onClick={this.handleClick}>
        <span className="ddbc-campaign-summary__label">Campaign:</span>
        <span className="ddbc-campaign-summary__name">
          {CampaignUtils.getName(campaign)}
        </span>
      </div>
    );
  }
}
