import React from "react";

import { BuilderChevronRightSvg } from "@dndbeyond/character-components/es";
import {
  RaceDefinitionContract,
  RaceUtils,
  RuleData,
  SourceUtils,
} from "@dndbeyond/character-rules-engine/es";

import { LegacyBadge } from "~/components/LegacyBadge";
import { Reference } from "~/components/Reference";

import {
  FilterListingItemContent,
  FilterListingItemContentHeading,
} from "../../../Shared/components/common/FilterListing";

interface SourceProps {
  isHomebrew: boolean;
  species: RaceDefinitionContract;
  ruleData: RuleData;
}
const Source: React.FC<SourceProps> = ({ isHomebrew, species, ruleData }) => {
  if (isHomebrew) {
    return <Reference name="Homebrew" />;
  }
  return (
    <React.Fragment>
      {SourceUtils.getSourceFullNames(
        RaceUtils.getSources(species),
        ruleData
      ).map((source, idx) => {
        return (
          <React.Fragment key={`${source}-${idx}`}>
            {idx > 0 ? " /" : ""} <Reference name={source} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

interface Props {
  species: RaceDefinitionContract;
  ruleData: RuleData;
  defaultImageUrl: string;
}

export const SpeciesFilterListingItemContent: React.FC<Props> = ({
  species,
  ruleData,
  defaultImageUrl,
}) => {
  const baseName = RaceUtils.getBaseName(species);
  const speciesOptionShortName = RaceUtils.getSubRaceShortName(species);
  const isHomebrew = RaceUtils.getIsHomebrew(species);
  const isLegacy = RaceUtils.getIsLegacy(species);
  const portraitAvatarUrl = RaceUtils.getPortraitAvatarUrl(species);

  return (
    <FilterListingItemContent clsNames={["filter-listing-item-content-race"]}>
      <FilterListingItemContentHeading>
        <div className="race-filter-item-header">
          <div className="race-filter-item-preview">
            <img
              className="race-filter-item-preview-img"
              src={portraitAvatarUrl ?? defaultImageUrl}
              alt=""
            />
          </div>
          <div className="race-filter-item-info">
            <div className="race-filter-item-parent">
              {speciesOptionShortName && `${speciesOptionShortName} `}
              {baseName}
              {isLegacy && <LegacyBadge id={baseName || ""} />}
            </div>
            <div className="race-filter-item-source">
              <Source
                ruleData={ruleData}
                isHomebrew={isHomebrew}
                species={species}
              />
            </div>
          </div>
          <div className="race-filter-item-action">
            <BuilderChevronRightSvg />
          </div>
        </div>
      </FilterListingItemContentHeading>
    </FilterListingItemContent>
  );
};

export default SpeciesFilterListingItemContent;
