import axios, { Canceler } from "axios";
import React from "react";

import { Button, LoadingPlaceholder } from "@dndbeyond/character-components/es";
import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiAdapterUtils,
  ApiResponse,
} from "@dndbeyond/character-rules-engine/es";

import { AppLoggerUtils, FilterUtils } from "../../../utils";
import FilterListingItem from "./FilterListingItem";

// THIS IS ONLY USED IN CLASS CHOOSE - RACE HAS MORE ADVANCED VERSION
//TODO finish typing all FilterListing subs and check for any's in Props!!!!

interface Props<T extends any = any, TData extends any = any> {
  loadItems: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<Array<TData>>>;
  onLoadItems: (items: Array<T>) => void;
  transformLoadedItems: (data: Array<TData>) => Array<T>;
  items: Array<T>;
  getDisabledIds?: (items: Array<T>) => Array<string> | Array<number>;
  getPermanentlyFilteredIds?: (
    items: Array<T>
  ) => Array<string> | Array<number>;
  pageSize: number;
  onItemSelected: (entity: any) => void; //TODO type any
  queryPlaceholder: string;
  minAddAmount: number;
  showAddAmountControls: boolean;
  selectText: string;
  ButtonComponent: React.ComponentType<any>;
  buttonOnlyClick: boolean;
}
interface State<T extends any = any> {
  query: string;
  lazyItems: Array<T>;
  filteredItems: Array<T>;
  currentPage: number;
  itemsLoading: boolean;
  itemsLoaded: boolean;
  addAmount: number;
  addAmountDisplay: number | string;
}
export class FilterListing extends React.PureComponent<Props, State> {
  static defaultProps = {
    selectText: "Add",
    items: [],
    pageSize: 40,
    queryPlaceholder: "Search...",
    transformLoadedItems: (data) => data,
    loadItems: () => {},
    onLoadItems: () => {},
    minAddAmount: 1,
    showAddAmountControls: false,
    ButtonComponent: Button,
    buttonOnlyClick: true,
  };

  loadItemsCanceler: null | Canceler = null;
  listingContentRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    const { items, minAddAmount } = props;

    this.state = {
      query: "",
      lazyItems: items,
      filteredItems: items,
      currentPage: 0,
      itemsLoading: false,
      itemsLoaded: false,
      addAmount: minAddAmount,
      addAmountDisplay: minAddAmount,
    };
  }

  componentDidMount() {
    const { loadItems, transformLoadedItems, onLoadItems } = this.props;

    this.setState({
      itemsLoading: true,
    });

    loadItems({
      cancelToken: new axios.CancelToken((c) => {
        this.loadItemsCanceler = c;
      }),
    })
      .then((response) => {
        const data = ApiAdapterUtils.getResponseData(response);
        if (data === null) {
          return;
        }

        let transformedItems = transformLoadedItems(data);
        transformedItems = this.disableItems(transformedItems);
        onLoadItems(transformedItems);
        this.setState({
          lazyItems: transformedItems,
          filteredItems: transformedItems,
          itemsLoaded: true,
          itemsLoading: false,
        });
        this.loadItemsCanceler = null;
      })
      .catch(AppLoggerUtils.handleAdhocApiError);
  }

  componentWillUnmount(): void {
    if (this.loadItemsCanceler !== null) {
      this.loadItemsCanceler();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { items, loadItems } = this.props;

    if (items !== prevProps.items || loadItems !== prevProps.loadItems) {
      this.setState((prevState) => ({
        lazyItems: this.disableItems(prevState.lazyItems),
        filteredItems: this.disableItems(this.getFilteredItems("")),
      }));
    }
  }

  resetUi = (): void => {
    if (this.listingContentRef.current) {
      this.listingContentRef.current.scrollTop = 0;
    }
  };

  disableItems = (
    items: Array<any>,
    disabledItemIds: Array<string> | Array<number> = []
  ): Array<any> => {
    const { getDisabledIds } = this.props;
    if (getDisabledIds) {
      disabledItemIds = getDisabledIds(items);
    }
    return items.map((item) => ({
      ...item,
      disabled: this.isItemDisabled(item, disabledItemIds),
    }));
  };

  isItemFilteredByQuery = (item: any, query: string): boolean => {
    const { headingText, metaItems } = item;

    let input = query.toLowerCase().trim();

    if (!input) {
      // if there is no query don't filter anything
      return false;
    }

    let itemHeading: string = headingText
      .replace(/["+]/g, "")
      .toLowerCase()
      .trim();
    let tags: Array<string> =
      metaItems && metaItems.length
        ? metaItems.map((mi) => (mi ? mi.text.toLowerCase().trim() : mi))
        : [];
    let pieces: Array<string> = itemHeading.split(/[ -]/);
    if (FilterUtils.checkQueryPiece(input, 0, 0, 0, pieces)) {
      //don't filter out item if heading matches
      return false;
    }

    // if heading isn't a match, see if input is an exact match for associated tags
    if (!tags.length) {
      // filter out item if no tags specified
      return true;
    }

    if (tags.indexOf(input) < 0) {
      // filter out item if any word of input isn't within tags
      return true;
    }

    return false; // don't filter out item if tags match
  };

  isItemDisabled = (item: any, disabledItemIds: Array<any>) => {
    return disabledItemIds.indexOf(item.data.id) > -1;
  };

  isItemFiltered = (item: any, filteredItemIds: Array<any>) => {
    return filteredItemIds.indexOf(item.data.id) > -1;
  };

  getFilteredItems = (query: string): Array<any> => {
    const { lazyItems } = this.state;
    const { getPermanentlyFilteredIds } = this.props;

    let filteredItems = lazyItems;

    if (getPermanentlyFilteredIds) {
      const filteredIds = getPermanentlyFilteredIds(lazyItems);
      filteredItems = lazyItems.filter(
        (item) => !this.isItemFiltered(item, filteredIds)
      );
    }

    return filteredItems.filter(
      (item) => !this.isItemFilteredByQuery(item.data, query)
    );
  };

  handleQueryChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { minAddAmount } = this.props;

    const query = evt.target.value;

    this.setState({
      query,
      filteredItems: this.getFilteredItems(query),
      currentPage: 0,
      addAmount: minAddAmount,
      addAmountDisplay: minAddAmount,
    });

    this.resetUi();
  };

  getLastPageIdx = (): number => {
    const { filteredItems } = this.state;
    const { pageSize } = this.props;

    return Math.ceil(filteredItems.length / pageSize) - 1;
  };

  handlePageMore = (evt: React.MouseEvent) => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  handleAmountChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { minAddAmount } = this.props;
    const val = evt.target.value;

    let display: number | string;
    let amount: number;
    if (typeof val === "string") {
      if (val.length === 0) {
        display = "";
        amount = minAddAmount;
      } else {
        let parsedAmount = parseInt(val);
        display = isNaN(parsedAmount) || parsedAmount < 1 ? "" : parsedAmount;
        amount = isNaN(parsedAmount) ? minAddAmount : parsedAmount;
      }
    } else if (val === 0 || val < 1) {
      display = minAddAmount;
      amount = minAddAmount;
    } else {
      display = val;
      amount = val;
    }

    this.setState({
      addAmount: Math.max(amount, minAddAmount),
      addAmountDisplay: display,
    });
  };

  handleDecrementCountClick = (evt: React.MouseEvent): void => {
    const { minAddAmount } = this.props;

    this.setState((prevState, props) => ({
      addAmount: Math.max(prevState.addAmount - 1, minAddAmount),
      addAmountDisplay: Math.max(prevState.addAmount - 1, minAddAmount),
    }));
  };

  handleIncrementCountClick = (evt: React.MouseEvent): void => {
    this.setState((prevState, props) => ({
      addAmount: prevState.addAmount + 1,
      addAmountDisplay: prevState.addAmount + 1,
    }));
  };

  handleItemSelected = (item: any, entity: any): void => {
    const { onItemSelected, minAddAmount } = this.props;
    const { addAmount } = this.state;

    if (onItemSelected) {
      onItemSelected(entity);
    }

    this.setState((prevState) => ({
      query: "",
      filteredItems: this.disableItems(this.getFilteredItems("")),
      lazyItems: this.disableItems(prevState.lazyItems),
      currentPage: 0,
      addAmount: minAddAmount,
      addAmountDisplay: minAddAmount,
    }));

    this.resetUi();
  };

  renderPagedListing = (): React.ReactNode => {
    const { filteredItems, currentPage, addAmount } = this.state;
    const { pageSize, selectText, ButtonComponent, buttonOnlyClick } =
      this.props;
    const pagedFilteredItems = filteredItems.slice(
      0,
      (currentPage + 1) * pageSize
    );

    return (
      <div className="filter-listing-items">
        {pagedFilteredItems.length ? (
          pagedFilteredItems.map((item) => (
            <FilterListingItem
              key={item.data.id}
              {...item}
              onItemSelected={this.handleItemSelected}
              addText={
                addAmount > 1 ? `${selectText} ${addAmount}` : selectText
              }
              addAmountControls={true}
              ButtonComponent={ButtonComponent}
              buttonOnlyClick={buttonOnlyClick}
            />
          ))
        ) : (
          <div className="filter-listing-no-results">No Results Found</div>
        )}
      </div>
    );
  };

  renderPager = (): React.ReactNode => {
    const { currentPage } = this.state;
    const { ButtonComponent } = this.props;
    const lastPageIdx = this.getLastPageIdx();

    if (lastPageIdx < 1 || currentPage >= lastPageIdx) {
      return null;
    }

    return (
      <div className="listing-pager">
        <ButtonComponent block={true} onClick={this.handlePageMore}>
          Load More
        </ButtonComponent>
      </div>
    );
  };

  renderLoading = (): React.ReactNode => {
    const labelNode: React.ReactNode = (
      <div className="filter-listing-loading-text">Loading...</div>
    );
    return (
      <div className="filter-listing-loading">
        <LoadingPlaceholder label={labelNode} />
      </div>
    );
  };

  renderAmountControls = (): React.ReactNode => {
    const { addAmount, addAmountDisplay, filteredItems } = this.state;
    const { ButtonComponent } = this.props;

    if (!filteredItems.length) {
      return null;
    }

    return (
      <div className="filter-listing-amount">
        <div className="filter-listing-amount-label">Amount to add</div>
        <div className="filter-listing-amount-controls">
          <div className="filter-listing-amount-decrease">
            <ButtonComponent
              clsNames={["button-action-decrease"]}
              onClick={this.handleDecrementCountClick}
              disabled={addAmount === 1}
            />
          </div>
          <div className="filter-listing-amount-value">
            <input
              type="number"
              value={addAmountDisplay}
              className="character-input filter-listing-amount-input"
              onChange={this.handleAmountChange}
            />
          </div>
          <div className="filter-listing-amount-increase">
            <ButtonComponent
              clsNames={["button-action-increase"]}
              onClick={this.handleIncrementCountClick}
            />
          </div>
        </div>
      </div>
    );
  };

  renderUi = (): React.ReactNode => {
    const { query } = this.state;
    const { queryPlaceholder, showAddAmountControls } = this.props;

    return (
      <div className="filter-listing-ui">
        <div className="filter-constraints">
          <div className="filter-constraint">
            <input
              type="search"
              className="filter-constraint-query"
              value={query}
              onChange={this.handleQueryChange}
              placeholder={queryPlaceholder}
              spellCheck={false}
              autoComplete={"off"}
            />
          </div>
        </div>
        {showAddAmountControls ? this.renderAmountControls() : null}
        <div className="filter-listing-content" ref={this.listingContentRef}>
          {this.renderPagedListing()}
          {this.renderPager()}
        </div>
      </div>
    );
  };

  render() {
    const { itemsLoaded, itemsLoading } = this.state;

    return (
      <div className="filter-listing">
        {itemsLoading && this.renderLoading()}
        {!itemsLoading && itemsLoaded && this.renderUi()}
      </div>
    );
  }
}

export default FilterListing;
