import * as React from "react";

interface Props {
  tabKey: number | string | null;
  label?: string | null;
}

export default class TabOptionsGroup extends React.PureComponent<Props> {
  render() {
    return null;
  }
}
