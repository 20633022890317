export const SPELL_CREATE = 'character.SPELL_CREATE';
export const SPELL_ADD = 'character.SPELL_ADD';
export const SPELL_ADD_COMMIT = 'character.SPELL_ADD_COMMIT';
export const SPELL_REMOVE = 'character.SPELL_REMOVE';
export const SPELL_REMOVE_COMMIT = 'character.SPELL_REMOVE_COMMIT';
export const SPELL_PREPARED_SET = 'character.SPELL_PREPARED_SET';
export const SPELL_PREPARED_SET_COMMIT = 'character.SPELL_PREPARED_SET_COMMIT';
export const SPELL_USE_SET = 'character.SPELL_USE_SET';
export const SPELL_USE_SET_COMMIT = 'character.SPELL_USE_SET_COMMIT';
export const SPELL_LEVEL_SPELL_SLOTS_SET = 'character.SPELL_LEVEL_SPELL_SLOTS_SET';
export const SPELL_LEVEL_SPELL_SLOTS_SET_COMMIT = 'character.SPELL_LEVEL_SPELL_SLOTS_SET_COMMIT';
export const PACT_MAGIC_SET = 'character.PACT_MAGIC_SET';
export const PACT_MAGIC_SET_COMMIT = 'character.PACT_MAGIC_SET_COMMIT';
export const SPELL_LEVEL_PACT_MAGIC_SLOTS_SET = 'character.SPELL_LEVEL_PACT_MAGIC_SLOTS_SET';
export const SPELL_LEVEL_PACT_MAGIC_SLOTS_SET_COMMIT = 'character.SPELL_LEVEL_PACT_MAGIC_SLOTS_SET_COMMIT';
export const SPELL_DISPLAY_AS_ATTACK_SET = 'character.SPELL_DISPLAY_AS_ATTACK_SET';
export const SPELL_DISPLAY_AS_ATTACK_SET_COMMIT = 'character.SPELL_DISPLAY_AS_ATTACK_SET_COMMIT';
export const SPELL_FEATURE_DISPLAY_AS_ATTACK_SET = 'character.SPELL_FEATURE_DISPLAY_AS_ATTACK_SET';
export const SPELL_FEATURE_DISPLAY_AS_ATTACK_SET_COMMIT = 'character.SPELL_FEATURE_DISPLAY_AS_ATTACK_SET_COMMIT';
export const SPELL_CUSTOMIZATIONS_DELETE = 'character.SPELL_CUSTOMIZATIONS_DELETE';
export const SPELLS_SLOTS_SET = 'character.SPELLS_SLOTS_SET';
export const SPELLS_SLOTS_SET_COMMIT = 'character.SPELLS_SLOTS_SET_COMMIT';
export const CHARACTER_SPELLS_SET = 'character.CHARACTER_SPELLS_SET';
export const CHARACTER_SPELLS_SET_COMMIT = 'character.CHARACTER_SPELLS_SET_COMMIT';
