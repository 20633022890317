import React from "react";

import HealthManagerDeathsavesMarkManager from "../HealthManagerDeathsavesMarkManager";

interface Props {
  type: string;
  label: string;
  activeCount: number;
  totalCount: number;
  willBeActiveCount: number;
  onUse: (evt: React.MouseEvent) => void;
  onClear: (evt: React.MouseEvent) => void;
  markIcon: (key: string) => React.ReactNode;
}
export default class HealthManagerDeathsavesGroup extends React.PureComponent<Props> {
  static defaultProps = {
    willBeActiveCount: 0,
  };

  render() {
    const {
      type,
      label,
      totalCount,
      willBeActiveCount,
      activeCount,
      onClear,
      onUse,
      markIcon,
    } = this.props;

    return (
      <div
        className={`ct-health-manager__deathsaves-group ct-health-manager__deathsaves-group--${type}`}
      >
        <div className="ct-health-manager__deathsaves-group-label">{label}</div>
        <HealthManagerDeathsavesMarkManager
          type={type}
          activeCount={activeCount}
          willBeActiveCount={willBeActiveCount}
          totalCount={totalCount}
          onUse={onUse}
          onClear={onClear}
          markIcon={markIcon}
        />
      </div>
    );
    //`
  }
}
