export const ACTIONS_SET = 'character.ACTIONS_SET';
export const ACTIONS_SET_COMMIT = 'character.ACTIONS_SET_COMMIT';
export const ACTION_USE_SET = 'character.ACTION_USE_SET';
export const ACTION_USE_SET_COMMIT = 'character.ACTION_USE_SET_COMMIT';
export const ACTION_CUSTOMIZATIONS_DELETE = 'character.ACTION_CUSTOMIZATIONS_DELETE';
export const CUSTOM_ACTION_CREATE = 'character.CUSTOM_ACTION_CREATE';
export const CUSTOM_ACTION_ADD = 'character.CUSTOM_ACTION_ADD';
export const CUSTOM_ACTION_ADD_COMMIT = 'character.CUSTOM_ACTION_ADD_COMMIT';
export const CUSTOM_ACTION_SET = 'character.CUSTOM_ACTION_SET';
export const CUSTOM_ACTION_SET_COMMIT = 'character.CUSTOM_ACTION_SET_COMMIT';
export const CUSTOM_ACTION_REMOVE = 'character.CUSTOM_ACTION_REMOVE';
export const CUSTOM_ACTION_REMOVE_COMMIT = 'character.CUSTOM_ACTION_REMOVE_COMMIT';
