import HealthManager from "./HealthManager";
import HealthManagerDeathsavesGroup from "./HealthManagerDeathsavesGroup";
import HealthManagerDeathsavesMarkManager from "./HealthManagerDeathsavesMarkManager";

export default HealthManager;
export {
  HealthManager,
  HealthManagerDeathsavesGroup,
  HealthManagerDeathsavesMarkManager,
};
