import React from "react";
import { connect } from "react-redux";

import { Collapsible, Select } from "@dndbeyond/character-components/es";
import {
  BuilderChoiceContract,
  BuilderChoiceOptionContract,
  Choice,
  ChoiceData,
  ChoiceUtils,
  ClassUtils,
  Constants,
  HelperUtils,
  HtmlSelectOption,
  LanguageContract,
  Modifier,
  ModifierUtils,
  EntityUtils,
  rulesEngineSelectors,
  ruleDataSelectors,
  EntityRestrictionData,
  HtmlSelectOptionGroup,
  SourceUtils,
  RuleData,
} from "@dndbeyond/character-rules-engine/es";

import { CollapsibleContent } from "~/components/CollapsibleContent";
import { HtmlContent } from "~/components/HtmlContent";
import { orderBy } from "~/helpers/sortUtils";
import { BuilderAppState } from "~/tools/js/CharacterBuilder/typings";

interface RemainingOptionsInfo {
  notificationMessage: string;
  options: Array<HtmlSelectOption & BuilderChoiceOptionContract>;
}

// TODO this needs to not extend build contract
interface Props extends Omit<BuilderChoiceContract, "options"> {
  choice: Choice;
  description?: string;
  choiceInfo: ChoiceData;
  classId: number | null;
  entityRestrictionData: EntityRestrictionData;
  maxDescriptionLength: number;
  showBackgroundProficiencyOptions: boolean;
  hideWhenOnlyDefaultSelected: boolean;
  languages: Array<LanguageContract>;
  className: string;
  options: Array<
    (HtmlSelectOption & BuilderChoiceOptionContract) | HtmlSelectOptionGroup
  >; //TODO this needs to be refactored, these are a combo of HtmlSelectOption & BuilderChoiceOptionContract
  onChange?: (
    id: string,
    type: number,
    subType: number | null,
    value: any,
    parentChoiceId: string | null
  ) => void;
  collapseDescription?: boolean;
  ruleData: RuleData;
}

class DetailChoice extends React.PureComponent<Props> {
  static defaultProps = {
    classId: null,
    maxDescriptionLength: 750,
    choiceInfo: {
      proficiencyModifiers: [],
      expertiseModifiers: [],
      languageModifiers: [],
      kenseiModifiers: [],
      abilityLookup: {},
    },
    defaultSubtypes: [],
    showBackgroundProficiencyOptions: false,
    hideWhenOnlyDefaultSelected: true,
    className: "",
  };

  handleChoiceChange = (value): void => {
    const { onChange, id, type, subType, parentChoiceId } = this.props;

    if (onChange && id !== null) {
      onChange(id, type, subType, value, parentChoiceId);
    }
  };

  getRemainingOptions = (
    options: Array<HtmlSelectOption & BuilderChoiceOptionContract>,
    modifiers: Array<Modifier>
  ): RemainingOptionsInfo => {
    const {
      defaultSubtypes,
      showBackgroundProficiencyOptions,
      subType,
      optionValue,
      id,
    } = this.props;
    let remainingSubtypes: Array<string> = [];
    let selectedOption = options.find((option) => option.value === optionValue);

    if (defaultSubtypes) {
      if (defaultSubtypes.length > 1) {
        defaultSubtypes.forEach((defaultSubtype) => {
          if (
            !modifiers.find(
              (modifier) => modifier.friendlySubtypeName === defaultSubtype
            ) ||
            (selectedOption && selectedOption.label === defaultSubtype)
          ) {
            remainingSubtypes.push(defaultSubtype);
          }
        });
      } else if (
        defaultSubtypes.length === 1 &&
        !modifiers.find(
          (modifier) => modifier.friendlySubtypeName === defaultSubtypes[0]
        )
      ) {
        remainingSubtypes = [...defaultSubtypes];
      }
    }

    // If there are any remaining subtypes, only show those
    if (remainingSubtypes.length) {
      options = options.filter(
        (option) =>
          remainingSubtypes.includes(option.label ? option.label : "") ||
          optionValue === option.value
      );
    } else {
      // If there aren't any remaining subtype defaults, just show the normal list of options
      if (modifiers.length) {
        options = options.reduce(
          (
            acc: Array<HtmlSelectOption & BuilderChoiceOptionContract>,
            option
          ) => {
            // Find any existing modifiers that are the current option
            let optionModifiers = modifiers.filter(
              (modifier) => modifier.friendlySubtypeName === option.label
            );

            let backgroundOptionModifier: Modifier | null = null;
            if (showBackgroundProficiencyOptions && optionModifiers.length) {
              // If there are any existing modifiers, find out if any of them are from a background
              const foundBackgroundOptionModifier = optionModifiers.find(
                (modifier) =>
                  !ModifierUtils.isGranted(modifier) &&
                  ModifierUtils.getDataOriginType(modifier) ===
                    Constants.DataOriginTypeEnum.BACKGROUND
              );

              if (foundBackgroundOptionModifier) {
                backgroundOptionModifier = foundBackgroundOptionModifier;
              }
            }

            if (
              !optionModifiers.length ||
              backgroundOptionModifier ||
              optionValue === option.value
            ) {
              let value =
                typeof option.value === "string"
                  ? HelperUtils.parseInputInt(option.value)
                  : option.value;
              if (value !== null) {
                acc.push({
                  ...option,
                  value: value,
                  label: backgroundOptionModifier
                    ? `${option.label} (${EntityUtils.getDataOriginName(
                        ModifierUtils.getDataOrigin(backgroundOptionModifier)
                      )})`
                    : option.label,
                });
              }
            }

            return acc;
          },
          []
        );
      }
    }

    let notificationMessage: string = "";
    if (
      defaultSubtypes &&
      defaultSubtypes.length &&
      !remainingSubtypes.length
    ) {
      let optionType: string = "";
      switch (subType) {
        case Constants.BuilderChoiceSubtypeEnum.PROFICIENCY:
          optionType =
            defaultSubtypes.length === 1 ? "Proficiency" : "Proficiencies";
          break;
        case Constants.BuilderChoiceSubtypeEnum.LANGUAGE:
          optionType = defaultSubtypes.length === 1 ? "Language" : "Languages";
          break;
      }
      notificationMessage = `The ${defaultSubtypes.join(", ")} ${optionType} ${
        defaultSubtypes.length === 1 ? "was" : "were"
      } already selected elsewhere. This is now open to choose anything remaining.`;
    }

    return {
      notificationMessage,
      options,
    };
  };

  renderUi = (): React.ReactNode => {
    const {
      classId,
      optionValue,
      label,
      type,
      subType,
      choiceInfo,
      entityRestrictionData,
      options,
      languages,
      ruleData,
    } = this.props;
    const {
      classSpellLists,
      proficiencyModifiers,
      languageModifiers,
      expertiseModifiers,
      kenseiModifiers,
      abilityLookup,
    } = choiceInfo;

    let placeholder: string = "- Choose an Option -";
    if (label) {
      placeholder = `- ${label} -`;
    }

    let renderOptions: Array<any> = options;
    let orderedRenderOptions: Array<any> = [];
    let notificationMessage: string;
    switch (type) {
      case Constants.BuilderChoiceTypeEnum.FEAT_CHOICE_OPTION:
        break;
      default:
        switch (subType) {
          case Constants.BuilderChoiceSubtypeEnum.PROFICIENCY:
            ({ options: renderOptions, notificationMessage } =
              this.getRemainingOptions(renderOptions, [
                ...proficiencyModifiers,
                ...expertiseModifiers,
              ]));
            break;
          case Constants.BuilderChoiceSubtypeEnum.LANGUAGE:
            //handle languages based on other language choices made
            ({ options: renderOptions, notificationMessage } =
              this.getRemainingOptions(renderOptions, languageModifiers));

            //create lookup from ruledata - name: rpgSourceId
            const languageNameLookup = languages.reduce((acc, curr) => {
              return { ...acc, [curr.name ?? ""]: curr.rpgSourceId };
            }, {});

            //transform languages to by source category
            const groupedOptions = renderOptions.map((option) => {
              return {
                name: option.label,
                sources: [
                  {
                    sourceId: languageNameLookup[option.label],
                    pageNumber: null,
                    sourceType: -1,
                  },
                ],
                ...option,
                value: option.id,
              };
            });

            //group languages by source category
            renderOptions = SourceUtils.getGroupedOptionsBySourceCategory(
              groupedOptions,
              ruleData,
              optionValue,
              entityRestrictionData,
              "Other"
            );

            break;
          case Constants.BuilderChoiceSubtypeEnum.KENSEI:
            ({ options: renderOptions, notificationMessage } =
              this.getRemainingOptions(renderOptions, kenseiModifiers));
            break;
          case Constants.BuilderChoiceSubtypeEnum.EXPERTISE:
            renderOptions = renderOptions.filter(
              (option) =>
                (proficiencyModifiers.find(
                  (modifier) =>
                    ModifierUtils.getFriendlySubtypeName(modifier) ===
                    option.label
                ) &&
                  !expertiseModifiers.find(
                    (modifier) =>
                      ModifierUtils.getFriendlySubtypeName(modifier) ===
                      option.label
                  )) ||
                optionValue === option.value
            );
            break;
          case Constants.BuilderChoiceSubtypeEnum.EXPERTISE_NO_REQUIREMENT:
            renderOptions = renderOptions.filter(
              (option) =>
                !expertiseModifiers.find(
                  (modifier) =>
                    ModifierUtils.getFriendlySubtypeName(modifier) ===
                    option.label
                ) || optionValue === option.value
            );
            break;
          case Constants.BuilderChoiceSubtypeEnum.KNOWN_SPELLS:
            const spellList = classSpellLists.find(
              (classSpellList) =>
                ClassUtils.getId(classSpellList.charClass) === classId
            );
            if (spellList) {
              renderOptions = renderOptions.filter(
                (option) =>
                  spellList.knownSpellNames.includes(
                    option.label ? option.label : ""
                  ) || optionValue === option.value
              );
            }
            break;
          case Constants.BuilderChoiceSubtypeEnum.ABILITY_SCORE: {
            renderOptions = renderOptions.filter((option) => {
              let abilityId: number | null = null;
              switch (option.label) {
                case "Charisma Score":
                  abilityId = Constants.AbilityStatEnum.CHARISMA;
                  break;
                case "Constitution Score":
                  abilityId = Constants.AbilityStatEnum.CONSTITUTION;
                  break;
                case "Dexterity Score":
                  abilityId = Constants.AbilityStatEnum.DEXTERITY;
                  break;
                case "Intelligence Score":
                  abilityId = Constants.AbilityStatEnum.INTELLIGENCE;
                  break;
                case "Strength Score":
                  abilityId = Constants.AbilityStatEnum.STRENGTH;
                  break;
                case "Wisdom Score":
                  abilityId = Constants.AbilityStatEnum.WISDOM;
                  break;
              }

              return (
                !abilityId ||
                (abilityId && !abilityLookup[abilityId].isMaxed) ||
                optionValue === option.value
              );
            });
            break;
          }
        }
    }

    if (
      renderOptions.length > 0 &&
      renderOptions[0].hasOwnProperty("optGroupLabel")
    ) {
      orderedRenderOptions = orderBy(renderOptions, "sortOrder");
    } else {
      orderedRenderOptions = orderBy(renderOptions, "label");
    }

    return (
      <Select
        className="detail-choice-input"
        options={orderedRenderOptions}
        value={optionValue}
        placeholder={placeholder}
        onChange={this.handleChoiceChange}
      />
    );
  };

  render() {
    const {
      description,
      optionValue,
      parentChoiceId,
      maxDescriptionLength,
      hideWhenOnlyDefaultSelected,
      type,
      className,
      choice,
      options,
      collapseDescription,
    } = this.props;

    if (
      hideWhenOnlyDefaultSelected &&
      ChoiceUtils.isOnlyDefaultSelected(choice)
    ) {
      return null;
    }

    let classNames: Array<string> = ["detail-choice", className];
    if (
      (ChoiceUtils.isInfinite(choice) &&
        !ChoiceUtils.isOptionSelected(choice)) ||
      ChoiceUtils.isTodo(choice)
    ) {
      classNames.push("detail-choice--todo");
    }

    // Use the description prop or, if a chosenOption is found and a description wasn't
    // passed in, use the chosenOption description instead.
    let choiceDescription = description;

    const chosenOption = options.find(
      (option) => option["value"] === optionValue
    );

    if (chosenOption) {
      choiceDescription = description
        ? description
        : chosenOption["description"];
    }

    if (parentChoiceId !== null) {
      classNames.push("detail-choice--child");
    }

    let choiceNode: React.ReactNode;
    if (choiceDescription) {
      switch (type) {
        case Constants.BuilderChoiceTypeEnum.ENTITY_SPELL_OPTION:
          choiceNode = (
            <Collapsible layoutType={"minimal"} header="Spell Details">
              <HtmlContent
                className="detail-choice-description"
                html={choiceDescription}
                withoutTooltips
              />
            </Collapsible>
          );
          break;
        default:
          if (collapseDescription) {
            choiceNode = (
              <Collapsible layoutType={"minimal"} header="Show Details">
                <HtmlContent
                  className="detail-choice-description"
                  html={choiceDescription}
                  withoutTooltips
                />
              </Collapsible>
            );
          } else if (choiceDescription.length > maxDescriptionLength) {
            choiceNode = (
              <CollapsibleContent className="detail-choice-description">
                {choiceDescription}
              </CollapsibleContent>
            );
          } else {
            choiceNode = (
              <HtmlContent
                className="detail-choice-description"
                html={choiceDescription}
                withoutTooltips
              />
            );
          }
      }
    }

    return (
      <div className={classNames.join(" ")}>
        {this.renderUi()}
        {choiceNode}
      </div>
    );
  }
}

export default connect((state: BuilderAppState) => ({
  languages: ruleDataSelectors.getLanguages(state),
  entityRestrictionData: rulesEngineSelectors.getEntityRestrictionData(state),
  ruleData: rulesEngineSelectors.getRuleData(state),
}))(DetailChoice);
