import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const MeleeSpellSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 18.52 17.03">
      <path
        fill={fillColor}
        d="M8.17 16.03L.63 17l5.12-3.33-5.54-3.64h5.71L0 0l7.67 4.92L6.4 1.13 9.38 3l2-2.54 2.41 3.37 2.42-3.1-1.38 13.81-6.66 1.49z"
      />
      <path
        fill={fillColor}
        d="M13.15 16.53c-1.62 0-3.15-.61-3.57-1.42A6 6 0 007 14.44h-.27a.87.87 0 01-.85-.71 1.14 1.14 0 01.73-1.26A3.49 3.49 0 018 12.29a8.08 8.08 0 012 .23.8.8 0 00.23 0h.06v-.14c-.09-.25-.54-1.08-1-1.88C7.34 7.11 7 6.37 7.39 5.91A.76.76 0 018 5.59c.87 0 2.05 1.3 2.58 2.18a7.07 7.07 0 00.66.93c-.23-.8-.61-1.93-.81-2.52-.14-.42-.25-.75-.3-.91a1.36 1.36 0 01.18-1.23 1.37 1.37 0 011.14-.52c.69 0 1 .93 1.58 3.38v-.46c0-1.3.45-2 1.21-2a.74.74 0 01.57.27c.62.74.26 3.42.26 3.45a1.31 1.31 0 000 .23c.56-.9 1.5-1.77 2.19-1.77a.71.71 0 01.68.47c.16.44 0 1.14-1.63 3.73l-.17.29v1.27a13.7 13.7 0 01-.1 2.35c-.27 1.4-2.58 1.72-2.85 1.76h-.06z"
      />
      <path
        d="M11.46 4c.64 0 1.39 5 1.69 5 .29 0 .4-1.94.42-2.54s.11-1.46.7-1.46c.6 0 .38 2.73.31 3.15s0 1.34.33 1.34c.1 0 .23-.12.39-.45.51-1 1.5-1.87 2-1.87a.21.21 0 01.21.14c.19.52-1.58 3.27-1.77 3.6s0 2.69-.17 3.77S13.15 16 13.15 16c-1.54 0-2.83-.58-3.12-1.15s-2.24-1-3-1h-.26c-.43 0-.58-.72.09-1A3.12 3.12 0 018 12.79a7.67 7.67 0 011.81.2 1.3 1.3 0 00.39.06c.37 0 .71-.19.49-.81-.29-.83-3.23-5.58-2.9-6A.26.26 0 018 6.09c.51 0 1.55.94 2.15 1.94s1.3 1.71 1.62 1.71a.15.15 0 00.13-.06c.21-.29-1.1-3.9-1.29-4.54A.8.8 0 0111.46 4m0-1a1.88 1.88 0 00-1.53.72 1.85 1.85 0 00-.26 1.67c0 .13.12.37.22.68A3 3 0 008 5.09a1.26 1.26 0 00-1 .52c-.54.74-.17 1.58 1.81 5.16l.61 1.12a10.32 10.32 0 00-1.4-.1 3.9 3.9 0 00-1.57.21 1.65 1.65 0 00-1 1.82A1.36 1.36 0 006.77 15a1.44 1.44 0 00.29 0 6.46 6.46 0 012.15.54c.58.91 2.2 1.56 3.94 1.56h.12c.49-.06 2.95-.45 3.28-2.16a13.94 13.94 0 00.11-2.45v-1.13l.1-.17C18.48 8.31 18.67 7.6 18.44 7a1.21 1.21 0 00-1.15-.8 2.58 2.58 0 00-1.58.77 4.22 4.22 0 00-.48-2.49 1.21 1.21 0 00-.9-.45h-.06a1.45 1.45 0 00-1.27.69C12.62 3.62 12.21 3 11.46 3z"
        fill={secondaryFillColor}
      />
    </BaseSvg>
  );
};

export default MeleeSpellSvg;
