import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";
import React, { ElementType } from "react";
import { connect, DispatchProp, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import {
  characterEnvSelectors,
  Constants,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine";

import { SharedAppState } from "~/tools/js/Shared/stores/typings";

import { builderActions } from "../../actions";
import Page from "../../components/Page";
import { navigationConfig } from "../../config";
import { BuilderMethod, RouteKey } from "../../constants/navigation";
import { NavigationUtils } from "../../utils";
import styles from "./styles.module.css";

interface Props extends DispatchProp {
  isEnabled?: boolean;
  characterIsLoading: Constants.CharacterLoadingStatusEnum;
  characterId: number;
}

const BuilderTypeChoicePage: React.FC<Props> = ({
  isEnabled = true,
  characterIsLoading,
  characterId,
}) => {
  const params = new URLSearchParams(globalThis.location.search);
  const isMobileApp = params.get("platform");
  const [showHelpText, setShowHelpText] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const creationMethods = [
    {
      image:
        "https://media.dndbeyond.com/character-app/static/media/standard-creation.jpg",
      title: "Standard",
      description: "Create a character using a step-by-step approach.",
      helpCheck: true,
      method: BuilderMethod.STEP_BY_STEP,
      component: "div",
    },
    {
      image:
        "https://media.dndbeyond.com/character-app/static/media/premade-characters.jpg",
      title: "Premade",
      description:
        "Browse a selection of ready-to-play, premade characters and claim one to your account.",
      href: "/characters/premade",
      method: "PREMADE",
      component: "a",
    },
    {
      title: "Quick Build",
      description:
        "Choose a species and class to quickly create a level 1 character.",
      to: navigationConfig.getRouteDefPath(RouteKey.QUICK_BUILD),
      method: BuilderMethod.QUICK,
      component: Link,
    },
    {
      title: "Random",
      description:
        "Roll up a randomized character! You can optionally set some parameters such as, level, species and class.",
      to: navigationConfig.getRouteDefPath(RouteKey.RANDOMIZE_BUILD),
      method: BuilderMethod.RANDOMIZE,
      component: Link,
    },
  ];

  React.useEffect(() => {
    if (characterIsLoading === Constants.CharacterLoadingStatusEnum.LOADED) {
      navigate(NavigationUtils.getCharacterBuilderUrl(characterId), {
        replace: true,
      });
    }
  }, [characterIsLoading, characterId, navigate]);

  return (
    <Page>
      <Typography
        className={styles.header}
        variant="h3"
        component="h1"
        textTransform="uppercase"
        sx={{ fontSize: { xs: 24, sm: 36 } }}
      >
        Character Creation Method
      </Typography>
      <Typography className={styles.header}>
        Choose how you would like to create your character
      </Typography>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        {creationMethods
          .filter((item) => (isMobileApp ? item.method !== "PREMADE" : item))
          .map(
            (
              {
                title,
                image,
                method,
                description,
                helpCheck,
                component,
                ...props
              },
              i
            ) => (
              <Grid item xs={12} sm={6} key={`CREATION_METHOD_${i}`}>
                <Box
                  component={component as ElementType<any>}
                  onClick={
                    !method
                      ? undefined
                      : method === BuilderMethod.STEP_BY_STEP
                      ? () =>
                          dispatch(
                            builderActions.stepBuildRequest(showHelpText)
                          )
                      : () => dispatch(builderActions.builderMethodSet(method))
                  }
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    display: "block",
                  }}
                  {...props}
                >
                  <Card
                    elevation={0}
                    style={{
                      borderRadius: 8,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      border: "2px solid #D1CDCB",
                    }}
                  >
                    {image && (
                      <CardMedia
                        style={{ height: 200 }}
                        image={image}
                        title={title}
                      />
                    )}
                    <CardContent
                      sx={{
                        backgroundColor: "grey.800",
                        flex: "1",
                        borderTop: "2px solid #4D4F54",
                      }}
                    >
                      <Typography
                        gutterBottom
                        component="h2"
                        textTransform="uppercase"
                        fontSize={20}
                        fontFamily="Roboto Condensed"
                        fontWeight={700}
                        color="grey.100"
                      >
                        {title}
                      </Typography>
                      <Typography variant="body2" color="grey.300">
                        {description}
                      </Typography>
                      {helpCheck && (
                        <Button
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isEnabled) setShowHelpText(!showHelpText);
                          }}
                          sx={{
                            cursor: "pointer",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "info.main",
                            fontSize: 14,
                            color: "grey.100",
                            mt: 2,
                            py: 0,
                            px: 1,
                          }}
                        >
                          <Checkbox
                            id="q-include-help"
                            size="small"
                            checked={showHelpText}
                            disabled={!isEnabled}
                            sx={{
                              pl: 0,
                              svg: {
                                color: showHelpText ? "info.main" : "grey.100",
                              },
                            }}
                          />
                          <Typography
                            component="span"
                            style={{ fontSize: 14, marginBottom: -1 }}
                          >
                            <strong style={{ marginLeft: 5 }}>Beginner?</strong>{" "}
                            Show help text
                          </Typography>{" "}
                        </Button>
                      )}
                    </CardContent>
                    <CardActions
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        marginTop: "auto",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        color="common.black"
                        textTransform="uppercase"
                        fontWeight={700}
                      >
                        {method === "PREMADE"
                          ? "Start Browsing"
                          : "Start Building"}
                      </Typography>
                      <ChevronRightIcon
                        style={{
                          fontSize: 18,
                          color: "rgba(0,0,0,0.54)",
                          position: "relative",
                          top: -1,
                          marginLeft: 8,
                        }}
                      />
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            )
          )}
      </Grid>
    </Page>
  );
};

export default connect((state: SharedAppState) => {
  return {
    characterIsLoading: characterEnvSelectors.getLoadingStatus(state),
    characterId: rulesEngineSelectors.getId(state),
  };
})(BuilderTypeChoicePage);
