export const getAllData = (state) => state.ruleData;
export const getAbilityScoreDisplayTypes = (state) => state.ruleData.abilityScoreDisplayTypes;
export const getAbilitySkills = (state) => state.ruleData.abilitySkills;
export const getActivationTypes = (state) => state.ruleData.activationTypes;
export const getAdditionalLevelTypes = (state) => state.ruleData.additionalLevelTypes;
export const getAdjustmentDataTypes = (state) => state.ruleData.adjustmentDataTypes;
export const getAdjustmentTypes = (state) => state.ruleData.adjustmentTypes;
export const getAlignments = (state) => state.ruleData.alignments;
export const getAoeTypes = (state) => state.ruleData.aoeTypes;
export const getArmor = (state) => state.ruleData.armor;
export const getBasicActions = (state) => state.ruleData.basicActions;
export const getBaseWeaponReach = (state) => state.ruleData.baseWeaponReach;
export const getBasicMaxStatScore = (state) => state.ruleData.basicMaxStatScore;
export const getChallengeRatings = (state) => state.ruleData.challengeRatings;
export const getConditionTypes = (state) => state.ruleData.conditionTypes;
export const getConditions = (state) => state.ruleData.conditions;
export const getCreatureGroupCategories = (state) => state.ruleData.creatureGroupCategories;
export const getCreatureGroupFlags = (state) => state.ruleData.creatureGroupFlags;
export const getCreatureGroups = (state) => state.ruleData.creatureGroups;
export const getCreatureSizes = (state) => state.ruleData.creatureSizes;
export const getCurrencyData = (state) => state.ruleData.currencyData;
export const getDamageAdjustments = (state) => state.ruleData.damageAdjustments;
export const getDamageTypes = (state) => state.ruleData.damageTypes;
export const getDefaultArmorImageUrl = (state) => state.ruleData.defaultArmorImageUrl;
export const getDefaultAttunedItemCountMax = (state) => state.ruleData.defaultAttunedItemCountMax;
export const getDefaultGearImageUrl = (state) => state.ruleData.defaultGearImageUrl;
export const getDefaultRacePortraitUrl = (state) => state.ruleData.defaultRacePortraitUrl;
export const getDefaultWeaponImageUrl = (state) => state.ruleData.defaultWeaponImageUrl;
export const getDiceValues = (state) => state.ruleData.diceValues;
export const getEnvironments = (state) => state.ruleData.environments;
export const getInitiativeScore = (state) => state.ruleData.initiativeScore;
export const getLanguageTypeId = (state) => state.ruleData.languageTypeId;
export const getLanguages = (state) => state.ruleData.languages;
export const getLevelExperiencePoints = (state) => state.ruleData.levelExperiencePoints;
export const getLevelProficiencyBonuses = (state) => state.ruleData.levelProficiencyBonuses;
export const getLifestyles = (state) => state.ruleData.lifestyles;
export const getLimitedUseResetTypes = (state) => state.ruleData.limitedUseResetTypes;
export const getLongRestMinimumHitDiceUsedRecovered = (state) => state.ruleData.longRestMinimumHitDiceUsedRecovered;
export const getMaxAttunedItemCountMax = (state) => state.ruleData.maxAttunedItemCountMax;
export const getMaxCharacterLevel = (state) => state.ruleData.maxCharacterLevel;
export const getMaxDeathsavesFail = (state) => state.ruleData.maxDeathsavesFail;
export const getMaxDeathsavesSuccess = (state) => state.ruleData.maxDeathsavesSuccess;
export const getMaxSpellLevel = (state) => state.ruleData.maxSpellLevel;
export const getMaxStatScore = (state) => state.ruleData.maxStatScore;
export const getMinAttunedItemCountMax = (state) => state.ruleData.minAttunedItemCountMax;
export const getMinStatScore = (state) => state.ruleData.minStatScore;
export const getMinimumHpTotal = (state) => state.ruleData.minimumHpTotal;
export const getMinimumLimitedUseMaxUse = (state) => state.ruleData.minimumLimitedUseMaxUse;
export const getMonsterSubTypes = (state) => state.ruleData.monsterSubTypes;
export const getMonsterTypes = (state) => state.ruleData.monsterTypes;
export const getMovements = (state) => state.ruleData.movements;
export const getMultiClassSpellSlots = (state) => state.ruleData.multiClassSpellSlots;
export const getNaturalActions = (state) => state.ruleData.naturalActions;
export const getNoArmorAcAmount = (state) => state.ruleData.noArmorAcAmount;
export const getPactMagicMultiClassSpellSlots = (state) => state.ruleData.pactMagicMultiClassSpellSlots;
export const getPrivacyTypes = (state) => state.ruleData.privacyTypes;
export const getProficiencyGroups = (state) => state.ruleData.proficiencyGroups;
export const getRaceGroups = (state) => state.ruleData.raceGroups;
export const getRangeTypes = (state) => state.ruleData.rangeTypes;
export const getRitualCastingTimeMinuteAddition = (state) => state.ruleData.ritualCastingTimeMinuteAddition;
export const getRules = (state) => state.ruleData.rules;
export const getSenses = (state) => state.ruleData.senses;
export const getSharingTypes = (state) => state.ruleData.sharingTypes;
export const getSourceCategories = (state) => state.ruleData.sourceCategories;
export const getSources = (state) => state.ruleData.sources;
export const getSpellComponents = (state) => state.ruleData.spellComponents;
export const getSpellConditionTypes = (state) => state.ruleData.spellConditionTypes;
export const getSpellRangeTypes = (state) => state.ruleData.spellRangeTypes;
export const getStatModifiers = (state) => state.ruleData.statModifiers;
export const getStats = (state) => state.ruleData.stats;
export const getStealthCheckTypes = (state) => state.ruleData.stealthCheckTypes;
export const getStringMartialArts = (state) => state.ruleData.stringMartialArts;
export const getStringPactMagic = (state) => state.ruleData.stringPactMagic;
export const getStringSpellCasting = (state) => state.ruleData.stringSpellCasting;
export const getStringSpellEldritchBlast = (state) => state.ruleData.stringSpellEldritchBlast;
export const getWeapons = (state) => state.ruleData.weapons;
export const getWeaponCategories = (state) => state.ruleData.weaponCategories;
export const getWeaponProperties = (state) => state.ruleData.weaponProperties;
export const getWeaponPropertyReachDistance = (state) => state.ruleData.weaponPropertyReachDistance;
