import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const IncapacitatedSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 24 24">
      <path
        fill={fillColor}
        d="M17.8,13.1c0,0-0.1,0-0.1,0c0-0.1,0-0.1,0-0.2c0-4.3-3.2-7.8-7.2-7.8s-7.2,3.5-7.2,7.8c0,0.1,0,0.1,0,0.2    c-0.1,0-0.1,0-0.2,0c-0.6,0.2-0.8,1.1-0.5,2.1c0.3,1,1,1.6,1.6,1.5c0.2,0.4,0.4,0.8,0.7,1.1c0.5,2.9,2.8,5.8,5.6,5.8    c2.8,0,5.1-3,5.6-5.8c0.3-0.3,0.5-0.7,0.7-1.1c0.6,0.1,1.2-0.5,1.5-1.5C18.5,14.2,18.3,13.2,17.8,13.1z"
      />
      <path fill={secondaryFillColor} d="M11.3,18.9H9.6v-8.5h1.7V18.9z" />
      <path
        fill={fillColor}
        d="M8.8,10.8c-1.5,0-3-0.1-4.6-0.2c-0.2,0-0.4-0.2-0.3-0.4c0-0.2,0.2-0.4,0.4-0.3c4.9,0.5,8.9,0.2,13.9-0.8    c1-0.2,1.5-0.5,1.6-0.8c0.1-0.2-0.1-0.5-0.3-0.7c-0.3-0.2-0.7-0.4-1.1-0.6c-0.4-0.2-0.9-0.4-1.4-0.5c-0.2,0.2-0.5,0.5-0.8,0.7    c-1.1,0.7-2.4,0.9-3.6,1.1C9,8.6,5.2,8.4,1.6,7.5C1,7.4,0.2,7.1,0,6.4c-0.1-0.4,0.2-0.9,0.7-1.2C1,5,1.5,4.9,1.9,4.8    c0.9-0.2,1.8-0.3,2.7-0.4C4.4,4.3,4.2,4.2,4,4.1C3.7,3.9,3.4,3.8,3.1,3.5C2.8,3.2,2.7,2.7,3,2.3c0.3-0.4,0.8-0.5,1.3-0.6    c3.2-0.3,6.4-0.2,9.5,0.3c0.2,0,0.5,0.1,0.7,0.1c0,0,0,0-0.1,0c-0.5-0.3-1-0.5-1.6-0.6c-2-0.5-4.2-0.8-6.3-0.8c0,0,0,0,0,0    c-0.2,0-0.4-0.2-0.4-0.4C6.2,0.1,6.4,0,6.7,0c2.2,0,4.3,0.3,6.4,0.8c0.6,0.2,1.2,0.4,1.8,0.7c0.5,0.3,0.8,0.7,1,1.1    c0.9,0.4,1.5,0.9,1.8,1.5c0.2,0.5,0.2,1.2-0.1,1.7c0.4,0.1,0.8,0.3,1.2,0.5C19.2,6.5,19.6,6.7,20,7c0.5,0.4,0.7,0.9,0.5,1.4    c-0.2,0.9-1.4,1.2-2.2,1.4C14.9,10.5,11.9,10.8,8.8,10.8z M6.5,5C5,5,3.5,5.2,2,5.5c-0.3,0.1-0.7,0.2-1,0.3    C0.9,5.9,0.7,6.1,0.8,6.2c0,0.1,0.2,0.3,1,0.6C5.3,7.6,9,7.9,12.6,7.4c1.1-0.1,2.4-0.4,3.3-0.9c0.1-0.1,0.3-0.2,0.4-0.3    c-1-0.3-2.1-0.6-3.2-0.8c-0.3,0-0.6,0.1-0.9,0.1C10.3,5.6,8.3,5.4,6.5,5z M14.6,4.9c0.7,0.2,1.5,0.4,2.2,0.6    c0.2-0.4,0.3-0.8,0.1-1.2c-0.2-0.4-0.5-0.7-1-0.9C15.8,4,15.3,4.6,14.6,4.9C14.6,4.9,14.6,4.9,14.6,4.9z M7.8,4.2    c1.8,0,3.5,0.2,5.2,0.5c0.4-0.1,0.8-0.2,1.2-0.4c0.6-0.3,0.9-0.7,0.9-1.1c-0.4-0.1-0.9-0.3-1.5-0.4c-3.1-0.5-6.2-0.6-9.3-0.3    c-0.4,0-0.7,0.1-0.8,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.4,0.3,0.6,0.4C5,3.7,5.8,4,6.6,4.2C7,4.2,7.4,4.2,7.8,4.2z"
      />
    </BaseSvg>
  );
};

export default IncapacitatedSvg;
