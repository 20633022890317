import SettingsIcon from "@mui/icons-material/Settings";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";

import { sidebarActions } from "../../../Shared/actions";
import { PaneComponentEnum } from "../../../Shared/utils";

interface Props {
  context: string;
  isReadonly: boolean;
}

const SettingsButton: React.FC<Props> = ({ context, isReadonly }) => {
  const dispatch = useDispatch();
  return !isReadonly ? (
    <Button
      onClick={(evt) => {
        dispatch(
          sidebarActions.paneHistoryPush(PaneComponentEnum.SETTINGS, {
            context,
          })
        );
      }}
      variant="text"
      startIcon={<SettingsIcon />}
    >
      Settings
    </Button>
  ) : null;
};

export default SettingsButton;
