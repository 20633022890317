export const VEHICLE_MAPPING_CREATE = 'serviceData.VEHICLE_MAPPING_CREATE';
export const VEHICLE_MAPPING_ADD = 'serviceData.VEHICLE_MAPPING_ADD';
export const VEHICLE_MAPPING_ADD_COMMIT = 'serviceData.VEHICLE_MAPPING_ADD_COMMIT';
export const VEHICLE_MAPPING_REMOVE = 'serviceData.VEHICLE_MAPPING_REMOVE';
export const VEHICLE_MAPPING_REMOVE_COMMIT = 'serviceData.VEHICLE_MAPPING_REMOVE_COMMIT';
export const VEHICLE_MAPPING_DATA_SET = 'serviceData.VEHICLE_MAPPING_DATA_SET';
export const VEHICLE_MAPPING_DATA_SET_COMMIT = 'serviceData.VEHICLE_MAPPING_DATA_SET_COMMIT';
export const VEHICLE_MAPPING_REMAINING_FUEL_SET = 'serviceData.VEHICLE_MAPPING_REMAINING_FUEL_SET';
export const VEHICLE_MAPPING_REMAINING_FUEL_SET_COMMIT = 'serviceData.VEHICLE_MAPPING_REMAINING_FUEL_SET_COMMIT';
export const VEHICLE_MAPPING_CONDITION_SET = 'serviceData.VEHICLE_MAPPING_CONDITION_SET';
export const VEHICLE_MAPPING_CONDITION_SET_COMMIT = 'serviceData.VEHICLE_MAPPING_CONDITION_SET_COMMIT';
export const VEHICLE_MAPPING_CONDITION_ADD = 'serviceData.VEHICLE_MAPPING_CONDITION_ADD';
export const VEHICLE_MAPPING_CONDITION_ADD_COMMIT = 'serviceData.VEHICLE_MAPPING_CONDITION_ADD_COMMIT';
export const VEHICLE_MAPPING_CONDITION_REMOVE = 'serviceData.VEHICLE_MAPPING_CONDITION_REMOVE';
export const VEHICLE_MAPPING_CONDITION_REMOVE_COMMIT = 'serviceData.VEHICLE_MAPPING_CONDITION_REMOVE_COMMIT';
