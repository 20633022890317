import { Constants } from "@dndbeyond/character-rules-engine/es";

export const SIDEBAR_FIXED_POSITION_START_WIDTH = 1600;

export enum PaneHistoryInitTypeEnum {
  START = "START",
  PUSH = "PUSH",
}

export const SENSE_TYPE_LIST: Array<Constants.SenseTypeEnum> = [
  Constants.SenseTypeEnum.BLINDSIGHT,
  Constants.SenseTypeEnum.DARKVISION,
  Constants.SenseTypeEnum.TREMORSENSE,
  Constants.SenseTypeEnum.TRUESIGHT,
];

export const MOVEMENT_TYPE_LIST: Array<Constants.MovementTypeEnum> = [
  Constants.MovementTypeEnum.BURROW,
  Constants.MovementTypeEnum.CLIMB,
  Constants.MovementTypeEnum.FLY,
  Constants.MovementTypeEnum.SWIM,
  Constants.MovementTypeEnum.WALK,
];

export const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
export const FILE_SIZE_3MB = 3 * 1024 * 1024;
export const SIGNED_32BIT_INT_MAX_VALUE = 1_000_000_000;
export const SIGNED_32BIT_INT_MIN_VALUE = -1_000_000_000;

export const CURRENCY_VALUE = {
  MAX: SIGNED_32BIT_INT_MAX_VALUE,
  MIN: 0,
};
export const CHARACTER_DESCRIPTION_NUMBER_VALUE = {
  MAX: SIGNED_32BIT_INT_MAX_VALUE,
  MIN: 0,
};
export const HP_BONUS_VALUE = {
  MAX: 99,
  MIN: -99,
};
export const HP_OVERRIDE_MAX_VALUE = 999;
export const HP_BASE_MAX_VALUE = 500;

export const HP_TEMP_VALUE = {
  MAX: 999,
  MIN: 0,
};

export const HP_DAMAGE_TAKEN_VALUE = {
  MAX: SIGNED_32BIT_INT_MAX_VALUE,
  MIN: 0,
};
