import { createSelector } from "reselect";

import {
  SidebarAlignmentEnum,
  SidebarPlacementEnum,
} from "~/subApps/sheet/components/Sidebar/types";

import { SharedAppState } from "../stores/typings";

export const getPanes = (state: SharedAppState) => state.sidebar.panes;
export const getActivePaneId = (state: SharedAppState) =>
  state.sidebar.activePaneId;
export const getPlacement = (state: SharedAppState) => state.sidebar.placement;
export const getAlignment = (state: SharedAppState) => state.sidebar.alignment;
export const getIsLocked = (state: SharedAppState) => state.sidebar.isLocked;
export const getIsVisible = (state: SharedAppState) => state.sidebar.isVisible;
export const getWidth = (state: SharedAppState) => state.sidebar.width;

export interface SidebarPositionInfo {
  placement: SidebarPlacementEnum;
  alignment: SidebarAlignmentEnum;
  isVisible: boolean;
}

export const getActivePane = createSelector(
  [getActivePaneId, getPanes],
  (activePaneId, panes) => panes.find((pane) => pane.id === activePaneId)
);

export const getPositionInfo = createSelector(
  [getPlacement, getAlignment, getIsVisible],
  (placement, alignment, isVisible): SidebarPositionInfo => ({
    placement,
    alignment,
    isVisible,
  })
);
