import React from "react";
import { connect, DispatchProp } from "react-redux";

import { Dice } from "@dndbeyond/dice";
import {
  GameLogContextProvider,
  GameLog,
} from "@dndbeyond/game-log-components";

import { appEnvActions } from "../../../actions/appEnv";
import { appEnvSelectors } from "../../../selectors";
import { GameLogState, SharedAppState } from "../../../stores/typings";

interface Props extends DispatchProp {
  characterId: number | null;
  gameLog: GameLogState;
}

class GameLogPane extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  updateOpenState(isOpen: boolean): void {
    const { characterId, gameLog, dispatch } = this.props;

    const lastMessageTime = new Date().getTime();

    dispatch(
      appEnvActions.dataSet({
        gameLog: {
          ...gameLog,
          isOpen: isOpen,
          lastMessageTime: lastMessageTime,
        },
      })
    );

    try {
      localStorage.setItem(
        `gameLog-lastMessageTime-${characterId}`,
        lastMessageTime.toString()
      );
    } catch (e) {}

    // turn the dice notifications off if the panel is open
    Dice.setDiceNotificationEnabled(!isOpen);
  }
  componentDidMount() {
    this.updateOpenState(true);
  }

  componentWillUnmount(): void {
    this.updateOpenState(false);
  }

  render() {
    const { characterId } = this.props;

    return (
      <div className="ct-reset-pane ct-game-log-pane">
        {characterId && <GameLog />}
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    characterId: appEnvSelectors.getCharacterId(state),
    gameLog: appEnvSelectors.getGameLog(state),
  };
}

export default connect(mapStateToProps)(GameLogPane);
