import * as RacialTraitAccessors from './accessors';
import * as RacialTraitDerivers from './derivers';
import * as RacialTraitGenerators from './generators';
import * as RacialTraitSimulators from './simulators';
import * as RacialTraitTypings from './typings';
import * as RacialTraitUtils from './utils';
import * as RacialTraitValidators from './validators';
export * from './typings';
export { RacialTraitAccessors, RacialTraitDerivers, RacialTraitGenerators, RacialTraitSimulators, RacialTraitUtils, RacialTraitValidators, };
export default Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, RacialTraitAccessors), RacialTraitDerivers), RacialTraitGenerators), RacialTraitSimulators), RacialTraitTypings), RacialTraitUtils), RacialTraitValidators);
