import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const DivinationSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 32 32">
      <path
        fill={fillColor}
        d="M25.05459,10.48719a13.80182,13.80182,0,0,0-9.33093,2.08692,5.2614,5.2614,0,0,1,.98451-2.35521c.65113-.806,2.51177-1.12656,4.0067-1.38412,1.75232-.30191,3.2653-.56258,3.86818-1.64106A1.93446,1.93446,0,0,0,24.2883,4.761a3.02924,3.02924,0,0,0-3.21581-.49582,1.11554,1.11554,0,0,0,1.05357,1.96227.82521.82521,0,0,1,.145.00264,17.48014,17.48014,0,0,1-1.935.406C18.3749,6.974,16.152,7.357,14.97265,8.817A9.04153,9.04153,0,0,0,13.349,14.45887a9.83591,9.83591,0,0,0-2.28674,5.34024c-.026.37412-.04606.76971-.06645,1.17465-.1024,2.02529-.24262,4.79882-1.37042,4.79882-.85858,0-1.39082-2.43707-1.3944-4.18317a1.11551,1.11551,0,1,0-2.231,0c0,.65642.10178,6.41419,3.62542,6.41419,3.249,0,3.451-3.998,3.59849-6.91717.01976-.39062.03906-.7719.06411-1.13294a7.754,7.754,0,0,1,1.926-4.24112c.01787-.01471.0375-.026.05466-.042.04077-.038,4.14676-3.79489,9.44544-2.97834a1.11551,1.11551,0,1,0,.3405-2.20488Z"
      />
    </BaseSvg>
  );
};

export default DivinationSvg;
