import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const RadiantSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 19 19">
      <path
        fill={fillColor}
        d="M9.36,18.69,9.61,17a7.46,7.46,0,0,0,4.61-1.82L19,19l-3.93-4.67a7.47,7.47,0,0,0,1.74-4.69l1.91-.28-1.92-.29A7.46,7.46,0,0,0,15,4.68L19,0,14.15,3.8A7.46,7.46,0,0,0,9.66,2L9.36,0l-.3,2h0L8.4,6.47a3,3,0,0,0-.78.39L4,4,6.84,7.63a3,3,0,0,0-.38.76L0,9.36l1.82.27a7.47,7.47,0,0,0,1.78,4.76L0,19l1-1,3.45-2.78A7.47,7.47,0,0,0,9.11,17l.25,1.67Zm-.71-3a6.82,6.82,0,0,1-6.78-6l4.57.67a3,3,0,0,0,.39.79l-1,1.75,1.74-1a3,3,0,0,0,.83.42l.5,3.4H8.65Zm1.18-.1.5-3.32a3,3,0,0,0,.77-.39l1.72,1-1-1.73a3,3,0,0,0,.4-.8l3.15-.47a6.82,6.82,0,0,1-5.57,5.73Zm5.64-6.71-3.24-.49a3,3,0,0,0-.4-.77l1-1.77-1.77,1a3,3,0,0,0-.73-.36L9.67,2.11a6.82,6.82,0,0,1,5.79,6.75Z"
      />
    </BaseSvg>
  );
};

export default RadiantSvg;
