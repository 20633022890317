import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class PortraitSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-portrait-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 17.9 22">
        <path
          fill={fillColor}
          d="M16.4,19.1C16.4,19.1,16.4,19.1,16.4,19.1c-0.6-0.2-1.1-0.4-1.7-0.6c-0.8-0.3-1.2-0.8-1.3-1.5
                    c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.3,0.1-0.4c0.2-0.4,0.5-0.8,0.6-1.2c0.2-0.6,0.4-1.2,0.6-1.8c0.3,0.1,0.5-0.1,0.7-0.3
                    c0.6-1,0.9-2.1,0.9-3.3c0-0.2-0.1-0.4-0.2-0.5c-0.3-0.2-0.2-0.4-0.2-0.7C16,8.3,16,8.1,16,8c0.2-1.2,0.3-2.3,0-3.5
                    c-0.2-0.8-0.5-1.6-1.2-1.9c-0.5-0.2-0.8-0.6-1.2-1c-1.5-1.5-3.3-1.9-5.2-1.5C6.8,0.5,5.6,1.5,4.5,2.7C3.5,3.9,2.9,5.3,3,6.9
                    c0,0.5,0.2,1,0.2,1.5c-0.6,0.1-0.8,0.4-0.8,1c0,0.1,0,0.2,0,0.3c0.1,0.9,0.3,1.7,0.7,2.5c0.1,0.2,0.3,0.4,0.5,0.6
                    c0.1,0.1,0.2,0.2,0.2,0.3c0.2,1.1,0.6,2.2,1.2,3.1c0.1,0.1,0.1,0.2,0.1,0.3C5.2,17,5.1,17.4,5,17.7c-0.1,0.2-0.2,0.4-0.4,0.5
                    c-1.2,0.5-2.4,1-3.6,1.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.3-0.3,0.5-0.4,0.8c1,0.6,2,1.1,3.1,1.3h11.6c1-0.2,2-0.6,3-0.9
                    c0.1,0,0.2-0.1,0.3-0.1v-1C17.4,19.7,17,19.3,16.4,19.1z"
        />
      </BaseSvg>
    );
  }
}
