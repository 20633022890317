import * as actionTypes from '../actions/ruleData/actionTypes';
export const initialState = {
    abilityScoreDisplayTypes: [],
    abilitySkills: [],
    activationTypes: [],
    additionalLevelTypes: [],
    adjustmentDataTypes: [],
    adjustmentTypes: [],
    alignments: [],
    aoeTypes: [],
    armor: [],
    armorTypes: [],
    backgroundSpellTypeId: -1,
    baseGearTypeIds: [],
    baseTypeArmorId: 0,
    baseTypeGearId: 0,
    baseTypeWeaponId: 0,
    baseWeaponReach: 0,
    basicActions: [],
    basicMaxStatScore: 0,
    builderHelperText: [],
    challengeRatings: [],
    classSpellTypeId: -1,
    conditionTypes: [],
    conditions: [],
    coverTypes: [],
    creatureGroupCategories: [],
    creatureGroupFlags: [],
    creatureGroups: [],
    creatureSizes: [],
    currencyData: [],
    damageAdjustments: [],
    damageTypes: [],
    defaultArmorImageUrl: '',
    defaultAttunedItemCountMax: 0,
    defaultGearImageUrl: '',
    defaultRacePortraitUrl: '',
    defaultWeaponImageUrl: '',
    diceValues: [],
    environments: [],
    gearTypes: [],
    initiativeScore: {
        amount: 0,
        advantage: 0,
        disadvantage: 0,
    },
    languageTypeId: -1,
    languages: [],
    levelExperiencePoints: [],
    levelProficiencyBonuses: [],
    lifestyles: [],
    limitedUseResetTypes: [],
    longRestMinimumHitDiceUsedRecovered: 0,
    maxAttunedItemCountMax: 0,
    maxCharacterLevel: 0,
    maxDeathsavesFail: 0,
    maxDeathsavesSuccess: 0,
    maxExhaustionLevel: 0,
    maxSpellLevel: 0,
    maxStatScore: 0,
    minAttunedItemCountMax: 0,
    minExhaustionLevel: 0,
    minStatScore: 0,
    minimumHpTotal: 0,
    minimumLimitedUseMaxUse: 0,
    monsterSubTypes: [],
    monsterTypes: [],
    movements: [],
    multiClassSpellSlots: [],
    naturalActions: [],
    noArmorAcAmount: 0,
    operators: [],
    pactMagicMultiClassSpellSlots: [],
    privacyTypes: [],
    proficiencyGroups: [],
    raceGroups: [],
    rangeTypes: [],
    restoreTypes: [],
    ritualCastingTimeMinuteAddition: 0,
    rules: [],
    senses: [],
    sharingTypes: [],
    sourceCategories: [],
    sources: [],
    spellComponents: [],
    spellConditionTypes: [],
    spellRangeTypes: [],
    statModifiers: [],
    stats: [],
    stealthCheckTypes: [],
    stringMartialArts: '',
    stringPactMagic: '',
    stringSpellCasting: '',
    stringSpellEldritchBlast: '',
    toolTypeId: -1,
    tools: [],
    weaponCategories: [],
    weaponProperties: [],
    weaponPropertyReachDistance: 0,
    weapons: [],
};
function ruleData(state = initialState, action) {
    switch (action.type) {
        case actionTypes.DATA_SET:
            return Object.assign(Object.assign({}, state), action.payload);
        default:
        // not implemented
    }
    return state;
}
export default ruleData;
