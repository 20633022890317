import React from "react";

import { ExclusiveCheckbox } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  RuleData,
  RuleDataUtils,
  ShortModelInfoContract,
} from "@dndbeyond/character-rules-engine/es";

import { TypeScriptUtils } from "../../utils";
import { ThemeButton } from "../common/Button";

interface Props {
  onSave?: (restoreType: ShortModelInfoContract) => void;
  ruleData: RuleData;
  theme?: CharacterTheme;
}
interface State {
  activeChoice: number | null;
}
export default class RestoreLifeManager extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = {
      activeChoice: null,
    };
  }

  handleSelection = (slotIdx: number): void => {
    this.setState({
      activeChoice: slotIdx,
    });
  };

  handleReset = (): void => {
    this.setState({
      activeChoice: null,
    });
  };

  handleRestoreToLife = (): void => {
    const { onSave, ruleData } = this.props;
    const { activeChoice } = this.state;

    if (onSave && activeChoice !== null) {
      let restoreTypes = RuleDataUtils.getRestoreTypes(ruleData);
      onSave(restoreTypes[activeChoice]);
    }
  };

  renderRestoreLifeChoices = (): React.ReactNode => {
    const { activeChoice } = this.state;
    const { ruleData, theme } = this.props;

    const restoreLifeChoices: Array<string> = RuleDataUtils.getRestoreTypes(
      ruleData
    )
      .map((type) => type.description)
      .filter(TypeScriptUtils.isNotNullOrUndefined);

    return (
      <ExclusiveCheckbox
        theme={theme}
        choices={restoreLifeChoices}
        activeChoice={activeChoice}
        onSelection={this.handleSelection}
      />
    );
  };

  renderActions = (): React.ReactNode => {
    const { activeChoice } = this.state;

    if (activeChoice === null) {
      return null;
    }

    return (
      <div className="ct-restore-life__actions">
        <div className="ct-restore-life__action">
          <ThemeButton onClick={this.handleRestoreToLife} enableConfirm={true}>
            Restore Life
          </ThemeButton>
        </div>
        <div className="ct-restore-life__action">
          <ThemeButton onClick={this.handleReset} style="outline">
            Reset
          </ThemeButton>
        </div>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const classNames: Array<string> = ["ct-restore-life"];
    if (theme?.isDarkMode) {
      classNames.push("ct-restore-life--dark-mode");
    }

    return (
      <div className={classNames.join(" ")}>
        {this.renderRestoreLifeChoices()}
        {this.renderActions()}
      </div>
    );
  }
}
