import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class BackdropSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-backdrop-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 32 30.43">
        <path
          fill="none"
          d="M15.53,19.47l2.58-2.13-2.61-3.48-4.45,4.05C12.9,18.11,14.69,17.56,15.53,19.47Z"
          transform="translate(0 -0.78)"
        />
        <path
          fill="none"
          d="M3,21.76c.51.47,1,1,1.64,1.49a1.15,1.15,0,0,0,1.63-.08c.45-.35,1.25-.24,2.11-.36L5.5,19.19Z"
          transform="translate(0 -0.78)"
        />
        <path
          fill="none"
          d="M25.36,21.41a7.21,7.21,0,0,0,1.51.77l.29-.44-3.87-4.46-2.66,2c.44.24.74.55,1,.52C23.22,19.59,24.27,20.49,25.36,21.41Z"
          transform="translate(0 -0.78)"
        />
        <path
          fill={fillColor}
          d="M23.68,28.81c-1.45-.23-2.9.54-4.41-.6S16.88,26.86,16.54,27s-1.65-.87-1.82-1l-3.84-4,.88-.44s2.42,1.14,2.53.77.17-1.61.34-1.61.81.33,1.18.17,1.62.37,1.62.37l1-.4.44-.13.34.3.81,0S23,22.88,23.18,23s3.27,2.78,3.47,2.91a8.16,8.16,0,0,0,1.11.67c.17,0,.64.5.77.33s.67-1.24.67-1.24L32,27.8V25c-2.51-2.87-5.06-5.72-7.52-8.63-.79-.93-1.4-1-2.25-.18-.57.53-1.25.94-2,1.49-1.32-1.74-2.58-3.42-3.86-5.09-.81-1.06-1.12-1.08-2-.14l-6.7,6.94c-.28-.3-.52-.53-.72-.78C5.53,17,5.54,17,4,18.52c-1.3,1.27-2.63,2.5-4,3.75v8.95H13.75c-.86-1.13-1.86-2.06-3.42-2a2.66,2.66,0,0,1-2.47-.8c-.81-1-1.74-1.7-3.18-.6L3.8,25.19c2,.46,2,.46,3.19-.84l2.35-.42c1.19,1.47,2.47,3,3.72,4.63.68.86,1.29,1.77,1.94,2.66H26.84C26.22,30.51,24.79,29,23.68,28.81Zm-.4-11.53,3.87,4.46-.29.44a7.21,7.21,0,0,1-1.51-.77c-1.09-.92-2.14-1.82-3.72-1.64-.27,0-.57-.28-1-.52Zm-7.78-3.41c.79,1.06,2,1.84,2.87,3.07L15.8,19.06c-.84-1.92-2.9-1-4.74-1.15ZM6.31,23.18a1.15,1.15,0,0,1-1.63.08c-.6-.47-1.13-1-1.64-1.49L5.5,19.19l2.92,3.62C7.56,22.94,6.76,22.83,6.31,23.18Z"
          transform="translate(0 -0.78)"
        />
        <path
          fill={fillColor}
          d="M29,8.24l2.82-2.1-3.53-.45,1-3.3L26.16,4,25,.78l-1.53,3.1L20.5,1.94l.64,3.38-3.55.1,2.57,2.37-2.82,2.1,3.53.45-1,3.3L23,12l1.19,3.24,1.53-3.1,2.93,1.94L28,10.71l3.56-.1Zm-4.41,3.3A4,4,0,0,1,21.06,8a4,4,0,0,1,3.53-3.53A4,4,0,0,1,28.1,8,4,4,0,0,1,24.58,11.54Z"
          transform="translate(0 -0.78)"
        />
      </BaseSvg>
    );
  }
}
