import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  Constants,
  characterActions,
} from "@dndbeyond/character-rules-engine/es";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import { sidebarActions } from "../../../actions/sidebar";
import { ThemeButton } from "../../../components/common/Button";
import { SharedAppState } from "../../../stores/typings";
import { PaneComponentEnum } from "../../../utils";
import { PaneIdentifiersPreferenceProgressionConfirm } from "../../../utils/PaneIdentifier/typings";

interface Props extends DispatchProp {
  identifiers: PaneIdentifiersPreferenceProgressionConfirm | null;
}
interface State {
  progressionType: number | null;
}
class PreferencesProgressionConfirmPane extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = this.generateStateData(props);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { identifiers } = this.props;

    if (identifiers !== prevProps.identifiers) {
      this.setState(this.generateStateData(this.props));
    }
  }

  generateStateData = (props: Props): State => {
    const { identifiers } = props;

    return {
      progressionType: identifiers !== null ? identifiers.id : null,
    };
  };

  handleConfirm = (): void => {
    const { progressionType } = this.state;
    const { dispatch } = this.props;

    dispatch(
      characterActions.preferenceChoose("progressionType", progressionType)
    );
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.PREFERENCES));
  };

  handleCancel = (): void => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.PREFERENCES));
  };

  renderContent = (): React.ReactNode => {
    const { progressionType } = this.state;

    switch (progressionType) {
      case Constants.PreferenceProgressionTypeEnum.XP:
        return (
          <React.Fragment>
            <Header>XP Advancement</Header>
            <div className="change-preference-content">
              <p>
                Are you sure you want to change your advancement method to XP
                progression?
              </p>
              <p>
                You will begin with the base XP value for your current level.
              </p>
            </div>
          </React.Fragment>
        );

      case Constants.PreferenceProgressionTypeEnum.MILESTONE:
        return (
          <React.Fragment>
            <Header>Milestone Advancement</Header>
            <div className="change-preference-content">
              <p>
                Are you sure you want to change your advancement method to
                Milestone progression?
              </p>
              <p>Your current XP values will be lost.</p>
            </div>
          </React.Fragment>
        );
    }

    return null;
  };

  render() {
    return (
      <div className="ct-preferences-pane">
        {this.renderContent()}
        <div className="ct-preferences-pane__actions">
          <div className="ct-preferences-pane__action">
            <ThemeButton onClick={this.handleConfirm}>Accept</ThemeButton>
          </div>
          <div className="ct-preferences-pane__action">
            <ThemeButton onClick={this.handleCancel} style="outline">
              Cancel
            </ThemeButton>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {};
}

export default connect(mapStateToProps)(PreferencesProgressionConfirmPane);
