import { visuallyHidden } from "@mui/utils";
import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  CharacterTheme,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { sidebarActions } from "../../../../Shared/actions";
import { appEnvSelectors } from "../../../../Shared/selectors";
import { PaneComponentEnum } from "../../../../Shared/utils";
import MobileDivider from "../../../components/MobileDivider";
import SubsectionMobile from "../../../components/SubsectionMobile";
import { SheetAppState } from "../../../typings";
import { Equipment } from "../../Equipment";

interface Props extends DispatchProp {
  isReadonly: boolean;
  theme: CharacterTheme;
}
class EquipmentMobile extends React.PureComponent<Props> {
  handleManageClick = (): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      dispatch(
        sidebarActions.paneHistoryStart(PaneComponentEnum.EQUIPMENT_MANAGE)
      );
    }
  };

  render() {
    const { isReadonly, theme } = this.props;

    return (
      <SubsectionMobile>
        <MobileDivider
          label="Inventory"
          onClick={this.handleManageClick}
          isReadonly={isReadonly}
          theme={theme}
        />
        <section className="ct-equipment-mobile">
          <h2 style={visuallyHidden}>Inventory</h2>
          <Equipment showNotes={false} />
        </section>
        <MobileDivider isEnd={true} theme={theme} />
      </SubsectionMobile>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    isReadonly: appEnvSelectors.getIsReadonly(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(EquipmentMobile);
