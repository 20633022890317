import { SidebarPaneComponentInfoState } from "~/tools/js/Shared/stores/typings";

import { PaneComponentEnum, PaneComponentProperties } from "../types";

export const getActiveEntry = (pane): SidebarPaneComponentInfoState | null => {
  if (pane === undefined) return null;

  const { historyIdx, history } = pane;

  if (historyIdx === null) return null;

  return history[historyIdx] ?? null;
};

// List any full width panes
const fullWidthPanes = [PaneComponentEnum.CHARACTER_MANAGE];

// Check to see if pane is in the list
const isFullWidthPane = (activeEntry: SidebarPaneComponentInfoState | null) =>
  activeEntry?.componentType &&
  fullWidthPanes.includes(activeEntry.componentType);

// List any forced dark mode panes
const darkModePanes = [PaneComponentEnum.CHARACTER_MANAGE];

// Check to see if pane is in the list
const forceDarkMode = (activeEntry: SidebarPaneComponentInfoState | null) =>
  activeEntry?.componentType &&
  darkModePanes.includes(activeEntry.componentType);

// Return optional pane props
export const getPaneProperties = (
  activeEntry: SidebarPaneComponentInfoState | null
): PaneComponentProperties => {
  const props: PaneComponentProperties = {
    isFullWidth: isFullWidthPane(activeEntry) ?? false,
    forceDarkMode: forceDarkMode(activeEntry) ?? false,
  };

  return { ...props };
};
