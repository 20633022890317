import character from './character';
import characterEnv from './characterEnv';
import featureFlagInfo from './featureFlagInfo';
import ruleData from './ruleData';
import serviceData from './serviceData';
import syncTransaction from './syncTransaction';
export const characterReducer = character;
export const characterEnvReducer = characterEnv;
export const featureFlagInfoReducer = featureFlagInfo;
export const ruleDataReducer = ruleData;
export const serviceDataReducer = serviceData;
export const syncTransactionReducer = syncTransaction;
