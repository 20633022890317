import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const PetrifiedSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 22 22">
      <path
        fill={fillColor}
        d="M8.1,6.4L7,4.9l1.1-0.6c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3L6.4,0.2C6.9,0.1,7.4,0,8,0   c3.3,0,6.1,2.4,6.9,5.8l-2.8-0.9c-0.1,0-0.2,0-0.3,0L8.1,6.4z"
      />
      <path
        fill={fillColor}
        d="M4.5,11.4l-1.9,2.4c-0.1-0.3-0.2-0.6-0.2-1c-0.3-0.4-0.5-0.7-0.7-1.1c-0.6,0.2-1.3-0.5-1.5-1.5   c-0.3-1-0.1-1.9,0.5-2.1c0.1,0,0.1,0,0.2,0c0-0.1,0-0.1,0-0.2c0-3.5,2.1-6.4,4.9-7.4l1.8,3.4L6.3,4.4c0,0-0.1,0-0.1,0.1L4.4,5.4   C4.2,5.5,4.2,5.6,4.2,5.7L4.5,11.4z"
      />
      <path
        fill={fillColor}
        d="M7.9,16.9c0.1,0.5,0.2,1.1,0.3,1.6c-0.1,0-0.2,0-0.3,0c-2.2,0-4.1-1.9-5-4l2.3-2.8c0-0.1,0.1-0.2,0.1-0.3   L4.9,5.9l1.4-0.7l1.3,1.9C7.7,7.2,7.9,7.3,8,7.3c0.1,0,0.1,0,0.2,0l3.9-1.6l3,1c0.1,0.4,0.1,0.8,0.1,1.2c0,0.1,0,0.1,0,0.2   c0.1,0,0.1,0,0.1,0c0.4,0.1,0.6,0.6,0.6,1.1L10.3,11c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3l1.6,3.1l-3.5,1.9   C8,16.6,7.9,16.8,7.9,16.9z"
      />

      <path
        fill={secondaryFillColor}
        d="M6.4,8.9c0,0.9-0.7,1-1.5,1s-1.5-0.1-1.5-1c0-0.9,0.7-1.5,1.5-1.5S6.4,8.1,6.4,8.9z"
      />

      <path
        fill={secondaryFillColor}
        d="M10.3,14.5c0,1.3-1,0-2.3,0s-2.3,1.3-2.3,0s1-2.3,2.3-2.3S10.3,13.2,10.3,14.5z"
      />

      <path
        fill={secondaryFillColor}
        d="M12.4,8.9c0,0.9-0.7,1-1.5,1s-1.5-0.1-1.5-1c0-0.9,0.7-1.5,1.5-1.5S12.4,8.1,12.4,8.9z"
      />

      <path
        fill={fillColor}
        d="M10.9,11.6l4.9-1.6c0,0.1,0,0.1,0,0.1c-0.3,1-1,1.6-1.5,1.5c-0.2,0.4-0.4,0.8-0.7,1.1   c-0.4,2.5-2.2,5.1-4.6,5.7h0c-0.1-0.4-0.1-0.8-0.2-1.4l3.6-1.9c0.2-0.1,0.2-0.3,0.2-0.5L10.9,11.6z"
      />
    </BaseSvg>
  );
};

export default PetrifiedSvg;
