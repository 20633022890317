import { orderBy } from "lodash";
import React from "react";

import { AttackTable } from "@dndbeyond/character-components/es";
import {
  AbilityLookup,
  Activatable,
  Attack,
  BasicActionContract,
  Constants,
  RuleData,
  RuleDataUtils,
  SnippetData,
  SourceData,
  Spell,
  SpellCasterInfo,
  SpellUtils,
  WeaponSpellDamageGroup,
  InventoryLookup,
  CharacterTheme,
  DataOriginRefData,
  ActionLookup,
  HelperUtils,
  ActionUtils,
} from "@dndbeyond/character-rules-engine/es";
import { IRollContext } from "@dndbeyond/dice";

import { useCharacterEngine } from "~/hooks/useCharacterEngine";

import { ActionHandlers } from "../../containers/Actions/Actions";
import ActionSnippet from "../ActionSnippet";
import BasicActions from "../BasicActions";
import { FeatureSnippetSpells } from "../FeatureSnippet";

interface Props extends Partial<ActionHandlers> {
  heading: React.ReactNode;
  activationType?: number;
  actions: Array<Activatable>;
  attacks: Array<Attack>;
  weaponSpellDamageGroups: Array<WeaponSpellDamageGroup>;
  ritualSpells: Array<Spell>;
  basicActions: Array<BasicActionContract>;
  showActivationInfo: boolean;

  showNotes: boolean;
  spellCasterInfo: SpellCasterInfo;
  abilityLookup: AbilityLookup;
  inventoryLookup: InventoryLookup;
  ruleData: RuleData;
  sourceDataLookup: Record<number, SourceData>;
  snippetData: SnippetData;
  isInteractive: boolean;
  diceEnabled: boolean;
  theme: CharacterTheme;
  dataOriginRefData: DataOriginRefData;
  proficiencyBonus: number;
  rollContext: IRollContext;
  actionLookup: ActionLookup;
}
export class ActionsList extends React.PureComponent<Props> {
  static defaultProps = {
    showActivationInfo: false,
    actions: [],
    attacks: [],
    basicActions: [],
    ritualSpells: [],
    diceEnabled: false,
  };

  renderFeature = (feature: Activatable): React.ReactNode => {
    const {
      showActivationInfo,
      abilityLookup,
      ruleData,
      sourceDataLookup,
      snippetData,
      inventoryLookup,
      onActionClick,
      onActionUseSet,
      isInteractive,
      proficiencyBonus,
      theme,
      actionLookup,
    } = this.props;

    let actionComponentNode: React.ReactNode;
    switch (feature.type) {
      case Constants.ActivatableTypeEnum.ACTION: {
        const action = HelperUtils.lookupDataOrFallback(
          actionLookup,
          ActionUtils.getUniqueKey(feature.entity)
        );

        actionComponentNode = (
          <ActionSnippet
            theme={theme}
            action={action ?? feature.entity}
            onActionClick={onActionClick}
            onActionUseSet={onActionUseSet}
            abilityLookup={abilityLookup}
            inventoryLookup={inventoryLookup}
            ruleData={ruleData}
            snippetData={snippetData}
            isInteractive={isInteractive}
            showActivationInfo={showActivationInfo}
            activation={feature.activation}
            proficiencyBonus={proficiencyBonus}
          />
        );
        break;
      }

      default:
        break;
    }

    return (
      <div className="ct-actions-list__activatable" key={feature.key}>
        {actionComponentNode}
      </div>
    );
  };

  renderBasicActions = (): React.ReactNode => {
    const { basicActions, onBasicActionClick, theme } = this.props;

    if (!basicActions.length) {
      return null;
    }

    return (
      <div className="ct-actions-list__basic">
        <div
          className={`ct-actions-list__basic-heading ${
            theme.isDarkMode ? "ct-actions-list__basic-heading--dark-mode" : ""
          }`}
        >
          Actions in Combat
        </div>
        <div
          className="ct-actions-list__basic-list"
          style={
            theme?.isDarkMode ? { borderColor: theme?.themeColor } : undefined
          }
        >
          <BasicActions
            onActionClick={onBasicActionClick}
            basicActions={basicActions}
            theme={theme}
          />
        </div>
      </div>
    );
  };

  renderRitualSpells = (): React.ReactNode => {
    const {
      ritualSpells,
      onSpellClick,
      onSpellUseSet,
      ruleData,
      abilityLookup,
      isInteractive,
      dataOriginRefData,
      proficiencyBonus,
      theme,
    } = this.props;

    if (!ritualSpells.length) {
      return null;
    }

    let orderedRitualSpells = orderBy(ritualSpells, (spell) =>
      SpellUtils.getName(spell)
    );

    return (
      <div className="ct-actions-list__spells ct-actions-list__spells--ritual">
        <div
          className={`ct-actions-list__spells-heading ${
            theme?.isDarkMode
              ? "ct-actions-list__spells-heading--dark-mode"
              : ""
          }`}
        >
          Ritual Spells
        </div>
        <div
          className="ct-actions-list__spells-list"
          style={
            theme?.isDarkMode ? { borderColor: theme?.themeColor } : undefined
          }
        >
          <FeatureSnippetSpells
            layoutType={"compact"}
            theme={theme}
            onSpellClick={onSpellClick}
            onSpellUseSet={onSpellUseSet}
            ruleData={ruleData}
            abilityLookup={abilityLookup}
            spells={orderedRitualSpells}
            isInteractive={isInteractive}
            dataOriginRefData={dataOriginRefData}
            proficiencyBonus={proficiencyBonus}
          />
        </div>
      </div>
    );
  };

  renderSpells = (): React.ReactNode => {
    const {
      actions,
      onSpellClick,
      onSpellUseSet,
      ruleData,
      abilityLookup,
      isInteractive,
      dataOriginRefData,
      proficiencyBonus,
      theme,
    } = this.props;

    let spells: Array<any> = [];
    actions.forEach((action) => {
      switch (action.type) {
        case Constants.ActivatableTypeEnum.CLASS_SPELL:
        case Constants.ActivatableTypeEnum.CHARACTER_SPELL:
          spells.push(action.entity);
          break;
      }
    });

    if (!spells.length) {
      return null;
    }

    return (
      <div className="ct-actions-list__spells">
        <div
          className={`ct-actions-list__spells-heading ${
            theme?.isDarkMode
              ? "ct-actions-list__spells-heading--dark-mode"
              : ""
          }`}
        >
          Spells
        </div>
        <div
          className="ct-actions-list__spells-list"
          style={
            theme?.isDarkMode ? { borderColor: theme?.themeColor } : undefined
          }
        >
          <FeatureSnippetSpells
            layoutType={"compact"}
            onSpellClick={onSpellClick}
            onSpellUseSet={onSpellUseSet}
            ruleData={ruleData}
            theme={theme}
            abilityLookup={abilityLookup}
            spells={spells}
            isInteractive={isInteractive}
            dataOriginRefData={dataOriginRefData}
            proficiencyBonus={proficiencyBonus}
          />
        </div>
      </div>
    );
  };

  renderAttacks = (): React.ReactNode => {
    const {
      attacks,
      weaponSpellDamageGroups,
      ruleData,
      spellCasterInfo,
      abilityLookup,
      onAttackClick,
      showNotes,
      diceEnabled,
      theme,
      dataOriginRefData,
      proficiencyBonus,
      rollContext,
    } = this.props;

    if (!attacks.length) {
      return null;
    }
    return (
      <AttackTable
        attacks={attacks}
        weaponSpellDamageGroups={weaponSpellDamageGroups}
        ruleData={ruleData}
        abilityLookup={abilityLookup}
        spellCasterInfo={spellCasterInfo}
        onAttackClick={onAttackClick}
        showNotes={showNotes}
        diceEnabled={diceEnabled}
        theme={theme}
        dataOriginRefData={dataOriginRefData}
        proficiencyBonus={proficiencyBonus}
        rollContext={rollContext}
      />
    );
  };

  renderDefault = (): React.ReactNode => {
    const { ruleData, activationType, theme } = this.props;

    let actionTypeName: string = "other types of actions";

    if (activationType) {
      let activationTypeInfo = RuleDataUtils.getActivationTypeInfo(
        activationType,
        ruleData
      );

      if (activationTypeInfo && activationTypeInfo.name) {
        actionTypeName = activationTypeInfo.name.toLowerCase() + "s";
      }
    }

    return (
      <div
        className={`ct-actions-list__default ${
          theme.isDarkMode ? "ct-actions-list__default--dark-mode" : ""
        }`}
      >
        Available {actionTypeName} for your character will appear in this
        section.
      </div>
    );
  };

  renderContent = (): React.ReactNode => {
    const { actions } = this.props;

    let features: Array<Activatable> = [];
    actions.forEach((action) => {
      switch (action.type) {
        case Constants.ActivatableTypeEnum.CLASS_SPELL:
        case Constants.ActivatableTypeEnum.CHARACTER_SPELL:
          break;
        default:
          features.push(action);
      }
    });

    return (
      <React.Fragment>
        {this.renderAttacks()}
        {this.renderBasicActions()}
        {this.renderSpells()}
        {this.renderRitualSpells()}
        {features.map((feature) => this.renderFeature(feature))}
      </React.Fragment>
    );
  };

  render() {
    const { basicActions, actions, ritualSpells, attacks, heading, theme } =
      this.props;

    let contentNode: React.ReactNode;
    if (
      basicActions.length ||
      actions.length ||
      ritualSpells.length ||
      attacks.length
    ) {
      contentNode = this.renderContent();
    } else {
      return null;
    }

    return (
      <div className="ct-actions-list">
        <div
          className="ct-actions-list__heading"
          style={
            theme?.isDarkMode
              ? { borderColor: `${theme?.themeColor}66` }
              : undefined
          }
        >
          {heading}
        </div>
        <div className="ct-actions-list__content">{contentNode}</div>
      </div>
    );
  }
}

const ActionListContainer = (props) => {
  const { actionLookup } = useCharacterEngine();
  return <ActionsList actionLookup={actionLookup} {...props} />;
};

export default ActionListContainer;
