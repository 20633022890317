import React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

const CubeSvg: React.FunctionComponent<BaseSvgProps> = ({
  className = "",
  fillColor,
  secondaryFillColor,
}) => {
  return (
    <BaseSvg className={className} viewBox="0 0 16.89 17.57">
      <path
        fill={fillColor}
        d="M13.43,17.57H.5a.5.5,0,0,1-.5-.5V4.14a.5.5,0,0,1,.5-.5H13.43a.5.5,0,0,1,.5.5V17.07A.5.5,0,0,1,13.43,17.57ZM1,16.57H12.93V4.64H1Z"
      />
      <path
        fill={fillColor}
        d="M13.43,17.57a.5.5,0,0,1-.4-.81l2.86-3.71V1.91L13.82,4.46A.5.5,0,1,1,13,3.83L16,.18a.5.5,0,0,1,.89.32V13.23a.5.5,0,0,1-.1.31l-3,3.85A.5.5,0,0,1,13.43,17.57Z"
      />
      <path
        fill={fillColor}
        d="M.5,4.64A.5.5,0,0,1,.13,3.8L3.55.16A.5.5,0,0,1,3.91,0H16.39a.5.5,0,0,1,0,1H4.13L.87,4.48A.5.5,0,0,1,.5,4.64Z"
      />
    </BaseSvg>
  );
};

export default CubeSvg;
