import { visuallyHidden } from "@mui/utils";
import React, { useContext } from "react";
import { connect, DispatchProp } from "react-redux";

import {
  TabOptions,
  TabOptionsContent,
  TabOptionsGroup,
} from "@dndbeyond/character-common-components/es";
import {
  rulesEngineSelectors,
  CharacterTheme,
  ExtrasFilterData,
  ExtraGroupInfo,
  Constants,
  ExtrasManager,
  ExtraManager,
} from "@dndbeyond/character-rules-engine/es";

import { useExtras } from "~/hooks/useExtras";

import { sidebarActions } from "../../../Shared/actions";
import ExtraList from "../../../Shared/components/ExtraList";
import { ThemeButton } from "../../../Shared/components/common/Button";
import { ExtrasManagerContext } from "../../../Shared/managers/ExtrasManagerContext";
import { appEnvSelectors } from "../../../Shared/selectors";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../Shared/utils";
import ContentGroup from "../../components/ContentGroup";
import ExtrasFilter from "../../components/ExtrasFilter";
import { SheetAppState } from "../../typings";

const TAB_KEY = {
  ALL: "ALL",
  OTHER: "OTHER",
  VEHICLE: "VEHICLE",
};

interface Props extends DispatchProp {
  extras: Array<ExtraManager>;
  extrasManager: ExtrasManager;
  isReadonly: boolean;
  showNotes: boolean;
  theme: CharacterTheme;
}
interface State {
  filterData: ExtrasFilterData;
}
class Extras extends React.PureComponent<Props, State> {
  static defaultProps = {
    isReadonly: false,
    showNotes: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      filterData: {
        filteredExtras: [],
        showAdvancedFilters: false,
        isFiltering: false,
        filterCount: 0,
      },
    };
  }

  handleFilterUpdate = (filterData: ExtrasFilterData): void => {
    this.setState({
      filterData,
    });
  };

  handleManageClick = (): void => {
    const { dispatch } = this.props;

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.EXTRA_MANAGE));
  };

  handleExtraShow = (extra: ExtraManager): void => {
    const { dispatch } = this.props;

    if (extra.isCreature()) {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.CREATURE,
          PaneIdentifierUtils.generateCreature(extra.getMappingId())
        )
      );
    } else if (extra.isVehicle()) {
      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.VEHICLE,
          PaneIdentifierUtils.generateVehicle(extra.getMappingId())
        )
      );
    }
  };

  handleExtraStatusChange = (extra: ExtraManager, isActive: boolean): void => {
    extra.handleSetActive({ isActive });
  };

  renderManageButton = (): React.ReactNode => {
    const { isReadonly } = this.props;

    if (isReadonly) {
      return null;
    }

    return (
      <ThemeButton
        style="outline"
        size="medium"
        onClick={this.handleManageClick}
      >
        Manage Extras
      </ThemeButton>
    );
  };

  renderDefault = (): React.ReactNode => {
    return (
      <div className="ct-extras__empty">
        Extras that you add will display here.
      </div>
    );
  };

  renderNoResults = (): React.ReactNode => {
    return (
      <div className="ct-extras__empty">No Extras Match the Current Filter</div>
    );
  };

  renderContent = (): React.ReactNode => {
    const { filterData } = this.state;
    const { extras, extrasManager, showNotes, isReadonly, theme } = this.props;

    if (!extras.length) {
      return this.renderDefault();
    }

    if (filterData.showAdvancedFilters) {
      return null;
    }

    if (!filterData.filteredExtras.length) {
      return this.renderNoResults();
    }

    let groups = extrasManager.getExtrasGroups(filterData.filteredExtras);
    const orderedGroupInfos = extrasManager.getGroupInfosForExtras(
      filterData.filteredExtras
    );

    let primaryGroups: Array<ExtraGroupInfo> = [];
    let otherGroups: Array<ExtraGroupInfo> = [];
    let vehicleGroups: Array<ExtraGroupInfo> = [];
    orderedGroupInfos.forEach((groupInfo) => {
      if (groupInfo) {
        if (groupInfo.isPrimary) {
          primaryGroups.push(groupInfo);
        } else if (groupInfo.id === Constants.ExtraGroupTypeEnum.VEHICLE) {
          vehicleGroups.push(groupInfo);
        } else {
          otherGroups.push(groupInfo);
        }
      }
    });

    return (
      <div className="ct-extras__content">
        <TabOptions
          hideBorder={true}
          initialActiveKey={TAB_KEY.ALL}
          layoutType="pill"
        >
          <TabOptionsGroup tabKey={TAB_KEY.ALL} label="All">
            <TabOptionsContent>
              {orderedGroupInfos.map((groupInfo) => (
                <ContentGroup header={groupInfo.name} key={groupInfo.id}>
                  <ExtraList
                    theme={theme}
                    extras={groups[groupInfo.id]}
                    showNotes={showNotes}
                    onShow={this.handleExtraShow}
                    onStatusChange={this.handleExtraStatusChange}
                    isReadonly={isReadonly}
                  />
                </ContentGroup>
              ))}
            </TabOptionsContent>
          </TabOptionsGroup>
          {primaryGroups.map((groupInfo) => (
            <TabOptionsGroup
              tabKey={groupInfo.id}
              label={groupInfo.name}
              key={groupInfo.id}
            >
              <TabOptionsContent>
                <ContentGroup header={groupInfo.name}>
                  <ExtraList
                    theme={theme}
                    extras={groups[groupInfo.id]}
                    showNotes={showNotes}
                    onShow={this.handleExtraShow}
                    onStatusChange={this.handleExtraStatusChange}
                    isReadonly={isReadonly}
                  />
                </ContentGroup>
              </TabOptionsContent>
            </TabOptionsGroup>
          ))}
          {otherGroups.length > 0 && (
            <TabOptionsGroup tabKey={TAB_KEY.OTHER} label="Other">
              <TabOptionsContent>
                {otherGroups.map((groupInfo) => (
                  <ContentGroup key={groupInfo.id} header={groupInfo.name}>
                    <ExtraList
                      theme={theme}
                      extras={groups[groupInfo.id]}
                      showNotes={showNotes}
                      onShow={this.handleExtraShow}
                      onStatusChange={this.handleExtraStatusChange}
                      isReadonly={isReadonly}
                    />
                  </ContentGroup>
                ))}
              </TabOptionsContent>
            </TabOptionsGroup>
          )}
          {vehicleGroups.length > 0 && (
            <TabOptionsGroup tabKey={TAB_KEY.VEHICLE} label="Vehicle">
              <TabOptionsContent>
                {vehicleGroups.map((groupInfo) => (
                  <ContentGroup key={groupInfo.id} header={groupInfo.name}>
                    <ExtraList
                      theme={theme}
                      extras={groups[groupInfo.id]}
                      showNotes={showNotes}
                      onShow={this.handleExtraShow}
                      onStatusChange={this.handleExtraStatusChange}
                      isReadonly={isReadonly}
                    />
                  </ContentGroup>
                ))}
              </TabOptionsContent>
            </TabOptionsGroup>
          )}
        </TabOptions>
      </div>
    );
  };

  render() {
    const { extras, theme } = this.props;

    return (
      <section
        className={`ct-extras ${
          theme.isDarkMode ? "ct-extras--dark-mode" : ""
        }`}
      >
        <h2 style={visuallyHidden}>Extras</h2>
        <div className="ct-extras__filter">
          <ExtrasFilter
            extras={extras}
            onDataUpdate={this.handleFilterUpdate}
            callout={this.renderManageButton()}
            theme={theme}
          />
        </div>
        {this.renderContent()}
      </section>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    isReadonly: appEnvSelectors.getIsReadonly(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

function ExtrasContainer(props) {
  const { extrasManager } = useContext(ExtrasManagerContext);
  const extras = useExtras();
  return <Extras extrasManager={extrasManager} extras={extras} {...props} />;
}

export default connect(mapStateToProps)(ExtrasContainer);
