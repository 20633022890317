import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  CharacterPreferences,
  CharacterUtils,
  Constants,
  RuleData,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { Link } from "~/components/Link";

import { modalActions } from "../../../Shared/actions";
import { BuilderAppState } from "../../typings";

interface Props extends DispatchProp {
  currentXp: number;
  preferences: CharacterPreferences;
  totalClassLevel: number;
  ruleData: RuleData;
}
class ProgressionManager extends React.PureComponent<Props> {
  handleManageXpClick = (): void => {
    const { dispatch } = this.props;
    dispatch(modalActions.open("xp"));
  };

  render() {
    const { currentXp, preferences, totalClassLevel, ruleData } = this.props;

    const currentLevel = CharacterUtils.deriveXpLevel(currentXp, ruleData);

    let manageProgressionNode: React.ReactNode;
    if (
      preferences.progressionType === Constants.PreferenceProgressionTypeEnum.XP
    ) {
      const levelDiff: number = currentLevel - totalClassLevel;
      let diffClsNames: Array<string> = ["progression-manager-heading-diff"];
      if (levelDiff > 0) {
        diffClsNames.push("progression-manager-heading-diff-positive");
      } else if (levelDiff < 0) {
        diffClsNames.push("progression-manager-heading-diff-negative");
      }
      manageProgressionNode = (
        <div className="progression-manager">
          <div className="progression-manager-info">
            <div className="progression-manager-heading">
              Character Level: {totalClassLevel}
              {levelDiff !== 0 && (
                <span className={diffClsNames.join(" ")}>
                  <span className="progression-manager-heading-diff-icon" />
                  <span className="progression-manager-heading-diff-amount">
                    {Math.abs(levelDiff)}
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className="progression-manager-action">
            <Link onClick={this.handleManageXpClick}>Manage XP</Link>
          </div>
        </div>
      );
    } else {
      manageProgressionNode = (
        <div className="progression-manager">
          <div className="progression-manager-info">
            <div className="progression-manager-heading">
              Character Level: {totalClassLevel}
            </div>
            <div className="progression-manager-meta">
              Milestone Advancement
            </div>
          </div>
        </div>
      );
    }

    return manageProgressionNode;
  }
}

function mapStateToProps(state: BuilderAppState) {
  return {
    currentXp: rulesEngineSelectors.getCurrentXp(state),
    preferences: rulesEngineSelectors.getCharacterPreferences(state),
    totalClassLevel: rulesEngineSelectors.getTotalClassLevel(state),
    ruleData: rulesEngineSelectors.getRuleData(state),
  };
}

export default connect(mapStateToProps)(ProgressionManager);
