import React from "react";

import { Button } from "@dndbeyond/character-components/es";

import FilterListingItemContentDefault from "../FilterListingItemContentDefault";

interface FilterListingData {
  type: string;
  id: any;
  entityTypeId: number;
  heading: any;
  headingText: string;
  metaItems: Array<string>;
}
interface Props {
  data: FilterListingData;
  entity: any; //TODO type
  ContentComponent: React.ComponentType<any>;
  onItemSelected?: (data: FilterListingData, entity: any) => void;
  disabled: boolean;
  ButtonComponent: React.ComponentType<any>;
  buttonOnlyClick: boolean;
  addText: string;
}
export default class FilterListingItem extends React.PureComponent<Props, {}> {
  static defaultProps = {
    ContentComponent: FilterListingItemContentDefault,
    ButtonComponent: Button,
    buttonOnlyClick: true,
  };

  handleItemClicked = (evt: React.MouseEvent): void => {
    const { onItemSelected, data, entity } = this.props;

    if (onItemSelected) {
      onItemSelected(data, entity);
    }
  };

  render() {
    const {
      data,
      addText,
      disabled,
      ContentComponent,
      ButtonComponent,
      entity,
      buttonOnlyClick,
    } = this.props;

    let clsNames: Array<string> = [
      "filter-listing-item",
      "filter-listing-entry",
    ];
    if (disabled) {
      clsNames.push("filter-listing-item-disabled");
    }

    return (
      <div
        className={clsNames.join(" ")}
        onClick={
          disabled || buttonOnlyClick ? undefined : this.handleItemClicked
        }
      >
        <ContentComponent {...data} entity={entity} />
        {buttonOnlyClick && !disabled && (
          <div className="filter-listing-item-action">
            <ButtonComponent
              onClick={buttonOnlyClick ? this.handleItemClicked : null}
              size="small"
            >
              {addText}
            </ButtonComponent>
          </div>
        )}
      </div>
    );
  }
}
