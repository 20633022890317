import { DdbBadgeSvg } from "@dndbeyond/character-components/es";
import {
  Constants,
  DefinitionUtils,
  Item,
  ItemUtils,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import { Accordion } from "~/components/Accordion";
import { HtmlContent } from "~/components/HtmlContent";

interface Props {
  ruleData: RuleData;
  item: Item;
}

export const ItemListInformationCollapsible = ({ ruleData, item }: Props) => {
  const definitionKey = DefinitionUtils.hack__generateDefinitionKey(
    ItemUtils.getEntityTypeId(item),
    ItemUtils.getId(item)
  );
  const builderText = RuleDataUtils.getBuilderHelperTextByDefinitionKeys(
    [definitionKey],
    ruleData,
    Constants.DisplayConfigurationTypeEnum.ITEM
  );

  return (
    <div className="ct-item-list-information-collapsible">
      {builderText.map((helperText, idx) => (
        <Accordion
          key={`${helperText.label}-${idx}`}
          summary={
            <>
              <DdbBadgeSvg /> {helperText.label}
            </>
          }
        >
          <HtmlContent html={helperText.description} />
        </Accordion>
      ))}
    </div>
  );
};
