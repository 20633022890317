import { createContext, useEffect, useState } from "react";

import {
  getUserPreferences,
  updateUserPreferences,
} from "~/helpers/characterServiceApi";
import { UserPreferences } from "~/types";

import PreferenceUpdateLocation from "../../Shared/constants/PreferenceUpdateLocation";

interface UserPreferenceContextProps extends UserPreferences {
  updatePreferences: (updated: UserPreferences) => void;
}

const initialValue = {
  abilityScoreDisplayType: 2,
  privacyType: 2,
  isDarkModeEnabled: false,
  isDiceRollingEnabled: true,
  updateLocation: PreferenceUpdateLocation.CharacterBuilder,
  updatePreferences: () => {},
};

export const UserPreferenceContext =
  createContext<UserPreferenceContextProps>(initialValue);

export const UserPreferenceProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(initialValue);
  const [isLoaded, setIsLoaded] = useState(false);

  const updatePreferences = (updated: UserPreferences) => {
    setPreferences({ ...preferences, ...updated });
  };

  const getPreferences = async () => {
    const req = await getUserPreferences();
    const res = await req.json();
    updatePreferences(res.data);
    setIsLoaded(true);
  };

  useEffect(() => {
    getPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoaded) {
      updateUserPreferences(preferences);
    }
  }, [preferences]);

  return (
    <UserPreferenceContext.Provider
      value={{ ...preferences, updatePreferences }}
    >
      {children}
    </UserPreferenceContext.Provider>
  );
};
