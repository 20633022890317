import React from "react";

interface Props {
  metaItems: Array<string>;
}
export default class FilterListingItemContentMeta extends React.PureComponent<
  Props,
  {}
> {
  static defaultProps = {
    metaItems: [],
  };

  render() {
    const { metaItems } = this.props;

    if (!metaItems || metaItems.length === 0) {
      return null;
    }

    return (
      <div className="filter-listing-item-meta">
        {metaItems.map((metaItem, idx) => (
          <div key={idx} className="filter-listing-item-meta-item">
            {metaItem}
          </div>
        ))}
      </div>
    );
  }
}
