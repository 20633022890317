import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class ChatBubbleSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-chat-bubble-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 21 21">
        <path
          fill={fillColor}
          d="M17.5 11.6666C17.5 12.3095 16.9761 12.8333 16.3333 12.8333H10.5C10.1908 12.8333 9.89329 12.9558 9.67513 13.1751L6.99996 15.8503V14C6.99996 13.3548 6.47729 12.8333 5.83329 12.8333H4.66663C4.02379 12.8333 3.49996 12.3095 3.49996 11.6666V4.66663C3.49996 4.02379 4.02379 3.49996 4.66663 3.49996H16.3333C16.9761 3.49996 17.5 4.02379 17.5 4.66663V11.6666ZM16.3333 1.16663H4.66663C2.73696 1.16663 1.16663 2.73696 1.16663 4.66663V11.6666C1.16663 13.5963 2.73696 15.1666 4.66663 15.1666V18.6666C4.66663 19.138 4.95129 19.565 5.38646 19.7446C5.53113 19.8041 5.68279 19.8333 5.83329 19.8333C6.13663 19.8333 6.43529 19.7143 6.65813 19.4915L10.983 15.1666H16.3333C18.263 15.1666 19.8333 13.5963 19.8333 11.6666V4.66663C19.8333 2.73696 18.263 1.16663 16.3333 1.16663ZM14 6.99996H6.99996C6.35596 6.99996 5.83329 7.52146 5.83329 8.16663C5.83329 8.81179 6.35596 9.33329 6.99996 9.33329H14C14.644 9.33329 15.1666 8.81179 15.1666 8.16663C15.1666 7.52146 14.644 6.99996 14 6.99996Z"
        />
      </BaseSvg>
    );
  }
}
