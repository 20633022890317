import {
  PaneComponentEnum,
  SidebarAlignmentEnum,
  SidebarPlacementEnum,
} from "~/subApps/sheet/components/Sidebar/types";

import { PaneIdentifiers } from "../../utils/PaneIdentifier/typings";
import * as types from "./actionTypes";
import {
  AlignmentSetAction,
  LockSetAction,
  PaneCreateAction,
  PaneHistoryNextAction,
  PaneHistoryPreviousAction,
  PaneHistoryPushAction,
  PaneHistoryStartAction,
  PaneRemoveAction,
  PlacementSetAction,
  VisibleSetAction,
} from "./typings";

export function lockSet(isLocked: boolean): LockSetAction {
  return {
    type: types.LOCK_SET,
    payload: {
      isLocked,
    },
  };
}

export function placementSet(
  placement: SidebarPlacementEnum
): PlacementSetAction {
  return {
    type: types.PLACEMENT_SET,
    payload: {
      placement,
    },
  };
}

export function alignmentSet(
  alignment: SidebarAlignmentEnum
): AlignmentSetAction {
  return {
    type: types.ALIGNMENT_SET,
    payload: {
      alignment,
    },
  };
}

export function visibleSet(isVisible: boolean): VisibleSetAction {
  return {
    type: types.VISIBLE_SET,
    payload: {
      isVisible,
    },
  };
}

export function paneRemove(id: string): PaneRemoveAction {
  return {
    type: types.PANE_REMOVE,
    payload: {
      id,
    },
  };
}

export function paneCreate(): PaneCreateAction {
  return {
    type: types.PANE_CREATE,
    payload: {},
  };
}

export function paneHistoryPush(
  componentType: PaneComponentEnum,
  componentIdentifiers: PaneIdentifiers | null = null,
  componentProps: any = null,
  id: string | null = null
): PaneHistoryPushAction {
  return {
    type: types.PANE_HISTORY_PUSH,
    payload: {
      id,
      componentType,
      componentIdentifiers,
      componentProps,
    },
  };
}

export function paneHistoryStart(
  componentType: PaneComponentEnum,
  componentIdentifiers: PaneIdentifiers | null = null,
  componentProps: any = null,
  id: string | null = null
): PaneHistoryStartAction {
  return {
    type: types.PANE_HISTORY_START,
    payload: {
      id,
      componentType,
      componentIdentifiers,
      componentProps,
    },
  };
}

export function paneHistoryPrevious(
  id: string | null = null
): PaneHistoryPreviousAction {
  return {
    type: types.PANE_HISTORY_PREVIOUS,
    payload: {
      id,
    },
  };
}

export function paneHistoryNext(
  id: string | null = null
): PaneHistoryNextAction {
  return {
    type: types.PANE_HISTORY_NEXT,
    payload: {
      id,
    },
  };
}
