import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  sidebarActions,
  toastMessageActions,
} from "../../../../../../tools/js/Shared/actions";

interface Props {
  errorMessage?: string;
}
export const PaneInitFailureContent: FC<Props> = ({ errorMessage }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sidebarActions.visibleSet(false));
    dispatch(
      toastMessageActions.toastError(
        "Content Error",
        errorMessage ?? "We couldn't find that content! Please try again."
      )
    );
  }, [errorMessage, dispatch]);

  return null;
};
