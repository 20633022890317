import React from "react";

import FullscreenModal from "../../common/FullscreenModal";
import TextareaContentEditable from "../../legacy/common/TextareaContentEditable";

interface Props {
  modalHeading: string;
  heading: string;
  content: string;
  placeholder: string;
  onRequestClose: (text: string) => void;
  onInputBlur: (text: string) => void;
  isOpen: boolean;
  extraNode: React.ReactNode;
}

export default class TextareaEditorManager extends React.PureComponent<Props> {
  textareaEditorInput = React.createRef<TextareaContentEditable>();

  handleModalOpen = () => {
    if (this.textareaEditorInput.current) {
      this.textareaEditorInput.current.focus();
    }
  };

  handleCloseModal = () => {
    const { onRequestClose } = this.props;

    if (this.textareaEditorInput.current) {
      onRequestClose(this.textareaEditorInput.current.getTextValue());
    }
  };

  handleInputBlur = (value) => {
    const { onInputBlur } = this.props;
    if (onInputBlur) {
      onInputBlur(value);
    }
  };

  render() {
    const { content, isOpen, modalHeading, heading, placeholder, extraNode } =
      this.props;

    return (
      <FullscreenModal
        clsNames={["manage-text-modal"]}
        hideActions={true}
        onRequestClose={this.handleCloseModal}
        isOpen={isOpen}
        heading={modalHeading}
        onAfterOpen={this.handleModalOpen}
      >
        <div className="textarea-editor-manager">
          <div className="textarea-editor-manager-heading">{heading}</div>
          <TextareaContentEditable
            ref={this.textareaEditorInput}
            className="textarea-editor-manager-input"
            placeholder={placeholder}
            content={content}
            onInputBlur={this.handleInputBlur}
          />
          {extraNode && (
            <div className="textarea-editor-manager-extra">{extraNode}</div>
          )}
        </div>
      </FullscreenModal>
    );
  }
}
