import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../BaseSvg";

export default class CharismaSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor, secondaryFillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 36.89 29.81" className={className}>
        <path
          fill={secondaryFillColor}
          d="M11.26,9.5a12.63,12.63,0,0,0-3.6,2.44,2.06,2.06,0,0,0,2,1.75,2.8,2.8,0,0,0,2.49-2.45A2.13,2.13,0,0,0,11.26,9.5Z"
        />
        <path
          fill={fillColor}
          d="M35.18,29.31a10.94,10.94,0,0,0,.08-4.38,4.24,4.24,0,0,0,1.63,2,17.82,17.82,0,0,1-2.07-8.2s.61,1.65,1.38,3.59c0,0,.32-1.84-1.16-6.76a37.09,37.09,0,0,1,1.52,4.55s-.1-11.94-5.1-14.83c0,0,1.22-.14,4.63,4.39,0,0-2.57-6.19-7.76-7.91l4.51,1.34S29.87.47,22.11.59c0,0,3.38-.37,4.08-.05,0,0-9.76-2.63-16.74,3.72,0,0-2.32.08-3,.73,0,0-2.36-.48-5,2.14-3.13,3.07-.29,6.57,1.25,8a3.78,3.78,0,0,1,.42-2.87s2,1.31,6.06,1c11.25-.74,8,4,4.39,7.46,0,0-1.87,1.75-5.54,5.58,0,0,5.56-1.68,6.42-2.75a10.86,10.86,0,0,1,1.78,3.57,8.09,8.09,0,0,0,1.72-3,9.1,9.1,0,0,0,.42,2.11,2.47,2.47,0,0,0,.94-1,4.83,4.83,0,0,0,.68,1.54,3.46,3.46,0,0,0,.55-1.54,6.4,6.4,0,0,0,1.82,3.36,3.52,3.52,0,0,0,.55-1.8,2.35,2.35,0,0,0,.73,1,11.67,11.67,0,0,0,.83-4.24s.47,4.32,2.08,5.81a5.41,5.41,0,0,0,.63-2.68,39.45,39.45,0,0,0,4.36,3.15,5.51,5.51,0,0,1-.7-2.45S30.75,27.56,35.18,29.31Z"
        />
        <path
          fill={secondaryFillColor}
          d="M16.29,4.95c0,1,1.6,1.75,2.56,1.75a1.74,1.74,0,0,0,1.4-2.79,21.32,21.32,0,0,0-3.77.49A1,1,0,0,0,16.29,4.95Z"
        />
        <path
          fill="none"
          stroke={secondaryFillColor}
          strokeMiterlimit={10}
          d="M9.37,15.38a6.18,6.18,0,0,1,5.37-2.71c3.83,0,1.17-1.87-2.33-2.54s-.33-5.67-8-7"
        />
        <path
          fill="none"
          stroke={secondaryFillColor}
          strokeMiterlimit={10}
          d="M3.66,13.55S.73,11,3.5,10.66c1.19-.12,10.25,0,10.25,0"
        />
      </BaseSvg>
    );
  }
}
