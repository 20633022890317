import * as React from "react";

interface Props {
  className: string;
}

export default class TabOptionsContent extends React.PureComponent<Props> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { children, className } = this.props;

    const classNames: Array<string> = ["ddbc-tab-options__content", className];

    return <div className={classNames.join(" ")}>{children}</div>;
  }
}
