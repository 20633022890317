import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  characterActions,
  rulesEngineSelectors,
  ClassSpellListSpellsLookup,
} from "@dndbeyond/character-rules-engine/es";

import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";

import { sidebarActions } from "../../../actions/sidebar";
import { SimpleClassSpellList } from "../../../components/SimpleClassSpellList";
import { ThemeButton } from "../../../components/common/Button";
import { SharedAppState } from "../../../stores/typings";
import { PaneComponentEnum } from "../../../utils";
import { PaneIdentifiersPreferenceOptionalOriginsConfirm } from "../../../utils/PaneIdentifier/typings";

interface Props extends DispatchProp {
  identifiers: PaneIdentifiersPreferenceOptionalOriginsConfirm | null;
  classSpellListSpellsLookup: ClassSpellListSpellsLookup;
}
interface State {
  spellListIds: Array<number>;
  newIsEnabled: boolean;
}
class PreferencesOptionalOriginsConfirmPane extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);

    this.state = this.generateStateData(props);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { identifiers } = this.props;

    if (identifiers !== prevProps.identifiers) {
      this.setState(this.generateStateData(this.props));
    }
  }

  generateStateData = (props: Props): State => {
    const { identifiers } = props;

    return {
      spellListIds: identifiers?.spellListIds ?? [],
      newIsEnabled: identifiers?.newIsEnabled ?? false,
    };
  };

  handleConfirm = (): void => {
    const { newIsEnabled } = this.state;
    const { dispatch } = this.props;

    dispatch(
      characterActions.preferenceChoose("enableOptionalOrigins", newIsEnabled)
    );
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.PREFERENCES));
  };

  handleCancel = (): void => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.PREFERENCES));
  };

  renderContent = (): React.ReactNode => {
    const { spellListIds, newIsEnabled } = this.state;
    const { classSpellListSpellsLookup } = this.props;

    return (
      <React.Fragment>
        <Header>
          {newIsEnabled ? "Enable" : "Disable"} Customized Origins
        </Header>
        <div className="change-preference-content">
          <p>
            Are you sure you want to {newIsEnabled ? "enable" : "disable"}{" "}
            <strong>Customized Origins</strong> for this character?
          </p>
          <p>
            After doing so, the following spells provided by these features will
            be removed from your character:
          </p>
          <SimpleClassSpellList
            spellListIds={spellListIds}
            classSpellListSpellsLookup={classSpellListSpellsLookup}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="ct-preferences-pane">
        {this.renderContent()}
        <div className="ct-preferences-pane__actions">
          <div className="ct-preferences-pane__action">
            <ThemeButton onClick={this.handleConfirm}>Accept</ThemeButton>
          </div>
          <div className="ct-preferences-pane__action">
            <ThemeButton onClick={this.handleCancel} style="outline">
              Cancel
            </ThemeButton>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    classSpellListSpellsLookup:
      rulesEngineSelectors.getClassSpellListSpellsLookup(state),
  };
}

export default connect(mapStateToProps)(PreferencesOptionalOriginsConfirmPane);
