import React from "react";

import { BuilderChevronRightSvg } from "@dndbeyond/character-components/es";

import {
  FilterListingItemContent,
  FilterListingItemContentHeading,
} from "../../../Shared/components/common/FilterListing";

interface Props {
  heading: React.ReactNode;
  entity: any;
  metaItems: Array<{
    type: string;
    text: string;
  }>;
}
export default class ClassFilterListingItemContent extends React.PureComponent<Props> {
  render() {
    const { heading, entity, metaItems } = this.props;

    const portraitAvatarUrl: string | null = entity.portraitAvatarUrl;

    return (
      <FilterListingItemContent
        clsNames={["filter-listing-item-content-class"]}
      >
        <FilterListingItemContentHeading>
          <div className="class-filter-item-header">
            <div className="class-filter-item-preview">
              <img
                className="class-filter-item-preview-img"
                src={portraitAvatarUrl ? portraitAvatarUrl : ""}
                alt=""
              />
            </div>
            <div className="class-filter-item-info">
              <div className="class-filter-item-heading">{heading}</div>
              {metaItems.length > 0 && (
                <div className="class-filter-item-meta-items">
                  {metaItems.map((metaItem, idx) => (
                    <span
                      className={`class-filter-item-meta-item class-filter-item-meta-item-type-${metaItem.type}`}
                      key={idx}
                    >
                      {metaItem.text}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <div className="class-filter-item-action">
              <BuilderChevronRightSvg />
            </div>
          </div>
        </FilterListingItemContentHeading>
      </FilterListingItemContent>
    );
  }
}
