import { visuallyHidden } from "@mui/utils";
import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  TabOptions,
  TabOptionsContent,
  TabOptionsGroup,
} from "@dndbeyond/character-common-components/es";
import {
  CharacterNotes,
  Constants,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { sidebarActions } from "../../../Shared/actions";
import { appEnvSelectors } from "../../../Shared/selectors";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../Shared/utils";
import ContentGroup from "../../components/ContentGroup";
import { SheetAppState } from "../../typings";

const TAB_KEY = {
  ALL: "ALL",
  ORGANIZATIONS: "ORGANIZATIONS",
  ALLIES: "ALLIES",
  ENEMIES: "ENEMIES",
  BACKSTORY: "BACKSTORY",
  OTHER: "OTHER",
};

interface Props extends DispatchProp {
  notes: CharacterNotes;
  isReadonly: boolean;
}
class Notes extends React.PureComponent<Props> {
  handleNotesManage = (
    noteType: Constants.NoteKeyEnum,
    evt: React.MouseEvent
  ): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();

      dispatch(
        sidebarActions.paneHistoryStart(
          PaneComponentEnum.NOTE_MANAGE,
          PaneIdentifierUtils.generateNote(noteType)
        )
      );
    }
  };

  renderNoteGroup(
    label: string,
    key: Constants.NoteKeyEnum,
    fallback: string = ""
  ) {
    const { notes } = this.props;

    let noteData = notes[key];
    let hasContent: boolean = !!noteData;
    let content: string = !!noteData ? noteData : fallback;

    let classNames: Array<string> = ["ct-notes__note"];
    if (!hasContent) {
      classNames.push("ct-notes__note--no-content");
    }

    return (
      <ContentGroup header={label}>
        <div
          className={classNames.join(" ")}
          onClick={this.handleNotesManage.bind(this, key)}
        >
          {content}
        </div>
      </ContentGroup>
    );
  }

  render() {
    return (
      <section className="ct-notes">
        <h2 style={visuallyHidden}>Notes</h2>
        <TabOptions
          hideBorder={true}
          initialActiveKey={TAB_KEY.ALL}
          layoutType="pill"
        >
          <TabOptionsGroup tabKey={TAB_KEY.ALL} label="All">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Organizations",
                Constants.NoteKeyEnum.ORGANIZATIONS,
                "+ Add Organizations"
              )}
              {this.renderNoteGroup(
                "Allies",
                Constants.NoteKeyEnum.ALLIES,
                "+ Add Allies"
              )}
              {this.renderNoteGroup(
                "Enemies",
                Constants.NoteKeyEnum.ENEMIES,
                "+ Add Enemies"
              )}
              {this.renderNoteGroup(
                "Backstory",
                Constants.NoteKeyEnum.BACKSTORY,
                "+ Add Backstory"
              )}
              {this.renderNoteGroup(
                "Other",
                Constants.NoteKeyEnum.OTHER,
                "+ Add Other"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.ORGANIZATIONS} label="Orgs">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Organizations",
                Constants.NoteKeyEnum.ORGANIZATIONS,
                "+ Add Organizations"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.ALLIES} label="Allies">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Allies",
                Constants.NoteKeyEnum.ALLIES,
                "+ Add Allies"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.ENEMIES} label="Enemies">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Enemies",
                Constants.NoteKeyEnum.ENEMIES,
                "+ Add Enemies"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.BACKSTORY} label="Backstory">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Backstory",
                Constants.NoteKeyEnum.BACKSTORY,
                "+ Add Backstory"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
          <TabOptionsGroup tabKey={TAB_KEY.OTHER} label="Other">
            <TabOptionsContent>
              {this.renderNoteGroup(
                "Other",
                Constants.NoteKeyEnum.OTHER,
                "+ Add Other"
              )}
            </TabOptionsContent>
          </TabOptionsGroup>
        </TabOptions>
      </section>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    notes: rulesEngineSelectors.getCharacterNotes(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
  };
}

export default connect(mapStateToProps)(Notes);
