import React from "react";
import { connect, DispatchProp } from "react-redux";

import { ManageIcon } from "@dndbeyond/character-components/es";
import {
  CharacterTheme,
  ProficiencyGroup,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { FeatureFlagContext } from "~/contexts/FeatureFlag";

import { sidebarActions } from "../../../Shared/actions";
import {
  Subsection,
  SubsectionFooter,
} from "../../../Shared/components/Subsection";
import { appEnvSelectors } from "../../../Shared/selectors";
import { AppEnvDimensionsState } from "../../../Shared/stores/typings";
import { PaneComponentEnum } from "../../../Shared/utils";
import ProficiencyGroupsBox from "../../components/ProficiencyGroupsBox";
import { SheetAppState } from "../../typings";

interface Props extends DispatchProp {
  dimensions: AppEnvDimensionsState;
  theme: CharacterTheme;
  proficiencyGroups: Array<ProficiencyGroup>;
  isReadonly: boolean;
}
class ProficiencyGroupsDesktop extends React.PureComponent<Props> {
  handleManageShow = (): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      dispatch(
        sidebarActions.paneHistoryStart(PaneComponentEnum.PROFICIENCIES)
      );
    }
  };

  render() {
    const { dimensions, theme, proficiencyGroups, isReadonly } = this.props;

    return (
      <FeatureFlagContext.Consumer>
        {({ featureFlags: { osirisCharacterAppFeFlag } }) => (
          <Subsection name="Proficiency Groups">
            <ProficiencyGroupsBox
              dimensions={dimensions}
              theme={theme}
              proficiencyGroups={proficiencyGroups}
              onClick={this.handleManageShow}
            />
            <SubsectionFooter>
              <ManageIcon
                onClick={this.handleManageShow}
                showIcon={!isReadonly}
                enableTooltip={!isReadonly}
                theme={theme}
              >
                Proficiencies &{" "}
                {osirisCharacterAppFeFlag ? "Training" : "Languages"}
              </ManageIcon>
            </SubsectionFooter>
          </Subsection>
        )}
      </FeatureFlagContext.Consumer>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    dimensions: appEnvSelectors.getDimensions(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
    proficiencyGroups: rulesEngineSelectors.getProficiencyGroups(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
  };
}

export default connect(mapStateToProps)(ProficiencyGroupsDesktop);
