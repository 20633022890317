import * as React from "react";

import {
  Creature,
  CreatureUtils,
  ConditionUtils,
  DamageAdjustmentContract,
  DiceUtils,
  FormatUtils,
  RuleDataUtils,
  RuleData,
  SnippetData,
} from "@dndbeyond/character-rules-engine/es";

import { HtmlContent } from "~/components/HtmlContent";

import { DDB_MEDIA_URL } from "../../../../constants";

interface Props {
  className: string;
  creature: Creature;
  ruleData: RuleData;
  snippetData: SnippetData;
}

export default class CreatureBlock extends React.PureComponent<Props, {}> {
  static defaultProps = {
    className: "",
  };

  renderMeta = (): string => {
    const { creature, ruleData } = this.props;

    let level: string = "";
    let levelValue = CreatureUtils.getLevel(creature);
    if (levelValue !== null) {
      level = `${FormatUtils.ordinalize(levelValue)}-level`;
    }

    const sizeInfo = RuleDataUtils.getCreatureSizeInfo(
      CreatureUtils.getSizeId(creature),
      ruleData
    );
    let size: string = "";
    if (sizeInfo && sizeInfo.name !== null) {
      size = sizeInfo.name;
    }

    let type = CreatureUtils.getTypeName(creature, ruleData);

    const alignmentInfo = RuleDataUtils.getAlignmentInfo(
      CreatureUtils.getAlignmentId(creature),
      ruleData
    );
    let alignment: string = "";
    if (alignmentInfo && alignmentInfo.name !== null) {
      alignment = alignmentInfo.name;
    }

    let extraTextChunks: Array<string> = [];
    if (
      CreatureUtils.getName(creature) !==
      CreatureUtils.getDefinitionName(creature)
    ) {
      extraTextChunks.push(CreatureUtils.getDefinitionName(creature));
    }
    let subTypes = CreatureUtils.getSubTypes(creature);
    if (subTypes.length) {
      subTypes.forEach((monsterInfo) => {
        if (monsterInfo.name !== null) {
          extraTextChunks.push(monsterInfo.name.toLowerCase());
        }
      });
    }

    let extraText: string = extraTextChunks.join(", ");

    return `${level} ${size} ${type}${extraText ? ` (${extraText})` : ""}${
      alignment ? `, ${alignment.toLowerCase()}` : ""
    }`.trim();
  };

  renderSpeed = (): string => {
    const { creature, ruleData } = this.props;

    let movements = CreatureUtils.getMovements(creature);

    if (movements.length) {
      let movementDisplays = movements.map((movement, idx) => {
        let label: string = "";
        if (idx !== 0) {
          label = RuleDataUtils.getMovementName(
            movement.movementId,
            ruleData
          ).toLowerCase();
        }
        return `${label} ${FormatUtils.renderDistance(movement.speed)} ${
          movement.notes ? movement.notes : ""
        }`.trim();
      });

      return movementDisplays.join(", ");
    }

    return "--";
  };

  renderSavingThrowTidbits = (): React.ReactNode => {
    const { creature } = this.props;

    let savingThrows = CreatureUtils.getSavingThrows(creature);

    if (!savingThrows.length) {
      return null;
    }

    let savingThrowDisplays: Array<string> = savingThrows.map((savingThrow) => {
      return `${savingThrow.statKey} ${FormatUtils.renderSignedNumber(
        savingThrow.modifier
      )}`;
    });

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Saving Throws</span>
        <span className="ddbc-creature-block__tidbit-data">
          {savingThrowDisplays.join(", ")}
        </span>
      </div>
    );
  };

  renderSkillTidbits = (): React.ReactNode => {
    const { creature, ruleData } = this.props;

    let skills = CreatureUtils.getSkills(creature);

    if (!skills.length) {
      return null;
    }

    let skillDisplays: Array<string> = skills.map((skill) => {
      let skillInfo = RuleDataUtils.getSkillInfo(skill.id, ruleData);
      let name: string =
        skillInfo && skillInfo.name !== null ? skillInfo.name : "";
      let modifier: number = skill.modifier;
      return `${name} ${FormatUtils.renderSignedNumber(modifier)}`;
    });

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Skills</span>
        <span className="ddbc-creature-block__tidbit-data">
          {skillDisplays.join(", ")}
        </span>
      </div>
    );
  };

  renderDamageAdjustmentTidbits = (
    label: string,
    damageAdjustments: Array<DamageAdjustmentContract>
  ): React.ReactNode => {
    if (!damageAdjustments.length) {
      return null;
    }

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">{label}</span>
        <span className="ddbc-creature-block__tidbit-data">
          {damageAdjustments
            .map((damageAdjustment) => damageAdjustment.name)
            .join(", ")}
        </span>
      </div>
    );
  };

  renderDamageVulnerabilityTidbits = (): React.ReactNode => {
    const { creature } = this.props;

    let damageAdjustments = CreatureUtils.getDamageVulnerabilities(creature);
    return this.renderDamageAdjustmentTidbits(
      "Damage Vulnerabilities",
      damageAdjustments
    );
  };

  renderDamageResistanceTidbits = (): React.ReactNode => {
    const { creature } = this.props;
    let damageAdjustments = CreatureUtils.getDamageResistances(creature);
    return this.renderDamageAdjustmentTidbits(
      "Damage Resistances",
      damageAdjustments
    );
  };

  renderDamageImmunityTidbits = (): React.ReactNode => {
    const { creature } = this.props;
    let damageAdjustments = CreatureUtils.getDamageImmunities(creature);
    return this.renderDamageAdjustmentTidbits(
      "Damage Immunities",
      damageAdjustments
    );
  };

  renderConditionImmunityTidbits = (): React.ReactNode => {
    const { creature } = this.props;

    let conditionImmunities = CreatureUtils.getConditionImmunities(creature);

    if (!conditionImmunities.length) {
      return null;
    }

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">
          Condition Immunities
        </span>
        <span className="ddbc-creature-block__tidbit-data">
          {conditionImmunities
            .map((condition) => ConditionUtils.getName(condition))
            .join(", ")}
        </span>
      </div>
    );
  };

  renderSenseTidbits = (): React.ReactNode => {
    const { creature, ruleData } = this.props;

    let senses = CreatureUtils.getSenses(creature);
    let senseDisplays: Array<string> = senses.map((sense) => {
      let senseInfo = RuleDataUtils.getSenseInfo(sense.senseId, ruleData);
      let senseName = senseInfo ? senseInfo.name : "";
      return `${senseName} ${sense.notes}`;
    });
    let senseDisplay: string = senseDisplays.length
      ? `${senseDisplays.join(", ")}, `
      : "";

    let passivePerception = CreatureUtils.getPassivePerception(creature);
    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Senses</span>
        <span className="ddbc-creature-block__tidbit-data">
          {senseDisplay}Passive Perception {passivePerception}
        </span>
      </div>
    );
  };

  renderLanguageTidbits = (): React.ReactNode => {
    const { creature, ruleData } = this.props;

    let languages = CreatureUtils.getLanguages(creature);
    let languageNote = CreatureUtils.getLanguageNote(creature);

    let languageText: string = "";
    if (languages.length || languageNote) {
      let languageDisplays: Array<string> = languages.map((language) => {
        let name = RuleDataUtils.getLanguageName(language.languageId, ruleData);
        return `${name} ${language.notes}`.trim();
      });

      if (languageNote) {
        languageDisplays.push(languageNote);
      }

      languageText = languageDisplays.join(", ");
    } else {
      languageText = "--";
    }

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Languages</span>
        <span className="ddbc-creature-block__tidbit-data">{languageText}</span>
      </div>
    );
  };

  renderChallengeTidbits = (): React.ReactNode => {
    const { creature } = this.props;

    let challengeInfo = CreatureUtils.getChallengeInfo(creature);
    const hideCr = CreatureUtils.getHideCr(creature);

    if (!challengeInfo || hideCr) {
      return null;
    }

    return (
      <div className="ddbc-creature-block__tidbit">
        <span className="ddbc-creature-block__tidbit-label">Challenge</span>
        <span className="ddbc-creature-block__tidbit-data">
          {FormatUtils.renderChallengeRating(challengeInfo.value)} (
          {FormatUtils.renderLocaleNumber(challengeInfo.xp)} XP)
        </span>
      </div>
    );
  };

  renderStats = (): React.ReactNode => {
    const { creature } = this.props;

    let stats = CreatureUtils.getStats(creature);

    return (
      <div className="ddbc-creature-block__abilities">
        {stats.map((stat) => {
          let statKey: string = stat.statKey ? stat.statKey : "";
          let modifier: number = stat.modifier ? stat.modifier : 0;
          let score: number | null = stat.score;

          return (
            <div
              className={`ddbc-creature-block__ability-stat ddbc-creature-block__ability-stat--${statKey.toLowerCase()}`}
              key={statKey}
            >
              <div className="ddbc-creature-block__ability-heading">
                {statKey}
              </div>
              <div className="ddbc-creature-block__ability-data">
                <span className="ddbc-creature-block__ability-score">
                  {score}
                </span>
                <span className="ddbc-creature-block__ability-modifier">
                  ({FormatUtils.renderSignedNumber(modifier)})
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderDescription = (
    label: string | null,
    description: React.ReactNode
  ): React.ReactNode => {
    if (!description) {
      return null;
    }

    let descriptionNode: React.ReactNode;
    if (typeof description === "string") {
      descriptionNode = (
        <HtmlContent
          className="ddbc-creature-block__description-block-content"
          html={description}
        />
      );
    } else {
      descriptionNode = (
        <div className="ddbc-creature-block__description-block-content">
          {description}
        </div>
      );
    }

    return (
      <div className="ddbc-creature-block__description-block">
        {label && (
          <div className="ddbc-creature-block__description-block-heading">
            {label}
          </div>
        )}
        {descriptionNode}
      </div>
    );
  };

  renderActions = (): React.ReactNode => {
    const { creature } = this.props;

    let groupActionNode: React.ReactNode;
    let groupActionSnippet = CreatureUtils.getGroupActionSnippet(creature);
    if (groupActionSnippet) {
      groupActionNode = <HtmlContent html={groupActionSnippet} />;
    }

    let actionsNode: React.ReactNode;
    let actionsDescription = CreatureUtils.getActionsDescription(creature);
    if (actionsDescription) {
      actionsNode = <HtmlContent html={actionsDescription} />;
    }

    let contentNode: React.ReactNode = (
      <React.Fragment>
        {groupActionNode}
        {actionsNode}
      </React.Fragment>
    );

    return this.renderDescription("Actions", contentNode);
  };

  renderLegendaryActions = (): React.ReactNode => {
    const { creature } = this.props;

    if (!CreatureUtils.canUseLegendaryActions(creature)) {
      return null;
    }

    return this.renderDescription(
      "Legendary Actions",
      CreatureUtils.getLegendaryActionsDescription(creature)
    );
  };

  renderSpecialDescription = (): React.ReactNode => {
    const { creature } = this.props;

    let groupInfo = CreatureUtils.getGroupInfo(creature);
    let groupNode: React.ReactNode;

    if (groupInfo && groupInfo.specialQualityText) {
      let groupTitle: React.ReactNode;
      if (groupInfo.specialQualityTitle) {
        groupTitle = (
          <React.Fragment>
            <strong>
              <em>{groupInfo.specialQualityTitle}</em>
            </strong>
            .{" "}
          </React.Fragment>
        );
      }
      groupNode = (
        <p>
          {groupTitle}
          {groupInfo.specialQualityText}
        </p>
      );
    }

    let monsterNode: React.ReactNode;
    let monsterDescription =
      CreatureUtils.getSpecialTraitsDescription(creature);
    if (monsterDescription) {
      monsterNode = <HtmlContent html={monsterDescription} />;
    }

    let contentNode: React.ReactNode = null;
    if (monsterNode || groupNode) {
      contentNode = (
        <React.Fragment>
          {monsterNode}
          {groupNode}
        </React.Fragment>
      );
    }

    return this.renderDescription(null, contentNode);
  };

  renderSeparator = (): React.ReactNode => {
    //TODO not hard-code the src url here?
    return (
      <div className="ddbc-creature-block__separator">
        <img
          className="ddbc-creature-block__separator-img"
          alt=""
          src={`${DDB_MEDIA_URL}/file-attachments/0/579/stat-block-header-bar.svg`}
        />
      </div>
    );
  };

  render() {
    const { creature, className } = this.props;

    let armorClassDescription =
      CreatureUtils.getArmorClassDescription(creature);
    let hitPointInfo = CreatureUtils.getHitPointInfo(creature);
    let hitPointDice = CreatureUtils.getHitPointDice(creature);

    let classNames: Array<string> = [className, "ddbc-creature-block"];

    return (
      <div className={classNames.join(" ")}>
        <div className="ddbc-creature-block__header">
          <div className="ddbc-creature-block__name">
            {CreatureUtils.getName(creature)}
          </div>
          <div className="ddbc-creature-block__meta">{this.renderMeta()}</div>
        </div>
        {this.renderSeparator()}
        <div className="ddbc-creature-block__attributes">
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">
              Armor Class
            </span>
            <span className="ddbc-creature-block__attribute-value">
              <span className="ddbc-creature-block__attribute-data-value">
                {CreatureUtils.getArmorClass(creature)}
              </span>
              {armorClassDescription && (
                <span className="ddbc-creature-block__attribute-data-extra">
                  {armorClassDescription}
                </span>
              )}
            </span>
          </div>
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">
              Hit Points
            </span>
            <span className="ddbc-creature-block__attribute-data">
              <span className="ddbc-creature-block__attribute-data-value">
                {hitPointInfo.totalHp}
              </span>
              <span className="ddbc-creature-block__attribute-data-extra">
                ({DiceUtils.renderDice(hitPointDice)})
              </span>
            </span>
          </div>
          <div className="ddbc-creature-block__attribute">
            <span className="ddbc-creature-block__attribute-label">Speed</span>
            <span className="ddbc-creature-block__attribute-data">
              <span className="ddbc-creature-block__attribute-data-value">
                {this.renderSpeed()}
              </span>
            </span>
          </div>
        </div>
        {this.renderSeparator()}
        {this.renderStats()}
        {this.renderSeparator()}
        <div className="ddbc-creature-block__tidbits">
          {this.renderSavingThrowTidbits()}
          {this.renderSkillTidbits()}
          {this.renderDamageVulnerabilityTidbits()}
          {this.renderDamageResistanceTidbits()}
          {this.renderDamageImmunityTidbits()}
          {this.renderConditionImmunityTidbits()}
          {this.renderSenseTidbits()}
          {this.renderLanguageTidbits()}
          {this.renderChallengeTidbits()}
        </div>
        {this.renderSeparator()}
        <div className="ddbc-creature-block__description-blocks">
          {this.renderSpecialDescription()}
          {this.renderActions()}
          {this.renderDescription(
            "Reactions",
            CreatureUtils.getReactionsDescription(creature)
          )}
          {this.renderLegendaryActions()}
        </div>
      </div>
    );
  }
}
