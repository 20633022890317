import uniqueId from "lodash/uniqueId";
import React from "react";

interface Props {
  type: string;
  placeholder?: string;
  label: string;
  onChange?: (value: string | number | null) => void;
  onBlur?: (value: string | number | null) => void;
  initialValue: string | number | null;
  inputAttributes: any; //TODO input attributes
  transformValueOnChange: (value: string) => any;
  transformValueOnBlur: (value: string) => any;
  maxLength: number;
}
interface State {
  uniqueId: string;
  value: string | number | null;
}
export default class FormInputField extends React.PureComponent<Props, State> {
  static defaultProps = {
    type: "text",
    initialValue: "",
    inputAttributes: {},
    transformValueOnChange: (value) => value,
    transformValueOnBlur: (value) => value,
    maxLength: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      uniqueId: uniqueId("qry_"),
      value: props.initialValue === null ? "" : props.initialValue,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
      this.setState({
        value: initialValue === null ? "" : initialValue,
      });
    }
  }

  handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, transformValueOnChange } = this.props;
    let { value } = evt.target;

    value = transformValueOnChange(value);

    if (onChange) {
      onChange(value);
    }

    this.setState({
      value,
    });
  };

  handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur, transformValueOnBlur } = this.props;
    let { value } = evt.target;

    value = transformValueOnBlur(value);

    if (onBlur) {
      onBlur(value);
    }

    this.setState({
      value,
    });
  };

  render() {
    const { uniqueId, value } = this.state;
    const { type, placeholder, label, inputAttributes, maxLength } = this.props;

    return (
      <div className="builder-field form-input-field">
        <span className="builder-field-label">
          <label
            className="builder-field-heading form-input-field-label"
            htmlFor={uniqueId}
          >
            {label}
          </label>
        </span>
        <span className="builder-field-input">
          <input
            className="builder-field-value"
            id={uniqueId}
            type={type}
            placeholder={placeholder}
            onChange={this.handleOnChange}
            onBlur={this.handleOnBlur}
            value={value === null ? "" : value}
            maxLength={maxLength}
            {...inputAttributes}
          />
        </span>
      </div>
    );
  }
}
