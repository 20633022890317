import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class ShortRestSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-short-rest-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 75.00001 91.37608">
        <path
          fill={fillColor}
          d="M58.745,34.053c-8.41376-5.41754,0-15.47864,0-15.47864C44.08877,22.05707,44.77651,32,44.77651,32c-4.07116-2.51532-3.67328-7.23413.94074-16.52136S37.032,0,37.032,0s12.21356,13.15686-8.68518,24.76587-8.68518,24.76587-8.68518,24.76587A16.47186,16.47186,0,0,1,6.63387,43.34027c0,10.289,10.45673,17.73334,21.37586,20.491a17.23033,17.23033,0,0,1-2.96533-7.19837c-.04621-.27893.12744-.6784.48211-.63293a17.25615,17.25615,0,0,1,8.43189,3.63074,21.50626,21.50626,0,0,0,1.09772-5.22364c.37316-4.59375-1.6828-8.0484-4.21137-11.65478-.27563-.39319.18073-.80023.5647-.7345a12.743,12.743,0,0,1,10.2716,10.49347c4.52625-1.53119,6.34034-7.317,6.42878-11.678.01117-.55072.86242-.697.98217-.13293,1.39893,6.59,6.35651,14.61285,2.942,21.23028C66.09347,54.97485,65.35421,38.30859,58.745,34.053Z"
        />
        <path
          fill={fillColor}
          d="M4.3,73.47608l10,2.7,18.3-5s-27.2-7.4-27.7-7.4a4.908,4.908,0,0,0-4.9,4.9A4.78148,4.78148,0,0,0,4.3,73.47608Z"
        />
        <path
          fill={fillColor}
          d="M70.1,63.77607c-.7,0-65.8,17.9-65.8,17.9a4.83787,4.83787,0,0,0-4.1,4.8,4.908,4.908,0,0,0,4.9,4.9c.6,0,65.8-17.9,65.8-17.9a4.83788,4.83788,0,0,0,4.1-4.8A4.908,4.908,0,0,0,70.1,63.77607Z"
        />
        <path
          fill={fillColor}
          d="M70.9,81.67607l-10.3-2.8-18.3,5s27.2,7.5,27.8,7.5a4.8815,4.8815,0,0,0,.8-9.7Z"
        />
      </BaseSvg>
    );
  }
}
