import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiLink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { has, sortBy } from "lodash";
import React, { useState } from "react";

import { Select } from "@dndbeyond/character-components/es";
import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiResponse,
  HtmlSelectOption,
  Race,
  RaceDefinitionContract,
  RuleData,
  RuleDataUtils,
} from "@dndbeyond/character-rules-engine/es";

import { Link } from "~/components/Link";
import { Toggle } from "~/components/Toggle";
import { Tooltip } from "~/components/Tooltip";

import SpeciesFilterListing from "../../../Shared/components/SpeciesFilterListing";

const HOMEBREW_ID = "HOMEBREW";

interface Props {
  loadSpecies: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<RaceDefinitionContract[]>>;
  onSpeciesSelected: (species: RaceDefinitionContract) => void;
  currentSpecies?: Race | null;
  ruleData: RuleData;
}
export const SpeciesChooser: React.FC<Props> = ({
  loadSpecies,
  onSpeciesSelected,
  currentSpecies = null,
  ruleData,
}) => {
  const [source, setSource] = useState<string | null>(null);
  const [sourceOptions, setSourceOptions] = useState<HtmlSelectOption[]>([]);
  const [isLegacyShowing, setIsLegacyShowing] = useState<boolean>(true);
  const [hasLegacyOptions, setHasLegacyOptions] = useState<boolean>(false);

  const handleSpeciesLoad = (species: RaceDefinitionContract[]) => {
    const itemSourceIds = species.reduce((acc: number[], species) => {
      const { sources } = species;
      if (sources) {
        sources.forEach((sourceMapping) => {
          if (!acc.includes(sourceMapping.sourceId)) {
            acc.push(sourceMapping.sourceId);
          }
        });
      }

      return acc;
    }, []);

    const sourceOptions = RuleDataUtils.getSources(ruleData)
      .filter((source) => itemSourceIds.includes(source.id))
      .map((source) => ({
        value: "" + source.id,
        label: source.description,
      }));
    const sortedSourceOptions = sortBy(sourceOptions, ["label"]);

    let speciesFilterOptions: HtmlSelectOption[] = [];
    if (species.find((species) => species.isHomebrew)) {
      speciesFilterOptions.push({
        label: "Homebrew",
        value: HOMEBREW_ID,
      });
    }

    setHasLegacyOptions(species.some((species) => has(species, "isLegacy")));

    if (sortedSourceOptions.length) {
      speciesFilterOptions.push(...sortedSourceOptions);
    }

    setSourceOptions(speciesFilterOptions);
  };

  return (
    <div className="race-chooser">
      {sourceOptions.length > 0 && (
        <div className="builder-field-heading">Filter Species Source(s)</div>
      )}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        spacing={{ xs: 1, sm: 0 }}
      >
        {sourceOptions.length > 0 && (
          <div className="race-chooser-source">
            <div className="builder-field-input">
              <Select
                options={sourceOptions}
                onChange={(value) => setSource(value)}
                value={source}
                placeholder={"-- All Sources --"}
              />
            </div>
          </div>
        )}
        {hasLegacyOptions && (
          <div className="race-chooser-legacy__toggle">
            <Toggle
              checked={isLegacyShowing}
              onClick={() =>
                setIsLegacyShowing((isLegacyShowing) => !isLegacyShowing)
              }
              color="secondary"
            />
            <label>Show legacy content</label>
            <InfoOutlinedIcon
              data-tooltip-id="new"
              data-tooltip-place="bottom"
              sx={{
                color: "#0000008A",
                fontSize: "14px",
                "&:hover": { cursor: "help" },
              }}
            />
            <Tooltip id="new" clickable>
              Legacy content doesn't reflect the latest rules and lore.{" "}
              <MuiLink
                sx={{ color: "info.main" }}
                href="https://dndbeyond.com/legacy"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                Learn More
              </MuiLink>
            </Tooltip>
          </div>
        )}
      </Stack>
      <div className="race-chooser__marketplace-callout">
        Looking for something not in the list below? Unlock all official options
        in the <Link href="/marketplace">Marketplace</Link>.
      </div>
      <SpeciesFilterListing
        isLegacyShowing={isLegacyShowing}
        loadSpecies={loadSpecies}
        onLoadSpecies={handleSpeciesLoad}
        currentSpecies={currentSpecies}
        currentSourceFilter={source}
        onSpeciesSelected={onSpeciesSelected}
        ruleData={ruleData}
      />
    </div>
  );
};

export default SpeciesChooser;
