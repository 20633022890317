import * as React from "react";

import BaseSvg, { BaseSvgProps } from "../../../BaseSvg";

export default class EquipmentSvg extends React.PureComponent<BaseSvgProps> {
  static defaultProps = {
    className: "",
  };

  render() {
    const { className, fillColor } = this.props;

    return (
      <BaseSvg viewBox="0 0 172 135" className={className}>
        <defs>
          <polygon
            id="icon---equipment-copy@2x-a"
            points="0 .614 77.491 .614 77.491 134.399 0 134.399"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill={fillColor}
            d="M9.023232 60.475968L8.984832 61.704768 8.984832 61.935168 40.434432 63.509568 67.237632 64.815168 67.237632 60.475968C67.237632 60.130368 67.237632 59.784768 67.199232 59.435328L58.063872 54.907968 66.124032 49.493568C62.706432 29.909568 51.378432 15.317568 38.360832 15.163968L38.130432 15.163968C27.032832 15.163968 17.202432 25.570368 12.325632 40.661568L31.141632 42.888768 11.404032 43.810368C9.983232 48.955968 9.176832 54.600768 9.023232 60.475968M171.2256 59.3232C170.6496 38.5872 161.2416 20.7312 147.7248 11.976 156.5952 23.496 162.3552 40.5456 162.7776 59.9376L162.7776 64.008 171.264 63.8928 171.264 59.3232 171.2256 59.3232z"
          />
          <polygon
            fill={fillColor}
            points="162.777 103.714 162.815 127.752 162.815 129.365 171.263 128.866 171.263 67.733 162.777 67.848"
          />
          <g transform="translate(0 .226)">
            <mask id="icon---equipment-copy@2x-b" fill="#fff">
              <use xlinkHref="#icon---equipment-copy@2x-a" />
            </mask>
            <path
              fill={fillColor}
              d="M44.2752,0.959232 L40.704,0.767232 L40.6272,0.767232 L38.1312,0.613632 C17.5488,0.613632 0.768,26.341632 0.0384,58.559232 L0,58.559232 L0,126.412032 L76.2624,134.399232 L77.4912,134.322432 L77.4912,127.064832 L77.4912,68.696832 L76.2624,68.696832 L73.4208,68.735232 L71.1168,68.620032 L71.1168,124.108032 L70.4256,124.031232 L68.4672,123.839232 L5.1456,117.234432 L5.2608,58.290432 C6.4896,31.679232 20.6976,11.096832 38.1312,11.096832 L38.208,11.096832 C54.1056,11.135232 67.3536,28.338432 70.4256,51.608832 C70.7328,53.797632 70.9248,56.024832 71.04,58.290432 L71.1168,58.290432 L71.1168,64.780032 L73.4976,64.895232 L76.2624,64.856832 L77.4912,64.856832 L77.4912,59.749632 C77.2224,47.500032 74.6112,36.133632 70.4256,26.687232 C64.3584,13.016832 54.9504,3.340032 44.2752,0.959232"
              mask="url(#icon---equipment-copy@2x-b)"
            />
          </g>
          <path
            fill={fillColor}
            d="M131.520768,74.9136 C134.554368,74.9136 136.973568,77.3712 136.973568,80.4048 C136.973568,82.2096 136.086528,83.8224 134.707968,84.8208 L134.707968,91.9248 L128.099328,92.5776 L128.099328,84.6288 C126.874368,83.6304 126.067968,82.0944 126.067968,80.4048 C126.067968,77.3712 128.487168,74.9136 131.520768,74.9136 Z M144.269568,98.3376 L144.269568,69 L116.045568,69.4224 L116.045568,100.0656 L144.269568,98.3376 Z"
          />
          <polygon
            fill={fillColor}
            points="67.276 102.791 67.237 68.654 40.588 67.348 8.984 65.774 8.984 87.124 31.141 90.618 8.984 93.114 8.984 95.457 23.768 96.302 8.984 99.45 8.984 114.004 65.356 119.879 67.276 120.071 67.276 108.782 56.101 107.322 56.101 107.284 38.13 104.942 53.528 103.828"
          />
          <polygon
            fill={fillColor}
            points="158.937 92.04 158.937 91.464 158.937 67.886 150.758 68.002 148.112 68.002 148.112 101.986 112.204 104.136 112.204 68.462 108.786 68.501 93.81 68.693 93.81 83.323 103.986 84.59 93.81 85.819 93.81 114.235 108.825 116.462 108.825 116.539 93.81 117.998 93.81 118.536 93.81 120.494 100.377 120.878 93.81 122.837 93.81 129.941 93.81 133.55 158.975 129.595 158.975 127.214 158.975 114.926 150.335 113.851 158.975 110.357"
          />
          <path
            fill={fillColor}
            d="M88.283136,44.077248 C85.322496,29.062848 78.794496,16.429248 70.427136,8.749248 C67.163136,5.715648 63.588096,3.450048 59.828736,2.106048 L55.562496,1.875648 C61.249536,5.562048 66.314496,11.168448 70.427136,18.157248 C76.874496,29.216448 80.948736,43.808448 81.328896,59.936448 L81.328896,65.005248 L86.128896,64.966848 L89.968896,64.928448 L89.968896,59.974848 C89.853696,54.483648 89.243136,49.184448 88.283136,44.115648 L88.283136,44.077248 Z"
          />
          <path
            fill={fillColor}
            d="M93.810816,64.852416 L108.786816,64.660416 L149.375616,64.161216 L158.937216,64.046016 L158.937216,59.975616 C158.860416,55.905216 158.476416,51.911616 157.900416,48.071616 L143.692416,48.263616 L143.500416,47.687616 L157.405056,44.961216 C157.251456,44.193216 157.094016,43.386816 156.902016,42.618816 L148.492416,41.658816 L156.210816,39.623616 C152.758656,25.799616 146.226816,14.356416 138.051456,7.521216 C135.052416,6.638016 131.942016,6.177216 128.716416,6.177216 L90.051456,3.911616 L90.009216,3.911616 L70.425216,2.759616 L69.238656,2.682816 C69.657216,2.951616 70.045056,3.258816 70.425216,3.566016 C77.682816,9.095616 83.711616,17.774016 87.858816,28.449216 L94.310016,28.871616 L104.758656,29.524416 L116.390016,30.254016 L105.330816,31.329216 L92.390016,32.596416 L89.433216,32.865216 C89.817216,34.170816 90.239616,35.476416 90.585216,36.820416 C92.505216,43.962816 93.618816,51.758016 93.810816,59.937216 L93.810816,64.852416 Z"
          />
          <polygon
            fill={fillColor}
            points="81.331 68.846 81.331 127.905 81.331 134.318 89.971 133.78 89.971 129.288 89.971 117.998 89.971 68.769 86.131 68.808"
          />
        </g>
      </BaseSvg>
    );
  }
}
