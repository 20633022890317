export const CHARACTER_SET = 'character.CHARACTER_SET';
export const CHARACTER_COMPONENTS_SET = 'character.CHARACTER_COMPONENTS_SET';
export const CHARACTER_COMPONENTS_SET_COMMIT = 'character.CHARACTER_COMPONENTS_SET_COMMIT';
export const ACTIVE_SOURCE_CATEGORIES_SET = 'character.ACTIVE_SOURCE_CATEGORIES_SET';
export const ACTIVE_SOURCE_CATEGORIES_SET_COMMIT = 'character.ACTIVE_SOURCE_CATEGORIES_SET_COMMIT';
export const ACTIVE_SOURCES_SET = 'character.ACTIVE_SOURCES_SET';
export const ACTIVE_SOURCES_SET_COMMIT = 'character.ACTIVE_SOURCES_SET_COMMIT';
export const PREFERENCE_CHOOSE = 'character.PREFERENCE_CHOOSE';
export const PREFERENCE_SET = 'character.PREFERENCE_SET';
export const PREFERENCE_SET_COMMIT = 'character.PREFERENCE_SET_COMMIT';
export const SHARE_URL = 'character.SHARE_URL';
export const SEND_SOCIAL_IMAGE_DATA = 'character.SEND_SOCIAL_IMAGE_DATA';
export const GENERATE_SHARE_LINK_REQUEST = 'character.GENERATE_SHARE_LINK_REQUEST';
export const GENERATE_SHARE_LINK_COMMIT = 'character.GENERATE_SHARE_LINK_COMMIT';
