import { asDarkModeNegativeSvg, asNegativeSvg } from "../../hocs";
import VulnerabilitySvg from "./VulnerabilitySvg";

const NegativeVulnerabilitySvg = asNegativeSvg(VulnerabilitySvg);
const DarkModeNegativeVulnerabilitySvg =
  asDarkModeNegativeSvg(VulnerabilitySvg);

export default VulnerabilitySvg;
export {
  VulnerabilitySvg,
  NegativeVulnerabilitySvg,
  DarkModeNegativeVulnerabilitySvg,
};
