import React from "react";
import ReactDOM from "react-dom";
import sanitizeHtml from "sanitize-html";

export class TextareaContentEditable extends React.PureComponent<{
  className: string;
  content: string;
  placeholder: string;
  onInputBlur: (text: string) => void;
}> {
  static defaultProps = {
    className: "",
  };

  getTextValue = (): string => {
    let node = ReactDOM.findDOMNode(this);
    if (node === null) {
      return "";
    }

    return (node as HTMLElement).innerText;
  };

  focus = () => {
    const node = ReactDOM.findDOMNode(this);
    if (node === null) {
      return;
    }

    (node as HTMLElement).focus();
    this.placeCaretAtEnd(node);
  };

  placeCaretAtEnd = (el) => {
    el.focus();
    if (
      typeof window.getSelection !== "undefined" &&
      typeof document.createRange !== "undefined"
    ) {
      let range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      let sel = window.getSelection();
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else if (typeof (document.body as any).createTextRange !== "undefined") {
      let textRange = (document.body as any).createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  };

  handleBlur = (evt) => {
    const { onInputBlur } = this.props;

    if (onInputBlur) {
      onInputBlur(this.getTextValue());
    }
  };

  render() {
    const { content, placeholder, className } = this.props;

    return (
      <div
        className={className}
        contentEditable={true}
        placeholder={placeholder}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, {
            allowedTags: [],
            allowedAttributes: {},
          }),
        }}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default TextareaContentEditable;
