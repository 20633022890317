import { visuallyHidden } from "@mui/utils";
import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  CharacterTheme,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { sidebarActions } from "../../../../Shared/actions";
import { appEnvSelectors } from "../../../../Shared/selectors";
import { PaneComponentEnum } from "../../../../Shared/utils";
import SubsectionTablet from "../../../components/SubsectionTablet";
import TabletBox from "../../../components/TabletBox";
import { SheetAppState } from "../../../typings";
import Equipment from "../../Equipment";

interface Props extends DispatchProp {
  isReadonly: boolean;
  theme: CharacterTheme;
}
class EquipmentTablet extends React.PureComponent<Props> {
  handleManageClick = (): void => {
    const { dispatch, isReadonly } = this.props;

    if (!isReadonly) {
      dispatch(
        sidebarActions.paneHistoryStart(PaneComponentEnum.EQUIPMENT_MANAGE)
      );
    }
  };

  render() {
    const { isReadonly, theme } = this.props;

    return (
      <SubsectionTablet>
        <TabletBox
          header="Inventory"
          className="ct-equipment-tablet"
          onHeaderClick={this.handleManageClick}
          isReadonly={isReadonly}
          theme={theme}
        >
          <section>
            <h2 style={visuallyHidden}>Inventory</h2>
            <Equipment />
          </section>
        </TabletBox>
      </SubsectionTablet>
    );
  }
}

function mapStateToProps(state: SheetAppState) {
  return {
    isReadonly: appEnvSelectors.getIsReadonly(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(EquipmentTablet);
