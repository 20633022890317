import React from "react";
import { DispatchProp } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";

import {
  ApiAdapterPromise,
  ApiAdapterRequestConfig,
  ApiResponse,
  CharacterPreferences,
  CharClass,
  ClassDefinitionContract,
  Constants,
  PrerequisiteData,
  RuleData,
  RuleDataUtils,
  rulesEngineSelectors,
} from "@dndbeyond/character-rules-engine/es";

import { modalActions } from "../../../../Shared/actions";
import { apiCreatorSelectors } from "../../../../Shared/selectors";
import { builderActions } from "../../../actions/builder";
import Button from "../../../components/Button";
import { ClassChooser } from "../../../components/ClassChooser";
import { Page } from "../../../components/Page";
import { PageBody } from "../../../components/PageBody";
import { PageHeader } from "../../../components/PageHeader";
import { navigationConfig } from "../../../config";
import { RouteKey } from "../../../constants/navigation";
import ConnectedBuilderPage from "../ConnectedBuilderPage";

interface Props extends DispatchProp {
  ruleData: RuleData;
  currentLevel: number;
  totalClassLevel: number;
  loadClasses: (
    additionalConfig?: Partial<ApiAdapterRequestConfig>
  ) => ApiAdapterPromise<ApiResponse<Array<ClassDefinitionContract>>>;
  classes: Array<CharClass>;
  startingClass: CharClass | null;
  preferences: CharacterPreferences;
  prerequisiteData: PrerequisiteData;
  navigate: NavigateFunction;
  characterId: number;
}
class ClassChoose extends React.PureComponent<Props> {
  handleClassSelected = (entity: any): void => {
    const { dispatch } = this.props;

    dispatch(builderActions.confirmClassSet(entity));
    dispatch(modalActions.open("class-choose-confirm"));
    window.scrollTo(0, 0);
  };

  handleNavigateToManage = (): void => {
    const { navigate, characterId } = this.props;

    navigate(
      navigationConfig
        .getRouteDefPath(RouteKey.CLASS_MANAGE)
        .replace(":characterId", characterId)
    );
  };

  renderClassesManageLinkUi = (): React.ReactNode => {
    const { classes } = this.props;

    return (
      <div>
        <div className="class-choose-classes-manage">
          <div className="class-choose-classes-manage__message">
            <strong>Your character is already level 20. Huzzah!</strong>
            <span className="class-choose-classes-manage__message-text">
              You are unable to choose any more classes.
            </span>
          </div>
          <div className="class-choose-classes-manage__link">
            <Button onClick={this.handleNavigateToManage} size={"large"}>
              {`Manage your current Class${classes.length > 1 ? "es" : ""}`}
            </Button>
          </div>
          <div className="class-choose-classes-manage__image" />
        </div>
      </div>
    );
  };

  renderClassChooserUi = (): React.ReactNode => {
    const {
      ruleData,
      totalClassLevel,
      currentLevel,
      preferences,
      loadClasses,
      classes,
      prerequisiteData,
      startingClass,
    } = this.props;

    let xpNotificationNode: React.ReactNode;
    if (
      classes.length > 0 &&
      preferences.progressionType ===
        Constants.PreferenceProgressionTypeEnum.XP &&
      totalClassLevel + 1 > currentLevel
    ) {
      xpNotificationNode = (
        <div className="class-choose-messages">
          <div className="message message-warning">
            Your XP total will be adjusted when you add your new class.
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <PageHeader>Choose a Class</PageHeader>
        {classes.length > 0 && (
          <div className="class-choose-info">
            {xpNotificationNode}
            <div className="class-choose-actions">
              <Button onClick={this.handleNavigateToManage}>
                Cancel Class Add
              </Button>
            </div>
          </div>
        )}
        <ClassChooser
          sourceLookup={RuleDataUtils.getSourceDataLookup(ruleData)}
          loadClasses={loadClasses}
          onClassSelected={this.handleClassSelected}
          classes={classes}
          startingClass={startingClass}
          preferences={preferences}
          prerequisiteData={prerequisiteData}
        />
      </React.Fragment>
    );
  };

  render() {
    const { ruleData, totalClassLevel } = this.props;

    const levelsRemaining: number = Math.max(
      0,
      RuleDataUtils.getMaxCharacterLevel(ruleData) - totalClassLevel
    );

    return (
      <Page clsNames={["class-choose"]}>
        <PageBody>
          {levelsRemaining !== 0
            ? this.renderClassChooserUi()
            : this.renderClassesManageLinkUi()}
        </PageBody>
      </Page>
    );
  }
}

const ClassChooseWithHooks = (props) => {
  const navigate = useNavigate();
  return <ClassChoose {...props} navigate={navigate} />;
};
export default ConnectedBuilderPage(
  ClassChooseWithHooks,
  RouteKey.CLASS_CHOOSE,
  (state) => {
    return {
      preferences: rulesEngineSelectors.getCharacterPreferences(state),
      prerequisiteData: rulesEngineSelectors.getPrerequisiteData(state),
      totalClassLevel: rulesEngineSelectors.getTotalClassLevel(state),
      currentLevel: rulesEngineSelectors.getCurrentLevel(state),
      classes: rulesEngineSelectors.getClasses(state),
      loadClasses: apiCreatorSelectors.makeLoadAvailableClasses(state),
      ruleData: rulesEngineSelectors.getRuleData(state),
      startingClass: rulesEngineSelectors.getStartingClass(state),
      characterId: rulesEngineSelectors.getId(state),
    };
  }
);
