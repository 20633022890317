import React from "react";

import BaseSvg from "../../BaseSvg";
import { InjectedSvgProps } from "../../hocs";

export default class SenseRowBoxSvg extends React.PureComponent<InjectedSvgProps> {
  render() {
    const { fillColor, className } = this.props;

    let classNames: Array<string> = ["ddbc-sense-row-box-svg"];

    if (className) {
      classNames.push(className);
    }

    return (
      <BaseSvg className={classNames.join(" ")} viewBox="0 0 241 34">
        <g id="SenseRowBoxSvg-Page-1_1_">
          <g
            id="SenseRowBoxSvg-Sheet_Desktop_Static"
            transform="translate(-683.000000, -651.000000)"
          >
            <path
              id="SenseRowBoxSvg-Page-1"
              fill={fillColor}
              d="M692.1,651.2c-5.7,3.8-9.1,10-9.1,16.8s3.4,13,9.1,16.8l0.3,0.2h22.1l0.3-0.2
                            c1.2-0.8,2.3-1.7,3.3-2.7h200.2l0.3-0.4c0.7-1.2,3-4.5,4.9-5.4l0.5-0.2l0-16.1l-0.5-0.2c-1.9-0.9-4.2-4.2-4.9-5.4l-0.3-0.4H718.1
                            c-1-1-2.1-1.9-3.3-2.7l-0.3-0.2h-22.1L692.1,651.2z M685.5,668c0-5.8,2.9-11.2,7.6-14.5h10.3c4.7,2.1,11.1,3.2,14.3,3.8
                            c2.3,3,3.7,6.8,3.7,10.7v0c0,3.9-1.3,7.7-3.7,10.7c-3.1,0.6-9.5,1.7-14.3,3.8h-10.3C688.4,679.2,685.5,673.8,685.5,668L685.5,668z
                             M909.3,655.6c3.1,1.1,11.1,4.5,12.9,9.3l0,6.2c-1.9,4.8-9.9,8.2-12.9,9.3H729.9c-0.8-0.7-2.5-1.8-5.7-2.1c0.9-1.5,3-5.5,3-10.3
                            s-2-8.8-3-10.3c3.2-0.3,4.9-1.4,5.7-2.1H909.3z M723.1,657.8c0.6,1,3.1,5.2,3.1,10.2s-2.5,9.2-3.1,10.2c-0.6,0-1.3,0-2,0
                            c1.8-3.1,2.9-6.6,2.9-10.3v0c0-3.7-1-7.2-2.8-10.3C721.8,657.8,722.5,657.8,723.1,657.8L723.1,657.8z M713.8,682.5h-5.3
                            c3.2-1.3,6-2.1,8.3-2.6C715.9,680.8,714.9,681.7,713.8,682.5L713.8,682.5z M720.5,679.3c3.9-0.4,6.3,0.4,7.7,1.1h-8.5
                            C719.9,680,720.2,679.7,720.5,679.3L720.5,679.3z M917.4,680.4h-5.5c3.3-1.4,8.1-3.9,10.4-7.3v1.9
                            C920.1,676.3,918.1,679.3,917.4,680.4L917.4,680.4z M922.2,661v1.9c-2.3-3.4-7.1-5.9-10.4-7.3h5.5
                            C918.1,656.7,920.1,659.7,922.2,661L922.2,661z M728.1,655.6c-1.4,0.7-3.7,1.4-7.7,1.1c-0.3-0.4-0.5-0.7-0.8-1.1H728.1z
                             M716.8,656.1c-2.4-0.5-5.1-1.3-8.3-2.6h5.3C714.9,654.3,715.9,655.2,716.8,656.1L716.8,656.1z"
            />
          </g>
        </g>
      </BaseSvg>
    );
  }
}
