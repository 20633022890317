import React from "react";
import { connect, DispatchProp } from "react-redux";

import {
  Collapsible,
  CollapsibleHeaderCallout,
  CollapsibleHeaderContent,
  CreatureBlock,
  CreatureName,
  InfusionPreview,
} from "@dndbeyond/character-components/es";
import {
  characterActions,
  CharacterTheme,
  CharacterUtils,
  Constants,
  Creature,
  CreatureUtils,
  EntityValueLookup,
  HelperUtils,
  HitPointInfo,
  InfusionChoiceLookup,
  InfusionUtils,
  RuleData,
  rulesEngineSelectors,
  serviceDataActions,
  SnippetData,
  SourceUtils,
  ValueUtils,
} from "@dndbeyond/character-rules-engine/es";

import { EditableName } from "~/components/EditableName";
import { HtmlContent } from "~/components/HtmlContent";
import { Reference } from "~/components/Reference";
import { Header } from "~/subApps/sheet/components/Sidebar/components/Header";
import { Heading } from "~/subApps/sheet/components/Sidebar/components/Heading";
import { Preview } from "~/subApps/sheet/components/Sidebar/components/Preview";

import { PaneInitFailureContent } from "../../../../../../subApps/sheet/components/Sidebar/components/PaneInitFailureContent";
import { sidebarActions } from "../../../actions/sidebar";
import CustomizeDataEditor from "../../../components/CustomizeDataEditor";
import EditorBox from "../../../components/EditorBox";
import HealthAdjuster from "../../../components/HealthAdjuster";
import ValueEditor from "../../../components/ValueEditor";
import { RemoveButton } from "../../../components/common/Button";
import { appEnvSelectors } from "../../../selectors";
import { SharedAppState } from "../../../stores/typings";
import { PaneComponentEnum, PaneIdentifierUtils } from "../../../utils";
import { PaneIdentifiersCreature } from "../../../utils/PaneIdentifier/typings";

interface Props extends DispatchProp {
  creatures: Array<Creature>;
  hitPointInfo: HitPointInfo;
  ruleData: RuleData;
  entityValueLookup: EntityValueLookup;
  snippetData: SnippetData;
  infusionChoiceLookup: InfusionChoiceLookup;

  identifiers: PaneIdentifiersCreature | null;
  isReadonly: boolean;
  proficiencyBonus: number;
  theme: CharacterTheme;
}
interface State {
  creature: Creature | null;
  newTemp: number;
  isCustomizeClosed: boolean;
}
class CreaturePane extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.generateStateData(props, true);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    const { creatures, identifiers } = this.props;

    if (
      creatures !== prevProps.creatures ||
      identifiers !== prevProps.identifiers
    ) {
      this.setState(
        this.generateStateData(this.props, prevState.isCustomizeClosed)
      );
    }
  }

  generateStateData = (props: Props, isCustomizeClosed: boolean): State => {
    const { creatures, identifiers } = props;

    let foundCreature: Creature | null | undefined = null;
    let newTemp: number = 0;
    if (identifiers !== null) {
      foundCreature = creatures.find(
        (creature) => identifiers.id === CreatureUtils.getMappingId(creature)
      );
      if (foundCreature) {
        let hitPointInfo = CreatureUtils.getHitPointInfo(foundCreature);
        newTemp = hitPointInfo.tempHp !== null ? hitPointInfo.tempHp : 0;
      }
    }

    return {
      creature: foundCreature ? foundCreature : null,
      newTemp,
      isCustomizeClosed,
    };
  };

  getData = (): Record<string, any> => {
    const { creature } = this.state;

    if (!creature) {
      return {};
    }

    return {
      name: creature.name,
      description: creature.description,
      groupId: CreatureUtils.getGroupId(creature),
    };
  };

  handleOpenCustomize = () => {
    this.setState({ isCustomizeClosed: !this.state.isCustomizeClosed });
  };

  handleTempBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (!creature) {
      return;
    }

    let id = CreatureUtils.getMappingId(creature);
    let hitPointInfo = CreatureUtils.getHitPointInfo(creature);
    let value = HelperUtils.parseInputInt(evt.target.value, 0);

    dispatch(
      characterActions.creatureHitPointsSet(
        id,
        hitPointInfo.removedHp,
        value ? value : 0
      )
    );
  };

  handleTempChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    let value = HelperUtils.parseInputInt(evt.target.value);

    this.setState({
      newTemp: value ? value : 0,
    });
  };

  handleHealthAdjusterSave = (hitPointDiff: number): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (!creature) {
      return;
    }

    let id = CreatureUtils.getMappingId(creature);
    let hitPointInfo = CreatureUtils.getHitPointInfo(creature);
    let newHitPoints = CharacterUtils.calculateHitPoints(
      hitPointInfo,
      hitPointDiff
    );

    dispatch(
      characterActions.creatureHitPointsSet(
        id,
        newHitPoints.newRemovedHp,
        newHitPoints.newTemp
      )
    );
  };

  handleSaveProperties = (properties: Record<string, any>): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (!creature) {
      return;
    }

    const data = this.getData();
    const prevData: Record<string, any> = data ? data : {};

    const newProperties: Record<string, any> = {
      ...prevData,
      ...properties,
    };

    dispatch(
      characterActions.creatureDataSet(
        CreatureUtils.getMappingId(creature),
        newProperties as any
      )
    );
  };

  handleDataUpdate = (data: Record<string, any>): void => {
    this.handleSaveProperties(data);
  };

  handleCustomDataUpdate = (
    key: number,
    value: string,
    source: string | null
  ): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (!creature) {
      return;
    }

    dispatch(
      characterActions.valueSet(
        key,
        value,
        source,
        ValueUtils.hack__toString(CreatureUtils.getMappingId(creature)),
        ValueUtils.hack__toString(
          CreatureUtils.getMappingEntityTypeId(creature)
        )
      )
    );
  };

  handleRemoveCustomizations = (): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (creature) {
      dispatch(
        characterActions.creatureCustomizationsDelete(
          CreatureUtils.getMappingId(creature),
          CreatureUtils.getMappingEntityTypeId(creature)
        )
      );
    }
  };

  handleRemove = (): void => {
    const { creature } = this.state;
    const { dispatch } = this.props;

    if (!creature) {
      return;
    }

    dispatch(sidebarActions.paneHistoryStart(PaneComponentEnum.EXTRA_MANAGE));
    dispatch(
      characterActions.creatureRemove(CreatureUtils.getMappingId(creature))
    );
  };

  handleRemoveInfusion = (): void => {
    const { dispatch } = this.props;

    const { creature } = this.state;

    if (creature === null) {
      return;
    }

    const infusion = CreatureUtils.getInfusion(creature);
    if (infusion) {
      const infusionId = InfusionUtils.getId(infusion);
      if (infusionId === null) {
        return;
      }

      const choiceKey = InfusionUtils.getChoiceKey(infusion);
      if (choiceKey !== null) {
        dispatch(
          sidebarActions.paneHistoryStart(
            PaneComponentEnum.INFUSION_CHOICE,
            PaneIdentifierUtils.generateInfusionChoice(choiceKey)
          )
        );
      }
      dispatch(
        serviceDataActions.infusionMappingDestroy(
          infusionId,
          InfusionUtils.getInventoryMappingId(infusion)
        )
      );
    }
  };

  handleInfusionClick = (): void => {
    const { dispatch } = this.props;

    const { creature } = this.state;

    if (creature === null) {
      return;
    }

    const infusion = CreatureUtils.getInfusion(creature);
    if (infusion) {
      const choiceKey = InfusionUtils.getChoiceKey(infusion);
      if (choiceKey !== null) {
        dispatch(
          sidebarActions.paneHistoryPush(
            PaneComponentEnum.INFUSION_CHOICE,
            PaneIdentifierUtils.generateInfusionChoice(choiceKey)
          )
        );
      }
    }
  };

  renderHealthAdjuster = (): React.ReactNode => {
    const { creature, newTemp } = this.state;
    const { isReadonly } = this.props;

    if (!creature) {
      return null;
    }

    let hitPointInfo = CreatureUtils.getHitPointInfo(creature);
    const tempHp = hitPointInfo.tempHp ? hitPointInfo.tempHp : 0;

    let extraNode: React.ReactNode = (
      <div className="ct-creature-pane__adjuster-summary">
        <span className="ct-creature-pane__adjuster-summary-value">
          {hitPointInfo.remainingHp + tempHp}
        </span>
        <span className="ct-creature-pane__adjuster-summary-sep">/</span>
        <span className="ct-creature-pane__adjuster-summary-value">
          {hitPointInfo.totalHp + tempHp}
        </span>
      </div>
    );

    let headerCalloutNode: React.ReactNode = (
      <CollapsibleHeaderCallout extra={extraNode} value={null} />
    );

    let headerNode: React.ReactNode = (
      <CollapsibleHeaderContent
        heading="Hit Points"
        callout={headerCalloutNode}
      />
    );

    let classNames: Array<string> = ["ct-creature-pane__adjuster"];
    if (tempHp > 0) {
      classNames.push("ct-creature-pane__adjuster--has-temp");
    }

    return (
      <div className={classNames.join(" ")}>
        <Collapsible header={headerNode}>
          <div className="ct-creature-pane__adjuster-groups">
            <div className="ct-creature-pane__adjuster-group">
              <div className="ct-creature-pane__adjuster-group-label">
                Current HP
              </div>
              <div className="ct-creature-pane__adjuster-group-value">
                {hitPointInfo.remainingHp}
              </div>
            </div>
            <div className="ct-creature-pane__adjuster-group">
              <div className="ct-creature-pane__adjuster-group-label">
                Max HP
              </div>
              <div className="ct-creature-pane__adjuster-group-value">
                {hitPointInfo.totalHp}
              </div>
            </div>
            <div className="ct-creature-pane__adjuster-group">
              <div className="ct-creature-pane__adjuster-group-label">
                Temp HP
              </div>
              <div className="ct-creature-pane__adjuster-group-value">
                <input
                  type="number"
                  onBlur={this.handleTempBlur}
                  onChange={this.handleTempChange}
                  value={newTemp === null ? "" : newTemp}
                  min={0}
                  className="ct-creature-pane__adjuster-group-input"
                  readOnly={isReadonly}
                />
              </div>
            </div>
          </div>
          {!isReadonly && (
            <HealthAdjuster
              hitPointInfo={CreatureUtils.getHitPointInfo(creature)}
              onSave={this.handleHealthAdjusterSave}
            />
          )}
        </Collapsible>
      </div>
    );
  };

  renderBlock = (): React.ReactNode => {
    const { creature } = this.state;
    const { ruleData, snippetData } = this.props;

    if (!creature) {
      return null;
    }

    return (
      <div className="ct-creature-pane__block">
        <CreatureBlock
          creature={creature}
          ruleData={ruleData}
          snippetData={snippetData}
        />
      </div>
    );
  };

  renderDescription = (
    label: React.ReactNode,
    description: string | null
  ): React.ReactNode => {
    if (!description) {
      return null;
    }

    return (
      <div className="ct-creature-pane__description">
        {label && (
          <div className="ct-creature-pane__description-heading">
            <Heading>{label}</Heading>
          </div>
        )}
        <HtmlContent
          className="ct-creature-pane__description-content"
          html={description}
          withoutTooltips
        />
      </div>
    );
  };

  renderImage = (): React.ReactNode => {
    const { creature } = this.state;

    if (!creature) {
      return null;
    }

    let largeAvatarUrl = CreatureUtils.getLargeAvatarUrl(creature);

    if (!largeAvatarUrl) {
      return null;
    }

    return (
      <div className="ct-creature-pane__full-image">
        <img
          className="ct-creature-pane__full-image-img"
          src={largeAvatarUrl}
          alt={CreatureUtils.getDefinitionName(creature)}
        />
      </div>
    );
  };

  renderTagGroup = (label: string, tags: Array<string>): React.ReactNode => {
    return (
      <div className="ct-creature-pane__tags">
        <div className="ct-creature-pane__tags-label">{label}:</div>
        <div className="ct-creature-pane__tags-list">
          {tags.map((tag) => (
            <div className="ct-creature-pane__tag" key={tag}>
              {tag}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderTags = (): React.ReactNode => {
    const { creature } = this.state;

    if (!creature) {
      return null;
    }

    const tags = CreatureUtils.getTags(creature);
    const envTags = CreatureUtils.getEnvironmentTags(creature);

    return (
      <React.Fragment>
        {tags.length ? this.renderTagGroup("Tags", tags) : null}
        {envTags.length ? this.renderTagGroup("Environments", envTags) : null}
      </React.Fragment>
    );
  };

  renderActions = (): React.ReactNode => {
    const { isReadonly } = this.props;
    const { creature } = this.state;

    if (creature === null) {
      return null;
    }

    if (isReadonly) {
      return null;
    }

    let buttonNode: React.ReactNode = (
      <RemoveButton onClick={this.handleRemove} />
    );

    const infusion = CreatureUtils.getInfusion(creature);
    if (infusion) {
      buttonNode = (
        <RemoveButton
          onClick={this.handleRemoveInfusion}
          data-testid="remove-infusion-button"
        >
          Remove Infusion
        </RemoveButton>
      );
    }

    return (
      <div className="ct-creature-pane__actions">
        <div className="ct-creature-pane__action">{buttonNode}</div>
      </div>
    );
  };

  renderCustomize = (): React.ReactNode => {
    const { creature, isCustomizeClosed } = this.state;
    const { entityValueLookup, isReadonly, ruleData } = this.props;

    if (isReadonly || !creature) {
      return null;
    }

    let optionRestrictions: Record<number, Array<number> | null> = {};
    let groupInfo = CreatureUtils.getGroupInfo(creature);
    if (
      groupInfo &&
      groupInfo.monsterTypes !== null &&
      groupInfo.monsterTypes.length
    ) {
      optionRestrictions[Constants.AdjustmentTypeEnum.CREATURE_TYPE_OVERRIDE] =
        groupInfo.monsterTypes;
    }

    const isCustomized = CreatureUtils.isCustomized(creature);

    return (
      <div className="ct-creature-pane__customize">
        <Collapsible
          layoutType={"minimal"}
          header={`Customize${isCustomized ? "*" : ""}`}
          collapsed={isCustomizeClosed}
          onChangeHandler={this.handleOpenCustomize}
        >
          <EditorBox>
            <CustomizeDataEditor
              data={this.getData()}
              enableName={true}
              onDataUpdate={this.handleDataUpdate}
            />
            <ValueEditor
              dataLookup={ValueUtils.getEntityData(
                entityValueLookup,
                ValueUtils.hack__toString(CreatureUtils.getMappingId(creature)),
                ValueUtils.hack__toString(
                  CreatureUtils.getMappingEntityTypeId(creature)
                )
              )}
              onDataUpdate={this.handleCustomDataUpdate}
              valueEditors={[
                Constants.AdjustmentTypeEnum.CREATURE_SIZE,
                Constants.AdjustmentTypeEnum.CREATURE_TYPE_OVERRIDE,
                Constants.AdjustmentTypeEnum.CREATURE_ALIGNMENT,
                Constants.AdjustmentTypeEnum.CREATURE_AC,
                Constants.AdjustmentTypeEnum.CREATURE_HIT_POINTS,
                Constants.AdjustmentTypeEnum.CREATURE_NOTES,
              ]}
              labelOverrides={{
                [Constants.AdjustmentTypeEnum.CREATURE_AC]: "Armor Class",
                [Constants.AdjustmentTypeEnum.CREATURE_ALIGNMENT]: "Alignment",
                [Constants.AdjustmentTypeEnum.CREATURE_HIT_POINTS]: "Max HP",
                [Constants.AdjustmentTypeEnum.CREATURE_SIZE]: "Size",
                [Constants.AdjustmentTypeEnum.CREATURE_TYPE_OVERRIDE]: "Type",
                [Constants.AdjustmentTypeEnum.CREATURE_NOTES]: "Notes",
              }}
              layoutType={"standard"}
              optionRestrictions={optionRestrictions}
              ruleData={ruleData}
            />
            <RemoveButton
              enableConfirm={true}
              size="medium"
              style="filled"
              disabled={!isCustomized}
              isInteractive={isCustomized}
              onClick={this.handleRemoveCustomizations}
            >
              {isCustomized ? "Remove" : "No"} Customizations
            </RemoveButton>
          </EditorBox>
        </Collapsible>
      </div>
    );
  };

  renderLairDescription = (): React.ReactNode => {
    const { creature } = this.state;

    if (!creature || !CreatureUtils.canUseLairActions(creature)) {
      return null;
    }

    return this.renderDescription(
      "Lair",
      CreatureUtils.getLairDescription(creature)
    );
  };

  renderInfusionPreview = (): React.ReactNode => {
    const { creature } = this.state;
    const { snippetData, ruleData, infusionChoiceLookup, proficiencyBonus } =
      this.props;

    if (creature === null) {
      return null;
    }

    const infusion = CreatureUtils.getInfusion(creature);
    if (infusion) {
      return (
        <div className="ct-creature-pane__infusion">
          <InfusionPreview
            infusion={infusion}
            snippetData={snippetData}
            ruleData={ruleData}
            infusionChoiceLookup={infusionChoiceLookup}
            onClick={this.handleInfusionClick}
            proficiencyBonus={proficiencyBonus}
          />
        </div>
      );
    }

    return null;
  };

  renderCreatureContent = (): React.ReactNode => {
    const { theme, ruleData } = this.props;
    const { creature } = this.state;

    if (creature === null) {
      return null;
    }

    let groupInfo = CreatureUtils.getGroupInfo(creature);

    return (
      <React.Fragment>
        <Header
          parent={groupInfo ? groupInfo.name : null}
          preview={<Preview imageUrl={CreatureUtils.getAvatarUrl(creature)} />}
        >
          <EditableName onClick={this.handleOpenCustomize}>
            <CreatureName theme={theme} creature={creature} />
          </EditableName>
        </Header>
        {CreatureUtils.isHomebrew(creature) ? (
          <Reference isDarkMode={theme.isDarkMode} name="Homebrew" />
        ) : (
          SourceUtils.getSourceFullNames(
            CreatureUtils.getSources(creature),
            ruleData
          ).map((source, idx) => {
            return (
              <React.Fragment key={source}>
                {idx > 0 ? " /" : ""}{" "}
                <Reference isDarkMode={theme.isDarkMode} name={source} />
              </React.Fragment>
            );
          })
        )}
        {this.renderCustomize()}
        {this.renderHealthAdjuster()}
        {this.renderBlock()}
        {this.renderImage()}
        {this.renderTags()}
        {this.renderDescription(
          null,
          CreatureUtils.getCharacteristicsDescription(creature)
        )}
        {this.renderLairDescription()}
        {this.renderInfusionPreview()}
        {this.renderActions()}
      </React.Fragment>
    );
  };

  renderCreatureMissing = (): React.ReactNode => {
    return (
      <React.Fragment>
        <Header>Missing Creature</Header>
        <PaneInitFailureContent />
      </React.Fragment>
    );
  };

  render() {
    const { creature } = this.state;

    let contentNode: React.ReactNode;
    let key: string = "";
    if (creature === null) {
      contentNode = this.renderCreatureMissing();
    } else {
      contentNode = this.renderCreatureContent();
      key = CreatureUtils.getUniqueKey(creature);
    }

    return (
      <div className="ct-creature-pane" key={key}>
        {contentNode}
      </div>
    );
  }
}

function mapStateToProps(state: SharedAppState) {
  return {
    ruleData: rulesEngineSelectors.getRuleData(state),
    creatures: rulesEngineSelectors.getCreatures(state),
    entityValueLookup:
      rulesEngineSelectors.getCharacterValueLookupByEntity(state),
    isReadonly: appEnvSelectors.getIsReadonly(state),
    snippetData: rulesEngineSelectors.getSnippetData(state),
    infusionChoiceLookup: rulesEngineSelectors.getInfusionChoiceLookup(state),
    proficiencyBonus: rulesEngineSelectors.getProficiencyBonus(state),
    theme: rulesEngineSelectors.getCharacterTheme(state),
  };
}

export default connect(mapStateToProps)(CreaturePane);
