export const NavigationType = {
  HelpPage: "HelpPage",
  Page: "StandardPage",
  Section: "Section",
  External: "External",
};

export const RouteKey = {
  QUICK_BUILD: "ROUTE:QUICK_BUILD",
  RANDOMIZE_BUILD: "ROUTE:RANDOMIZE_BUILD",

  HOME_HELP: "ROUTE:HOME_HELP",
  HOME_INTRODUCTION: "ROUTE:HOME_INTRODUCTION",
  HOME_BASIC_INFO: "ROUTE:HOME_BASIC_INFO",

  RACE_HELP: "ROUTE:RACE_HELP",
  RACE_CHOOSE: "ROUTE:RACE_CHOOSE",
  RACE_MANAGE: "ROUTE:RACE_MANAGE",

  CLASS_HELP: "ROUTE:CLASS_HELP",
  CLASS_CHOOSE: "ROUTE:CLASS_CHOOSE",
  CLASS_MANAGE: "ROUTE:CLASS_MANAGE",

  ABILITY_SCORES_HELP: "ROUTE:ABILITY_SCORES_HELP",
  ABILITY_SCORES_MANAGE: "ROUTE:ABILITY_SCORES_MANAGE",

  DESCRIPTION_HELP: "ROUTE:DESCRIPTION_HELP",
  DESCRIPTION_MANAGE: "ROUTE:DESCRIPTION_MANAGE",

  EQUIPMENT_HELP: "ROUTE:EQUIPMENT_HELP",
  EQUIPMENT_MANAGE: "ROUTE:EQUIPMENT_MANAGE",

  WHATS_NEXT: "ROUTE:WHATS_NEXT",
};

export const SectionKey = {
  HOME: "SECTION:HOME",
  RACE: "SECTION:RACE",
  CLASS: "SECTION:CLASS",
  ABILITY_SCORES: "SECTION:ABILITY_SCORES",
  DESCRIPTION: "SECTION:DESCRIPTION",
  EQUIPMENT: "SECTION:EQUIPMENT",
  QUICK_BUILD: "SECTION:QUICK_BUILD",
  RANDOMIZE: "SECTION:RANDOMIZE",
  WHATS_NEXT: "SECTION:WHATS_NEXT",
};

export const BuilderMethod = {
  QUICK: "QUICK",
  STEP_BY_STEP: "STEP_BY_STEP",
  RANDOMIZE: "RANDOMIZE",
};
